import { Field, FieldArray, useField } from "formik";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FormikTextInput } from "../../shared/components/forms/formik/formikTextInput";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";

interface IProps {
  name: string;
}

function FormikStringArrayInput(props: IProps) {
  const { name } = props;
  const [field, meta, helpers] = useField<string[] | undefined>(name);
  const { t } = useTranslation();

  return (
    <FieldArray
      name={name}
      render={({ push, remove }) => (
        <div className="d-flex flex-column">
          {field.value?.map((homie, index) => (
            <InputGroup key={index} className="mb-3">
              <Field name={`${name}[${index}]`} component={FormikTextInput} />
              <Button variant="outline-danger" onClick={() => remove(index)}>
                <qmBaseIcons.Delete />
              </Button>
            </InputGroup>
          ))}
          <Form.Group className="mb-3">
            <Button variant="success" size="sm" onClick={() => push("")}>
              <qmBaseIcons.Plus /> {t("Add")}
            </Button>
          </Form.Group>
        </div>
      )}
    />
  );
}

export default FormikStringArrayInput;
