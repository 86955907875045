import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IAuditRequirementDTO, ICategoryDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../services/serviceContext";

import { SettingsIndexDTO } from "../../../../../Models/Settings/SettingsIndexDTO";
import { wrapApiCallWithToast } from "../../../../../pbdServices/services/Api/api-wrapper";
import AuditMatrixService from "../../../../../pbdServices/services/Audits/auditMatrixService";
import { BaseSettingsTable } from "../../../../settings/components/baseSettingsTable";
import { SettingsRoutePaths } from "../../../../settings/settingsRoutePaths";
import EnhancedSubmitButton from "../../buttons/enhancedSubmitButton";
import { BaseModalProps } from "../../modals/baseModalProps";

interface IProps extends BaseModalProps {
  connectedElements: Pick<ICategoryDTO, "id">[];
  onSubmit: (selectedIds: number[]) => Promise<void>;
  canSubmit: boolean;
  refreshParent: () => void;
}

/**
 * Generic Modal to connect claims with anything else. For example defects
 * */
function ConnectAuditRequirementsModal(props: IProps) {
  const { modal, toggle, refreshParent, onSubmit } = props;
  const { auditRequirementsApi } = useAPIs();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = React.useState(false);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [data, setData] = React.useState<IAuditRequirementDTO[]>([]);
  const [filtered, setFiltered] = React.useState<SettingsIndexDTO[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    async function getData() {
      const rawData = await auditRequirementsApi.getAll();
      const rawDataExcludeConnected = rawData.filter(
        (x) => !props.connectedElements.map((y) => y.id).includes(x.id) && !x.parentAuditRequirementId,
      );
      setData(rawDataExcludeConnected);
      const mapped = AuditMatrixService.mapAuditRequirementToSettingsDTO(rawDataExcludeConnected);
      setFiltered(mapped);
    }
    if (modal) {
      getData();
    }
  }, [props.connectedElements, modal]);

  async function handleSubmit() {
    setSubmitting(true);

    const resp = await wrapApiCallWithToast(() => onSubmit(selected));
    if (resp.isOk) {
      setSubmitting(false);
      setSelected([]);
      toggle();
      refreshParent();
    }
  }

  return (
    <Modal show={modal} onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Audit requirements")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BaseSettingsTable
          onCreateClick={() => navigate(SettingsRoutePaths.CreatePageAuditRequirements)}
          tableRows={filtered}
          setSelected={(ids) => setSelected(ids.map((x) => Number(x.id)))}
          isAlwaysSelectable
          includeTitleLink={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <EnhancedSubmitButton
          onClick={handleSubmit}
          submitting={submitting}
          disabled={selected.length == 0}
          canSubmit={props.canSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ConnectAuditRequirementsModal;
