import Linkify from "linkify-react";
import { Opts } from "linkifyjs";
import React from "react";

interface IProps {
  content: React.ReactNode;
  tagName?: string;
}

function LinkifyStringComponent({ tagName, content }: IProps) {
  const options: Opts = {
    defaultProtocol: "https",
    nl2br: true,
  };

  return (
    <Linkify tagName={tagName} options={options}>
      {content}
    </Linkify>
  );
}

export default LinkifyStringComponent;
