import { FocusEventHandler } from "react";
import useSWR from "swr";

import { useAPIs } from "../../../../../services/serviceContext";

import { LoadingComponent } from "../../loading/loadingComponent";
import CustomSelect from "./customSelect";

interface IProps {
  onChange: (id?: string | string[]) => void;
  selectedIds?: string[];
  isMulti?: boolean;
  isClearable?: boolean;
  invalid?: boolean;
  onBlur?: FocusEventHandler;
  inputId?: string;
}

function GroupSelect(props: IProps) {
  const { inputId = "groupId" } = props;
  const { groupsApi } = useAPIs();
  const { data } = useSWR("/api/inventoryCategories", () => groupsApi.getAll());

  if (!data) return <LoadingComponent />;

  return (
    <CustomSelect
      data={data.map((x) => {
        return { title: x.name, id: x.id };
      })}
      name={inputId}
      {...props}
    />
  );
}

export default GroupSelect;
