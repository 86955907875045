import { IAuditTrailControllerClient, IAuditTrailDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { TableClickCommand } from "../../../ClientApp/shared/components/tables/TableClickCommand";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";

import { AuditTrailQueryParameters } from "./models/queryParameters";

export default class AuditTrailService extends BaseExportService<IAuditTrailDTO> {
  private readonly api: IAuditTrailControllerClient;
  constructor(api: IAuditTrailControllerClient) {
    super("Audit trail");
    this.api = api;
  }

  mapToExport(x: IAuditTrailDTO): ExportType {
    return {
      id: x.id,
      actor: x.actorId,
      ipAddress: x.ipAddress,
      userAgent: x.userAgent,
      timestamp: x.timestamp,
      area: x.area,
      auditActionType: x.auditActionType,
      details: x.details,
      // technicalDetails:x.technicalDetails,
      correlationId: x.correlationId,
      tableName: x.tableName,
      keyValues: x.keyValues,
      oldValues: x.oldValues,
      newValues: x.newValues,
      service: x.service,
    };
  }

  async exportToCsvFromCommand(command: TableClickCommand, query?: AuditTrailQueryParameters): Promise<void> {
    if (!command.isAllSelected && command.selected == undefined) {
      throw Error("Wrong data");
    }

    const pageSize = command.isAllSelected
      ? GlobalQmBaseConstants.DefaultPageSize_DuringMigration
      : command.selected!.length;
    const ids = !command.isAllSelected ? command.selected?.map((x) => Number(x)) : undefined;
    const resp = await this.api.getAllQuery({ ...query, id: ids, pageSize });
    this.exportToCsv(resp.data);
  }
}
