/* eslint-disable @typescript-eslint/naming-convention */
import { EntityKey, PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

const routePrefix = "/Settings";

const generalPrefix = routePrefix + "/General";

export class SettingsRoutePaths {
  public static Home = routePrefix;
  public static General = generalPrefix;
  public static IndexPagesUnits = `${generalPrefix}/Units`;
  public static CreatePageUnits = `${generalPrefix}/Units/Create`;
  public static EditPageUnits = `${generalPrefix}/Units/Edit/:id`;
  public static HomePageCosts = `${generalPrefix}/Costs`;
  public static IndexPageCostCategories = `${generalPrefix}/Costs/CostCategories`;
  public static CreatePageCostCategories = `${generalPrefix}/Costs/CostCategories/Create`;
  public static EditPageCostCategories = `${generalPrefix}/Costs/CostCategories/Edit/:id`;
  public static IndexPageCostCenters = `${generalPrefix}/Costs/CostCenters`;
  public static CreatePageCostCenters = `${generalPrefix}/Costs/CostCenters/Create`;
  public static EditPageCostCenters = `${generalPrefix}/Costs/CostCenters/Edit/:id`;
  public static IndexPageCurrencies = `${generalPrefix}/Costs/Currencies`;
  public static IndexPageTags = `${generalPrefix}/Tags`;
  public static EditPageTags = `${generalPrefix}/Tags/Edit/:id`;
  public static CreatePageTags = `${generalPrefix}/Tags/Create`;
  public static IndexPagePlaces = `${generalPrefix}/Places`;
  public static CreatePagePlaces = `${generalPrefix}/Places/Create`;
  public static EditPagePlaces = `${generalPrefix}/Places/Edit/:id`;

  public static IndexPageFileCategories = `${generalPrefix}/Files/FileCategories`;
  public static CreatePageFileCategories = `${generalPrefix}/Files/FileCategories/Create`;
  public static EditPageFileCategories = `${generalPrefix}/Files/FileCategories/Edit/:id`;

  public static IdeaManagementHome = `${routePrefix}/${PbdModule.IdeaManagement}`;
  public static IndexPageEmployeeIdeaCategories = `${routePrefix}/${PbdModule.IdeaManagement}/EmployeeIdeaCategories`;
  public static CreatePageEmployeeIdeaCategories = `${routePrefix}/${PbdModule.IdeaManagement}/EmployeeIdeaCategories/Create`;
  public static EditPageEmployeeIdeaCategories = `${routePrefix}/${PbdModule.IdeaManagement}/EmployeeIdeaCategories/Edit/:id`;

  public static TrainingsManagementHome = `${routePrefix}/${PbdModule.TrainingManagement}`;
  public static IndexPageTrainingTypes = `${routePrefix}/${PbdModule.TrainingManagement}/TrainingTypes`;
  public static CreatePageTrainingTypes = `${routePrefix}/${PbdModule.TrainingManagement}/TrainingTypes/Create`;
  public static EditPageTrainingTypes = `${routePrefix}/${PbdModule.TrainingManagement}/TrainingTypes/Edit/:id`;

  public static IndexPageCustomFields = `${routePrefix}/:module/CustomFields`;

  public static IndexPageCustomFieldsFn = (dto: PbdModule) =>
    `${routePrefix}/:module/CustomFields`.replace(":module", dto);
  public static EditPageCustomFields = `${routePrefix}/:module/CustomFields/Edit/:id`;
  public static CreatePageCustomFields = `${routePrefix}/:module/CustomFields/Create`;

  public static IndexPageWorkflows = `${routePrefix}/:module/Workflows`;

  public static DocumentManagementHome = `${routePrefix}/DocumentManagement`;
  public static IndexPageDocumentManagementChangelogPlaceholers = `${routePrefix}/DocumentManagement/ChangelogPlaceholders`;

  public static ClaimManagementHome = `${routePrefix}/${PbdModule.ClaimManagement}`;
  public static ClaimDeadlines = `${routePrefix}/ClaimManagement/Deadlines`;
  public static ClaimNotifications = `${routePrefix}/ClaimManagement/Notifications`;

  public static AuditManagementHome = `${routePrefix}/${PbdModule.AuditManagement}`;

  public static IndexPageAuditRequirements = `${routePrefix}/${PbdModule.AuditManagement}/AuditRequirements`;
  public static CreatePageAuditRequirements = `${routePrefix}/${PbdModule.AuditManagement}/AuditRequirements/Create`;
  public static EditPageAuditRequirements = `${routePrefix}/${PbdModule.AuditManagement}/AuditRequirements/Edit/:id`;

  public static IndexPageAuditTypes = `${routePrefix}/${PbdModule.AuditManagement}/AuditTypes`;
  public static CreatePageAuditTypes = `${routePrefix}/${PbdModule.AuditManagement}/AuditTypes/Create`;
  public static EditPageAuditTypes = `${routePrefix}/${PbdModule.AuditManagement}/AuditTypes/Edit/:id`;

  public static EmployeesHome = `${routePrefix}/${PbdModule.Employees}`;

  public static TenantsHome = `${routePrefix}/${PbdModule.TenantManagement}`;

  public static CreatePageBankHolidays = `${routePrefix}/General/BankHolidays/Create`;

  public static CreatePageWorkingHours = `${routePrefix}/General/WorkingHours/Create`;

  public static IndexPageDepartments = `${routePrefix}/Employees/Departments`;
  public static CreatePageDepartment = `${routePrefix}/Employees/Departments/Create`;
  public static EditPageDepartment = `${routePrefix}/Employees/Departments/Edit/:id`;

  public static IndexPageDepartmentPosition = `${routePrefix}/Employees/DepartmentPositions`;
  public static EditPageDepartmentPositions = `${routePrefix}/Employees/DepartmentPositions/Edit/:id`;
  public static CreatePageDepartmentPositions = `${routePrefix}/Employees/DepartmentPositions/Create`;

  //public static IndexPageOpportunityCategories = `/Settings/OpportunityCategories`;

  public static IndexPageTooltips = `/Settings/Tooltips?module=OpportunityManagement`;

  public static IndexPageConnectionCategories = `${routePrefix}/General/ConnectionCategories`;

  public static MaintenanceManagementHome = `${routePrefix}/${PbdModule.MaintenanceManagement}`;
  public static IndexPageInventoryStatus = `${routePrefix}/Maintenance/InventoryStatus`;
  public static CreatePageInventoryStatus = `${routePrefix}/Maintenance/InventoryStatus/Create`;
  public static EditPageInventoryStatus = `${routePrefix}/Maintenance/InventoryStatus/Edit/:id`;

  public static IndexPageInventoryCategories = `${routePrefix}/Maintenance/InventoryCategories`;
  public static CreatePageInventoryCategories = `${routePrefix}/Maintenance/InventoryCategories/Create`;
  public static EditPageInventoryCategories = `${routePrefix}/Maintenance/InventoryCategories/Edit/:id`;

  public static IndexPageInventoryInspections = `${routePrefix}/Maintenance/InventoryInspections`;
  public static CreatePageInventoryInspections = `${routePrefix}/Maintenance/InventoryInspections/Create`;
  public static EditPageInventoryInspections = `${routePrefix}/Maintenance/InventoryInspections/Edit/:id`;

  public static DefectManagementHome = `${routePrefix}/DefectManagement`;
  public static IndexPageDefectCategories = `${routePrefix}/DefectManagement/DefectCategories`;
  public static CreatePageDefectCategories = `${routePrefix}/DefectManagement/DefectCategories/Create`;
  public static EditPageDefectCategories = `${routePrefix}/DefectManagement/DefectCategories/Edit/:id`;

  public static OpportunityManagementHome = `${routePrefix}/OpportunityManagement`;
  public static IndexPageOpportunityCategories = `${routePrefix}/OpportunityManagement/OpportunityCategories`;
  public static CreatePageOpportunityCategories = `${routePrefix}/OpportunityManagement/OpportunityCategories/Create`;
  public static EditPageOpportunityCategories = `${routePrefix}/OpportunityManagement/OpportunityCategories/Edit/:id`;

  public static TaskManagementHome = `${routePrefix}/${PbdModule.ProjectAndTaskManagement}`;
  public static TasksHome = `${routePrefix}/ToDoManagement`;

  public static GoalHome = `${routePrefix}/GoalManagement`;

  public static CrmHome = `${routePrefix}/Crm`;

  public static ProductsHome = `${routePrefix}/${PbdModule.Products}`;

  public static RoutingRulesIndexPage = `${routePrefix}/General/RoutingRules/Index`;
  public static RoutingRulesCreatePage = `${routePrefix}/General/RoutingRules/Create`;
  public static RoutingRulesEditPage = `${routePrefix}/General/RoutingRules/Edit/:id`;

  public static AppSettingsHomePage = `${routePrefix}/:pbdModule`;

  public static AbsencePlannerHomePage = `${routePrefix}/${PbdModule.AbsencePlanner}`;
  public static IndexPageAbsenceType = `${routePrefix}/${PbdModule.AbsencePlanner}/AbsenceType`;
  public static CreatePageAbsenceType = `${routePrefix}/${PbdModule.AbsencePlanner}/AbsenceType/Create`;
  public static EditPageAbsenceType = `${routePrefix}/${PbdModule.AbsencePlanner}/AbsenceType/Edit/:id`;

  public static IndexPageAbsencePolicy = `${routePrefix}/${PbdModule.AbsencePlanner}/AbsencePolicies`;
  public static CreatePageAbsencePolicy = `${routePrefix}/${PbdModule.AbsencePlanner}/AbsencePolicies/Create`;
  public static EditPageAbsencePolicy = `${routePrefix}/${PbdModule.AbsencePlanner}/AbsencePolicies/Edit/:id`;

  public static getCategoryPath(module: EntityKey, id: string) {
    switch (module) {
      case EntityKey.AbsenceType:
        return SettingsRoutePaths.EditPageAbsenceType.replace(":id", id);
      case EntityKey.AuditType:
        return SettingsRoutePaths.EditPageAuditTypes.replace(":id", id);
      case EntityKey.AuditRequirement:
        return SettingsRoutePaths.EditPageAuditRequirements.replace(":id", id);
      case EntityKey.CostCategory:
        return SettingsRoutePaths.EditPageCostCategories.replace(":id", id);
      case EntityKey.CostCenter:
        return SettingsRoutePaths.EditPageCostCenters.replace(":id", id);
      case EntityKey.DefectCategory:
        return SettingsRoutePaths.EditPageDefectCategories.replace(":id", id);
      case EntityKey.EmployeeIdeaCategory:
        return SettingsRoutePaths.EditPageEmployeeIdeaCategories.replace(":id", id);
      case EntityKey.InventoryCategory:
        return SettingsRoutePaths.EditPageInventoryCategories.replace(":id", id);
      case EntityKey.OpportunityType:
        return SettingsRoutePaths.EditPageOpportunityCategories.replace(":id", id);
      case EntityKey.TrainingType:
        return SettingsRoutePaths.EditPageTrainingTypes.replace(":id", id);
      default:
        return "";
    }
  }
}
