import _ from "lodash";
import React from "react";
import { DecodedValueMap, QueryParamConfigMap, SetQuery } from "use-query-params";

export function useLocalQueryParams<QPCMap extends QueryParamConfigMap>(
  paramConfigMap: QPCMap,
  defaultValue?: DecodedValueMap<QPCMap>,
): [DecodedValueMap<QPCMap>, SetQuery<QPCMap>] {
  const [q, setQ] = React.useState<DecodedValueMap<QPCMap>>(defaultValue ?? ({} as unknown as DecodedValueMap<QPCMap>));

  const setQuery: SetQuery<QPCMap> = React.useCallback(
    (val) => {
      const part = _.isFunction(val) ? val(q) : val;
      const newQ = {
        ...q,
        ...part,
      };
      setQ(newQ);
    },
    [q],
  );

  return [q, setQuery];
}
