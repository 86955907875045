import { t } from "i18next";
import { DateTime } from "luxon";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import useSWR from "swr";

import { OrderBy } from "@/generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { useAPIs } from "../../../services/serviceContext";
import { MeAsUser } from "../../../pbdServices/services/UserSettings/models/me-as-user";
import { ProfileRoutePaths } from "../../profile/profileRoutePaths";

import NotificationBellToggle from "./notificationBellToggle";

interface IProps {
  meAsUser: MeAsUser;
}

function getTenMinutesAgo() {
  return DateTime.now().minus({ minutes: 10 });
}

export function NotificationBellComponent(props: IProps) {
  const { meAsUser } = props;
  const { emailsApi } = useAPIs();
  const [showDropdown, setShowDropdown] = useState(false);

  const { data: notifications } = useSWR(
    "notifications",
    () => {
      return emailsApi
        .getAllQuery({ sortBy: "SentAt", orderBy: OrderBy.Desc, to: meAsUser.user.email })
        .then((resp) => resp.data);
    },
    {
      refreshInterval: GlobalQmBaseConstants.RefreshInterval_Medium,
    },
  );

  const filteredNotifications = notifications?.filter((notification) => {
    const tenMinutesAgo = getTenMinutesAgo();
    return notification.sentAt.toMillis() >= tenMinutesAgo.toMillis();
  });
  const notificationCount = filteredNotifications?.length ?? 0;

  return (
    <Dropdown align="end" show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
      <Dropdown.Toggle as={NotificationBellToggle} showBadge={notificationCount > 0} badgeCount={notificationCount} />
      <Dropdown.Menu>
        {filteredNotifications && filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification) => (
            <Dropdown.Item key={notification.id} as={Link} to={ProfileRoutePaths.Notifications}>
              {notification.subject}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item>{t("No new notifications")}</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
