import React from "react";
import { Button, ButtonGroup, Form, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../icons/qmBaseIcons";
import { EdgeTypes, EdgeTypesArray, NodeTypes, VisibleNodeTypes } from "./models/pbd-flow-utils";

interface IProps {
  onAdd: (type: NodeTypes) => void;
  onSetEdgeType: (type: EdgeTypes) => void;
  onSetEdgeAnimation: (isAnimated: boolean) => void;
  onRestore: () => void;
}

function FlowEditorToolbox(props: IProps) {
  const { t } = useTranslation();
  const { onRestore, onAdd, onSetEdgeType, onSetEdgeAnimation } = props;
  const [edgeIsAnimated, setEdgeIsAnimated] = React.useState<boolean>(false);
  const [selectedEdgeType, setSelectedEdgeType] = React.useState<EdgeTypes>();

  const handleSetEdge = (type: EdgeTypes) => {
    setSelectedEdgeType(type);
    onSetEdgeType(type);
  };

  const handleSetAnimatedEdge = (value: boolean) => {
    setEdgeIsAnimated(value);
    onSetEdgeAnimation(value);
  };

  const setEdgeButtonColor = (type?: EdgeTypes) => {
    return type == selectedEdgeType ? "primary" : "outline-primary";
  };
  return (
    <div>
      <ListGroup horizontal>
        <ListGroup.Item>
          <div>{t("Nodes")}</div>
          <ButtonGroup size="sm">
            {VisibleNodeTypes.map((x) => (
              <Button key={x} onClick={() => onAdd(x)} variant="outline-secondary">
                {t(x)}
              </Button>
            ))}
          </ButtonGroup>
        </ListGroup.Item>
        <ListGroup.Item>
          {t("Edges")}
          <ButtonGroup size="sm">
            {EdgeTypesArray.map((x) => (
              <Button variant="secondary" key={x} onClick={() => handleSetEdge(x)} color={setEdgeButtonColor(x)}>
                {t(x)}
              </Button>
            ))}
          </ButtonGroup>
        </ListGroup.Item>
        <ListGroup.Item>
          <div>{t("Animate the edge")}</div>
          <Form.Check
            type="switch"
            id="isAnimated"
            checked={edgeIsAnimated}
            onChange={(y) => handleSetAnimatedEdge(y.target.checked)}
            label={t("Animated edge")}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <div>{t("Reset to initial state")}</div>
          <Button variant="outline-primary" onClick={onRestore} size="sm">
            <qmBaseIcons.RotateRight /> {t("Reset")}
          </Button>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
}

export default FlowEditorToolbox;
