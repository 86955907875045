/* eslint-disable @typescript-eslint/naming-convention */

export class GlobalQmBaseConstants {
  public static TinyMceUrl = "/vendors/tinymce/tinymce.min.js";
  public static qmBaseDarkBlue = "#65ACE0";
  public static qmBaseLightBlue = "#29375F";
  public static MoneyIcon = "fas fa-money-bill-wave";
  public static TimeIcon = "far fa-clock";
  public static Articles = { DynamicContentTag: "_dynamicContent" };
  /**Show sort from greater then defined value */
  public static MinEntriesForSortButton = 1;
  public static MinEntriesForCollapse = 4;
  public static MaxEntriesForRecent = 10;
  public static MaxHoursUpdatedBadge = 24;
  public static MinEntriesForFilter = 5;
  public static MinEntriesForPagination = 9;
  public static DocNeckelCostCategory = "First-year-net-savings";
  public static Tasks = { MeetingMinutes: "_MeetingMinutes" };
  public static DescriptionMaxCharsBeforeCutOff = 1700;
  public static SupportMail = "SUPPORT@QMBASE.COM";
  public static QmBaseDomain = "QMBASE.COM";
  public static MaxEntriesForSettings = 100;
  public static DebounceMs = 300;
  public static DefaultPageSize = 100;
  public static MaxTitleLength = 250;
  public static MaxUserBulkCreate = 100;
  public static MissingTitle = "NotSet";
  /**
   * Unless we have a sophisticated solution we take this pageSize
   */
  public static DefaultPageSize_DuringMigration = 10000;

  public static PasswordRequiredLength = 8;

  /**
   * Medium refresh rate for dashboards or notifications.
   *
   * Currently **1** Minute
   */
  public static RefreshInterval_Medium = 60 * 1000;

  public static SecondsToDisplayCreateLink = 30 * 1000;

  public static MaxConnectionPerEntity = 100;
}
