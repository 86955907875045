import { ErrorMessage, FieldProps } from "formik";
import { FormFeedback, FormText, Input } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";

interface FormTextProps {
  id: string;
  type: InputType;
  formText?: string;
}

/**
 * id is a required prop
 * @param param0
 * @returns
 */
export const FormikCheckboxInputPlain = ({
  field,
  formText,
  form: { touched, errors },
  ...props
}: FieldProps & FormTextProps) => (
  <div>
    <Input
      invalid={!!(touched[field.name] && errors[field.name])}
      defaultChecked={field.value}
      {...field}
      {...props}
      type="checkbox"
    />
    <ErrorMessage component={FormFeedback} className="order-last" {...field} />
    {formText && <FormText color="muted">{formText}</FormText>}
  </div>
);
