import React from "react";
import { Outlet } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { AppWrapperFallbackLoading } from "@/components/ui/appWrapperFallbackLoading";

import { AppContextProvider } from "@/components/contexts/appContext";
import { AuthenticationContextProvider } from "@/components/contexts/authenticationContext";
import AuthorizationWrapper from "@/components/contexts/authorizationWrapper";
import { CustomHelmetContextProvider } from "@/components/contexts/customHelmetContext";

export function Root() {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <CustomHelmetContextProvider>
        <AuthenticationContextProvider>
          <AuthorizationWrapper>
            <React.Suspense fallback={<AppWrapperFallbackLoading />}>
              <AppContextProvider>
                <Outlet />
              </AppContextProvider>
            </React.Suspense>
          </AuthorizationWrapper>
        </AuthenticationContextProvider>
      </CustomHelmetContextProvider>
    </QueryParamProvider>
  );
}
