import { useLocation, useNavigate } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import { paths } from "@/config/paths";
import { ApiException } from "@/generatedCode/pbd-core/pbd-core-api";

import { AccountRoutePaths } from "../ClientApp/account/accountRoutePaths";
import { StartpageRoutePaths } from "../ClientApp/startpage/startpageRoutePaths";
import { useAPIs } from "../services/serviceContext";

/**
 * This hook can be used in situations where it is not sure whether the user is authenticated or not.
 */
export const useMyProfileHook = () => {
  const { userApi } = useAPIs();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, mutate, isLoading } = useSWRImmutable("/api/me/user", () => userApi.getMe(), {
    onError(err, _key, _config) {
      if (err && err instanceof ApiException) {
        console.log(err);
        if (err.status == 401) {
          navigate(AccountRoutePaths.Login);
        } else if (err.status == 404) {
          navigate(paths.error.getHref("404"));
        } else if (err.status == 400) {
          navigate(paths.error.getHref("400"));
        }
      }
    },
    onErrorRetry(err, _key, _config, _revalidate, _revalidateOpts) {
      if (err && err instanceof ApiException) {
        // Never retry on 401
        if (err.status == 401) return;
      }
    },
    onSuccess(data, _key, _config) {
      if (data.isAuthenticated && location.pathname == AccountRoutePaths.Login) {
        window.location.href = StartpageRoutePaths.StartPage;
      } else if (!data.isAuthenticated && !location.pathname.startsWith("/Account")) {
        navigate(AccountRoutePaths.Login);
      }
    },
  });

  return { data, mutate, isLoading };
};
