import { qmBaseIcons } from "../icons/qmBaseIcons";
import FormattedUserInput from "../text/formattedUserInput";

export interface IDescriptionListItemProps {
  label?: string;
  value?: string | number | boolean | JSX.Element;
  type?: "html";
}

function DescriptionListItem(props: IDescriptionListItemProps) {
  const { label, value, type } = props;
  return (
    <>
      {label && <dt>{label}</dt>}
      {value != undefined && (
        <dd>
          {type == "html" && <FormattedUserInput content={value.toString()} />}
          {type != "html" && (
            <>
              {typeof value == "boolean" ? (
                <>{value ? <qmBaseIcons.CheckSquare style={{ color: "green" }} /> : <qmBaseIcons.Square />}</>
              ) : (
                value
              )}
            </>
          )}
        </dd>
      )}
    </>
  );
}

export default DescriptionListItem;
