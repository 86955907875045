import { Field, Formik } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Label } from "reactstrap";
import * as yup from "yup";

import { ConversionFactorBase, IUnitCreateDTO, IUnitDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { requiredTitleStringSchema } from "../../../../services/validation/stringSchemas";
import { nameofFactory } from "../../../../utils/nameofFactory";
import FormikCustomForm from "../../../shared/components/forms/formik/formikCustomForm";
import { FormikNumberInputGroup } from "../../../shared/components/forms/formik/formikNumberInput";
import { FormikSelectInput, FormikSelectInputGroup } from "../../../shared/components/forms/formik/formikSelectInput";
import { FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";

const getSchema = (t: TFunction) => {
  const ValidationSchema: yup.ObjectSchema<IUnitCreateDTO> = yup.object({
    title: requiredTitleStringSchema(t),
    conversionFactor: yup.number().required(),
    conversionFactorBase: yup.mixed<ConversionFactorBase>().oneOf(Object.values(ConversionFactorBase)).required(),
  });
  return ValidationSchema;
};

interface IProps {
  itemToUpdate?: IUnitDTO;
  onSubmit: (values: IUnitCreateDTO) => Promise<void>;
  onCancel: () => void;
}

const nameof = nameofFactory<IUnitCreateDTO>();

const UnitsForm: React.FC<IProps> = (props) => {
  const { itemToUpdate, onSubmit, onCancel } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IUnitCreateDTO>((values) => onSubmit(values), [onSubmit]);

  const initialValues: IUnitCreateDTO = {
    title: itemToUpdate?.title ?? "",
    conversionFactor: itemToUpdate?.conversionFactor ?? 0,
    conversionFactorBase: itemToUpdate?.conversionFactorBase ?? ConversionFactorBase.None,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getSchema(t)}>
      {(formikBag) => (
        <FormikCustomForm formikBag={formikBag} onCancel={onCancel}>
          <FormikTextInputGroup name={nameof("title")} />
          <FormikNumberInputGroup label={t("Conversion factor")} name={nameof("conversionFactor")} />
          <FormGroup>
            <Label for="conversionFactorBase">{t("Base")}</Label>
            <Field component={FormikSelectInput} id="conversionFactorBase" name="conversionFactorBase">
              {Object.keys(ConversionFactorBase).map((x) => (
                <option key={x} value={x} defaultValue={formikBag.initialValues.conversionFactorBase}>
                  {t(x)}
                </option>
              ))}
            </Field>
          </FormGroup>
          <FormikSelectInputGroup
            label={t("Base")}
            name={nameof("conversionFactorBase")}
            options={Object.keys(ConversionFactorBase)}
          />
        </FormikCustomForm>
      )}
    </Formik>
  );
};

export default UnitsForm;
