import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const HomePageArticleSettings: React.FC = () => {
  let title = "Articles";
  const { t } = useTranslation();
  const location = useLocation();
  if (location.pathname.toLowerCase().includes("documentmanagement")) {
    title = "Document management";
  }
  return (
    <React.Fragment>
      <h2>{t(title)}</h2>
      <hr />
    </React.Fragment>
  );
};

export default HomePageArticleSettings;
