/**
 * This is based on
 * https://github.com/rehooks/local-storage
 * which is currently not working because missing ie11 support 2020-04-42
 */
export class LocalStorageHelpers {
  /**
   * Use this instead of directly using localStorage.setItem
   * in order to correctly send events within the same window.
   *
   * @example
   * ```js
   * writeStorage('hello', JSON.stringify({ name: 'world' }));
   * const { name } = JSON.parse(localStorage.getItem('hello'));
   * ```
   *
   * @export
   * @param {string} key The key to write to in the localStorage.
   * @param {string} value The value to write to in the localStorage.
   */
  static writeStorage<TValue>(key: string, value: TValue) {
    try {
      localStorage.setItem(key, typeof value === "object" ? JSON.stringify(value) : `${value}`);
    } catch (err) {
      if (err instanceof TypeError && err.message.includes("circular structure")) {
        throw new TypeError(
          "The object that was given to the writeStorage function has circular references.\n" +
            "For more information, check here: " +
            "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value",
        );
      }
      throw err;
    }
  }

  static readStorage<TValue>(key: string): TValue {
    const valueParsed = localStorage.getItem(key) == null ? null : tryParse(localStorage.getItem(key)!);
    return valueParsed;
  }

  /**
   * Use this function to delete a value from localStorage.
   *
   * @example
   * ```js
   * const user = { name: 'John', email: 'John@fakemail.com' };
   *
   * // Add a user to your localStorage
   * writeStorage('user', JSON.stringify(user));
   *
   * // This will also trigger an update to the state of your component
   * deleteFromStorage('user');
   * ```
   *
   * @export
   * @param {string} key The key of the item you wish to delete from localStorage.
   */
  static deleteFromStorage(key: string) {
    localStorage.removeItem(key);
  }
}

function tryParse(value: string) {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}
