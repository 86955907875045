import { DeepKeys } from "@tanstack/react-table";

/**
 * This should be used as default
 */
export const nameofFactory =
  <T>() =>
  (name: keyof T) =>
    name;

/**
 * This is required to generate deep (nested) keys like 'foo.bar'
 */
export const nameOfFactoryDeep =
  <T>() =>
  (name: DeepKeys<T>) =>
    name;
