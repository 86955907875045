export default class AzureFunctionStore {
  static STORAGE_KEY = "azureFunctionStore";

  static getToken() {
    const token = window.sessionStorage.getItem(AzureFunctionStore.STORAGE_KEY);
    if (!token || token == "") {
      alert("AzureFunction token is missing");
    }
    return token;
  }

  /**return code=secretKey */
  static getTokenAsQueryParameter() {
    const token = AzureFunctionStore.getToken();
    return `code=${token}`;
  }

  static setToken(token: string) {
    window.sessionStorage.setItem(AzureFunctionStore.STORAGE_KEY, token);
  }

  static removeToken(): void {
    window.sessionStorage.removeItem(AzureFunctionStore.STORAGE_KEY);
  }
}
