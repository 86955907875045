import { download, generateCsv } from "export-to-csv";

import ExportService, { ExportData } from "./exportService";

export abstract class ExportDTOService<T> {
  abstract mapExport(dto: T): ExportData;
  abstract getExportName(): string;

  exportToCsv(items: T[]) {
    const exportData = items.map((data) => this.mapExport(data));
    const options = ExportService.getOptions(this.getExportName());
    const csv = generateCsv(options)(exportData);
    download(options)(csv);
  }
}
