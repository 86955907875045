export default class JsonHelpers {
  static isJson(value: string | unknown): boolean {
    if (typeof value != "string") return false;
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  }

  static parse<T>(value: string, defaultValue?: T) {
    if (this.isJson(value)) {
      return JSON.parse(value) as T;
    } else if (defaultValue) {
      return defaultValue;
    } else {
      throw Error("No valid json");
    }
  }

  static parseUnknown<T>(value: unknown) {
    if (typeof value != "string") throw Error("No string input");
    if (this.isJson(value)) {
      return JSON.parse(value) as T;
    } else {
      console.warn("No valid json");
      throw Error("No valid json");
    }
  }

  static stringifyToCamelCase(value: string): string {
    return JSON.stringify(this.parseToCamelCase(value));
  }

  static parseToCamelCase<T>(value: string) {
    if (this.isJson(value)) {
      return JSON.parse(value, this.toCamelCase) as T;
    } else {
      console.log(value);
      throw Error("No valid json");
    }
  }

  static toCamelCase(key: string, value: any) {
    if (value && typeof value === "object") {
      for (const k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  }
}
