import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { CellProps, Column } from "react-table";

import LinkRenderer from "./linkRenderer";

interface IProps {
  id: number | string;
  /**React link */
  linkTo?: string;
}

export function getIdColumn<T extends IProps>(includeTitleLink = true, titleColumnBaseLink?: string) {
  const column: Column<T> = {
    id: "id",
    Header: <>{i18next.t("ID")}</>,
    // accessor: (x) => `#${x.id}`,
    accessor: (x) => x.id,
    // filter: "equalsHashtagId",
    Cell: ({ row }: CellProps<T>) => (
      <LinkRenderer
        renderAsLink={includeTitleLink}
        titleColumnBaseLink={titleColumnBaseLink}
        id={row.original.id}
        title={`#${row.original.id}`}
        linkTo={row.original.linkTo}
      />
    ),
  };
  return column;
}

const columnHelper = createColumnHelper<IProps>();

export const idColumn = columnHelper.accessor("id", {
  id: "id",
  header: () => <>{i18next.t("ID")}</>,
});
