import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

const options: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode instanceof Element && domNode.name == "a") {
      const href = domNode.attribs.href || "#";
      const target = domNode.attribs.target || "_self";
      const rel = domNode.attribs.rel || "noopener noreferrer";

      return (
        <a href={href} target={target} rel={rel} onClick={(e) => e.stopPropagation()}>
          {domToReact(
            //@ts-expect-error 2024-09-25 PP Forcefully overwrite type complaint.
            domNode.children,
            options,
          )}
        </a>
      );
    }

    return undefined;
  },
};

interface IProps {
  content: string;
}

export function RenderHtmlUserInput(props: IProps) {
  const { content } = props;
  return parse(content, options);
}
