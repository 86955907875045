import { VisibilityState } from "@tanstack/react-table";
import { IdType, SortingRule } from "react-table";

export class ReactTableStorageDTO<T> {
  constructor(defaultHidden?: IdType<T>[]) {
    this.pageSize = 10;
    this.hiddenColumns = defaultHidden;
    this.sortBy = [];
  }
  hiddenColumns?: IdType<T>[];
  sortBy: SortingRule<T>[];
  pageSize: number;
  columnVisibility?: VisibilityState;
}
