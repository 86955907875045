// used in Q-Monitor
export enum TimeSpans {
  ThisWeek = "ThisWeek",
  ThisMonth = "ThisMonth",
  LastMonth = "LastMonth",
  ThisQuarter = "ThisQuarter",
  LastQuarter = "LastQuarter",
  ThisYear = "ThisYear",
  YearToDate = "YearToDate",
  LastYear = "LastYear",
  All = "All",
  Custom = "Custom",
}

// used in Categorizing data by date frame (Ex: recent article views)
export enum DataFilterDateFrames {
  Today = "Today",
  Yesterday = "Yesterday",
  EarilerThisWeek = "EarilerThisWeek",
  EarilerThisMonth = "EarilerThisMonth",
  LastMonth = "LastMonth",
  EarilerThisYear = "EarilerThisYear",
}

export enum TimeGroups {
  AsDays = "AsDays",
  AsMonths = "AsMonths",
  AsYears = "AsYears",
}
