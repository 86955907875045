import ReactAvatar from "react-avatar";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  isLinkHref?: boolean;
  onClick?: () => void;
  title?: string;
  floatRight?: boolean;
}

function AvatarSpanWithoutTenant(props: IProps) {
  const { onClick } = props;
  const { t } = useTranslation();

  return (
    <div
      className={`avatarWithNameContainer${props.floatRight ? " float-end " : ""}${
        onClick != null ? " hoverInput" : ""
      }`}
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : undefined }}
      title={props.title}
    >
      <ReactAvatar
        name={t("No one selected")}
        src={`/images/ghost-solid.svg`}
        size={"32px"}
        round
        style={{ opacity: 0.5 }}
      />
      <span>{t("No one selected")}</span>
      {props.onClick && <qmBaseIcons.Pencil className="ms-1 me-1" style={{ color: "white" }} />}
    </div>
  );
}

export default AvatarSpanWithoutTenant;
