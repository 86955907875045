import { useTranslation } from "react-i18next";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { useAppContext } from "@/components/contexts/appContext";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { SettingsRoutePaths } from "../settingsRoutePaths";

function SidebarSettings() {
  const { availableModules } = useAppContext();
  const { t } = useTranslation();
  const avSettings = availableModules
    .filter((x) => x.appInfo?.settings)
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  const links: SidebarItem[] = [];
  links.push(
    new SidebarItem({
      title: t("General"),
      href: SettingsRoutePaths.General,
    }),
  );
  avSettings.forEach((x, i) => {
    links.push(new SidebarItem({ title: t(x.displayName), href: x.appInfo.settings }));
  });

  links.push(
    new SidebarItem({
      title: t("Tenants"),
      href: SettingsRoutePaths.TenantsHome,
    }),
  );

  return <SidebarNew app={PbdModule.Settings} items={links} />;
}
export default SidebarSettings;
