import { NodeOrEdgeData } from "../ClientApp/shared/components/FlowEditor/models/pbd-flow-utils";

export class FlowChartHelpers {
  static commonStyles(): React.CSSProperties {
    const style: React.CSSProperties = {
      width: "100%",
      height: "100%",
      padding: "5px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    return style;
  }

  static getStylesForCustomNodes(data: NodeOrEdgeData) {
    const style: React.CSSProperties = {
      background: "white",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "3px",
      textAlign: "center",

      backgroundColor: data.backgroundColor,
      color: data.fontColor,
      borderColor: data.borderColor,
    };

    const result: React.CSSProperties = { ...FlowChartHelpers.commonStyles(), ...style };
    return result;
  }

  static getStylesForCustomCircle(data: NodeOrEdgeData) {
    data.backgroundColor = "rgba(0, 0, 0, 0.0)";
    const style: React.CSSProperties = {
      borderWidth: "1.5px",
      borderStyle: "solid",
      borderRadius: "50%",

      backgroundColor: "rgba(0, 0, 0, 0.0)",
      color: data.fontColor,
      borderColor: data.borderColor,
    };

    const result: React.CSSProperties = { ...FlowChartHelpers.commonStyles(), ...style };
    return result;
  }

  static getStylesForCustomRhombus(data: NodeOrEdgeData) {
    data.backgroundColor = "rgba(0, 0, 0, 0.0)";
    const style: React.CSSProperties = {
      aspectRatio: 1,
      borderWidth: "1px",
      borderStyle: "solid",
      transform: "rotate(45deg)",
      backgroundColor: "rgba(0, 0, 0, 0.0)",
      color: data.fontColor,
      borderColor: data.borderColor,
    };

    const result: React.CSSProperties = { ...FlowChartHelpers.commonStyles(), ...style };
    return result;
  }

  static getStylesForParallelogram(data: NodeOrEdgeData) {
    data.backgroundColor = "rgba(0, 0, 0, 0.0)";
    const style: React.CSSProperties = {
      aspectRatio: 1 / 1,
      borderWidth: "1px",
      borderStyle: "solid",
      transform: "skew(20deg)",
      backgroundColor: "rgba(0, 0, 0, 0.0)",
      color: data.fontColor,
      borderColor: data.borderColor,
    };

    const result: React.CSSProperties = { ...FlowChartHelpers.commonStyles(), ...style };
    return result;
  }
}
