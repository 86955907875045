import React from "react";
import { useTranslation } from "react-i18next";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { EmployeeRoutePaths } from "../employeeRoutePaths";

/**
 * Employees sidebar
 * */
const SidebarEmployees: React.FC = () => {
  const { t } = useTranslation();
  const links: SidebarItem[] = [
    SidebarItem.created(EmployeeRoutePaths.CreatePage, t),
    SidebarItem.overview(EmployeeRoutePaths.IndexPage, t),
  ];
  links.push(
    new SidebarItem({
      title: t("Departments"),
      icon: qmBaseIcons.Department,
      href: EmployeeRoutePaths.IndexPageDepartments,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Positions"),
      icon: qmBaseIcons.Position,
      href: EmployeeRoutePaths.IndexPageDepartmentPositions,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Organisation chart"),
      icon: qmBaseIcons.FlowGraph,
      href: EmployeeRoutePaths.OrganizationChart,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Birthdays"),
      icon: qmBaseIcons.BirthdayCake,
      href: EmployeeRoutePaths.BirthdayPage,
    }),
  );
  return <SidebarNew app={PbdModule.Employees} items={links} />;
};

export default SidebarEmployees;
