const routePrefix = "/Audits";

export class AuditRoutePaths {
  public static HomePage = routePrefix;
  public static IndexPage = `${routePrefix}/Index`;
  public static IndexDeleted = `${routePrefix}/Index/?isDeleted=1`;
  public static CreatePage = `${routePrefix}/Create`;
  public static EditPage = `${routePrefix}/Edit/:id`;
  public static EditAccessPage = `${routePrefix}/Details/:id/Access`;
  public static MatrixPage = `${routePrefix}/AuditMatrix`;
}
