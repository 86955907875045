import { ErrorMessage, FieldHookConfig, FieldProps, getIn, useField } from "formik";
import { Form } from "react-bootstrap";
import { FormFeedback, FormText, Input } from "reactstrap";

import { IBaseFormElementProps } from "./baseFormElementProps";
import { CustomFormLabel } from "./customFormLabel";

interface FormTextProps {
  formText: string;
  autoFocus?: boolean;
}

/**Use type="textarea" to render a textarea */
export const FormikTextInput = ({
  field,
  form: { touched, errors },
  formText,
  ...props
}: FieldProps<string> & FormTextProps) => (
  <>
    <Input
      aria-label={field.name}
      invalid={getIn(errors, field.name) && getIn(touched, field.name)}
      {...field}
      {...props}
    />
    <ErrorMessage component={FormFeedback} className="order-last" {...field} />
    {formText && <FormText color="muted">{formText}</FormText>}
  </>
);

export function FormikTextInputGroup(
  props: FieldHookConfig<string> &
    IBaseFormElementProps & {
      rows?: number;
      placeholder?: string;
      hideLabel?: boolean;
      type?: "text" | "password" | "email";
      textarea?: boolean;
    },
) {
  const { formText, id, type = "text", readOnly, rows, placeholder, hideLabel, textarea } = props;
  const [field, meta] = useField(props);

  const localId = id ?? props.name;

  const invalid = meta.error != undefined && meta.touched;

  return (
    <Form.Group className="mb-3" controlId={localId}>
      {!hideLabel && <CustomFormLabel {...props} />}
      <Form.Control
        {...field}
        isInvalid={invalid}
        as={textarea ? "textarea" : undefined}
        type={type}
        readOnly={readOnly}
        rows={rows}
        placeholder={placeholder}
        autoComplete={props.autoComplete}
      />
      <ErrorMessage {...field}>
        {(msg) => (
          <Form.Control.Feedback type="invalid" className="order-last">
            {msg}
          </Form.Control.Feedback>
        )}
      </ErrorMessage>
      {formText && <Form.Text muted>{formText}</Form.Text>}
    </Form.Group>
  );
}
