import { ArticleDetailsDTO, ArticleRevisionType, IArticleDetailsDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { IStoreItemDTO, PackageDTO, PackagesClient } from "@/generatedCode/store/storeApi";

import JsonHelpers from "../../../services/Json/jsonHelpers";
import { ListHelpers } from "../../../utils/listHelpers";

export default class StoreService {
  #storePackagesClient: PackagesClient;
  constructor(packagesClient: PackagesClient) {
    this.#storePackagesClient = packagesClient;
  }

  getAllPackagesAsBuyer() {
    return this.#storePackagesClient.getAll(undefined, true).then((resp) => this.postProcessPackageResponse(resp));
  }
  /**
   * This function expects data as {Title:"Test",Content:"Test"}.
   * It will pass the Json to camelCase and returns a correct Article Object with initiated DateTime and so on
   */
  static mapArticleToDTO(data: IStoreItemDTO): IArticleDetailsDTO | undefined {
    if (!data.storeObject) return undefined;
    const camelCase = JsonHelpers.parseToCamelCase(data.storeObject);
    const articleFromJs = ArticleDetailsDTO.fromJS(camelCase);
    if (!data.storeItemFile && !data.filePathUrl) {
      articleFromJs.type = ArticleRevisionType.Html;
    }
    return articleFromJs;
  }

  postProcessPackageResponse(items: PackageDTO[]) {
    const sorted = ListHelpers.sortByString(items, (x) => x.title);
    for (const item of sorted) {
      item.packageStoreItems = ListHelpers.sortByString(item.packageStoreItems, (x) => x.title);
    }
    return sorted;
  }
}
