import { memo } from "react";
import { Handle, NodeProps, NodeResizer, Position } from "reactflow";

import { FlowChartHelpers } from "../../../../../utils/flowChartHelpers";
import { NodeOrEdgeData } from "../models/pbd-flow-utils";

function CircleNode({ data, selected }: NodeProps<NodeOrEdgeData>) {
  return (
    <>
      <NodeResizer isVisible={selected} />
      <div style={FlowChartHelpers.getStylesForCustomCircle(data)}>
        <Handle type="target" position={Position.Top} />
        {data.label && <div dangerouslySetInnerHTML={{ __html: data.label }} />}
        <Handle type="source" position={Position.Bottom} />
      </div>
    </>
  );
}

export default memo(CircleNode);
