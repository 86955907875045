import React from "react";
import { Card, CardBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { IExternalIdSetting, PbdModule, SettingType } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../../services/serviceContext";

import ExternalIdForm from "../../components/externalIdForm";

const EmployeeConfigurationPage: React.FC = () => {
  const { moduleSettingsService } = useAPIServices();
  const { moduleSettingsApi } = useAPIs();
  const { t } = useTranslation();

  const { data: externalIdSetting } = useSWR("/api/moduleSettings/employees/externalId", () =>
    moduleSettingsApi.getExternalIdSetting(PbdModule.Employees),
  );

  const updateExternalIdSetting = (dto: IExternalIdSetting) => {
    return moduleSettingsService.save(PbdModule.Employees, SettingType.ExternalId, dto);
  };

  return (
    <React.Fragment>
      <h2>{t("Employees")}</h2>
      <hr />
      <Card>
        <CardBody>
          {externalIdSetting && <ExternalIdForm onSubmit={updateExternalIdSetting} itemToUpdate={externalIdSetting} />}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default EmployeeConfigurationPage;
