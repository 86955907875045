import { useTranslation } from "react-i18next";

import { PbdModule, ProductId } from "@/generatedCode/pbd-core/pbd-core-api";

import { useAppContext } from "@/components/contexts/appContext";
import { CostRoutePaths } from "../../costs/costRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { AdminRoutePaths } from "../adminRoutePaths";

function SidebarAdmin() {
  const { productConfig } = useAppContext();
  const { t } = useTranslation();
  const links: SidebarItem[] = [];
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Gear,
      title: t("Customization"),
      href: AdminRoutePaths.IndexPageCustomization,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Users,
      title: t("Users"),
      href: AdminRoutePaths.HomePageUsers,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Key,
      title: t("Permissions"),
      href: AdminRoutePaths.HomePagePermissions,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Pencil,
      title: t("Configuration"),
      href: AdminRoutePaths.ConfigurationsIndex,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.FileUpload,
      title: t("Import"),
      href: AdminRoutePaths.ImportHomePage,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Envelope,
      title: t("Email"),
      href: AdminRoutePaths.EmailIndex,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Integration,
      title: t("Integrations"),
      href: AdminRoutePaths.HomePageIntegrations,
    }),
  );

  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Billing,
      title: t("Billing"),
      href: AdminRoutePaths.HomePageBilling,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.AuditTrail,
      title: t("Audit trail"),
      href: AdminRoutePaths.AuditTrail,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Files,
      title: t("Files"),
      href: AdminRoutePaths.IndexPageFiles,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Info,
      title: t("My information"),
      href: AdminRoutePaths.ContactPage,
    }),
  );
  links.push(SidebarItem.costs(CostRoutePaths.HomePageCostsByModule.replace(":pbdModule", PbdModule.Admin), t));
  if (productConfig.productId == ProductId.QmBase) {
    links.push(
      new SidebarItem({
        icon: qmBaseIcons.Backup,
        title: t("Backup"),
        href: AdminRoutePaths.IndexPageBackup,
      }),
    );
  }
  return <SidebarNew app={PbdModule.Admin} items={links} />;
}

export default SidebarAdmin;
