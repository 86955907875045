import { CustomFormAnswerQueryParameters } from "../../pbdServices/services/CustomFormAnswers/models/query-parameters";
import RestUtilities from "../../services/restClients/restUtilities";

const routePrefix = "/Forms";

export class FormRoutePaths {
  public static HomePage = routePrefix;
  public static IndexPageCustomFormAnswers = `${routePrefix}/CustomFormAnswers/Index`;
  public static IndexPageCustomFormAnswersFn = (qs: CustomFormAnswerQueryParameters) =>
    `${routePrefix}/CustomFormAnswers/Index${RestUtilities.getQueryString(qs)}`;
  public static CreatePageCustomFormAnswer = `${routePrefix}/CustomFormAnswers/Create`;
  public static CreatePageCustomFormAnswerFn = (customFormId?: number, customFormLinkId?: number) =>
    `${routePrefix}/CustomFormAnswers/Create${RestUtilities.getQueryString({ customFormId, customFormLinkId })}`;
  public static DetailsPageCustomFormAnswer = `${routePrefix}/CustomFormAnswers/Details/:id`;
  public static PrintPageCustomFormAnswer = "/Forms/CustomFormAnswers/Print/:id";
  public static DetailsPageCustomForm = `${routePrefix}/CustomForms/Details/:id`;
  public static DetailsPageCustomFormFn = (id: number) =>
    `${routePrefix}/CustomForms/Details/:id`.replace(":id", id.toString());
  public static IndexPageCustomForm = `${routePrefix}/CustomForms/Index`;
  public static FillOutFormRoute = `${this.IndexPageCustomForm}/?isPublished=1&view=cards`;
  public static CreatePageCustomForm = `${routePrefix}/CustomForms/Create`;
  public static EvaluationPageCustomForm = `${routePrefix}/CustomForms/Evaluation/:id`;
}
