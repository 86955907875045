import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { CommentEditDTO, ICommentDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { useAppContext } from "@/components/contexts/appContext";
import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { useToggle } from "@/hooks/useToggle";
import CommentsService from "../../../../services/Comments/commentService";
import { DateTimeLuxonHelpers } from "../../../../utils/dateTimeLuxonHelpers";
import CustomMarkdownContainer from "../../markdown/customMarkdownContainer";
import UpdatedBadge from "../badges/updatedBadge";
import { ICommentRequiredBaseApi } from "../fileUploaderCommentsTab/baseApi";
import { IBaseItem } from "../fileUploaderCommentsTab/baseItem";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import AvatarSpan from "../tenants/avatarSpan";
import AddCommentForm, { CommentForm } from "./addCommentForm";
import { CommentType } from "./comment-tree";
import "./commentStyle.css";

const commentService = new CommentsService();

interface IProps {
  replyToComment?: (content: string, parent: ICommentDTO) => Promise<void>;
  comment: CommentType;
  baseApi: ICommentRequiredBaseApi;
  item: IBaseItem;
  refreshParent: () => void;
}

function CommentItemComponent(props: IProps) {
  const { comment, refreshParent, item, baseApi, replyToComment } = props;
  const { meAsUser } = useAppContext();
  const { t } = useTranslation();
  const confirm = useConfirmation();
  const location = useLocation();
  const canReply = replyToComment !== undefined;

  const [editMode, toggleEditMode] = useToggle();
  const [replyMode, toggleReplyMode] = useToggle();

  async function handleDelete() {
    await baseApi.deleteComment?.(item.id, comment.id);
    refreshParent();
    toast.success(t("Comment deleted"));
  }

  const tryToDelete = async (dto: { id: number; content?: string }) => {
    await confirm({ itemsToDelete: [{ id: dto.id, title: dto.content }] }).then(() => handleDelete());
  };

  const editComment = async (values: CommentForm) => {
    await baseApi.editComment?.(item.id, comment.id, new CommentEditDTO({ id: comment.id, content: values.comment }));
    toggleEditMode();
    refreshParent();
  };

  comment.capabilities = commentService.getCapabilities(comment, location.pathname, meAsUser);

  const onReplyToComment = React.useCallback(
    async (x: CommentForm) => {
      await props.replyToComment?.(x.comment, comment);
      toggleReplyMode();
    },
    [comment, props, toggleReplyMode],
  );

  return (
    <>
      <div className="d-flex text-body-secondary pt-3 showBtnOnHover">
        {comment.createdBy && <AvatarSpan tenant={comment.createdBy} className="flex-shrink-0 me-2" />}
        <div className="me-2 flex-grow-1">
          {!editMode ? (
            <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
              <strong className="text-gray-dark">
                {comment.createdBy?.fullName}{" "}
                <small className="fw-lighter">
                  {DateTimeLuxonHelpers.convertUtcToTimeFromUnknown(comment.createdAt)}
                  <UpdatedBadge lastUpdatedAt={comment.lastUpdatedAt} createdAt={comment.createdAt} />
                </small>
              </strong>
              <div>
                {comment.content && !comment.isDeleted && <CustomMarkdownContainer content={comment.content} />}
                {comment.isDeleted && (
                  <span>
                    {t("Comment deleted")}{" "}
                    {comment.isDeletedAt &&
                      DateTimeLuxonHelpers.conditionallyReturnFromNowOrDateTime(comment.isDeletedAt)}
                  </span>
                )}
              </div>
            </div>
          ) : (
            <AddCommentForm onSubmit={editComment} onCancel={toggleEditMode} itemToUpdate={comment} />
          )}
        </div>
        {(comment.capabilities.canEdit || comment.capabilities.canDelete || canReply) && (
          <ButtonGroup className="hiddenBtnGroup" size="sm">
            {comment.capabilities.canEdit && baseApi.editComment && (
              <Button variant="light" onClick={toggleEditMode} title={t("Edit")}>
                <qmBaseIcons.Pencil />
              </Button>
            )}
            {canReply && (
              <Button variant="light" onClick={toggleReplyMode} title={t("Reply")}>
                <qmBaseIcons.Reply />
              </Button>
            )}
            {comment.capabilities.canDelete && baseApi.deleteComment && (
              <Button variant="light" onClick={() => tryToDelete(comment)} title={t("Delete")}>
                <qmBaseIcons.Delete />
              </Button>
            )}
          </ButtonGroup>
        )}
      </div>
      {replyMode && <AddCommentForm onSubmit={onReplyToComment} onCancel={toggleReplyMode} />}
    </>
  );
}

export default CommentItemComponent;
