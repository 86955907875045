import React from "react";
import { useTranslation } from "react-i18next";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { QualificationMatrixRoutePaths } from "../qualificationMatrixRoutePaths";

const SidebarQualificationMatrix: React.FC = () => {
  const { t } = useTranslation();
  const links: SidebarItem[] = [];
  links.push(
    new SidebarItem({
      id: "functions",
      icon: qmBaseIcons.ListSolid,
      title: t("Functions"),
      href: QualificationMatrixRoutePaths.IndexPageCompanyFunction,
    }),
  );
  links.push(
    new SidebarItem({
      id: "qualification",
      icon: qmBaseIcons.ListSolid,
      title: t("Qualifications"),
      href: QualificationMatrixRoutePaths.IndexPageQualification,
    }),
  );
  links.push(
    new SidebarItem({
      id: "jobs",
      icon: qmBaseIcons.JobDescription,
      title: t("Job descriptions"),
      href: QualificationMatrixRoutePaths.IndexPageJobDescriptions,
    }),
  );
  links.push(
    new SidebarItem({
      id: "requirements",
      icon: qmBaseIcons.School,
      title: t("Qualification requirements"),
      href: QualificationMatrixRoutePaths.IndexPageTenants,
    }),
  );
  return <SidebarNew app={PbdModule.QualificationMatrix} items={links} />;
};

export default SidebarQualificationMatrix;
