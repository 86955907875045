import { IApplicationGroupPermission } from "../../../Models/Identity/ApplicationGroupPermission";
import { IApplicationUserPermission } from "../../Users/models/application-user-permission";

export type CollaboratorType = "User" | "Group";
type TypeObject = IApplicationUserPermission | IApplicationGroupPermission;

export class Collaborator {
  constructor(type: CollaboratorType, item: TypeObject) {
    this.type = type;
    this.id = `${type}_${item.id}`;
    this._item = item;
    this.name = type;
    this.capabilities = item.capabilities;
  }
  id: string;
  type: CollaboratorType;
  name: string;
  capabilities?: string;
  private _item: TypeObject;

  getUser() {
    if (this.type === "User") {
      return this._item as IApplicationUserPermission;
    }
    return undefined;
  }
  getGroup() {
    if (this.type === "Group") {
      return this._item as IApplicationGroupPermission;
    }
    return undefined;
  }
}
