import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { AdminRoutePaths } from "../adminRoutePaths";
import SubsidebarConfiguration from "./subsidebarConfiguration";
import SubsidebarCustomization from "./subsidebarCustomization";

const AdminSubsidebar = () => {
  const location = useLocation();
  const { t } = useTranslation();

  if (location.pathname.startsWith(AdminRoutePaths.ConfigurationsIndex)) {
    return <SubsidebarConfiguration />;
  } else if (location.pathname.startsWith(AdminRoutePaths.IndexPageCustomization)) {
    return <SubsidebarCustomization />;
  } else {
    return <h1>{t("Under construction")}</h1>;
  }
};

export default AdminSubsidebar;
