import { useField } from "formik";
import { isArray } from "lodash";
import { useTranslation } from "react-i18next";
import { PropsValue } from "react-select";
import { FormFeedback } from "reactstrap";

import { IBaseDTO, PrefetchSelectDTOComponent } from "../../inputControl/select/v2/prefetch-select";

export interface FormikDTOSelectProps<
  TKey,
  TDTO extends IBaseDTO<TKey>,
  TQuery = void,
  IsMulti extends boolean = boolean,
> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SelectComponent: PrefetchSelectDTOComponent<TKey, TDTO, TQuery, IsMulti>;
  fieldName: string;
  query: TQuery;
  required?: boolean;
  onAddOption?: (txt: string) => void;
}

export function FormikDTOSelect<TKey, TDTO extends IBaseDTO<TKey>, TQuery = void, IsMulti extends boolean = boolean>(
  props: FormikDTOSelectProps<TKey, TDTO, TQuery, IsMulti>,
) {
  const [field, meta, helpers] = useField<PropsValue<TKey>>(props.fieldName);
  const { t } = useTranslation();
  const { SelectComponent } = props;

  return (
    <>
      <SelectComponent
        onChange={(v) => {
          let val = undefined;
          if (v == null) val = null;
          else if (isArray(v)) val = v.map((dto) => dto.id);
          else val = (v as TDTO).id;
          helpers.setValue(val);
        }}
        keyValue={meta.value as unknown as PropsValue<TKey>}
        query={props.query}
        onBlur={field.onBlur}
        onAddOption={props.onAddOption}
        isInvalid={meta.error !== undefined}
      />
      {meta.touched && meta.error && props.required && (
        <FormFeedback name={field.name} style={{ display: "block" }}>
          {t("This field is required")}
        </FormFeedback>
      )}
    </>
  );
}
