/**
 * This config file can used to define settings that are different based in the environment. DO NOT put any secrets inside.
 */

const dev = {
  applicationInsightsConnectionString:
    "InstrumentationKey=3a00e2b0-95e4-45f9-8489-34ecab625594;IngestionEndpoint=https://westeurope-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=3c49d8b8-1d27-4a46-aed9-fdc5e4f35ea4",
  adminApi: {
    url: "https://localhost:44390",
    apimKey: "secretKey",
  },
  appQmBaseCom: {
    url: "https://app.qmbase.com",
  },
  store: {
    baseUrl: { url: "https://localhost:44373" },
    apimKey: "devStoreApiKey",
  },
  // store: {
  //   baseUrl: { url: "https://qmbasestore.azurewebsites.net" },
  // },
  azureFunctionApp: {
    baseUrl: "http://localhost:7073/api",
    apimKey: "devStoreApiKey",
  },
  azureArmFunctionApp: {
    baseUrl: "http://localhost:7071",
    keys: {
      orderCreate: "developKey",
      orderGetAll: "developKey==",
    },
  },
};

const prod = {
  applicationInsightsConnectionString:
    "InstrumentationKey=0952ba4a-eb98-4ee9-9468-798d8eba7525;IngestionEndpoint=https://westeurope-3.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=f84c0e1f-c526-4e62-8aea-90ede57c74f1",
  adminApi: {
    url: "https://api.qmbase.com/issue-service",
    apimKey: "ce696de04a6c43f48fb1f44cd5d06f7e",
  },
  appQmBaseCom: {
    url: "https://app.qmbase.com",
  },
  store: {
    baseUrl: { url: "https://api.qmbase.com/store" },
    apimKey: "81f29aef9ab64ce3860f1f98b0f9a68e",
  },
  // PRODUCTION: change the baseUrl to the production URL
  azureFunctionApp: {
    baseUrl: "https://api.qmbase.com/marketing",
    apimKey: "7e4b75758b624007afdfbc2decaa145e",
  },
  azureArmFunctionApp: {
    baseUrl: "https://qmbase-armfunctions.azurewebsites.net",
    keys: {
      orderCreate: "9gTynwTsnZHmtcHSZjkraf4010DwR1cmH3sJkdhFneao34aTjID02g==",
      orderGetAll: "Fe5R2lakvboPrlLoHPZYKR3B4ql2wSfv6j7lRZ2yGnLccxp3XqUH5w==",
    },
  },
};

const config = process.env.NODE_ENV === "production" ? prod : dev;

/**
 * This is the base qmBase config file
 */
export default {
  // Add common config values here
  /**Atom feed for the changelog */
  changeLogUrl: "https://help.qmbase.com/blog/atom.xml",
  env: process.env.NODE_ENV,
  supportEmail: "support@qmbase.com",
  ...config,
};
