import { TinyMCE } from "tinymce";

export type EditorOptions = Parameters<TinyMCE["init"]>[0];

export type EditorOptionsCustom = EditorOptions &
  Partial<Record<"selector" | "target" | "readonly" | "license_key", undefined>> & {
    selector?: undefined;
    target?: undefined;
  };

export function getBaseTinyConfig(lng: string): EditorOptionsCustom {
  return {
    entity_encoding: "raw",
    language: lng,
    statusbar: false,
    brandings: false,
    browser_spellcheck: true,
    imagetools_toolbar: "imageoptions",
    link_title: false,
    file_picker_types: "image",
    relative_urls: false,
  };
}
