import { isNumber } from "lodash";
import { OptionProps, components } from "react-select";

import { ReactSelectDTO } from "../../../../../pbdServices/Models/ReactSelect/reactSelectDTO";
import IdComponent from "../../id/idComponent";

export function CustomOption<T extends string | number = number>(props: OptionProps<ReactSelectDTO<T>>) {
  const { isDisabled, data } = props;
  if (isDisabled) return null;
  return (
    <components.Option {...props}>
      {data.label} {isNumber(data.value) && <IdComponent id={data.value} />}
    </components.Option>
  );
}
