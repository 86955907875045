import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import { qmBaseIcons } from "../icons/qmBaseIcons";
import GenericAlert from "./genericAlert";

interface IProps {
  createLink?: string;
}

function NoEntriesAlert(props: IProps) {
  const { createLink } = props;
  const { t } = useTranslation();
  return (
    <GenericAlert type="info" className="text-center" heading={t("No entries")}>
      <p>{t("There are no entries defined so far. Create your first entry.")}</p>
      {createLink && (
        <Button tag={Link} to={props.createLink}>
          <qmBaseIcons.Plus /> {t("New")}
        </Button>
      )}
    </GenericAlert>
  );
}

export default NoEntriesAlert;
