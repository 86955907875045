import ReactAvatar from "react-avatar";
import { Link } from "react-router-dom";

import { IOrganisationMinDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { CrmRoutePaths } from "../../crm/crmRoutePaths";

interface IProps {
  organisation: IOrganisationMinDTO;
}

function AvatarSpanOrganisation(props: IProps) {
  const { organisation } = props;
  const linkTo = CrmRoutePaths.OrganisationEdit.replace(":id", organisation.id.toString());
  return (
    <div className="avatarWithNameContainer">
      <ReactAvatar name={organisation.title} size={"32px"} maxInitials={3} />
      <span className="ms-1">
        <Link to={linkTo}>{organisation.title}</Link>
      </span>
    </div>
  );
}

export default AvatarSpanOrganisation;
