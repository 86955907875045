import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { CellProps, Column } from "react-table";

import { TenantConnectedToDepartmentPosition } from "../../../Models/Tenants/TenantConnectedToDepartmentPosition";
import { EmployeeRoutePaths } from "../../employees/employeeRoutePaths";
import { ProgressBar } from "../../shared/components/progress/progressBar";
import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";
import AvatarSpanWithName from "../../shared/components/tenants/avatarSpanWithName";

type IProps = BaseTableProps<TenantConnectedToDepartmentPosition>;

export const TenantConnectedToDepartmentPositionTable: React.FC<IProps> = (props) => {
  const {
    columnsVisibleDefault = ["fullName", "department", "fitness"],
    titleColumnBaseLink = EmployeeRoutePaths.EditPage,
    localStorageStateKey = "TenantDTO",
  } = props;

  const columns = React.useMemo<Column<TenantConnectedToDepartmentPosition>[]>(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id", // accessor is the "key" in the data
        Cell: ({ row }) => (
          <>
            <Link to={titleColumnBaseLink.replace(":id", row.original.id.toString())}>#{row.original.id}</Link>
          </>
        ),
      },
      {
        id: "fullName",
        Header: <>{i18next.t("Name")}</>,
        accessor: (x) => x.fullName,
        Cell: ({ row }: CellProps<TenantConnectedToDepartmentPosition>) => (
          <AvatarSpanWithName
            linkTo={titleColumnBaseLink.replace(":id", row.original.id.toString())}
            tenant={row.original}
          />
        ),
      },
      {
        id: "fitness",
        Header: <>{i18next.t("Fitness")}</>,
        accessor: (x) => x.fitness,
        sortType: "basic",
        Cell: ({ row }: CellProps<TenantConnectedToDepartmentPosition>) => (
          <div className="d-flex">
            <div className="flex-grow-1">
              <ProgressBar currentValue={row.original.fitness ?? 0} maxValue={1} />
            </div>
            {row.original.isPrimary && (
              <i className="fa fa-star" style={{ color: "purple" }} title={i18next.t("Primary")} />
            )}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <ReactstrapTable<TenantConnectedToDepartmentPosition>
        columns={columns}
        columnsVisibleDefault={columnsVisibleDefault}
        localStorageStateKey={localStorageStateKey}
        {...props}
      />
    </React.Fragment>
  );
};
