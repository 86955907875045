import { TFunction } from "i18next";
import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import { IQuickAccessLink, PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIServices } from "../../../services/serviceContext";

import { useAppContext } from "@/components/contexts/appContext";
import { ITenantForBirthdayVM } from "../../../pbdServices/services/Tenants/models/tenant-for-birthday-vm";
import { DateTimeLuxonHelpers } from "../../../utils/dateTimeLuxonHelpers";
import LinkHelpers from "../../../utils/linkHelpers";
import { AdminRoutePaths } from "../../admin/adminRoutePaths";
import { EmployeeRoutePaths } from "../../employees/employeeRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import AvatarSpanWithName from "../../shared/components/tenants/avatarSpanWithName";
import InviteEntry from "./inviteEntry";
import ReleaseNoteEntry from "./releaseNoteEntry";
import StatusEntry from "./statusEntry";

const maxBirthdayEntries = 2;

/**
 * This returns sidebar entries for tenants with birthday.
 * Maximum 2 entries.
 * 3rd entry will be go to to see more
 * @param items
 * @returns
 */
export const getBirthdayEntries = (items: ITenantForBirthdayVM[] | undefined, t: TFunction): SidebarItem[] => {
  const links: SidebarItem[] = [];
  if (!items) return links;
  const upcomingBirthdayTenants = items.filter((x) =>
    DateTimeLuxonHelpers.checkDateIsBetweenRange(x.birthday.endOf("day"), 7),
  );
  if (upcomingBirthdayTenants.length > 0) {
    links.push({
      id: "birthdayHeading",
      component: (
        <span>
          <qmBaseIcons.BirthdayCake /> {t("Upcoming birthdays")}
        </span>
      ),
    });
    for (let i = 0; i < upcomingBirthdayTenants.slice(0, maxBirthdayEntries).length; i++) {
      const tenant = upcomingBirthdayTenants[i];
      const sidebarLink = new SidebarItem({
        component: <AvatarSpanWithName tenant={tenant} />,
        description: DateTimeLuxonHelpers.convertUtcToDate(tenant.birthday),
        href: EmployeeRoutePaths.EditPage.replace(":id", tenant.id.toString()),
      });

      links.push(sidebarLink);
    }

    if (upcomingBirthdayTenants.length > maxBirthdayEntries) {
      const restOfTenantsToShowInCalender = upcomingBirthdayTenants.length - maxBirthdayEntries;
      links.push(
        new SidebarItem({
          component: (
            <small>
              <Link to={EmployeeRoutePaths.BirthdayPage}>
                {restOfTenantsToShowInCalender} {t("More birthdays")}
              </Link>
            </small>
          ),
        }),
      );
    }
  }

  return links;
};

function getQuickAccessLinks(links: IQuickAccessLink[], t: TFunction) {
  const items = [
    new SidebarItem({
      id: "quickAccessHeading",
      component: (
        <span>
          <qmBaseIcons.Bolt style={{ color: "orange" }} /> {t("Quick access")}
        </span>
      ),
    }),
  ];
  for (const element of links.sort((a, b) => a.title.localeCompare(b.title))) {
    const isExternal = LinkHelpers.isExternal(LinkHelpers.sanitizeUrl(element.link.href));
    items.push(
      new SidebarItem({
        title: element.title,
        description: element.description,
        href: isExternal ? LinkHelpers.sanitizeUrl(element.link.href) : element.link.href,
        isExternal,
      }),
    );
  }
  items.push(
    new SidebarItem({
      id: "editQuickAccess",
      component: (
        <small>
          <Link to={AdminRoutePaths.ConfigurationsStartpage}>
            <qmBaseIcons.Pencil /> {t("Edit")}
          </Link>
        </small>
      ),
    }),
  );
  return items;
}

function SidebarStartpage() {
  const { appSettings, productConfig, meAsUser } = useAppContext();
  const { t } = useTranslation();
  const { tenantService } = useAPIServices();
  const { data: birthdayTenants } = useSWRImmutable(
    appSettings.Startpage.features.disableBirthdayCalendar ? null : "/api/tenants/birthdays",
    () => tenantService.getBirthdays(),
  );
  const itemsNew = getQuickAccessLinks(appSettings.Startpage.quickAccessLinks, t);
  itemsNew.push(...getBirthdayEntries(birthdayTenants, t));
  return (
    <SidebarNew app={PbdModule.Startpage} items={itemsNew}>
      <div style={{ bottom: "0px", position: "fixed" }}>
        <ListGroup className="bg-white" variant="flush">
          <StatusEntry />
          <ReleaseNoteEntry helpUrl={productConfig.helpLinks.changelog.url} />
          <InviteEntry meAsUser={meAsUser} productConfig={productConfig} />
        </ListGroup>
      </div>
    </SidebarNew>
  );
}

export default SidebarStartpage;
