import { Button, ButtonGroup, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ISmartViewDTO, PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import FeatureFlagService from "../../../../pbdServices/services/FeatureFlags/featureFlagService";
import SmartViewService from "../../../../pbdServices/services/smart-views/smart-view-service";
import UpdatedBadge from "../badges/updatedBadge";
import { qmBaseIcons } from "../icons/qmBaseIcons";

import AbsencePlannerSmartViewButtons from "./absencePlannerSmartViewButtons";
import QualificationMatrixSmartViewButtons from "./qualificationMatrixSmartViewButtons";

interface IProps {
  item: ISmartViewDTO;
  hideEditButtons?: boolean;
  toggleSmartViewModal: (id: string) => void;
  tryToDelete: (dto: ISmartViewDTO) => void;
  module: PbdModule;
  /**In case the smart views get rendered in modal */
  toggleParentModal?: () => void;
  isActive?: boolean;
  baseLink?: string;
}

function SmartViewItem(props: IProps) {
  const { item, hideEditButtons, toggleSmartViewModal, module, tryToDelete, toggleParentModal, isActive, baseLink } =
    props;
  const { t } = useTranslation();
  return (
    <ListGroup.Item className="d-flex justify-content-between" active={isActive}>
      <div>
        <h5>
          {!isActive ? (
            <>
              <Link
                to={
                  baseLink
                    ? baseLink + SmartViewService.getSmartViewQueryString(item)
                    : SmartViewService.linkTo(module, item)
                }
                onClick={() => toggleParentModal?.()}
              >
                {item.isShared && <qmBaseIcons.Globe className="me-1 text-secondary" title={t("Public Smart View")} />}
                {item.name}
              </Link>
              <UpdatedBadge createdAt={item.createdAt} />
            </>
          ) : (
            <>
              {item.isShared && <qmBaseIcons.Globe className="me-1 text-secondary" title={t("Public Smart View")} />}
              {item.name}
              <UpdatedBadge createdAt={item.createdAt} />
            </>
          )}
        </h5>
        {item.description != null && <p>{item.description}</p>}
        {FeatureFlagService.isRhb() && module == PbdModule.QualificationMatrix && (
          <QualificationMatrixSmartViewButtons item={item} />
        )}
        {module == PbdModule.AbsencePlanner && <AbsencePlannerSmartViewButtons item={item} />}
      </div>
      {!hideEditButtons && (
        <div>
          <ButtonGroup size="sm">
            <Button variant="outline-primary" onClick={() => toggleSmartViewModal(item.id)}>
              <qmBaseIcons.Pencil />
            </Button>
            <Button variant="outline-danger" onClick={() => tryToDelete(item)}>
              <qmBaseIcons.Delete />
            </Button>
          </ButtonGroup>
        </div>
      )}
    </ListGroup.Item>
  );
}

export default SmartViewItem;
