import { Dropdown, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { UiData } from "../../../../Constants/app-ui-data";
import { EntityDefinitionData } from "../../../../Constants/entityDefinitions";
import { CrmRoutePaths } from "../../../crm/crmRoutePaths";
import { CustomDropdownToggleButtonPlus } from "../buttons/customDropdownToggleButton";
import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  uiData?: UiData;
}

function SidebarNewEntry(props: IProps) {
  const { t } = useTranslation();
  const { uiData } = props;
  return (
    <ListGroup.Item action as="div" className="d-flex bg-body-tertiary">
      <Link to={CrmRoutePaths.CreatePageSelect} className="flex-grow-1 text-decoration-none text-reset">
        <qmBaseIcons.Plus /> {t("New")}
      </Link>
      <div>
        <Dropdown
          // align="end"
          drop="down-centered"
          // show={dropdownCreate}
          // // onMouseLeave={() => setDropdownCreate(false)}
          // onMouseOver={() => setDropdownCreate(true)}
        >
          <Dropdown.Toggle as={CustomDropdownToggleButtonPlus} variant="primary" size="sm" title={t("Context menu")} />
          <Dropdown.Menu>
            {uiData?.entityKeys?.map((x) => {
              const entityData = EntityDefinitionData[x];
              if (!entityData) {
                return <Dropdown.Item key={x}>{t("Missing data")}</Dropdown.Item>;
              }
              return (
                <Dropdown.Item key={x} as={Link} to={entityData.create}>
                  <entityData.iconComponent /> {t(`entityKeys::${x}`)}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </ListGroup.Item>
  );
}
export default SidebarNewEntry;
