import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { IAbsencePolicyDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { useAppContext } from "@/components/contexts/appContext";
import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { useRecycleBinHook } from "@/hooks/useRecycleBinHook";
import { ErrorMessage } from "../../../../Models/Errors/ErrorMessage";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import SwrApiHelpers from "../../../../pbdServices/services/Api/swr-api-helpers";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import { TableClickCommand } from "../../../shared/components/tables/TableClickCommand";
import BaseSettingsIndexCard from "../../components/baseSettingsIndexCard";
import { SettingsRoutePaths } from "../../settingsRoutePaths";
import { AbsencePolicyTable } from "../components/absencePolicyTable";

function IndexPageAbsencePolicy() {
  const { absencePoliciesApi } = useAPIs();
  const navigate = useNavigate();
  const { showRecycleBin, toggleRecycleBin } = useRecycleBinHook();
  const { meAsUser, setErrorMessage } = useAppContext();
  const [selected, setSelected] = React.useState<IAbsencePolicyDTO[]>([]);
  const confirm = useConfirmation();

  const { data, mutate, isValidating } = useSWR(
    [`/api/absencePolicies`, showRecycleBin],
    () => absencePoliciesApi.getAllQuery({ isDeleted: showRecycleBin }),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  const handleCreateClick = () => navigate(SettingsRoutePaths.CreatePageAbsencePolicy);

  const handleClick = (command: TableClickCommand) => {
    if (command.action == "Delete") {
      if (selected) {
        confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
      }
    }
  };

  const submitDeleteRequest = async (items: number[]) => {
    if (!hasRole(meAsUser, [PbdRoles.Admin])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => absencePoliciesApi.delete(Number(s)));
      }
      mutate();
    }
  };

  return (
    <BaseSettingsIndexCard
      data={data}
      cardTitle="Absence policy"
      pathToCreate={SettingsRoutePaths.CreatePageAbsencePolicy}
      loading={isValidating}
      showRecycleBin={showRecycleBin}
      toggleRecycleBin={toggleRecycleBin}
    >
      <AbsencePolicyTable
        tableRows={data}
        onCreateClick={handleCreateClick}
        setSelected={setSelected}
        availableTableActions={["Delete"]}
        onClick={handleClick}
      />
    </BaseSettingsIndexCard>
  );
}

export default IndexPageAbsencePolicy;
