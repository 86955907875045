import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ConfigurationsIndex() {
  const { t } = useTranslation();
  return (
    <>
      <h3>&nbsp;</h3>
      <Card>
        <Card.Body>{t("Please select a module")}</Card.Body>
      </Card>
    </>
  );
}

export default ConfigurationsIndex;
