interface ProgressBarProps {
  maxValue?: number;
  currentValue: number;
  markExceedsCapacity?: boolean;
  exceedsCapacityColor?: string;
  color?: "success" | "info" | "warning" | "danger" | "secondary";
  title?: string;
  minWidth?: string;
  showValue?: boolean;
}

export function ProgressBar(props: ProgressBarProps) {
  const {
    exceedsCapacityColor = "bg-danger",
    maxValue = 1,
    currentValue,
    color,
    markExceedsCapacity,
    title,
    minWidth,
    showValue = true,
  } = props;

  let progress = 100;
  if (maxValue > 0) {
    const calculatedProgress = (currentValue / maxValue) * 100;
    progress = Math.round(calculatedProgress);
  }
  const divStyle = {
    width: progress + "%",
  };

  const progressBarColor = color ? `bg-${color}` : "";

  return (
    <div className="progress" title={`${title ?? ""} ${progress}%`.trim()} style={{ minWidth: minWidth }}>
      <div
        className={`progress-bar ${
          markExceedsCapacity && maxValue < currentValue ? exceedsCapacityColor : ""
        }${progressBarColor}`}
        role="progressbar"
        style={divStyle}
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        {showValue && <>{progress}%</>}
      </div>
    </div>
  );
}
