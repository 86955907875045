import { t } from "i18next";
import { Button, ListGroup } from "react-bootstrap";

import { ICleanUpButton } from "./entryCleanUp/entryCleanUpComponent";

function CountCleanUpButton(props: ICleanUpButton) {
  const { name, cleanUpPossible, onClickCleanUp, disabled } = props;
  return (
    <ListGroup variant="flush">
      {cleanUpPossible && (
        <ListGroup.Item className="d-flex justify-content-between">
          <div>
            <h5>{t("Unused " + name)}</h5>
            <p>{t("Remove " + name + " that are not used anymore.")}</p>
          </div>
          <div>
            <Button
              size="sm"
              variant="outline-secondary"
              title={t("Clear unused " + name)}
              onClick={onClickCleanUp}
              disabled={disabled}
            >
              {t("Cleanup")}
            </Button>
          </div>
        </ListGroup.Item>
      )}
    </ListGroup>
  );
}
export default CountCleanUpButton;
