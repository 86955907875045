import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import FormattedUserInput from "../../shared/components/text/formattedUserInput";

interface LocalType {
  title: string;
  description?: string;
}

interface IProps<T extends LocalType> {
  data: T[];
  type: string;
}

function ConnectedElementsTable<T extends LocalType>(props: IProps<T>) {
  const { t } = useTranslation();
  const { data, type } = props;
  return (
    <div>
      <h5>{type}:</h5>
      <Table responsive>
        <thead>
          <tr>
            <th>{t("Title")}</th>
            <th>{t("Description")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((element, index) => (
            <tr key={index}>
              <td>{element.title}</td>
              <td>
                <FormattedUserInput content={element.description} />
              </td>
            </tr>
          ))}
          {data.length == 0 && (
            <tr>
              <td colSpan={2} className="text-center">
                <em>{t("No data available")}</em>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}
export default ConnectedElementsTable;
