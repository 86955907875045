import i18next from "i18next";
import React from "react";
import { CellProps, Column } from "react-table";

import { ICustomField, IToDoDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { WithWarnings } from "../../../pbdServices/Models/Shared/with-warnings";
import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import VisibleColumns from "../../shared/components/tables/VisibleColumns";
import { getCreatedAtColumn } from "../../shared/components/tables/columns/createdAtColumn";
import { getCreatedByColumn } from "../../shared/components/tables/columns/createdByColumn";
import { getCustomFieldsColumns } from "../../shared/components/tables/columns/customFieldColumns";
import { getDeadlineColumn } from "../../shared/components/tables/columns/deadlineColumn";
import { getIdColumn } from "../../shared/components/tables/columns/idColumn";
import { getManyToManyColumn } from "../../shared/components/tables/columns/manyToManyColumn";
import { getResponsibleColumn } from "../../shared/components/tables/columns/responsibleColumn";
import { getTagColumn } from "../../shared/components/tables/columns/tagsColumn";
import { getTitleColumn } from "../../shared/components/tables/columns/titleColumn";
import { getLastUpdatedAtColumn } from "../../shared/components/tables/columns/updatedAtColumn";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";
import { useFilteredColumnsMemoTodoTable } from "../../shared/components/tables/tableHelperFunctions";
import { TaskManagementRoutePaths } from "../../taskManagement/taskManagementRoutePaths";

type TableData = WithWarnings<IToDoDTO>;

interface IProps<T extends TableData> extends BaseTableProps<T> {
  customFields?: ICustomField[];
}

export function TodoTable<T extends TableData>(props: IProps<T>) {
  const {
    customFields,
    columnsVisibleDefault = VisibleColumns.appTableVisibleColumns,
    titleColumnBaseLink = TaskManagementRoutePaths.EditTodoPage,
    includeTitleLink = true,
    localStorageStateKey = "ToDo",
    columnsToExclude = ["manyToMany"],
  } = props;

  const columns = React.useMemo<Column<T>[]>(
    () => [
      getManyToManyColumn(),
      getIdColumn(includeTitleLink, titleColumnBaseLink),
      getTitleColumn(includeTitleLink, titleColumnBaseLink),
      getTagColumn(),
      getResponsibleColumn(),
      getDeadlineColumn(),
      {
        id: "project",
        Header: <>{i18next.t("Project")}</>,
        accessor: (x) => x.project?.title,
        Cell: ({ row }: CellProps<T>) => <>{row.original.project?.title}</>,
      },
      getCreatedAtColumn(),
      getLastUpdatedAtColumn(),
      getCreatedByColumn(),
      ...getCustomFieldsColumns<T>(customFields),
    ],
    [],
  );
  const filteredColumns = useFilteredColumnsMemoTodoTable(columns, columnsToExclude);
  return (
    <ReactstrapTable<T>
      columns={filteredColumns}
      columnsVisibleDefault={columnsVisibleDefault}
      localStorageStateKey={localStorageStateKey}
      {...props}
    />
  );
}
