import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { StoreRoutePaths } from "./storeRoutePaths";

export const storeLazyRoutes: RouteObject[] = [
  {
    path: StoreRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePage")),
  },
  {
    path: StoreRoutePaths.PackagesIndex,
    Component: lazy(() => import("./pages/indexPagePackages")),
  },
  {
    path: StoreRoutePaths.PackageDetails,
    Component: lazy(() => import("./pages/detailsPagePackage")),
  },
  {
    path: StoreRoutePaths.PackageCreate,
    Component: lazy(() => import("./pages/createPagePackage")),
  },
  {
    path: StoreRoutePaths.StoreItemsIndex,
    Component: lazy(() => import("./pages/indexPageStoreItems")),
  },
];
