/* eslint-disable @typescript-eslint/naming-convention */
export class CostRoutePaths {
  public static HomePageCostsByModule = "/:pbdModule/Costs";
  /**Params to replace :pbdModule */
  public static IndexPageCostsByModule = "/:pbdModule/Costs/Index";
  /**Params to replace :pbdModule, :id */
  public static DetailsPageCostsByModule = "/:pbdModule/Costs/Details/:id";

  public static CreatePageCostsByModuleTemplate = "/:pbdModule/Costs/Create/:id";
}
