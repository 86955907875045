import { Parser } from "expr-eval";

import { IOpportunityCategoryVariable } from "@/generatedCode/pbd-core/pbd-core-api";

export type Variables = Record<string, number>;

export function getMaxResult(convertedFormula: string, variablesDTO: IOpportunityCategoryVariable[]) {
  const variablesMaxValue: Variables = {};
  for (const element of variablesDTO) {
    variablesMaxValue[element.variableName] = element.scaleMaximum;
  }
  const result = calculateFormula(convertedFormula, variablesMaxValue);
  return result;
}

export function getMinResult(convertedFormula: string, variablesDTO: IOpportunityCategoryVariable[]): number {
  const variablesMinValue: Variables = {};
  for (const element of variablesDTO) {
    variablesMinValue[element.variableName] = element.scaleMinimum;
  }
  const result = calculateFormula(convertedFormula, variablesMinValue);
  return result;
}

export function convertFormula(formula: string): string {
  // RegExp to replace all @
  const convertedFormula = formula.replace(/@/g, "");
  return convertedFormula;
}

export function calculateFormula(formula: string, variables: Variables): number {
  const parser = new Parser();
  const output = parser.parse(formula);
  return output.evaluate(variables);
}
