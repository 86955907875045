import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";

import { AuditRoutePaths } from "./auditRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.AuditManagement_Standard, PbdRoles.AuditManagement_ModuleAdmin],
};

export const auditLazyRoutes: RouteObject[] = [
  {
    path: AuditRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageAudits")),
    ...defaultRouteParams,
  },
  {
    path: AuditRoutePaths.IndexPage,
    Component: lazy(() => import("./pages/indexPageAudits")),
    ...defaultRouteParams,
  },
  {
    path: AuditRoutePaths.CreatePage,
    Component: lazy(() => import("./pages/createPageAudits")),
    ...defaultRouteParams,
  },
  {
    path: AuditRoutePaths.EditPage,
    Component: lazy(() => import("./pages/detailsPageAudit")),
    ...defaultRouteParams,
  },
  {
    path: AuditRoutePaths.MatrixPage,
    Component: lazy(() => import("./pages/matrixPageAudits")),
    ...defaultRouteParams,
  },
];
