import update from "immutability-helper";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import CustomFieldMoveable from "../../../forms/customForms/components/customFieldMoveable";

interface IProps {
  customFields: string[];
  onChange: (results: string[]) => void;
}

export function ChangeCustomFieldForm(props: IProps) {
  const { customFields, onChange } = props;
  const [cards, setCards] = React.useState(customFields);

  const moveCard = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      const newValue = update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      setCards(newValue);
      onChange(newValue);
    },
    [cards],
  );

  const renderCard = (cf: string, index: number) => {
    return <CustomFieldMoveable key={index} index={index} id={cf} text={cf} moveCard={moveCard} />;
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <div>{cards.map((c, i) => renderCard(c, i))}</div>
    </DndProvider>
  );
}
