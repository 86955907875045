export type LazyInitFn<T> = () => T;

export class Lazy<T> {
  init: LazyInitFn<T>;
  value: T | undefined;

  constructor(init: LazyInitFn<T>) {
    this.init = init;
    this.value = undefined;
  }

  get() {
    if (this.value === undefined) {
      this.value = this.init();
    }

    return this.value;
  }
}
