import { Field, Form, Formik } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, InputGroup, Label } from "reactstrap";
import * as yup from "yup";

import { IInventoryStatusCreateDTO, IInventoryStatusDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { requiredTitleStringSchema } from "../../../../services/validation/stringSchemas";
import { FormikTextInput } from "../../../shared/components/forms/formik/formikTextInput";

const getValidationSchema = (t: TFunction) => {
  const validation: yup.ObjectSchema<IInventoryStatusCreateDTO> = yup.object({
    title: requiredTitleStringSchema(t),
  });
  return validation;
};

interface IProps {
  itemToUpdate?: IInventoryStatusDTO;
  onSubmit: (values: IInventoryStatusCreateDTO) => Promise<unknown>;
  onSuccess: () => void;
  entityTemplateComponent?: JSX.Element;
}

const InventoryStatusForm: React.FC<IProps> = (props) => {
  const { itemToUpdate, onSubmit, entityTemplateComponent, onSuccess } = props;
  const { t } = useTranslation();

  const initialValues: IInventoryStatusCreateDTO = { title: itemToUpdate?.title ?? "" };

  const submitter = useFormikAPISubmitter<IInventoryStatusCreateDTO, unknown>(
    (val) => onSubmit(val),
    [onSubmit],
    () => onSuccess(),
  );

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getValidationSchema(t)}>
      <Form>
        <FormGroup>
          <Label for="title">{t("Title")}</Label>

          <InputGroup>
            <Field name="title" component={FormikTextInput} />
            {entityTemplateComponent && entityTemplateComponent}
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Button color="primary" type="submit">
            {t("Save")}
          </Button>
        </FormGroup>
      </Form>
    </Formik>
  );
};

export default InventoryStatusForm;
