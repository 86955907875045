import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, ListGroup, ListGroupItem } from "reactstrap";

import { IArticleLayoutSettings } from "@/generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { nameofFactory } from "../../../../utils/nameofFactory";
import { BaseFormProps } from "../../../shared/components/forms/formik/BaseFormProps";
import { FormikCheckboxInput } from "../../../shared/components/forms/formik/formikCheckboxInput";
import FormikDebugInfo from "../../../shared/components/forms/formik/formikDebugInfo";
import FormikSubmitButton from "../../../shared/components/forms/formik/formikSubmitButton";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";

const nameof = nameofFactory<IArticleLayoutSettings>();

interface IProps extends BaseFormProps<IArticleLayoutSettings> {
  itemToUpdate: IArticleLayoutSettings;
}

const ArticleLayoutForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { onSubmit, itemToUpdate, onSuccess } = props;

  const submitter = useFormikAPISubmitter<IArticleLayoutSettings>((val) => onSubmit(val), [onSubmit], onSuccess);

  return (
    <React.Fragment>
      <h4>{t("Layout")}</h4>
      <hr />
      <Formik
        initialValues={itemToUpdate}
        onSubmit={submitter}
        // validationSchema={ValidationSchema}
      >
        {(formikBag) => (
          <Form>
            <FormikDebugInfo formikBag={formikBag} />
            <ListGroup className="mb-3">
              {Object.keys(itemToUpdate)
                .filter((x) => ["showReadme"].includes(x))
                .map((x) => (
                  <ListGroupItem key={x}>
                    <Field
                      name={x}
                      id={x}
                      value={itemToUpdate[x as keyof IArticleLayoutSettings]}
                      component={FormikCheckboxInput}
                      label={t(x)}
                    />
                  </ListGroupItem>
                ))}
            </ListGroup>
            <FormGroup>
              <FormikSubmitButton formikBag={formikBag} />
            </FormGroup>
            <FormikValidationSummary formikBag={formikBag} />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default ArticleLayoutForm;
