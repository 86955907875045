import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  title?: React.ReactNode;
  size?: "sm" | "lg";
}

function CancelButton(props: IProps) {
  const { onClick, title, className = "me-1", size } = props;
  const { t } = useTranslation();
  return (
    <Button variant="link" onClick={onClick} className={className} size={size}>
      {title ?? t("Cancel")}
    </Button>
  );
}
export default CancelButton;
