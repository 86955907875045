import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IEntityFieldTemplateDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { idOfActionColumn } from "../../shared/components/tables/reactstrapTable";
import { BaseTableProps } from "../../shared/components/tables/v8/base-table-props";
import { useCustomTableContext } from "../../shared/components/tables/v8/customTableContext";
import ReactstrapTable from "../../shared/components/tables/v8/reactstrapTable";

interface IPropsCell {
  data: IEntityFieldTemplateDTO;
}

function TitleCell(props: IPropsCell) {
  const { t } = useTranslation();
  const { onRowClickNew } = useCustomTableContext();
  const { data } = props;
  if (!onRowClickNew) throw Error("Missing onRowClickProp");

  return (
    <Button variant="link" onClick={() => onRowClickNew(data)} className="p-0">
      {data.title}
    </Button>
  );
}

function DeleteCell(props: IPropsCell) {
  const { t } = useTranslation();
  const { onDeleteClick } = useCustomTableContext();
  const { data } = props;
  if (!onDeleteClick) throw Error("Missing onRowClickProp");

  return (
    <Button variant="outline-danger" onClick={() => onDeleteClick(data)} size="sm">
      <qmBaseIcons.Delete />
    </Button>
  );
}

const columnHelper = createColumnHelper<IEntityFieldTemplateDTO>();

const columns = [
  columnHelper.accessor((x) => x.id, {
    id: "id",
    header: i18next.t("ID"),
  }),
  columnHelper.accessor((x) => x.title, {
    id: "title",
    header: () => <>{i18next.t("Title")}</>,
    cell: (x) => <TitleCell data={x.row.original} />,
  }),
  columnHelper.accessor((x) => x.fieldKeys, {
    id: "fieldKeys",
    header: i18next.t("Field keys"),
    cell: (x) => <>{x.getValue()?.join()}</>,
  }),
  columnHelper.accessor((x) => x.id, {
    id: idOfActionColumn,
    header: "",
    cell: (x) => <DeleteCell data={x.row.original} />,
    enableSorting: false,
  }),
];

type IProps = BaseTableProps<IEntityFieldTemplateDTO>;

function EntityFieldTemplateTable(props: IProps) {
  const { localStorageStateKey = "EntityFieldTemplate" } = props;
  return (
    <ReactstrapTable<IEntityFieldTemplateDTO>
      columns={columns}
      localStorageStateKey={localStorageStateKey}
      columnsVisibleDefault={["title", idOfActionColumn]}
      {...props}
    />
  );
}

export default EntityFieldTemplateTable;
