import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { IDepartmentDTO, TenantsQueryField } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../../services/serviceContext";

import { useAppContext } from "@/components/contexts/appContext";
import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import { ErrorMessage } from "../../../../Models/Errors/ErrorMessage";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import SwrApiHelpers from "../../../../pbdServices/services/Api/swr-api-helpers";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import DepartmentTable from "../../../departments/components/departmentTable";
import { TableClickCommand } from "../../../shared/components/tables/TableClickCommand";
import BaseSettingsIndexCard from "../../components/baseSettingsIndexCard";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

const IndexPageDepartments: React.FC = () => {
  const { departmentsApi, tenantsApi } = useAPIs();
  const { meAsUser, setErrorMessage } = useAppContext();
  const [selected, setSelected] = React.useState<IDepartmentDTO[]>([]);
  const confirm = useConfirmation();
  const { departmentService } = useAPIServices();
  const { data: tenants } = useSWR("/api/tenants/?isEmployee=true", () =>
    tenantsApi.getAllQuery({
      isEmployee: true,
      fields: [TenantsQueryField.DepartmentPositions],
      pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
    }),
  );

  const [showRecycleBin, setShowRecycleBin] = React.useState(false);

  const navigate = useNavigate();

  const { data, mutate, isValidating } = useSWR(
    [`/api/departments`, showRecycleBin],
    () => departmentsApi.getAllQuery({ isDeleted: showRecycleBin }),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  function toggleRecycleBin() {
    setShowRecycleBin(!showRecycleBin);
  }

  const handleClick = (command: TableClickCommand) => {
    if (command.action == "Delete") {
      if (selected) {
        confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
      }
    }
  };

  const submitDeleteRequest = async (items: number[]) => {
    if (!hasRole(meAsUser, [PbdRoles.Admin])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => departmentsApi.delete(Number(s)));
      }
      mutate();
    }
  };

  const mappedData = React.useMemo(() => {
    if (tenants && data) {
      return departmentService.mapDepartmentsWithTenants(data, tenants.data);
    } else {
      return undefined;
    }
  }, [data, departmentService, tenants]);

  const handleCreateClick = () => navigate(SettingsRoutePaths.CreatePageDepartment);

  return (
    <React.Fragment>
      <BaseSettingsIndexCard
        data={data}
        cardTitle="Departments"
        pathToCreate={SettingsRoutePaths.CreatePageDepartment}
        loading={isValidating}
        showRecycleBin={showRecycleBin}
        toggleRecycleBin={toggleRecycleBin}
      >
        <DepartmentTable
          tableRows={mappedData}
          titleColumnBaseLink={SettingsRoutePaths.EditPageDepartment}
          onCreateClick={handleCreateClick}
          setSelected={setSelected}
          availableTableActions={["Delete"]}
          onClick={handleClick}
        />
      </BaseSettingsIndexCard>
    </React.Fragment>
  );
};

export default IndexPageDepartments;
