import { DateTime } from "luxon";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DateTimeLuxonHelpers } from "../../../../utils/dateTimeLuxonHelpers";

interface IProps {
  plannedAt: DateTime;
  plannedAtEnd?: DateTime;
  isAllDayEvent?: boolean;
}

function PlannedDateComponent(props: IProps) {
  const { t } = useTranslation();
  const { plannedAt, plannedAtEnd, isAllDayEvent } = props;
  return (
    <>
      {isAllDayEvent && <Badge className="me-1">{t("All day")}</Badge>}
      {isAllDayEvent
        ? DateTimeLuxonHelpers.convertUtcToDate(plannedAt)
        : DateTimeLuxonHelpers.convertUtcToDateTime(plannedAt, false, "LT")}
      {plannedAtEnd && (
        <>
          {" - "}
          {isAllDayEvent
            ? DateTimeLuxonHelpers.convertUtcToDate(plannedAtEnd)
            : DateTimeLuxonHelpers.convertUtcToDateTime(plannedAtEnd, false, "LT")}
        </>
      )}
    </>
  );
}
export default PlannedDateComponent;
