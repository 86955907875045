import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody } from "reactstrap";
import useSWR from "swr";

import { useAPIs } from "../../../../services/serviceContext";

import { useToggle } from "@/hooks/useToggle";
import BankHolidayForm from "../components/bankHolidayForm";
import { BankHolidayTable } from "../components/bankHolidayTable";

const CreatePageBankHolidays: React.FC = () => {
  const { t } = useTranslation();
  const { appSettingsApi } = useAPIs();
  const [editMode, toggleEditMode] = useToggle();

  const { data, mutate } = useSWR("/api/appSettings/bankHolidays", () => appSettingsApi.getBankHolidays());

  return (
    <React.Fragment>
      <h2>{t("Bank holidays")}</h2>
      <hr />
      <Card className="mb-3">
        <CardBody>
          {!editMode && (
            <>
              <BankHolidayTable tableRows={data?.holidays} />
              <Button onClick={toggleEditMode}>{t("Edit")}</Button>
            </>
          )}
          {editMode && (
            <BankHolidayForm
              itemToUpdate={data}
              onCancel={toggleEditMode}
              onSubmit={(x) => appSettingsApi.updateBankHolidays(x)}
              onSuccess={() => {
                toggleEditMode();
                mutate();
              }}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default CreatePageBankHolidays;
