import React from "react";
import { Badge, Button, ListGroupItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ICategoryEntityTemplate } from "../../../../Models/EntityTemplates/CategoryEntityTemplate";
import StringHelpers from "../../../../utils/stringHelpers";
import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps<T extends ICategoryEntityTemplate = ICategoryEntityTemplate> {
  item: T;
  onSelect: (item: T) => void;
}

function EntityTemplateListGroupItem<T extends ICategoryEntityTemplate = ICategoryEntityTemplate>(props: IProps<T>) {
  const { item, onSelect } = props;
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = React.useState(true);
  const toggleCollapsed = () => setCollapsed(!collapsed);
  return (
    <ListGroupItem>
      <div className="d-flex w-100 justify-content-between">
        <h5>
          {item.color && <qmBaseIcons.Circle className="me-2" style={{ color: item.color }} />}
          {item.title}{" "}
          {item.tags && (
            <small>
              {item.tags.map((x) => (
                <Badge bg="secondary" className="me-1" key={x}>
                  {x}
                </Badge>
              ))}
            </small>
          )}
        </h5>
        <div>
          <Button variant="outline-primary" size="sm" onClick={() => onSelect(item)}>
            {t("Select")}
          </Button>
        </div>
      </div>
      {item.description && (
        <>
          <p>
            {collapsed
              ? StringHelpers.truncate(item.description, StringHelpers.maxVisibleStringLengthForDescription)
              : item.description}
          </p>
          {item.description.length > StringHelpers.maxVisibleStringLengthForDescription && (
            <Button variant="link" size="sm" onClick={toggleCollapsed} className="ps-0 pt-0">
              {t("Show more")}
            </Button>
          )}
        </>
      )}
    </ListGroupItem>
  );
}
export default EntityTemplateListGroupItem;
