import i18next from "i18next";

export class ExternalIdSettings {
  isRequired: boolean;
  isUsed: boolean;
  displayName?: string;
  hideInternalId: boolean;
}

export const getExternalIdName = (externalIdSetting?: ExternalIdSettings) => {
  if (!externalIdSetting?.displayName) return i18next.t("External ID");

  return externalIdSetting.displayName;
};
