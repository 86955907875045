import React from "react";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Badge, Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Table } from "reactstrap";
import useSWR from "swr";

import SwrApiHelpers from "../../../../pbdServices/services/Api/swr-api-helpers";
import SettingsService from "../../../../services/Settings/settingsService";
import { CurrencyList } from "../components/currencyList";

const settingsService = new SettingsService();

const IndexPageCurrencies: React.FC = () => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const { t } = useTranslation();

  const { data, mutate } = useSWR(
    "/api/settings/currencies",
    () => settingsService.getValueByKey<string[]>("Currencies"),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  const handleSave = async () => {
    const resp = await settingsService.updateAppSettings({ key: "Currencies", value: JSON.stringify(selected) });
    if (resp.success) {
      toast.success(t("Save"));
      mutate();
    }
    if (resp.isError) {
      toast.error(t("Error something went wrong."));
    }
  };

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.includes(name);

  const items = data ?? [];

  return (
    <React.Fragment>
      <Card className="mb-3">
        <CardHeader>
          <CardTitle tag="h5">{t("Currencies")}</CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            <h4>{t("Available currencies")}</h4>
            {items.map((x) => (
              <Badge key={x} className="me-1">
                {x}
              </Badge>
            ))}
          </div>
          {selected.length > 0 && (
            <>
              <h5>{t("New selection")}</h5>
              <FormGroup>
                {selected.map((x) => (
                  <Badge key={x} className="me-1">
                    {x}
                  </Badge>
                ))}
              </FormGroup>
            </>
          )}

          <FormGroup>
            <Button color="primary" type="button" onClick={handleSave}>
              {t("Save")}
            </Button>
          </FormGroup>
          <Table responsive>
            <tbody>
              {Object.values(CurrencyList).map((x) => (
                <tr key={x.code}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      id={x.code}
                      onChange={(e) => handleClick(e, x.code)}
                      checked={isSelected(x.code)}
                    />
                  </td>
                  <td>{x.symbol_native}</td>
                  <td>{x.code}</td>
                  <td>{x.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default IndexPageCurrencies;
