import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  CategoryCreateDTO,
  ConnectAuditTypeDTO,
  IAuditTypeDTO,
  ICategoryCreateDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { useRefreshHook } from "@/hooks/useRefreshHook";
import AuditRequirementsConnectedCard from "../../../shared/components/connectionElements/auditRequirements/auditRequirementsConnectedCard";
import BaseSettingsForm, { hasId } from "../../components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function EditPageAuditTypes() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { auditTypesApi, auditRequirementsApi } = useAPIs();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<IAuditTypeDTO>();
  const [{ refresh, handleRefresh }] = useRefreshHook();

  const { data: existingAuditTypes } = useSWR(`auditTypes`, () => auditTypesApi.getAll());

  React.useEffect(() => {
    async function getData() {
      const type = await auditTypesApi.getById(Number(id));
      setItemToUpdate(type);
    }
    if (id != null) {
      getData().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id, refresh]);

  async function handleSubmit(itemToCreate: ICategoryCreateDTO) {
    if (id) {
      const resp = await auditTypesApi.edit(Number(id), new CategoryCreateDTO(itemToCreate));
      if (
        itemToCreate.responsibleId &&
        itemToUpdate?.responsible &&
        itemToCreate.responsibleId != itemToUpdate.responsible.id
      ) {
        handleRefresh();
        return resp;
      }
    } else {
      return auditTypesApi.create(new CategoryCreateDTO(itemToCreate));
    }
  }

  async function toggleDelete() {
    if (!itemToUpdate) throw Error("Missing itemToUpdate");
    if (itemToUpdate.isDeleted) {
      await auditTypesApi.restore(itemToUpdate.id);
    } else {
      await auditTypesApi.delete(itemToUpdate.id);
    }
    handleRefresh();
  }

  const handleSuccess = (dto?: unknown) => {
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageAuditTypes.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };

  async function handleConnectChildren(_id: number, auditRequirementIds: number[]) {
    for (const requirementId of auditRequirementIds) {
      await auditRequirementsApi.connectAuditTypes(requirementId, new ConnectAuditTypeDTO({ auditTypeIds: [_id] }));
    }
  }

  function handleDisconnectChild(_id: number, auditRequirementId: number) {
    return auditRequirementsApi.disconnectAuditType(auditRequirementId, _id);
  }

  return (
    <>
      {!loading && (
        <BaseSettingsForm
          itemToUpdate={itemToUpdate}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          additionalFields={["Color", "Responsible", "Recurring"]}
          onDelete={toggleDelete}
          onRestore={(dto) => auditTypesApi.restore(dto)}
          handleRefresh={handleRefresh}
          existingItems={existingAuditTypes}
          formType={"AuditType"}
        />
      )}
      {itemToUpdate?.auditRequirements && (
        <AuditRequirementsConnectedCard
          baseDTO={itemToUpdate}
          data={itemToUpdate.auditRequirements}
          refreshParent={handleRefresh}
          onConnectSubmit={(ids) => handleConnectChildren(itemToUpdate.id, ids)}
          onDisconnectSubmit={(idToDisconnect) => handleDisconnectChild(itemToUpdate.id, idToDisconnect)}
        />
      )}
    </>
  );
}

export default EditPageAuditTypes;
