import { DateTime } from "luxon";

import {
  ConnectionCondition,
  ConnectionConditionType,
  ConnectionConditionWrapper,
  EntityKey,
  IQualificationDTO,
  QualificationDTO,
  TenantQualificationConnection,
  TimeInterval,
  TimeIntervalDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { DTOMap } from "../../../../utils/filterMap";
import { buildCapabilities } from "./capabilityMockData";

export class QualificationsMockData extends DTOMap<IQualificationDTO> {
  constructor() {
    const now = DateTime.now();
    super([
      {
        id: 1,
        title: "Qualification1",
        isRecurring: true,
        monitoringInterval: new TimeIntervalDTO({ timeSpanISO: "P1Y", type: TimeInterval.Year, value: 1 }),
        useWarningTime: false,
        color: "red",
        capabilities: buildCapabilities(),
        createdAt: now,
        tags: [],
        isDeleted: false,
        entityKey: EntityKey.Qualification,
        createdById: 1,
        linkedResources: [],
      },
      {
        id: 2,
        title: "Qualification2",
        isRecurring: true,
        monitoringInterval: new TimeIntervalDTO({ timeSpanISO: "P1Y", type: TimeInterval.Year, value: 1 }),
        useWarningTime: true,
        warningTimeInterval: new TimeIntervalDTO({ timeSpanISO: "P360D", type: TimeInterval.Day, value: 360 }),
        color: "red",
        capabilities: buildCapabilities(),
        createdAt: now,
        isDeleted: false,
        tags: [],
        entityKey: EntityKey.Qualification,
        createdById: 1,
        linkedResources: [],
      },
      {
        id: 3,
        title: "Qualification3",
        isRecurring: true,
        monitoringInterval: new TimeIntervalDTO({ timeSpanISO: "P1Y", type: TimeInterval.Year, value: 1 }),
        useWarningTime: false,
        color: "red",
        capabilities: buildCapabilities(),
        createdAt: now,
        tags: [],
        isDeleted: false,
        entityKey: EntityKey.Qualification,
        createdById: 1,
        linkedResources: [],
      },
      {
        id: 4,
        title: "Qualification4",
        isRecurring: true,
        monitoringInterval: new TimeIntervalDTO({ timeSpanISO: "P1Y", type: TimeInterval.Year, value: 1 }),
        useWarningTime: true,
        warningTimeInterval: new TimeIntervalDTO({ timeSpanISO: "P1M", type: TimeInterval.Month, value: 1 }),
        color: "red",
        capabilities: buildCapabilities(),
        createdAt: now,
        tags: [],
        isDeleted: false,
        entityKey: EntityKey.Qualification,
        createdById: 1,
        linkedResources: [],
      },
      {
        id: 5,
        title: "Qualification5",
        isRecurring: true,
        monitoringInterval: new TimeIntervalDTO({ timeSpanISO: "P1Y", type: TimeInterval.Year, value: 1 }),
        useWarningTime: true,
        warningTimeInterval: new TimeIntervalDTO({ timeSpanISO: "P1M", type: TimeInterval.Month, value: 1 }),
        color: "red",
        capabilities: buildCapabilities(),
        createdAt: now,
        tags: [],
        isDeleted: false,
        entityKey: EntityKey.Qualification,
        createdById: 1,
        linkedResources: [],
      },
      {
        id: 6,
        title: "Qualification6",
        isRecurring: true,
        monitoringInterval: new TimeIntervalDTO({ timeSpanISO: "P1Y", type: TimeInterval.Year, value: 1 }),
        useWarningTime: true,
        warningTimeInterval: new TimeIntervalDTO({ timeSpanISO: "P1M", type: TimeInterval.Month, value: 1 }),
        color: "red",
        capabilities: buildCapabilities(),
        createdAt: now,
        tags: [],
        isDeleted: false,
        entityKey: EntityKey.Qualification,
        createdById: 1,
        linkedResources: [],
      },
      {
        id: 7,
        title: "Qualification7",
        isRecurring: true,
        monitoringInterval: new TimeIntervalDTO({ timeSpanISO: "P1Y", type: TimeInterval.Year, value: 1 }),
        useWarningTime: true,
        warningTimeInterval: new TimeIntervalDTO({ timeSpanISO: "P1M", type: TimeInterval.Month, value: 1 }),
        color: "red",
        capabilities: buildCapabilities(),
        createdAt: now,
        tags: [],
        isDeleted: false,
        entityKey: EntityKey.Qualification,
        createdById: 1,
        linkedResources: [],
      },
      {
        id: 99,
        title: "Qualification99",
        isRecurring: false,
        useWarningTime: false,
        color: "red",
        capabilities: buildCapabilities(),
        createdAt: now,
        tags: [],
        isDeleted: false,
        entityKey: EntityKey.Qualification,
        createdById: 1,
        linkedResources: [],
        connectionCondition: new ConnectionConditionWrapper({
          tenantQualificationConnection: new TenantQualificationConnection({
            isSelfAssignmentEnabled: true,
            customForms: [
              new ConnectionCondition({
                id: 1,
                conditionType: ConnectionConditionType.CustomFormPassed,
                isActive: true,
                value: "",
              }),
            ],
            articles: [],
          }),
        }),
      },
      {
        id: 100,
        title: "Qualification100",
        isRecurring: false,
        useWarningTime: false,
        color: "red",
        capabilities: buildCapabilities(),
        createdAt: now,
        tags: [],
        isDeleted: false,
        entityKey: EntityKey.Qualification,
        createdById: 1,
        linkedResources: [],
        connectionCondition: new ConnectionConditionWrapper({
          tenantQualificationConnection: new TenantQualificationConnection({
            isSelfAssignmentEnabled: true,
            customForms: [
              new ConnectionCondition({
                id: 1,
                conditionType: ConnectionConditionType.CustomFormPassed,
                isActive: true,
                value: "",
              }),
            ],
            articles: [],
          }),
        }),
      },
      {
        id: 400,
        title: "Qualification400",
        isRecurring: false,
        useWarningTime: false,
        color: "red",
        capabilities: buildCapabilities(),
        createdAt: now,
        tags: [],
        isDeleted: false,
        entityKey: EntityKey.Qualification,
        createdById: 1,
        linkedResources: [],
        connectionCondition: new ConnectionConditionWrapper({
          tenantQualificationConnection: new TenantQualificationConnection({
            isSelfAssignmentEnabled: true,
            customForms: [
              new ConnectionCondition({
                id: 1,
                conditionType: ConnectionConditionType.CustomFormPassed,
                isActive: true,
                value: "",
              }),
            ],
            articles: [],
          }),
        }),
      },
    ]);
  }

  static getDeletePlaceholder(id: number) {
    return new QualificationDTO({
      id,
      title: `Deleted #${id}`,
      isDeleted: true,
      isRecurring: false,
      useWarningTime: false,
      createdAt: DateTime.now(),
      entityKey: EntityKey.Qualification,
      createdById: 1,
      linkedResources: [],
    });
  }
}
