import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { EntityKey } from "@/generatedCode/pbd-core/pbd-core-api";

import { EntityDefinitionData } from "../../../Constants/entityDefinitions";
import ButtonLink from "../components/buttons/buttonLink";
import { qmBaseIcons } from "../components/icons/qmBaseIcons";

interface IProps {
  entities: EntityKey[];
}

function CreatePageSelect(props: IProps) {
  const { t } = useTranslation();
  const { entities } = props;
  return (
    <>
      <h2>{t("Create")}</h2>
      <hr />
      <Row xs={1} md={3} className="g-4">
        {entities.map((x) => {
          const data = EntityDefinitionData[x];
          if (!data) {
            return <div key={x}>{t("Missing data")}</div>;
          }
          const Icon = data.iconComponent;
          return (
            <Col key={x}>
              <Card>
                <Card.Body>
                  <div className="text-center">
                    <Icon className="fa-2x" />
                  </div>
                  <Card.Title>{t(`entityKeys::${x}`)}</Card.Title>
                  <Card.Text>{t(`entityKeys::${x}.description`)}</Card.Text>
                  <div className="d-grid gap-2">
                    <ButtonLink variant="primary" to={data.create}>
                      <qmBaseIcons.Plus /> {t("Create")}
                    </ButtonLink>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
}
export default CreatePageSelect;
