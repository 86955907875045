import React from "react";
import { Badge, Button, ListGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import BaseEntityTemplate from "../../../../Models/EntityTemplates/BaseEntityTemplate";
import { ICategoryEntityTemplate } from "../../../../Models/EntityTemplates/CategoryEntityTemplate";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import { filterMap } from "../../../../utils/filterMap";
import { ListHelpers } from "../../../../utils/listHelpers";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import ExternalLink from "../links/externalLink";
import { LoadingComponent } from "../loading/loadingComponent";
import { BaseModalProps } from "../modals/baseModalProps";
import EntityTemplateListGroupItem from "./entityTemplateListGroupItem";
import { IEntityTemplateButtonActions } from "./entityTemplatesButton";

interface IProps<T extends ICategoryEntityTemplate = ICategoryEntityTemplate>
  extends IEntityTemplateButtonActions<T>,
    BaseModalProps {
  data?: BaseEntityTemplate<T>;
}

function EntityTemplateModal<T extends ICategoryEntityTemplate = ICategoryEntityTemplate>(props: IProps<T>) {
  const { t } = useTranslation();
  const { data, modal, toggle, onSelect, onSuccess } = props;
  const [filter, setFilter] = React.useState<string>();

  const tagCount = React.useMemo(() => {
    if (data?.data) {
      const tags = filterMap(data.data, (x) => x.tags);
      const tagsConcatenated = tags.reduce((pv, cv) => pv.concat(cv), []);
      return ListHelpers.groupAndSortStringArray(tagsConcatenated);
    } else {
      return undefined;
    }
  }, [data?.data]);

  const handleFilterClick = (item: string) => {
    if (filter == item) {
      setFilter(undefined);
    } else {
      setFilter(item);
    }
  };

  const handleClick = async (dto: T) => {
    const resp = await wrapApiCallWithToast(() => onSelect(dto));
    if (resp.isOk) {
      toggle();
      onSuccess(resp.value);
    }
  };

  return (
    <Modal show={modal} onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <qmBaseIcons.Folder /> {t("Templates")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!data && <LoadingComponent />}
        {tagCount && tagCount.length > 1 && (
          <div className="mb-3">
            <h6>{t("Filter by category")}</h6>
            {tagCount.map((x) => (
              <Button
                variant="outline-secondary"
                className="me-1"
                key={x.key}
                size="sm"
                onClick={() => handleFilterClick(x.key)}
                active={x.key == filter}
              >
                <Badge pill bg="primary">
                  {x.count}
                </Badge>{" "}
                {x.key} {x.key == filter && <qmBaseIcons.Check />}
              </Button>
            ))}
          </div>
        )}

        <ListGroup>
          {data?.data
            .filter((x) => (filter != undefined ? x.tags?.includes(filter) : true))
            .map((template, i) => <EntityTemplateListGroupItem key={i} item={template} onSelect={handleClick} />)}
        </ListGroup>
      </Modal.Body>
      {data && (
        <Modal.Footer>
          {data.blogUrl && (
            <ExternalLink href={data.blogUrl} label={t("Find out more about this topic in our blog.")} />
          )}
          {data.helpUrl && (
            <ExternalLink href={data.helpUrl} label={t("Find out more about this topic in our help.")} />
          )}
          {data.externalSources && (
            <>
              <h6>{t("Further information")}</h6>
              {data.externalSources.map((x) => (
                <ExternalLink key={x.url} href={x.url} label={x.title} />
              ))}
            </>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default EntityTemplateModal;
