import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { ICustomField } from "@/generatedCode/pbd-core/pbd-core-api";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { ProductRoutePaths } from "./productRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.Products_Standard, PbdRoles.Products_ModuleAdmin],
};

export class ProductSettings {
  constructor() {
    this.customFields = [];
  }
  customFields: ICustomField[];
}

export const productLazyRoutes: RouteObject[] = [
  {
    path: ProductRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageProducts")),
    ...defaultRouteParams,
  },
  {
    path: ProductRoutePaths.IndexPage,
    Component: lazy(() => import("./pages/indexPageProducts")),
    ...defaultRouteParams,
  },
  {
    path: ProductRoutePaths.CreatePage,
    Component: lazy(() => import("./pages/createPageProducts")),
    ...defaultRouteParams,
  },
  {
    path: ProductRoutePaths.EditPage,
    Component: lazy(() => import("./pages/detailsPageProduct")),
    ...defaultRouteParams,
  },
];
