import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  submitting: boolean;
  color?: string;
  label?: string;
  onClick: () => void;
  disabled?: boolean;
  title?: string;
  size?: string;
  canSubmit?: boolean;
  className?: string;
  children?: React.ReactNode;
}

function EnhancedSubmitButton(props: IProps) {
  const { t } = useTranslation();
  const { label = "Save", color = "primary" } = props;
  return (
    <Button
      title={props.title}
      disabled={props.disabled ?? !props.canSubmit ?? props.submitting}
      onClick={props.onClick}
      color={color}
      size={props.size}
      className={props.className}
    >
      {props.submitting ? (
        <React.Fragment>
          <qmBaseIcons.Spinner /> {t("Loading...")}
        </React.Fragment>
      ) : (
        <React.Fragment>{props.children ? props.children : t(label)}</React.Fragment>
      )}
    </Button>
  );
}

export default EnhancedSubmitButton;
