import useSWRImmutable from "swr/immutable";

import { SwrKeyNames } from "../Constants/swr-key-names";
import { useAPIServices } from "../services/serviceContext";

export function useModuleSettingsHook() {
  const { moduleSettingsService } = useAPIServices();
  const { data, mutate, error, isLoading } = useSWRImmutable([SwrKeyNames.ModuleSettings, "test"], () =>
    moduleSettingsService.getAllAsVm(),
  );

  return {
    data: data,
    isLoading,
    error,
    mutate,
  };
}
