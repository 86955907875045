import React from "react";
import useSWR from "swr";

import { IDepartmentPositionDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../services/serviceContext";

import { BaseSelectProps } from "./BaseSelectProps";
import CustomSelect from "./customSelect";

interface IProps extends BaseSelectProps<IDepartmentPositionDTO> {
  onChange: (id?: number | number[]) => void;
  departmentId?: number;
}

function DepartmentPositionSelect(props: IProps) {
  const { onChange, isMulti = true, isClearable = true, availableOptions, departmentId, selectedIds } = props;
  const { departmentPositionsApi } = useAPIs();
  const { data } = useSWR(availableOptions ? null : ["api/departmentPositions", `departmentId=${departmentId}`], () =>
    departmentPositionsApi.getAllQuery({ departmentId: departmentId ? [departmentId] : undefined }),
  );

  const combined = React.useMemo(() => {
    if (!data) {
      return availableOptions;
    }

    const options: { id: number; title: string }[] = [];

    data.map((x) => {
      const opt = { id: x.id, title: x.title + " - " + x.department.title };
      options.push(opt);
    });
    return options;
  }, [availableOptions, data]);

  if (!combined) return null;

  return (
    <CustomSelect
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={onChange}
      name="departmentPositionId"
      data={combined}
      selectedIds={selectedIds}
    />
  );
}

export default DepartmentPositionSelect;
