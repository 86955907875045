import { FormikProps, FormikValues } from "formik";
import { isEqual } from "lodash";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../../icons/qmBaseIcons";

interface IProps<T extends FormikValues> {
  formikBag: FormikProps<T>;
  className?: string;
  label?: string;
  size?: "sm" | "lg";
  /**
   * There are  situations where we want to submit directly without changing the form.
   * In this case you use this prop.
   */
  canSubmitDirty?: boolean;
  variant?: "danger";
}

/**
 * This is our default submit button. Create forms should typically can connect dirty. For example if we have good default values.
 * Edit forms should only submit if dirty. Which means a value within the form has been changed.
 */
function FormikSubmitButton<T extends FormikValues>(props: IProps<T>) {
  const { t } = useTranslation();
  const { formikBag, className = "me-1", label = t("Save"), size, canSubmitDirty, variant } = props;

  // This is required to work with our api-formik-submitter
  const status = formikBag.status as { success?: boolean; submittedValues?: T } | undefined;

  const canSubmit = status?.submittedValues != undefined || formikBag.dirty || canSubmitDirty;
  const isSubmitted = status?.submittedValues != undefined && isEqual(formikBag.values, status.submittedValues);

  const isDisabled = formikBag.isSubmitting || !formikBag.isValid || !canSubmit || isSubmitted;

  return (
    <>
      <Button type="submit" className={className} variant={variant} disabled={isDisabled} size={size}>
        {label}
      </Button>
      {isSubmitted && formikBag.submitCount > 0 && <qmBaseIcons.Check color="success" title={t("Saved")} />}
    </>
  );
}
export default FormikSubmitButton;
