import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IAuditRequirementDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { useToggle } from "@/hooks/useToggle";
import { wrapApiCallWithToast } from "../../../../../pbdServices/services/Api/api-wrapper";
import CategoryTable from "../../../../categories/components/categoryTable";
import { SettingsRoutePaths } from "../../../../settings/settingsRoutePaths";
import { TableClickCommand } from "../../tables/TableClickCommand";
import { ConnectedCardProps } from "../connectedCardProps";
import ConnectedCardHeader from "../shared/connectedCardHeader";
import ConnectAuditRequirementsModal from "./connectAuditRequirementsModal";

type IProps = ConnectedCardProps<IAuditRequirementDTO> & { children?: React.ReactNode };

function AuditRequirementsConnectedCard(props: IProps) {
  const { t } = useTranslation();
  const { refreshParent, data, baseDTO, cardTitle = t("Audit requirements"), onDisconnectSubmit } = props;
  const confirm = useConfirmation();
  const [connectionModal, toggleConnectModal] = useToggle();
  const [selected, setSelected] = React.useState<{ id: number }[]>([]);

  const handleTableClick = async (command: TableClickCommand) => {
    const { action } = command;
    switch (action) {
      case "Delete":
        confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => x.id)));
        break;
      default:
        throw Error("Not implemented");
    }
  };

  const submitDeleteRequest = async (dto: number[]) => {
    if (!onDisconnectSubmit) throw Error("Missing disconnect");
    for (const i of dto) {
      const resp = await wrapApiCallWithToast(() => onDisconnectSubmit(i));
    }
    refreshParent?.();
  };

  const allConnected: { id: number }[] = [];
  allConnected.push(baseDTO);
  allConnected.push(...data);
  return (
    <Card className="mb-3">
      <ConnectedCardHeader
        title={
          <span>
            <i className="icon-qmBase-ProjectAndTaskManagement" /> {cardTitle}
          </span>
        }
        toggleConnectModal={props.onConnectSubmit ? toggleConnectModal : undefined}
        data={data}
        canAdd={baseDTO.capabilities?.canEdit || baseDTO.capabilities?.canConnectTask}
      />
      {data && data.length > 0 && (
        <Card.Body>
          {props.children}
          <CategoryTable
            tableRows={data}
            columnsVisibleDefault={["title"]}
            titleColumnBaseLink={SettingsRoutePaths.EditPageAuditRequirements}
            setSelected={setSelected}
            availableTableActions={onDisconnectSubmit ? ["Delete"] : undefined}
            onClick={handleTableClick}
            onCreateClick={props.onConnectSubmit ? toggleConnectModal : undefined}
          />
        </Card.Body>
      )}
      {props.onConnectSubmit && refreshParent && (
        <ConnectAuditRequirementsModal
          modal={connectionModal}
          toggle={toggleConnectModal}
          connectedElements={allConnected}
          onSubmit={props.onConnectSubmit}
          // Needs to be handled via logic
          canSubmit={true}
          refreshParent={refreshParent}
        />
      )}
    </Card>
  );
}

export default AuditRequirementsConnectedCard;
