import useSWR from "swr";

import { useAPIs } from "../../../../../services/serviceContext";

import CustomSelect from "./customSelect";

interface IProps {
  onChange: (id?: number | number[]) => void;
  selectedIds?: number[];
  isMulti?: boolean;
  isClearable?: boolean;
}

function GoalSelect(props: IProps) {
  const { onChange, selectedIds, isMulti, isClearable } = props;
  const { goalsApi } = useAPIs();
  const { data } = useSWR("api/goals", () => goalsApi.getAll());

  if (!data) return null;

  return (
    <CustomSelect
      data={data}
      isClearable={isClearable}
      onChange={onChange}
      name="goalId"
      isMulti={isMulti}
      selectedIds={selectedIds}
    />
  );
}

export default GoalSelect;
