import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { EmployeeRoutePaths } from "./employeeRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.Employees_Standard, PbdRoles.Employees_ModuleAdmin],
};

export const employeeLazyRoutes: RouteObject[] = [
  {
    path: EmployeeRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageEmployees")),
    ...defaultRouteParams,
  },
  {
    path: EmployeeRoutePaths.IndexPage,
    Component: lazy(() => import("./pages/indexPageEmployees")),
    ...defaultRouteParams,
  },
  {
    path: EmployeeRoutePaths.CreatePage,
    Component: lazy(() => import("./pages/createPageEmployee")),
    ...defaultRouteParams,
  },
  {
    path: EmployeeRoutePaths.EditPage,
    Component: lazy(() => import("./pages/detailsPageEmployee")),
    ...defaultRouteParams,
  },
  {
    path: EmployeeRoutePaths.IndexPageDepartments,
    Component: lazy(() => import("../departments/pages/indexPageDepartments")),
    ...defaultRouteParams,
  },
  {
    path: EmployeeRoutePaths.DetailsPageDepartment,
    Component: lazy(() => import("../departments/pages/detailsPageDepartment")),
    ...defaultRouteParams,
  },
  {
    path: EmployeeRoutePaths.IndexPageDepartmentPositions,
    Component: lazy(() => import("../departmentPositions/pages/indexPageDepartmentPositions")),
    ...defaultRouteParams,
  },
  {
    path: EmployeeRoutePaths.DetailsPageDepartmentPosition,
    Component: lazy(() => import("../departmentPositions/pages/detailsPageDepartmentPosition")),
    ...defaultRouteParams,
  },
  {
    path: EmployeeRoutePaths.OrganizationChart,
    Component: lazy(() => import("./pages/organizationChartPage")),
    ...defaultRouteParams,
  },
  {
    path: EmployeeRoutePaths.OrganizationChartNew,
    Component: lazy(() => import("./pages/organizationChartPageLatest")),
    ...defaultRouteParams,
  },
  {
    path: EmployeeRoutePaths.OrganizationRfChart,
    Component: lazy(() => import("./pages/organizationRfChartPage")),
    ...defaultRouteParams,
  },
  {
    path: EmployeeRoutePaths.BirthdayPage,
    Component: lazy(() => import("./pages/birthdayPage")),
    ...defaultRouteParams,
  },
];
