import "../../pbdServices/services/i18n/i18n";

// import "../wydr";

/**
 * This will also handle some basic imports:
 *  i18n
 */
export function getReactAppNode() {
  //This can be used to work with mock test data in the whole ab
  // console.log(process.env.NODE_ENV);
  if (process.env.NODE_ENV === "development") {
    // Things you want to test in dev
  }

  const div = document.getElementById("root");
  if (!div) throw Error("The main app div in html page is missing");
  return div;
}
