import React, { memo } from "react";
import { Handle, NodeProps, NodeResizer, Position } from "reactflow";

import { NodeOrEdgeData } from "../models/pbd-flow-utils";

const diamondStyle: React.CSSProperties = {
  width: 40,
  height: 40,
  transform: "translate(-50%, -50%) rotate(45deg)",
  background: "white",
  position: "absolute",
  left: "50%",
  top: "50%",
  border: "1px solid #222",
  borderRadius: 2,
};

const labelStyle: React.CSSProperties = {
  zIndex: 10,
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
};

const handleStyle = {
  zIndex: 1,
};

function DiamondNode({ data, selected }: NodeProps<NodeOrEdgeData>) {
  return (
    <div>
      <NodeResizer isVisible={selected} />
      <Handle type="target" position={Position.Top} style={handleStyle} />
      <Handle type="source" position={Position.Bottom} style={handleStyle} />
      <div style={diamondStyle} />
      <div style={labelStyle}>{data.label}</div>
    </div>
  );
}

export default memo(DiamondNode);
