import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "bpmn-js/dist/assets/bpmn-js.css";
import "bpmn-js/dist/assets/diagram-js.css";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import GenericAlert from "../../shared/components/alerts/genericAlert";
import IdComponent from "../../shared/components/id/idComponent";
import FormattedUserInput from "../../shared/components/text/formattedUserInput";

import { CustomBpmnBusinessObject, CustomBpmnEvent } from "./bpmnModels";

const CustomBpmnViewerInner = React.lazy(() => import("./customBpmnViewerInner"));

interface IProps {
  diagramXML: string;
  legends?: Record<string, string>;
  printMode?: boolean;
}

export function CustomBpmnViewer(props: IProps) {
  const { legends, printMode } = props;
  const [show, setShow] = useState<CustomBpmnBusinessObject>();
  const [error, setError] = useState<unknown>();
  const handleClose = () => setShow(undefined);

  const handleOnClick = (e: CustomBpmnEvent) => {
    if (legends?.[e.element.businessObject.id]) {
      setShow(e.element.businessObject);
    }
  };

  return (
    <>
      {error && <GenericAlert type="danger">{JSON.stringify(error)}</GenericAlert>}
      <Modal show={show != undefined} onHide={handleClose}>
        <Modal.Header closeButton>
          {show && (
            <Modal.Title>
              {show.name} <IdComponent id={show.id} />
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>{legends && show && <FormattedUserInput content={legends[show.id]} />}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <CustomBpmnViewerInner {...props} onClick={handleOnClick} onError={(e) => setError(e)} printMode={printMode} />
    </>
  );
}
