import i18next from "i18next";
import React from "react";
import { CellProps, Column } from "react-table";

import { ValidationResult } from "../../../../../pbdServices/Models/Shared/validation-result";
import WarningTableCell from "../cells/warningTableCell";

interface IProps {
  warnings?: ValidationResult[];
}

/**
 * This column requires a property of type tags:TagDTO
 */
export function getWarningsColumn<T extends IProps>() {
  const column: Column<T> = {
    id: "warnings",
    Header: <>{i18next.t("Warnings")}</>,
    accessor: "warnings",
    Cell: ({ row }: CellProps<T>) => (
      <>
        <WarningTableCell warnings={row.original.warnings} />
      </>
    ),
  };
  return column;
}
