// This is based on this idea
// https://dev.to/dmtrkovalenko/the-neatest-way-to-handle-alert-dialogs-in-react-1aoe
import React from "react";

import ConfirmDeleteModalGlobal, {
  ConfirmationOptions,
} from "../../ClientApp/shared/components/modals/confirmDeleteModalGlobal";

const ConfirmationServiceContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(() =>
  Promise.reject(new Error("Confirmation rejected")),
);

export const useConfirmation = () => React.useContext(ConfirmationServiceContext);

interface IProps {
  children: React.ReactNode;
}

export const ConfirmationServiceProvider = ({ children }: IProps) => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    // setOpen(false);
    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    // setOpen(false);
    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>{children}</ConfirmationServiceContext.Provider>
      <ConfirmDeleteModalGlobal
        open={confirmationState != null}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
};
