import useSWR from "swr";

import { useAPIs } from "../../../../../services/serviceContext";

import CustomSelect from "./customSelect";
import { ICustomSelectProps } from "./models/customSelectProps";

interface IProps extends ICustomSelectProps {
  inputId?: string;
}

function InventoryStatusSelect(props: IProps) {
  const { inventoryStatusApi } = useAPIs();
  const { inputId = "inventoryStatusId" } = props;
  const { data } = useSWR("/api/inventoryStatus", () => inventoryStatusApi.getAll());

  if (!data) return null;

  return <CustomSelect data={data} name={inputId} {...props} />;
}

export default InventoryStatusSelect;
