import { ITenantDTO } from "@/generatedCode/pbd-core/pbd-core-api";

export const tenantFormatService = (tenant: ITenantDTO) => {
  return {
    secondaryPositions: () => {
      return (
        tenant.departmentPositions
          ?.map((y) => y.department?.title.concat(" - ", y.title ?? ""))
          .filter(
            (y) =>
              y !=
              tenant.primaryDepartmentPosition?.department.title.concat(" - ", tenant.primaryDepartmentPosition.title),
          )
          .join(", ") ?? ""
      );
    },
  };
};
