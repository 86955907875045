import {
  IOpportunityCategoriesControllerClient,
  IOpportunityCategoryDTO,
  IOpportunityCategoryFormulaDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { FormulaHelpers } from "../../../utils/formulaHelpers";
import { getMaxResult, getMinResult } from "../Calculator/getMinAndMaxResult";

export default class OpportunityCategoryService {
  private api: IOpportunityCategoriesControllerClient;
  constructor(api: IOpportunityCategoriesControllerClient) {
    this.api = api;
  }

  static validateFormulaForm(values: IOpportunityCategoryFormulaDTO, itemToUpdate: IOpportunityCategoryDTO) {
    if (itemToUpdate.variables != undefined) {
      const isValid = FormulaHelpers.isValid(values.formula, itemToUpdate.variables);
      if (isValid.isValid) {
        values.minResult = getMinResult(FormulaHelpers.sanitizeFormula(values.formula), itemToUpdate.variables);
        values.maxResult = getMaxResult(FormulaHelpers.sanitizeFormula(values.formula), itemToUpdate.variables);
      }
    }
  }
}
