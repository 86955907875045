import React from "react";
import { Button, ButtonGroup, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  ConnectTeamMembersDTO,
  ConnectionsToEightDReportDTO,
  EightDReportStep,
  EightDReportStep as EightDReportStepNew,
  IConnectionsToEightDReportDTO,
  IEightDReportEditDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../services/serviceContext";

import { useToggle } from "@/hooks/useToggle";
import { EightDReportStepDataValue } from "../../../Models/EightDReports/EightDReportStep";
import { EightDReportTeamMemberDTO } from "../../../Models/EightDReports/EightDReportTeamMemberDTO";
import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import { PrintOptions } from "../../prints/components/printoutIncludeOptions";
import { PrintRoutePaths } from "../../prints/printRoutePaths";
import ConnectTenantsModal from "../../shared/components/connectionElements/tenants/connectTenantsModal";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { isDimensionReady } from "../helpers/helperFunctions";
import EightDReportDescriptionForm, { DescriptionFormValues } from "./eightDReportDescriptionForm";
import EightDReportDescriptionRenderer from "./eightDReportDescriptionRenderer";
import FilloutSectionPlaceholder from "./filloutSectionPlaceholder";
import TeamTable from "./teamTable";

interface IProps {
  itemToUpdate: IEightDReportEditDTO;
  refreshParent: () => void;
  contributors: EightDReportTeamMemberDTO[];
  printOptions?: PrintOptions;
  step: EightDReportStepDataValue;
}

function TeamCard(props: IProps) {
  const { itemToUpdate, refreshParent, printOptions, step, contributors } = props;
  const { t } = useTranslation();
  const { eightDReportsApi } = useAPIs();
  const location = useLocation();
  const isPrintView = location.pathname == PrintRoutePaths.EightDReports.replace(":id", itemToUpdate.id.toString());

  const [editMode, toggleEditMode] = useToggle();
  const [connectTenantsModal, setConnectTenantsModal] = React.useState(false);

  const toggleConnectTenantsModal = () => setConnectTenantsModal(!connectTenantsModal);

  const handleSetTeam = (values: DescriptionFormValues) => {
    const item: IConnectionsToEightDReportDTO = {
      step: EightDReportStepNew.Team,
      description: values.description,
      teamLeaderId: contributors.find((x) => x.isTeamLeader)?.id,
      connectionIds: contributors.map((x) => x.tenant.id),
      showClaim: false,
    };
    return eightDReportsApi.setReportStep(itemToUpdate.id, new ConnectionsToEightDReportDTO(item));
  };

  const handleMarkAsTeamLeader = async (dto: EightDReportTeamMemberDTO) => {
    const item: IConnectionsToEightDReportDTO = {
      step: EightDReportStepNew.Team,
      description: itemToUpdate.teamDescription,
      teamLeaderId: dto.id,
      connectionIds: contributors.map((x) => x.tenant.id),
      showClaim: false,
    };
    const resp = await wrapApiCallWithToast(() =>
      eightDReportsApi.setReportStep(itemToUpdate.id, new ConnectionsToEightDReportDTO(item)),
    );
    if (resp.isOk) {
      refreshParent();
    }
  };

  const handleDelete = async (dto: EightDReportTeamMemberDTO) => {
    const contributorsNew = contributors
      .filter((x) => x.isMember)
      .map((x) => x.id)
      .filter((x) => x != dto.id);
    const item: IConnectionsToEightDReportDTO = {
      step: EightDReportStepNew.Team,
      description: itemToUpdate.teamDescription,
      teamLeaderId: contributors.find((x) => x.isTeamLeader)?.id,
      connectionIds: contributorsNew,
      showClaim: false,
    };
    const resp = await wrapApiCallWithToast(() =>
      eightDReportsApi.setReportStep(itemToUpdate.id, new ConnectionsToEightDReportDTO(item)),
    );
    if (resp.isOk) {
      refreshParent();
    }
  };

  const handleConnectTeamMembers = async (tenantsIds: number[]) => {
    const resp = await wrapApiCallWithToast(() =>
      eightDReportsApi.connectTeamMembers(itemToUpdate.id, new ConnectTeamMembersDTO({ teamMembers: tenantsIds })),
    );
    if (resp.isOk) {
      refreshParent();
    }
  };

  if (printOptions?.showOnlyCompletedSections && !isDimensionReady(itemToUpdate, EightDReportStep.Team)) return null;

  return (
    <Card className="mb-3">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <Card.Title as="h5">{t("D1 - Team")}</Card.Title>
          <div>
            <ButtonGroup>
              {!isPrintView && (
                <Button onClick={toggleEditMode}>
                  <qmBaseIcons.Pencil />
                </Button>
              )}
              {!isPrintView && editMode && (
                <Button onClick={toggleConnectTenantsModal}>
                  <qmBaseIcons.Users /> {t("Add member")}
                </Button>
              )}
            </ButtonGroup>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <TeamTable
          items={contributors}
          isEdit={editMode}
          onMarkAsTeamLeader={handleMarkAsTeamLeader}
          onRemove={handleDelete}
          onAdd={(e) => handleConnectTeamMembers([e.id])}
        />
      </Card.Body>
      {!editMode ? (
        <Card.Body>
          {!isPrintView && (
            <FilloutSectionPlaceholder show={!itemToUpdate.isTeamReady} toggleEditMode={toggleEditMode} />
          )}

          {itemToUpdate.teamDescription && (
            <EightDReportDescriptionRenderer
              includeImagesInPrintView={printOptions?.includeImages}
              description={itemToUpdate.teamDescription}
            />
          )}
        </Card.Body>
      ) : (
        <Card.Body>
          <EightDReportDescriptionForm
            onSubmit={(val) => handleSetTeam(val)}
            valueToUpdate={itemToUpdate.teamDescription}
            heading={t("Description")}
            toggleEditMode={toggleEditMode}
            onSuccess={() => {
              refreshParent();
              toggleEditMode();
            }}
            step={step}
          />

          {connectTenantsModal && (
            <ConnectTenantsModal
              modal={connectTenantsModal}
              toggle={toggleConnectTenantsModal}
              connectedElements={contributors.map((x) => x.tenant)}
              onSubmit={handleConnectTeamMembers}
              refreshParent={refreshParent}
              canSubmit
            />
          )}
        </Card.Body>
      )}
    </Card>
  );
}

export default TeamCard;
