import { Formik } from "formik";
import { CardTitle, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IEmployeeIdeaReviewConfiguration } from "@/generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import FeatureFlagService from "../../../pbdServices/services/FeatureFlags/featureFlagService";
import { nameofFactory } from "../../../utils/nameofFactory";
import { FormikCheckbox } from "../../shared/components/forms/formik/formikCheckboxInput";
import FormikCustomForm from "../../shared/components/forms/formik/formikCustomForm";
import { FormikNumberInputGroup } from "../../shared/components/forms/formik/formikNumberInput";
import { toastSuccess } from "../../shared/components/toasts/toastSuccess";
import FormikStringArrayInput from "../../todos/components/formikStringArrayInput";

const nameof = nameofFactory<IEmployeeIdeaReviewConfiguration>();

interface IProps {
  data: IEmployeeIdeaReviewConfiguration;
  onSubmit: (dto: IEmployeeIdeaReviewConfiguration) => Promise<void>;
}

function ReviewAreaConfiguration(props: IProps) {
  const { t } = useTranslation();
  const { data, onSubmit } = props;

  const submitter = useFormikAPISubmitter<IEmployeeIdeaReviewConfiguration>(
    (val) => onSubmit(val),
    [onSubmit],
    () => toastSuccess(t("Saved")),
  );

  return (
    <>
      <CardTitle as="h5">{t("Review area")}</CardTitle>
      <hr />
      <Formik
        initialValues={data}
        onSubmit={submitter}
        //   validationSchema={ValidationSchema}
      >
        {(formikBag) => (
          <FormikCustomForm formikBag={formikBag}>
            <FormikCheckbox
              name={nameof("isActive")}
              label={t("Active")}
              formText={t("Use expert opinions during the processing of ideas")}
            />
            {FeatureFlagService.isAdvancedIdeaReviewConfigurationAvailable() && (
              <>
                <FormikCheckbox name={nameof("requireReviewBeforeComplete")} label={t("requireReviewBeforeComplete")} />
                <FormikCheckbox name={nameof("requireApprovals")} label={t("requireApprovals")} />
                <FormikNumberInputGroup
                  name={nameof("requiredNumberOfApprovals")}
                  label={t("requiredNumberOfApprovals")}
                />
                <Form.Group className="mb-3" controlId={nameof("requiredRolesForReview")}>
                  <Form.Label>{t("Required roles for approval")}</Form.Label>
                  <FormikStringArrayInput name={nameof("requiredRolesForReview")} />
                </Form.Group>
              </>
            )}
          </FormikCustomForm>
        )}
      </Formik>
    </>
  );
}
export default ReviewAreaConfiguration;
