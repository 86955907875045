import { TFunction } from "i18next";
import * as yup from "yup";

export const requiredStringSchema = (t: TFunction) => yup.string().required(t("This field is required"));

// TODO: Not tested
export const requiredTitleStringSchema = (t: TFunction) =>
  yup.string().required(t("This field is required")).min(2).max(250).label(t("Title"));

export const notRequiredStringSchema = () => yup.string();

/**
 * This validation can be used for descriptions in general for fields like:
 * description: string | undefined
 */
export const StringOrUndefinedSchema: yup.Lazy<any> = yup.lazy((value) => {
  if (value == null) {
    return yup.string().nullable();
  }
  switch (typeof value) {
    case "undefined":
      return yup.string();
    case "string":
      return yup.string().strict();
    default:
      throw new yup.ValidationError("Value must be a string or `undefined`");
  }
});
