import React from "react";

import { RenderHtmlUserInput } from "./renderHtmlUserInput";

interface IProps {
  content?: string;
  type?: "HTML";
  defaultValue?: React.ReactNode;
  italicDefaultValue?: boolean;
  ariaLabel?: string;
}

function FormattedUserInput(props: IProps) {
  const { content, defaultValue, type = "HTML", italicDefaultValue = true, ariaLabel } = props;
  if (!content && defaultValue) {
    if (italicDefaultValue) {
      return <em className="text-muted">{defaultValue}</em>;
    }
    return <>{defaultValue}</>;
  } else if (content) {
    switch (type) {
      case "HTML":
        return (
          <div aria-label={ariaLabel}>
            <RenderHtmlUserInput content={content} />
          </div>
        );
      default:
        throw Error("Not implemented");
    }
  } else {
    return null;
  }
}

export default FormattedUserInput;
