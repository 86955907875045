import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "./qmBaseIcons";

interface IProps {
  isPrivate?: boolean;
  className?: string;
}

function IsPrivateIcon(props: IProps) {
  const { isPrivate = false, className } = props;
  const { t } = useTranslation();

  if (isPrivate) {
    return <qmBaseIcons.Private className={className} title={t("Private")} />;
  } else {
    return <qmBaseIcons.Public className={className} title={t("Public")} />;
  }
}
export default IsPrivateIcon;
