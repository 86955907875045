import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { OpportunityRoutePaths } from "./opportunityRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.OpportunityManagement_Standard, PbdRoles.OpportunityManagement_ModuleAdmin],
};

export const opportunityLazyRoutes: RouteObject[] = [
  {
    path: OpportunityRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageOpportunities")),
    ...defaultRouteParams,
  },
  {
    path: OpportunityRoutePaths.IndexPage,
    Component: lazy(() => import("./pages/indexPageOpportunities")),
    ...defaultRouteParams,
  },
  {
    path: OpportunityRoutePaths.CreatePage,
    Component: lazy(() => import("./pages/createPageOpportunity")),
    ...defaultRouteParams,
  },
  {
    path: OpportunityRoutePaths.EditPage,
    Component: lazy(() => import("./pages/detailsPageOpportunity")),
    ...defaultRouteParams,
  },
  {
    path: OpportunityRoutePaths.IndexPageEvaluations,
    Component: lazy(() => import("../opportunityEvaluations/pages/indexPageOpportunityEvaluation")),
    ...defaultRouteParams,
  },
  {
    path: OpportunityRoutePaths.CreatePageEvaluations,
    Component: lazy(() => import("../opportunityEvaluations/pages/createPageOpportunityEvaluation")),
    ...defaultRouteParams,
  },
  {
    path: OpportunityRoutePaths.EditPageEvaluations,
    Component: lazy(() => import("../opportunityEvaluations/pages/editPageOpportunityEvaluation")),
    ...defaultRouteParams,
  },
];
