import i18next from "i18next";
import * as yup from "yup";

import { ITimeIntervalDTO, TimeInterval } from "@/generatedCode/pbd-core/pbd-core-api";

import { nullableString } from "./stringSchemas";

// Error goes away in non strict //@ts-expect-error TODO: Fix with better typings
const nullableProp: yup.ObjectSchema<Pick<ITimeIntervalDTO, "timeSpanISO">> = yup.object({
  timeSpanISO: nullableString,
});

export const timeIntervalSchema: yup.ObjectSchema<ITimeIntervalDTO> = yup
  .object({
    value: yup.number().required().moreThan(0).label(i18next.t("Interval")),
    type: yup.mixed<TimeInterval>().oneOf(Object.values(TimeInterval)).required(),
  })
  .concat(nullableProp);
