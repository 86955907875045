import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { ListGroup, ListGroupItem, ListGroupItemHeading } from "reactstrap";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import FeatureFlagService from "../../../../pbdServices/services/FeatureFlags/featureFlagService";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

const TaskManagementSubsidebar: React.FC = (props) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <ListGroup flush>
        <ListGroupItem>
          <ListGroupItemHeading>{t("Projects & Tasks")}</ListGroupItemHeading>
        </ListGroupItem>
      </ListGroup>

      <ListGroup flush>
        <ListGroupItem>
          <ListGroupItemHeading>{t("Tasks")}</ListGroupItemHeading>
        </ListGroupItem>
        {FeatureFlagService.isTaskPrioritizationAvailable() && (
          <ListGroupItem
            action
            tag={Link}
            to={SettingsRoutePaths.TaskManagementHome}
            active={pathname.startsWith(SettingsRoutePaths.TaskManagementHome)}
          >
            {t("Tasks")}
          </ListGroupItem>
        )}
        <ListGroupItem
          action
          tag={Link}
          to={SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.ToDoManagement)}
          active={pathname.startsWith(
            SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.ToDoManagement).toLowerCase(),
          )}
        >
          {t("Custom Fields")}
        </ListGroupItem>
      </ListGroup>
    </React.Fragment>
  );
};

export default TaskManagementSubsidebar;
