import Cookies from "js-cookie";

export const XSFR_TOKEN_NAME = "X-XSRF-TOKEN-CORE";

export default class XsfrService {
  static getXSFRToken() {
    const antiforgeryToken = Cookies.get(XSFR_TOKEN_NAME);
    if (!antiforgeryToken) {
      throw new Error("XSFR Token is missing");
    }
    const tokenHeader = { tokenName: antiforgeryToken };
    return tokenHeader;
  }
}
