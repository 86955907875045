import { TFunction } from "i18next";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { MeAsUser } from "../../pbdServices/services/UserSettings/models/me-as-user";

import { PbdRoles } from "./PbdRoles";

/**Admin will be automatically included and overrides everything. User has to be in any of these roles */
export const hasRole = (user: MeAsUser, roles: string[], includeAdmin = true, includeDev = true) => {
  if (roles.length == 1 && roles[0] == PbdRoles.Dev && includeDev) {
    return isInDev(user);
  }
  if (includeAdmin) {
    roles.push(PbdRoles.Admin);
  }
  return user != null && roles.some((role) => user.roles.includes(role));
};

export const isQmUser = (user: MeAsUser) => user.user.email?.toUpperCase().endsWith("@QMBASE.COM");

export const isInPreview = (user: MeAsUser) => user != null && user.roles.includes(PbdRoles.Preview);

export const isInReadOnly = (user: MeAsUser) => {
  return user != null && user.user.claims?.find((x) => x.claimType == "ReadOnlyUser")?.claimValue == "True";
};

const isInDev = (user: MeAsUser) => user != null && user.roles.includes(PbdRoles.Dev);

export const getCapabiltiesOfRole = (role: string) => (role.includes("_") ? role.split("_")[1] : role);

/**
 * This functions splits predefined roles to translated readable roles
 * @param role ClaimManagement_Admin
 * @param t
 * @returns Reklamationsmanagement Admin
 */
export const convertRoleToHumanReadable = (role: string, t: TFunction) => {
  if (role == PbdRoles.Admin) {
    return "Admin";
  } else if (role.includes("_")) {
    return `${t(role.split("_")[0])} ${t(role.split("_")[1])}`;
  }
  return role;
};

export const getModuleAdminRoleName = (module: PbdModule) => {
  switch (module) {
    case PbdModule.IdeaManagement:
      return PbdRoles.IdeaManagement_ModuleAdmin;
    case PbdModule.AbsencePlanner:
      return PbdRoles.AbsencePlanner_ModuleAdmin;
    case PbdModule.CustomForms:
      return PbdRoles.CustomForms_ModuleAdmin;
    case PbdModule.ClaimManagement:
      return PbdRoles.ClaimManagement_ModuleAdmin;
    case PbdModule.Employees:
      return PbdRoles.Employees_ModuleAdmin;
    case PbdModule.Blog:
      return PbdRoles.Blog_ModuleAdmin;
    case PbdModule.DocumentManagement:
      return PbdRoles.DocumentManagement_ModuleAdmin;
    case PbdModule.KnowledgeBase:
      return PbdRoles.KnowledgeBase_ModuleAdmin;
    case PbdModule.QualificationMatrix:
      return PbdRoles.QualificationMatrix_ModuleAdmin;
    case PbdModule.ProjectAndTaskManagement:
      return PbdRoles.ProjectAndTaskManagement_ModuleAdmin;
    case PbdModule.AuditManagement:
      return PbdRoles.AuditManagement_ModuleAdmin;
    case PbdModule.GoalManagement:
      return PbdRoles.GoalManagement_ModuleAdmin;
    case PbdModule.Crm:
      return PbdRoles.Crm_ModuleAdmin;
    case PbdModule.TrainingManagement:
      return PbdRoles.Trainings_ModuleAdmin;
    case PbdModule.MaintenanceManagement:
      return PbdRoles.MaintenanceManagement_ModuleAdmin;
    case PbdModule.OpportunityManagement:
      return PbdRoles.OpportunityManagement_ModuleAdmin;
    case PbdModule.Products:
      return PbdRoles.Products_ModuleAdmin;
    case PbdModule.DefectManagement:
      return PbdRoles.DefectManagement_ModuleAdmin;
    case PbdModule.Admin:
      return PbdRoles.Admin;
    case PbdModule.Store:
      return PbdRoles.Admin;
    case PbdModule.QualityMonitor:
      return PbdRoles.QualityMonitor_ModuleAdmin;
    case PbdModule.Startpage:
      return undefined;
    default:
      console.log(module);
      throw Error(`Not implemented for app: ${module}`);
  }
};

export const getModuleStandardRoleName = (module: PbdModule) => {
  switch (module) {
    case PbdModule.IdeaManagement:
      return PbdRoles.IdeaManagement_Standard;
    case PbdModule.AbsencePlanner:
      return PbdRoles.AbsencePlanner_Standard;
    case PbdModule.CustomForms:
      return PbdRoles.CustomForms_Standard;
    case PbdModule.ClaimManagement:
      return PbdRoles.ClaimManagement_Standard;
    case PbdModule.Employees:
      return PbdRoles.Employees_Standard;
    case PbdModule.Blog:
      return PbdRoles.Blog_Standard;
    case PbdModule.DocumentManagement:
      return PbdRoles.DocumentManagement_Standard;
    case PbdModule.KnowledgeBase:
      return PbdRoles.KnowledgeBase_Standard;
    case PbdModule.QualificationMatrix:
      return PbdRoles.QualificationMatrix_Standard;
    case PbdModule.ProjectAndTaskManagement:
      return PbdRoles.ProjectAndTaskManagement_Standard;
    case PbdModule.AuditManagement:
      return PbdRoles.AuditManagement_Standard;
    case PbdModule.GoalManagement:
      return PbdRoles.GoalManagement_Standard;
    case PbdModule.Crm:
      return PbdRoles.Crm_Standard;
    case PbdModule.TrainingManagement:
      return PbdRoles.Trainings_Standard;
    case PbdModule.MaintenanceManagement:
      return PbdRoles.MaintenanceManagement_Standard;
    case PbdModule.OpportunityManagement:
      return PbdRoles.OpportunityManagement_Standard;
    case PbdModule.Products:
      return PbdRoles.Products_Standard;
    case PbdModule.DefectManagement:
      return PbdRoles.DefectManagement_Standard;
    case PbdModule.Admin:
      return PbdRoles.Admin;
    case PbdModule.Store:
      return PbdRoles.Admin;
    case PbdModule.Startpage:
      return undefined;
    default:
      console.log(module);
      throw Error("Undefined");
  }
};
