import { EmployeeIdeaQueryParameters } from "../../pbdServices/services/IdeaManagement/models/query-parameters";
import RestUtilities from "../../services/restClients/restUtilities";

const routePrefix = "/IdeaManagement";

export class IdeaManagementRoutePaths {
  public static HomePage = routePrefix;
  public static Overviews = `${routePrefix}/Overviews`;
  public static IndexPage = `${routePrefix}/Index`;
  public static IndexPageFn = (qs: EmployeeIdeaQueryParameters) =>
    `${routePrefix}/Index${RestUtilities.getQueryString(qs)}`;
  public static DetailsPage = `${routePrefix}/Details/:id`;
  public static DetailsPageFn = (id: number) => `${routePrefix}/Details/:id`.replace(":id", id.toString());
  public static DetailsPageNew = `${routePrefix}/DetailsNew/:id`;
  public static EditPage = `${routePrefix}/Edit/:id`;
  public static CreatePage = `${routePrefix}/Create`;
  public static Review = `${routePrefix}/Review/:id`;
  public static BestPractices = `${routePrefix}/BestPractices`;
  public static ImageUploadUrl = "/api/employeeIdeas/:id/images";
  public static CommentsPage = `${routePrefix}/Comments`;
  public static NotificationsPage = `${routePrefix}/Notifications`;
}
