import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import Select, { OnChangeValue } from "react-select";

import { ReactSelectDTO } from "../../../../Models/ReactSelect/reactSelectDTO";

import { FilterElement, SearchFilterTypes } from "./availableSearchFilters";

interface IProps {
  data?: FilterElement[];
  selected: FilterElement[];
  onSelect: (value: SearchFilterTypes) => void;
  inputId: string;
}

function AddFilterComponent(props: IProps) {
  const { data, selected, onSelect, inputId } = props;
  const { t } = useTranslation();

  const options = React.useMemo(
    () =>
      (data ?? [])
        .filter((x) => !selected.map((y) => y.type).includes(x.type))
        .map((f) => ({ label: f.label, value: f.type })),
    [data, selected],
  );

  const handleChange = React.useCallback(
    (newValue: OnChangeValue<ReactSelectDTO, false>) => {
      if (newValue && !Array.isArray(newValue)) {
        const option = newValue;
        onSelect(option.value as SearchFilterTypes);
      } else {
        throw Error("Input not allowed");
      }
    },
    [onSelect],
  );

  return (
    <>
      {options.length == 0 && <div>{t("All filters active")}</div>}
      {options.length > 0 && (
        <Select
          key={"filterSelect"}
          menuPosition="fixed"
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          options={options}
          onChange={handleChange}
          controlShouldRenderValue={false}
          placeholder={`${t("Select new filter")}...`}
          inputId={inputId}
          getOptionLabel={(x) => getLabel(x, t)}
        />
      )}
    </>
  );
}

function getLabel(val: ReactSelectDTO | null, t: TFunction) {
  return val ? t(val.label) : "";
}
export default AddFilterComponent;
