import { Form as FormFormik, Formik } from "formik";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import * as yup from "yup";

import { ILoginWithRecoveryCodeDTO, LoginWithRecoveryCodeDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../services/serviceContext";

import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import { FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";
import CardFooterAccount from "../components/cardFooterAccount";

const ValidationSchema: yup.ObjectSchema<ILoginWithRecoveryCodeDTO> = yup.object({
  recoveryCode: yup.string().required(),
});

function LoginWithRecoveryCodePage() {
  const { t } = useTranslation();
  const { accountApi } = useAPIs();
  const [query, setQuery] = useQueryParams({
    returnUrl: withDefault(StringParam, undefined),
  });

  const submitter = useFormikAPISubmitter<ILoginWithRecoveryCodeDTO>(
    (values) => accountApi.loginWithRecoveryCode(new LoginWithRecoveryCodeDTO(values)),
    [accountApi],
    () => {
      let urlAfterLogin = query.returnUrl ? query.returnUrl : "/";
      if (urlAfterLogin.toLowerCase().includes("error")) {
        urlAfterLogin = "/";
      }
      const href = urlAfterLogin;
      window.location.href = href;
    },
  );
  const initialValues: ILoginWithRecoveryCodeDTO = {
    recoveryCode: "",
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <h1>{t("Recovery code verification")}</h1>
        <hr />
        <p>
          {t(
            "You have requested to log in with a recovery code. This login will not be remembered until you provide an authenticator app code at log in or disable 2FA and log in again",
          )}
        </p>
        <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
          {(formikBag) => (
            <FormFormik>
              {formikBag.submitCount > 0 && (
                <ul className="text-danger">
                  {Object.values(formikBag.errors).map((x, i) => (
                    <li key={i}>{t(x)}</li>
                  ))}
                </ul>
              )}
              <FormikTextInputGroup name="recoveryCode" label={t("Code")} />
              <Button
                variant="primary"
                type="submit"
                className="me-1"
                disabled={formikBag.isSubmitting || !formikBag.isValid}
              >
                {t("Submit")}
              </Button>
            </FormFormik>
          )}
        </Formik>
        <CardFooterAccount />
      </Card.Body>
    </Card>
  );
}

export default LoginWithRecoveryCodePage;
