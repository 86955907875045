import {
  ChangeResponsibleDTO,
  ConnectClaimsDTO,
  ConnectDefectsDTO,
  ConnectToDosDTO,
  IProductDTO,
  IProductsControllerClient,
  ProductCreateDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { TableClickCommand } from "../../../ClientApp/shared/components/tables/TableClickCommand";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";
import { CopyForm, CopyIncludedOption } from "../copy/copyService";
import { ProductQueryParameters } from "./models/queryParameters";

export default class ProductService extends BaseExportService<IProductDTO> {
  api: IProductsControllerClient;
  constructor(api: IProductsControllerClient) {
    super("Products");
    this.api = api;
  }

  mapToExport(x: IProductDTO): ExportType {
    return {
      id: x.id,
      externalId: x.externalId,
      title: x.title,
      description: x.description,
      createdAt: x.createdAt,
    };
  }

  async exportToCsvFromCommand(command: TableClickCommand, query?: ProductQueryParameters): Promise<void> {
    if (!command.isAllSelected && command.selected == undefined) {
      throw Error("Wrong data");
    }

    const pageSize = command.isAllSelected
      ? GlobalQmBaseConstants.DefaultPageSize_DuringMigration
      : command.selected!.length;
    const ids = !command.isAllSelected ? command.selected?.map((x) => Number(x)) : undefined;
    const resp = await this.api.getAllQuery({ ...query, id: ids, pageSize });
    this.exportToCsv(resp.data);
  }

  async copyConnected(form: CopyForm, source: IProductDTO, item: IProductDTO) {
    if (form.options.tags && source.tags && source.tags.length > 0) {
      const tags = source.tags.map((x) => x.title);
      await this.api.updateTags(item.id, tags);
    }
    if (form.options.customFields && source.customFields && source.customFields.length > 0) {
      for (const field of source.customFields) {
        await this.api.addCustomField(item.id, field);
      }
    }

    if (form.options.defects) {
      const defects = await this.api.getDefectsProductId(source.id);
      if (defects.length > 0) {
        const dto = new ConnectDefectsDTO({
          defectIds: defects.map((x) => x.id),
        });
        await this.api.connectDefects(item.id, dto);
      }
    }

    if (form.options.claims) {
      const claims = await this.api.getClaims(source.id);
      if (claims.length > 0) {
        const dto = new ConnectClaimsDTO({
          claimIds: claims.map((x) => x.id),
        });
        await this.api.connectClaims(item.id, dto);
      }
    }

    if (form.options.todos) {
      const tasks = await this.api.getConnectedToDos(source.id);
      if (tasks.length > 0) {
        const dto = new ConnectToDosDTO({
          toDoIds: tasks.map((x) => x.id),
        });
        await this.api.addToDo(item.id, dto);
      }
    }
  }

  async copy(form: CopyForm, item: IProductDTO, responsibleId?: number) {
    const toCreate = new ProductCreateDTO({
      title: form.title,
      externalId: item.externalId,
      description: item.description,
    });

    const resp = await this.api.create(toCreate);

    if (responsibleId) {
      const dto = new ChangeResponsibleDTO({
        responsibleId: responsibleId,
      });
      await this.api.changeResponsible(resp.id, dto);
    }

    await this.copyConnected(form, item, resp);

    return resp;
  }

  static get copyModalOptions(): CopyIncludedOption[] {
    return ["customFields", "claims", "defects", "todos", "tags"];
  }
}
