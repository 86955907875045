/* eslint-disable @typescript-eslint/naming-convention */
export class SettingKeyNames {
  static readonly AllowedDomains = "AllowedDomains";
  static readonly EmailConfiguration = "EmailConfiguration";
  static readonly InterfaceSalesOrders = "InterfaceSalesOrders";
  static readonly InterfacePurchaseOrders = "InterfacePurchaseOrders";
  static readonly BankHolidays = "BankHolidays";
  static readonly WorkingDays = "WorkingDays";
  static readonly DefaultPermissions_Readonly = "DefaultPermissions_Readonly";
  static readonly DefaultPermissions = "DefaultPermissions";
  static readonly GettingStartedAdminDone = "GettingStartedAdminDone";
  static readonly BasicAdminData = "BasicAdminData";
  static readonly AuditTrailSettings = "AuditTrailSettings";
  static readonly ConnectionCategories = "ConnectionCategories";
  static readonly Currencies = "Currencies";
  static readonly InterfaceCalendar = "InterfaceCalendar";

  static readonly Global_PrintLogo = "Global_PrintLogo";
  static readonly TagSettings = "TagSettings";
  static readonly DefaultTheme = "DefaultTheme";
  static readonly StartPageFeatureSettings = "StartPageFeatureSettings";
  static readonly SampleDataInitialized = "SampleDataInitialized";
}
