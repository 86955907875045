import React from "react";
import { Button, FormText, ListGroup, Modal, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ISmartViewDTO, PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { useAppContext } from "@/components/contexts/appContext";
import { useFuzzy } from "@/hooks/useFuzzy";
import { useSmartViewsHook } from "@/hooks/useSmartViewsHook";
import { useTryDelete } from "../../../../pbdServices/services/Api/api-hooks";
import { useAPIs } from "../../../../services/serviceContext";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import SearchCriteria from "../search/searchCriteria";

import SmartViewForm from "./smartViewForm";
import SmartViewItem from "./smartViewItem";

interface IProps {
  module: PbdModule;
  hideEditButtons?: boolean;
  hideSaveButton?: boolean;
  /**In case the smart views get rendered in modal */
  toggleParentModal?: () => void;
  baseLink?: string;
}

function SmartViewTabs(props: IProps) {
  const { hideEditButtons, module, hideSaveButton, toggleParentModal, baseLink } = props;
  const { t } = useTranslation();
  const { smartViewsApi } = useAPIs();
  const { meAsUser } = useAppContext();
  const location = useLocation();
  const [selected, setSelected] = React.useState<ISmartViewDTO>();
  const [smartViewModal, setSmartViewModal] = React.useState(false);

  const { data, mutate, activeSmartViewId } = useSmartViewsHook(module);

  const { result, search } = useFuzzy<ISmartViewDTO>(data?.allVisible ?? [], {
    keys: ["name", "description"],
  });

  const tryToDelete = useTryDelete<ISmartViewDTO, string>(
    (val) => smartViewsApi.delete(module, val.id),
    [module, smartViewsApi],
    () => {
      mutate();
    },
  );

  const toggleSmartViewModal = (id?: string) => {
    if (id) {
      const sel = data?.allVisible.find((x) => x.id == id);
      setSelected(sel);
    } else {
      setSelected(undefined);
    }
    setSmartViewModal(!smartViewModal);
  };

  const handleSuccess = () => {
    setSelected(undefined);
    setSmartViewModal(false);
    mutate();
  };

  return (
    <>
      <Tabs variant="underline">
        <Tab
          eventKey="mySmartViews"
          title={
            <>
              <qmBaseIcons.User /> {t("My Smart Views")}
            </>
          }
        >
          <ListGroup>
            {data?.mySmartViews && data.mySmartViews.length == 0 && (
              <ListGroup.Item>
                <FormText muted>
                  <qmBaseIcons.QuestionCircle />{" "}
                  {t(
                    "You have not saved any Smart Views. Check out our help documentation to learn more about Smart Views.",
                  )}
                </FormText>
              </ListGroup.Item>
            )}
            {data?.mySmartViews.map((x) => (
              <SmartViewItem
                key={x.id}
                item={x}
                tryToDelete={tryToDelete}
                toggleSmartViewModal={toggleSmartViewModal}
                module={module}
                hideEditButtons={hideEditButtons}
                toggleParentModal={toggleParentModal}
                isActive={x.id == activeSmartViewId}
                baseLink={baseLink}
              />
            ))}
          </ListGroup>
        </Tab>
        <Tab
          eventKey="globalSmartViews"
          title={
            <>
              <qmBaseIcons.Globe /> {t("Smart Views")}
            </>
          }
        >
          <ListGroup>
            {data?.globalSmartViews.map((x) => (
              <SmartViewItem
                key={x.id}
                item={x}
                tryToDelete={tryToDelete}
                toggleSmartViewModal={toggleSmartViewModal}
                module={module}
                hideEditButtons={!hasRole(meAsUser, [PbdRoles.Admin])}
                toggleParentModal={toggleParentModal}
                isActive={x.id == activeSmartViewId}
                baseLink={baseLink}
              />
            ))}
          </ListGroup>
        </Tab>
        <Tab eventKey="searchSmartViews" title={<qmBaseIcons.Search />}>
          <ListGroup>
            <ListGroup.Item>
              <SearchCriteria placeholder={`${t("Search")}...`} doSearch={(s) => search(s)} />
            </ListGroup.Item>
            {result.map((x) => (
              <SmartViewItem
                key={x.id}
                item={x}
                tryToDelete={tryToDelete}
                toggleSmartViewModal={toggleSmartViewModal}
                module={module}
                hideEditButtons={true}
                toggleParentModal={toggleParentModal}
                baseLink={baseLink}
              />
            ))}
          </ListGroup>
        </Tab>
      </Tabs>

      {!hideEditButtons && (
        <>
          {!hideSaveButton && (
            <>
              <p>{t("Save the current filter as a new Smart View")}</p>
              <Button
                variant="outline-success"
                onClick={() => toggleSmartViewModal()}
                className="mb-3"
                disabled={location.search == ""}
              >
                <qmBaseIcons.Filter /> {t("Save")}
              </Button>
            </>
          )}

          <Modal show={smartViewModal} onHide={() => toggleSmartViewModal()}>
            <Modal.Header closeButton>
              <Modal.Title>{selected == null ? t("New") : t("Edit")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SmartViewForm
                itemToUpdate={selected}
                module={module}
                toggleModal={toggleSmartViewModal}
                onSuccess={handleSuccess}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default SmartViewTabs;
