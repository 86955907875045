import {
  ArticleSettings,
  ICustomField,
  IEmployeeIdeaSettings,
  IModuleSettingDTO,
  PbdModule,
  SettingType,
  StartpageAppSettingsDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { AbsencePlannerSettings } from "../../../../ClientApp/absencePlanner/absencePlannerRoutes";
import { DefectSettings } from "../../../../ClientApp/defects/defectRoutes";
import { GoalSettings } from "../../../../ClientApp/goals/goalRoutes";
import { MaintenanceManagementSettings } from "../../../../ClientApp/maintenanceManagement/maintenanceManagementRoutes";
import { ProductSettings } from "../../../../ClientApp/products/productRoutes";
import { TodoSettings } from "../../../../ClientApp/taskManagement/taskManagementRoutes";
import { AuditSettings } from "../../../../Models/Audits/AuditSettings";
import { ClaimSettings } from "../../../../Models/Claims/ClaimSettings";
import { OpportunitySettings } from "../../../../Models/Opportunities/OpportunitySettings";
import { OrganisationSettings } from "../../../../Models/Organisations/OrganisationSettings";
import { ExternalIdSettings } from "../../../../Models/Settings/ExternalIdSettings";
import { TrainingSettings } from "../../../../Models/Trainings/TrainingSettings";
import JsonHelpers from "../../../../services/Json/jsonHelpers";
import ModuleSettingsMapper from "../../../../services/ModuleSettings/moduleSettingsMapper";

export class AppSettingsVm {
  constructor(
    data: IModuleSettingDTO[],
    {
      blogSetting,
      docSetting,
      wikiSetting,
      startpageSettings,
      employeeIdeaSettings,
    }: {
      blogSetting: ArticleSettings;
      docSetting: ArticleSettings;
      wikiSetting: ArticleSettings;
      startpageSettings: StartpageAppSettingsDTO;
      employeeIdeaSettings: IEmployeeIdeaSettings;
    },
  ) {
    this[PbdModule.TenantManagement] = getTenantSettings(data.filter((x) => x.module == PbdModule.TenantManagement));
    this[PbdModule.Employees] = getTenantSettings(data.filter((x) => x.module == PbdModule.Employees));
    this[PbdModule.GoalManagement] = getGoalSettings(data.filter((x) => x.module == PbdModule.GoalManagement));
    this[PbdModule.Startpage] = startpageSettings;
    this[PbdModule.ToDoManagement] = getTodoSettings(data.filter((x) => x.module == PbdModule.ToDoManagement));
    this[PbdModule.IdeaManagement] = employeeIdeaSettings;
    this[PbdModule.ClaimManagement] = getClaimSettings(data.filter((x) => x.module == PbdModule.ClaimManagement));
    this[PbdModule.TrainingManagement] = getTrainingSettings(
      data.filter((x) => x.module == PbdModule.TrainingManagement),
    );
    this[PbdModule.OpportunityManagement] = getOpportunitySettings(
      data.filter((x) => x.module == PbdModule.OpportunityManagement),
    );
    this[PbdModule.AuditManagement] = getAuditSettings(data.filter((x) => x.module == PbdModule.AuditManagement));
    this[PbdModule.Organisations] = getOrganisationSettings(data.filter((x) => x.module == PbdModule.Organisations));
    this[PbdModule.Blog] = blogSetting;
    this[PbdModule.DocumentManagement] = docSetting;
    this[PbdModule.KnowledgeBase] = wikiSetting;
    this[PbdModule.AbsencePlanner] = getAbsencePlannerSettings(
      data.filter((x) => x.module == PbdModule.AbsencePlanner),
    );
    this[PbdModule.Products] = ModuleSettingsMapper.mapToObject(
      data.filter((x) => x.module == PbdModule.Products),
      new ProductSettings(),
    );
    this[PbdModule.DefectManagement] = ModuleSettingsMapper.mapToObject(
      data.filter((x) => x.module == PbdModule.DefectManagement),
      new DefectSettings(),
    );
    this[PbdModule.MaintenanceManagement] = ModuleSettingsMapper.mapToObject(
      data.filter((x) => x.module == PbdModule.MaintenanceManagement),
      new MaintenanceManagementSettings(),
    );
  }
  [PbdModule.TenantManagement]: TenantApp;
  [PbdModule.Employees]: TenantApp;
  [PbdModule.GoalManagement]: GoalSettings;
  [PbdModule.Startpage]: StartpageAppSettingsDTO;
  [PbdModule.ToDoManagement]: TodoSettings;
  [PbdModule.IdeaManagement]: IEmployeeIdeaSettings;
  [PbdModule.ClaimManagement]: ClaimSettings;
  [PbdModule.TrainingManagement]: TrainingSettings;
  [PbdModule.OpportunityManagement]: OpportunitySettings;
  [PbdModule.AuditManagement]: AuditSettings;
  [PbdModule.Organisations]: OrganisationSettings;
  [PbdModule.Blog]: ArticleSettings;
  [PbdModule.DocumentManagement]: ArticleSettings;
  [PbdModule.KnowledgeBase]: ArticleSettings;
  [PbdModule.AbsencePlanner]: AbsencePlannerSettings;
  [PbdModule.Products]: ProductSettings;
  [PbdModule.DefectManagement]: DefectSettings;
  [PbdModule.MaintenanceManagement]: MaintenanceManagementSettings;
}

// class EmployeeApp {
//   externalIdSetting?: ExternalIdSettings;
// }

export class TenantApp {
  externalIdSetting?: ExternalIdSettings;
  customFields?: ICustomField[];
}

const getTenantSettings = (data: IModuleSettingDTO[]) => {
  const dto = new TenantApp();
  const extSet = data.find((x) => x.type == SettingType.ExternalId);
  if (extSet?.value && typeof extSet.value == "string") {
    dto.externalIdSetting = JsonHelpers.parse<ExternalIdSettings>(extSet.value);
  }
  const customFieldSettings = data.find((x) => x.type == SettingType.CustomFields);
  if (customFieldSettings?.value && typeof customFieldSettings.value == "string") {
    dto.customFields = JsonHelpers.parseUnknown(customFieldSettings.value);
  }
  return dto;
};

const getGoalSettings = (data: IModuleSettingDTO[]) => ModuleSettingsMapper.mapToObject(data, new GoalSettings());

const getAbsencePlannerSettings = (data: IModuleSettingDTO[]) =>
  ModuleSettingsMapper.mapToObject(data, new AbsencePlannerSettings());

const getTodoSettings = (data: IModuleSettingDTO[]) => ModuleSettingsMapper.mapToObject(data, new TodoSettings());

const getClaimSettings = (data: IModuleSettingDTO[]) => {
  return ModuleSettingsMapper.mapToObject(data, new ClaimSettings());
};

const getTrainingSettings = (data: IModuleSettingDTO[]) => {
  return ModuleSettingsMapper.mapToObject(data, new TrainingSettings());
};

const getOpportunitySettings = (data: IModuleSettingDTO[]) => {
  return ModuleSettingsMapper.mapToObject(data, new OpportunitySettings());
};

const getAuditSettings = (data: IModuleSettingDTO[]) => {
  return ModuleSettingsMapper.mapToObject(data, new AuditSettings());
};

const getOrganisationSettings = (data: IModuleSettingDTO[]) => {
  return ModuleSettingsMapper.mapToObject<OrganisationSettings>(data, new OrganisationSettings());
};
