import React from "react";
import ReactAvatar from "react-avatar";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import AppIcon from "./appIcon";

interface IProps {
  thumbnailId?: number;
  pbdModule: PbdModule;
}

function AppInfoIconComponent(props: IProps) {
  const { thumbnailId } = props;

  const src = React.useMemo(() => (thumbnailId ? `/api/blobs/${thumbnailId}/sasUrl` : ""), [thumbnailId]);

  return (
    <div>
      {thumbnailId != 0 && src ? (
        <ReactAvatar src={src} size="80px" className="rounded-1" />
      ) : (
        <AppIcon app={props.pbdModule} />
      )}
    </div>
  );
}

export default AppInfoIconComponent;
