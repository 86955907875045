import { DateTime } from "luxon";

import config from "../config";

export interface ReleaseNote {
  releaseNoteId: string;
  createdById?: number;
  createdAt: DateTime;
  title: string;
  content?: string;
}

export type PersonTypeFromAdmin = "ManagingDirector" | "DataProtectionOfficer";

export default class AdminService {
  getChangelogAtom() {
    const t = (entry: Element, tname: string) => entry.getElementsByTagName(tname)[0];
    const date = (entry: Element) => {
      const nodeContent = t(entry, "updated").textContent;
      if (!nodeContent) throw Error("Missing updated date");
      return DateTime.fromISO(nodeContent);
    };

    const myRegexp = new RegExp(/<!\[CDATA\[(.*)\]\]>/);

    return fetch(config.changeLogUrl)
      .then((response) => response.text())
      .then((xml) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, "text/xml");
        return Array.from(xmlDoc.getElementsByTagName("entry")).map((x) => {
          const item: ReleaseNote = {
            releaseNoteId: t(x, "id").innerHTML,
            createdAt: date(x),
            title: myRegexp.exec(t(x, "title").innerHTML)[1],
          };
          return item;
        });
      });
  }

  /**This returns a changelog that is not older than 10 days */
  async getActiveChangelog() {
    try {
      const changelog = await this.getChangelogAtom();
      if (changelog.length > 0) {
        const latest = changelog[0];
        if (latest.createdAt > DateTime.now().minus({ days: 10 })) {
          return latest;
        }
      }
    } catch {
      console.log("Admin service not reachable");
    }
    return undefined;
  }
}
