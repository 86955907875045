import { Result } from "@badrap/result";
import { Parser } from "expr-eval";

import { FormulaVariables } from "./models/formulaVariable";

type CalculationResult = Result<number>;

export class CalculationService {
  static safeCalculationOfFormula(formula: string, variables: FormulaVariables): CalculationResult {
    const result = this.isValidForParsing(formula, variables);
    if (result.isOk) {
      return Result.ok(this.calculateFormula(formula, variables));
    } else {
      if (result.isErr) {
        return Result.err(result.error);
      } else {
        return Result.err(Error("Unknown error"));
      }
    }
  }
  static calculateFormula(formula: string, variables: FormulaVariables): number {
    return Parser.evaluate(formula, variables);
  }

  static simplify(formula: string, variables: FormulaVariables) {
    const output = Parser.parse(formula);
    return output.simplify(variables);
  }

  static isValidForParsing(formula: string | undefined, variables: FormulaVariables): Result<boolean> {
    if (!formula) return Result.err(Error("Missing formula"));
    const variablesInFormula = this.getVariablesInForm(formula);
    const variablesArray = Object.keys(variables);
    // Here we make sure that all varaibles in formular can we replaced.
    const isEqualVariables = variablesInFormula.every((x) => variablesArray.includes(x));
    // const isEqualVariables = isEqual(variablesInFormula, variablesArray);
    if (isEqualVariables) {
      return Result.ok(true);
    } else {
      return Result.err(Error("Missing variables"));
    }
  }

  static getVariablesInForm(formula: string) {
    const output = Parser.parse(formula);
    return output.variables();
  }
}
