import { PbdModuleString } from "../Enums/PbdModule";

export class LinkEnriched {
  constructor(link: HTMLAnchorElement) {
    this.url = link.href;
    this.textContent = link.textContent ?? "";
  }
  textContent: string;
  url: string;
  isExternal?: boolean;
  app?: PbdModuleString | null;
  id?: number | string | null;
  status?: 200 | 404 | 403 | number;
  private _customStatus?: "OK" | "Forbidden" | "Not found" | "Deleted" | "Withdrawn";
  get customStatus() {
    return this._customStatus;
  }
  set setCustomStatus(value: 200 | 403 | 404 | number | "Deleted" | "Withdrawn") {
    switch (value) {
      case 200:
        this._customStatus = "OK";
        break;
      case 403:
        this._customStatus = "Forbidden";
        break;
      case 404:
        this._customStatus = "Not found";
        break;
      case "Deleted":
      case "Withdrawn":
        this._customStatus = value;
        break;
      default:
        throw Error("Not implemented");
    }
  }
}
