import { KeyValue } from "../Shared/KeyValue";

export class ServiceResult {
  constructor(success = true) {
    this.success = success;
    this.errors = [];
    this.warnings = [];
  }
  success: boolean;
  errors: KeyValue[];
  addError(key: string, value: string) {
    this.errors.push({ key, value });
    this.success = false;
  }
  warnings: KeyValue[];
  addWarning(key: string, value: string) {
    this.warnings.push({ key, value });
  }
}
