import { get } from "lodash";
import { DateTime } from "luxon";

import {
  ConnectAuditsDTO,
  ConnectEntityCommand,
  ConnectGoalsDTO,
  ConnectInventoryItemDTO,
  ConnectOpportunitiesDTO,
  ConnectProductDTO,
  ConnectTenantDTO,
  ConnectToDosDTO,
  ConnectTrainingsDTO,
  EntityKey,
  ICheckListItemDTO,
  ICostDTO,
  IToDoDTO,
  IToDosControllerClient,
  PbdModule,
  ToDoCreateDTO,
  ToDoDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { AvailableConnection } from "../../../ClientApp/shared/components/connectionElements/generic/available-connection";
import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { DateTimeLuxonHelpers } from "../../../utils/dateTimeLuxonHelpers";
import { BaseExportService } from "../Base/BaseExportService";
import CostService from "../Costs/costService";
import { ExportType } from "../Export/exportService";
import TagService from "../Tags/tagService";
import { CopyForm, CopyIncludedOption } from "../copy/copyService";
import { ToDoQueryParameters } from "./models/query-parameter";
import { ToDoKpis } from "./models/todo-kpis";

export default class ToDoService extends BaseExportService<IToDoDTO> {
  toDoApi: IToDosControllerClient;
  constructor(toDoApi: IToDosControllerClient) {
    super("Tasks");
    this.toDoApi = toDoApi;
  }

  upgradeCheckListItem = async (checkListItem: ICheckListItemDTO, item: IToDoDTO) => {
    const newTodo = new ToDoCreateDTO({
      title: checkListItem.content,
      deadline: item.deadline,
      isPrivate: item.isPrivate,
      responsibleId: item.responsible?.id ?? 0,
    });
    const resp = await this.toDoApi.create(newTodo);
    const connect = new ConnectToDosDTO({ toDoIds: [resp.id] });
    await this.toDoApi.connectToDos(item.id, true, connect);
    await this.toDoApi.deleteCheckListItem(item.id, checkListItem.id);
  };

  mapToExport(x: IToDoDTO): ExportType {
    //TODO2 maybe a better way to do this
    const lastUpdatedAt = get(x, "lastUpdatedAt");
    return {
      id: x.id,
      title: x.title,
      responsible: x.responsible?.fullName,
      createdAt: x.createdAt,
      lastUpdatedAt: lastUpdatedAt,
      deadline: x.deadline,
      project: x.project?.title ?? "",
    };
  }

  async copyTodo(dto: CopyForm, item: IToDoDTO, responsibleId: number): Promise<ToDoDTO> {
    const resp = await this.toDoApi.create(
      new ToDoCreateDTO({
        title: dto.title,
        deadline: dto.options.deadline ? item.deadline : DateTime.now(),
        description: dto.options.description ? item.description : "",
        projectId: item.project?.id,
        responsibleId: item.responsible?.id ?? responsibleId,
        checkListItems: dto.options.checklist ? item.checkListItems?.map((x) => x.content) : undefined,
        isPrivate: false,
        tags: dto.options.tags ? item.tags?.map((x) => x.title) : undefined,
        customFields: item.customFields,
      }),
    );
    await this.copyConnections(item, resp, dto);
    return resp;
  }

  static isMeetingMinutes(item: IToDoDTO) {
    return item.tags?.map((x) => x.title).includes(GlobalQmBaseConstants.Tasks.MeetingMinutes);
  }

  static addMeetingMinutes(item: IToDoDTO, description?: string): IToDoDTO {
    const meetingMinutes = `\n<b>${DateTimeLuxonHelpers.convertUtcToDate(
      DateTime.now(),
    )} Meeting Minutes</b>\n${description}\n${item.description}`;
    return { ...item, description: meetingMinutes };
  }

  static getKpis(all: IToDoDTO[], costs?: ICostDTO[], totalUrl?: string) {
    const withCosts = this.mapCosts(all, costs ?? []);
    const kpis = new ToDoKpis(withCosts, totalUrl);

    kpis.tags = TagService.groupAndSortTagArrays(all.filterMap((x) => x.tags));
    return kpis;
  }

  static mapCosts(items: IToDoDTO[], costs: ICostDTO[]) {
    return CostService.mapWithCosts(items, costs);
  }

  static get availableFilter() {
    return [
      SearchFilterTypes.Responsible,
      SearchFilterTypes.Status,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.Tags,
      SearchFilterTypes.Deadline,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.CustomField,
      SearchFilterTypes.Project,
      SearchFilterTypes.SupporterId,
      SearchFilterTypes.CreatedBy,
    ];
  }

  static localQuery<T extends IToDoDTO>(items: T[], query?: ToDoQueryParameters) {
    if (!query) return items;
    let copy = [...items];
    if (query.status && query.status.length > 0) {
      copy = copy.filter((x) => query.status?.includes(x.status));
    }
    if (query.responsibleId && query.responsibleId.length > 0) {
      copy = copy.filter((x) => x.responsible && query.responsibleId?.includes(x.responsible.id));
    }
    return copy;
  }

  async copyConnections(item: IToDoDTO, resp: IToDoDTO, dto: CopyForm) {
    if (dto.options.audits && item.connectedElements?.audits != undefined) {
      const connectAudits = new ConnectAuditsDTO({ auditIds: item.connectedElements.audits.map((x) => x.id) });
      await this.toDoApi.connectAudits(resp.id, connectAudits);
    }

    if (dto.options.trainings && item.connectedElements?.trainings != undefined) {
      const connectTrainings = new ConnectTrainingsDTO({
        trainingIds: item.connectedElements.trainings.map((x) => x.id),
      });
      await this.toDoApi.connectTrainings(resp.id, connectTrainings);
    }

    if (dto.options.products && item.connectedElements?.products != undefined) {
      const connectProducts = item.connectedElements.products.map((x) => new ConnectProductDTO({ id: x.id }));
      await this.toDoApi.connectProducts(resp.id, connectProducts);
    }

    if (dto.options.opportunities && item.connectedElements?.opportunities != undefined) {
      const connectOpportunities = new ConnectOpportunitiesDTO({
        opportunityIds: item.connectedElements.opportunities.map((x) => x.id),
      });
      await this.toDoApi.connectOpportunities(resp.id, connectOpportunities);
    }

    if (dto.options.inventoryItems && item.connectedElements?.inventoryItems != undefined) {
      const connectInventoryItem = new ConnectInventoryItemDTO({
        inventoryItemIds: item.connectedElements.inventoryItems.map((x) => x.id),
      });
      await this.toDoApi.connectInventoryItems(resp.id, connectInventoryItem);
    }
    if (dto.options.goals && item.connectedElements?.goals != undefined) {
      const connectGoals = new ConnectGoalsDTO({
        goalIds: item.connectedElements.goals.map((x) => x.id),
        isChildGoals: true,
      });
      await this.toDoApi.connectGoals(resp.id, connectGoals);
    }

    if (dto.options.tenants && item.connectedElements?.tenants != undefined) {
      const connectTenant = new ConnectTenantDTO({ tenantIds: item.connectedElements.tenants.map((x) => x.id) });
      await this.toDoApi.connectTenants(resp.id, connectTenant);
    }

    if (dto.options.todos && item.connectedElements?.tasks != undefined) {
      await this.toDoApi.connectToDos(
        resp.id,
        true,
        new ConnectToDosDTO({ toDoIds: item.connectedElements.tasks.map((x) => x.id) }),
      );
    }
    if (dto.options.claims) {
      const connectClaims = await this.toDoApi.getConnectedClaims(item.id);
      const connectClaimEntity = new ConnectEntityCommand({
        connectFrom: item.type,
        connectFromId: item.id,
        connectTo: PbdModule.ClaimManagement,
        connectToIds: connectClaims.map((x) => x.id),
        commandId: "connect",
      });
      await this.toDoApi.connectSomething(resp.id, connectClaimEntity);
    }
    if (dto.options.organisations) {
      const connectOrganisations = await this.toDoApi.getConnectedOrganisations(item.id);
      const connectOrganisationsEntity = new ConnectEntityCommand({
        connectFrom: item.type,
        connectFromId: item.id,
        connectTo: PbdModule.Organisations,
        connectToIds: connectOrganisations.map((x) => x.id),
        commandId: "connect",
      });
      await this.toDoApi.connectSomething(resp.id, connectOrganisationsEntity);
    }
    if (dto.options.defects) {
      const connectedDefects = await this.toDoApi.getConnectedDefects(item.id);
      const connectedDefectsEntity = new ConnectEntityCommand({
        connectFrom: item.type,
        connectFromId: item.id,
        connectTo: PbdModule.DefectManagement,
        connectToIds: connectedDefects.map((x) => x.id),
        commandId: "connect",
      });
      await this.toDoApi.connectSomething(resp.id, connectedDefectsEntity);
    }
  }

  static get getConnections() {
    return [
      new AvailableConnection(EntityKey.ToDo, undefined, undefined, { includeParent: true }),
      new AvailableConnection(EntityKey.Audit),
      new AvailableConnection(EntityKey.Claim),
      new AvailableConnection(EntityKey.Goal),
      new AvailableConnection(EntityKey.Training),
      new AvailableConnection(EntityKey.Defect),
      new AvailableConnection(EntityKey.Opportunity),
      new AvailableConnection(EntityKey.EmployeeIdea),
      new AvailableConnection(EntityKey.Organisation),
      new AvailableConnection(EntityKey.Tenant),
      new AvailableConnection(EntityKey.Product),
      new AvailableConnection(EntityKey.InventoryItem),
      new AvailableConnection(EntityKey.InventoryCategory),
    ];
  }

  static get copyModalOptions(): CopyIncludedOption[] {
    return [
      "tags",
      "checklist",
      "deadline",
      "description",
      "todos",
      "tenants",
      "goals",
      "inventoryItems",
      "trainings",
      "organisations",
      "audits",
      "claims",
      "defects",
    ];
  }
}
