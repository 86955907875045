import { HttpVerbs, ICategoryDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { SettingsIndexDTO } from "../../Models/Settings/SettingsIndexDTO";

export function mapCategoryDTOToSettingsDTO(settingsRoute: string, data?: ICategoryDTO[]) {
  if (!data) return undefined;
  return data.map((d) => {
    const item = new SettingsIndexDTO({
      id: d.id,
      title: d.title,
      createdAt: d.createdAt,
      color: d.color,
      links: [
        {
          rel: "self",
          href: settingsRoute.replace(":id", d.id.toString()),
          method: HttpVerbs.GET,
        },
      ],
    });
    return item;
  });
}
