import { TFunction } from "i18next";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { moduleUiData } from "./ModuleUiData";

export interface ModuleUiInfoDefinition {
  pbdModule: PbdModule;
  backgroundClassName: string;
  iconClassName: string;
  /**Startpage of the module */
  homePage?: string;
  /**Create page */
  create?: string;
  /**Choose template page */
  chooseTemplate?: string;
  /**Edit page */
  edit?: string;
  /**Index or list page */
  list?: string;
  /**Recently accessed items */
  recent?: string;
  /**Link to settings */
  settings?: string;
  /**Link to dashboard */
  dashboard?: string;

  thumbnailId?: number;
}

export default class ModuleUiInfo {
  items: ModuleUiInfoDefinition[];
  constructor() {
    this.items = moduleUiData;
  }

  getByPathName(pathname: string) {
    const app = this.items.find((x) => pathname.startsWith(x.homePage ?? "n/a"));
    return app;
  }

  getByModule(key: PbdModule) {
    const found = this.items.find((x) => x.pbdModule == key);
    if (!found) {
      console.warn("Module not found", key);
    }
    return found;
  }

  getColor(module: PbdModule) {
    const selected = this.getByModule(module);
    if (selected) {
      return selected.backgroundClassName;
    } else {
      return "qmBaseDarkToLightBlueGradient";
    }
  }

  getIcon(module: PbdModule) {
    const selected = this.getByModule(module);
    if (selected) {
      return selected.iconClassName;
    } else {
      return "fas fa-frog";
    }
  }

  getCreateLink(module: PbdModule) {
    const selected = this.getByModule(module);
    if (selected) {
      return selected.create;
    } else {
      return false;
    }
  }

  getEditLink(module: PbdModule) {
    const selected = this.getByModule(module);
    if (selected) {
      return selected.edit; //.replace(":id", id.toString());
    } else {
      return false;
    }
  }

  getHomePageLink(module: PbdModule) {
    const selected = this.getByModule(module);
    if (selected) {
      return selected.homePage ?? selected.list;
    } else {
      return false;
    }
  }

  getRecentLink(module: PbdModule) {
    const selected = this.getByModule(module);
    if (selected) {
      return selected.recent;
    } else {
      return false;
    }
  }

  getListLink(module: PbdModule) {
    const selected = this.getByModule(module);
    if (selected) {
      return selected.list;
    } else {
      return false;
    }
  }

  static getShortInfoKey(module: PbdModule): string {
    return `description.${module}`;
  }

  static getShortInfoText(module: PbdModule, t: TFunction<"moduleInfo">) {
    const description = t(`moduleInfo::description.${module}`);

    if (description) {
      return description;
    } else {
      return "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam";
    }
  }
}
