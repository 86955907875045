import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { IExternalIdSetting, PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../services/serviceContext";

import { useTypedParams } from "@/hooks/useTypedParams";
import DetailsPrintPageLayout from "../../../components/layouts/print/detailsPrintPageLayout";
import SwrApiHelpers from "../../../pbdServices/services/Api/swr-api-helpers";
import { IdeaManagementRoutePaths } from "../../ideaManagement/ideaManagementRoutePaths";
import IdComponent from "../../shared/components/id/idComponent";
import PrintIdeaComponent from "../components/employeeIdea/printIdeaComponent";

function PrintIdeaPage() {
  const navigate = useNavigate();
  const { employeeIdeasApi, moduleSettingsApi } = useAPIs();
  const { ideaManagementService } = useAPIServices();
  const [extIdSetting, setExtIdSetting] = React.useState<IExternalIdSetting>();
  const [extIdEmployee, setExtIdEmployee] = React.useState<IExternalIdSetting>();
  const { id } = useTypedParams(["id"]);

  const { data: item } = useSWR(
    `api/employeeIdeas/${id}`,
    () => employeeIdeasApi.getById(Number(id)),
    SwrApiHelpers.getDefaultGetOptions(navigate),
  );
  const { data: contributors } = useSWR(`api/employeeIdeas/${id}/contributors`, () =>
    employeeIdeasApi.getConnectedContributors(Number(id)),
  );
  const { data: comments } = useSWR(`api/employeeIdeas/${id}/comments`, () => employeeIdeasApi.getComments(Number(id)));
  const { data: attachments } = useSWR(`api/employeeIdeas/${id}/files`, () =>
    employeeIdeasApi.getConnectedFiles(Number(id)),
  );

  const costs = React.useMemo(() => {
    if (item) {
      return ideaManagementService.mapLegacyCosts(item);
    }
    return undefined;
  }, [ideaManagementService, item]);

  React.useEffect(() => {
    async function getData() {
      const respSettings = await moduleSettingsApi.getByModule(PbdModule.IdeaManagement);
      setExtIdSetting(respSettings.externalIdSetting);

      const respEmp = await moduleSettingsApi.getByModule(PbdModule.Employees);
      setExtIdEmployee(respEmp.externalIdSetting);
    }
    getData();
  }, []);

  return (
    <DetailsPrintPageLayout
      availableOptions={[
        "showComments",
        "showQrCode",
        "showAttachments",
        "showCosts",
        "decisionOfAcceptance",
        "decisionOnRealization",
        "implementation",
        "showReviews",
      ]}
      urlToDetailsPage={IdeaManagementRoutePaths.DetailsPage.replace(":id", id)}
      title={
        <>
          {item?.title} <IdComponent id={id} externalIdSettings={extIdSetting} />
        </>
      }
      attachments={attachments}
      comments={comments}
      costs={costs}
      commentsApi={employeeIdeasApi}
      item={item}
    >
      {item && (
        <PrintIdeaComponent
          item={item}
          extIdEmployee={extIdEmployee}
          extIdSetting={extIdSetting}
          contributors={contributors}
        />
      )}
    </DetailsPrintPageLayout>
  );
}

export default PrintIdeaPage;
