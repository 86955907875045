import React from "react";
import { ListGroup, Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "@/components/contexts/appContext";
import { useFuzzy } from "@/hooks/useFuzzy";
import { AppUiData } from "../../../../Constants/app-ui-data";
import { AppVM } from "../../../../pbdServices/Models/App/AppVM";
import AccessDeniedBadge from "../badges/accessDeniedBadge";
import SearchCriteria from "../search/searchCriteria";

interface IProps {
  show: boolean;
  onClose: () => void;
}

function AppLauncher(props: IProps) {
  const { t } = useTranslation();
  const { availableModules } = useAppContext();
  const { show, onClose } = props;
  const navigate = useNavigate();
  const { result, search } = useFuzzy(
    availableModules.filter((x) => x.isVisibleInAppList),
    {
      keys: ["displayName"],
    },
  );

  // console.log(availableModules.find((x) => x.type == PbdModule.ClaimManagement));

  const handleClick = (e: React.MouseEvent, x: AppVM) => {
    if (e.ctrlKey) {
      // Normal link
    } else {
      e.preventDefault();
      onClose();
      search("");
      navigate(x.href);
    }
  };

  return (
    <Offcanvas show={show} onHide={onClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t("Apps")}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <SearchCriteria placeholder={`${t("Search")}...`} doSearch={search} />
          </ListGroup.Item>
          {result.map((x) => {
            const ui = AppUiData[x.type];
            return (
              <ListGroup.Item
                key={x.id}
                as="a"
                href={x.href}
                onClick={(e) => {
                  handleClick(e, x);
                }}
              >
                <i className={ui.iconClassName} /> {x.displayName}
                {x.hasAccess != undefined && !x.hasAccess && <AccessDeniedBadge className="ms-2" />}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
export default AppLauncher;
