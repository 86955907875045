import React from "react";
import { useTranslation } from "react-i18next";

import FormattedUserInput from "../../shared/components/text/formattedUserInput";

const updateDescriptionIfPrint = (description: string, includeImagesInPrintView?: boolean) => {
  if (!includeImagesInPrintView) return description;

  const tempHtml = document.createElement("div");
  tempHtml.innerHTML = description;
  tempHtml.querySelectorAll("img").forEach((x) => x.remove());
  return tempHtml.innerHTML;
};

interface IProps {
  includeImagesInPrintView?: boolean;
  description: string;
}

function EightDReportDescriptionRenderer(props: IProps) {
  const { t } = useTranslation();
  const { description, includeImagesInPrintView } = props;

  const descriptionSanitized = React.useMemo(() => {
    return updateDescriptionIfPrint(description, includeImagesInPrintView);
  }, [description, includeImagesInPrintView]);

  return (
    <>
      <h5>{t("Description")}</h5>
      <FormattedUserInput content={descriptionSanitized} />
    </>
  );
}
export default EightDReportDescriptionRenderer;
