import { download, generateCsv } from "export-to-csv";
import i18next from "i18next";
import { orderBy } from "lodash";
import { DateTime, Duration } from "luxon";

import {
  IQualificationDTO,
  ITenantDTO,
  ITenantQualificationDTO,
  ITenantQualificationDoneDTO,
  ITenantQualificationsControllerClient,
  ITenantQualificationsDoneControllerClient,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../utils/dateTimeLuxonHelpers";
import { filterMap } from "../../../utils/filterMap";
import ExportService from "../Export/exportService";
import { TenantQualification } from "../QualificationMatrix/models/tenantQualification";
import { TenantQualificationDoneQueryParameters } from "./models/query-parameters";
import { ITenantQualificationDoneMapped } from "./models/tenant-qualification-done-mapped";

export default class TenantQualificationDoneService {
  tenantQualificationsDoneControllerClient: ITenantQualificationsDoneControllerClient;
  tenantQualificationsControllerClient: ITenantQualificationsControllerClient;
  constructor(
    tenantQualificationsDoneControllerClient: ITenantQualificationsDoneControllerClient,
    tenantQualificationsControllerClient: ITenantQualificationsControllerClient,
  ) {
    this.tenantQualificationsDoneControllerClient = tenantQualificationsDoneControllerClient;
    this.tenantQualificationsControllerClient = tenantQualificationsControllerClient;
  }

  async getAllByQueryMapped(
    qualifications: IQualificationDTO[],
    tenants: ITenantDTO[],
    query: TenantQualificationDoneQueryParameters,
  ): Promise<ITenantQualificationDoneMapped[]> {
    const resp = await this.tenantQualificationsDoneControllerClient.getAllQuery(query);
    const mapped = TenantQualificationDoneService.mapTenantQualificationDone(resp, qualifications, tenants);
    for (let element of mapped) {
      element = TenantQualificationDoneService.calculateNextInspectionDate(element);
    }
    // if (query.showOnlyDistinct) {
    //   const respDistinct = await this.tenantQualificationsControllerClient.getAllQuery(query);

    //   const mappedId = respDistinct.map((x) => {
    //     const m = `${x.tenantId}_${x.qualificationId}`;
    //     return m;
    //   });
    //   const reducedMap: ITenantQualificationDoneMapped[] = [];
    //   for (const re of mapped) {
    //     const includes = mappedId.includes(`${re.tenantId}_${re.qualificationId}`);
    //     if (includes) {
    //       reducedMap.push(re);
    //     }
    //   }
    //   mapped = reducedMap;
    // }
    return orderBy(mapped, (x) => x.doneAt, "desc");
  }

  static mapTenantQualificationDone(
    items: ITenantQualificationDoneDTO[],
    qualifications: IQualificationDTO[],
    tenants: ITenantDTO[],
  ): ITenantQualificationDoneMapped[] {
    return filterMap(items, (elem) => {
      const q = qualifications.find((x) => x.id == elem.qualificationId);
      const t = tenants.find((x) => x.id == elem.tenantId);
      if (q === undefined || t === undefined) return undefined;

      //TODO2 figure the boolean values
      return {
        ...elem,
        tenant: t,
        qualification: q,
        isExpired: false,
        isWarningTimeExpired: false,
      };
    });
  }

  static calculateExpiration(item: IQualificationDTO, lastInspection: DateTime) {
    let isExpired = false;
    let warningTime: DateTime | undefined = undefined;
    let nextInspection: DateTime | undefined = undefined;
    let isWarningTimeExpired = false;

    if (item.isRecurring) {
      const monitoringInterval = item.monitoringInterval;
      if (!monitoringInterval?.timeSpanISO) throw Error(`Missing monitoring time timespan for id: ${item.id}`);

      const dur = Duration.fromISO(monitoringInterval.timeSpanISO);
      nextInspection = lastInspection.plus(dur);
      isExpired = DateTimeLuxonHelpers.inPast(nextInspection);
      if (item.useWarningTime) {
        if (!item.warningTimeInterval?.timeSpanISO) throw Error(`Missing warning time timeSpan for id: ${item.id}`);

        const warningDur = Duration.fromISO(item.warningTimeInterval.timeSpanISO);
        warningTime = nextInspection.plus(warningDur);

        isWarningTimeExpired = DateTimeLuxonHelpers.inPast(warningTime);
      }
    }

    return {
      isExpired,
      warningTime,
      nextInspection,
      isWarningTimeExpired,
    };
  }

  static calculateNextInspectionDate(element: ITenantQualificationDoneMapped) {
    if (!element.doneAt) throw Error("Missing last inspection");

    if (!element.qualification.isRecurring) return element;

    const qual = element.qualification;
    const monitoringInterval = qual.monitoringInterval;

    if (!monitoringInterval?.timeSpanISO)
      throw Error(`Missing monitoring time timespan for id: ${element.qualificationId}`);

    const dur = Duration.fromISO(monitoringInterval.timeSpanISO);
    element.nextInspection = element.doneAt.plus(dur);
    element.isExpired = DateTimeLuxonHelpers.inPast(element.nextInspection);

    element.isWarningTimeExpired =
      !element.isExpired && element.warningTime !== undefined && DateTimeLuxonHelpers.inPast(element.warningTime);
    return element;
  }

  validateConnection(
    tenant: ITenantDTO,
    qualification: IQualificationDTO,
    qualificationsDone?: ITenantQualificationDTO,
  ): { isValid: boolean; status: string } {
    if (!qualification.connectionCondition?.tenantQualificationConnection?.isSelfAssignmentEnabled) {
      return { isValid: false, status: "Self check in not enabled" };
    }
    if (qualificationsDone) {
      return { isValid: false, status: "Qualification already connected" };
    }
    return { isValid: true, status: "OK" };
  }

  static exportToCsv(items: ITenantQualificationDoneMapped[]) {
    const exportData = items.map((x) => {
      const data = {
        id: x.id,
        personId: x.tenant.id,
        externalPersonId: x.tenant.externalId,
        fullName: x.tenant.fullName,
        birthday: x.tenant.birthday ? DateTimeLuxonHelpers.convertUtcToDate(x.tenant.birthday) : "",
        department: x.tenant.primaryDepartmentPosition?.department.title,
        departmentPosition: x.tenant.primaryDepartmentPosition?.title,
        qualificationId: x.qualification.id,
        qualification: x.qualification.title,
        isRecurring: x.qualification.isRecurring,
        validFrom: x.doneAt.toFormat("YYYY-MM-DD"),
        invalidFrom: x.nextInspection?.toFormat("YYYY-MM-DD") ?? "",
        actualValue: x.actualValue,
      };
      return data;
    });
    const options = ExportService.getOptions("QualificationHistory");
    const csv = generateCsv(options)(exportData);
    download(options)(csv);
  }

  static exportToCsvFromRequirements(rows: TenantQualification[]) {
    return ExportService.exportCSV("qualifcationHistory_created", rows, (x) => {
      const qualification = x.availableQualification || x.requiredQualification;

      return {
        ["ID"]: x.id,
        [i18next.t("Person ID")]: x.tenant.tenant.id,
        [i18next.t("External ID")]: x.tenant.tenant.externalId,
        [i18next.t("Name")]: x.tenant.tenant.fullName,
        [i18next.t("Birthday")]: x.tenant.tenant.birthday,
        [i18next.t("Department")]: x.tenant.tenant.primaryDepartmentPosition?.department.title,
        [i18next.t("Position")]: x.tenant.tenant.primaryDepartmentPosition?.title,
        [i18next.t("Qualification ID")]: qualification?.id || "undefined",
        [i18next.t("Qualification")]: qualification?.title, //s?.find((q) => q.id == qualification?.id)?.title || "undefined",
        // isRecurring: qualificationRecurring || "undefined",
        [i18next.t("Valid from")]: x.availableQualification?.lastInspection?.toFormat("YYYY-MM-DD") ?? "",
        [i18next.t("Invalid from")]: x.availableQualification?.nextInspection?.toFormat("YYYY-MM-DD") ?? "",
        [i18next.t("Fitness")]:
          x.availableQualification?.lastInspection != null ? x.availableQualification.actualValue : "",
        [i18next.t("Required by company function")]: x.isRequired,
      };
    });
  }
}
