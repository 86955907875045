import { ExternalChartSourceConfigExtension } from "./external-chart-source-config-extension";
import { GoalProgressDashboardItemConfigExtension } from "./goal-progress-dashboard-item-config-extension";
import { OpportunityProgressDashboardItemConfigExtension } from "./opportunityProgressDashboardItemConfigExtension";

export type DashboardItemContentData =
  | ExternalChartSourceConfigExtension
  | GoalProgressDashboardItemConfigExtension
  | OpportunityProgressDashboardItemConfigExtension;

export enum ViewAs {
  Chart = "Chart",
  Table = "Table",
  DistinctValues = "DistinctValues",
}
