import React from "react";
import { useTranslation } from "react-i18next";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { CostRoutePaths } from "../../costs/costRoutePaths";
import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { MaintenanceManagementRoutePaths } from "../maintenanceManagementRoutePaths";

const SidebarMaintenanceManagement: React.FC = () => {
  const { t } = useTranslation();
  const links: SidebarItem[] = [SidebarItem.created(MaintenanceManagementRoutePaths.CreateInventoryItem, t)];
  links.push(
    new SidebarItem({
      id: "inventoryItems",
      icon: qmBaseIcons.ListSolid,
      title: t("Inventory items"),
      href: MaintenanceManagementRoutePaths.IndexPage,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.CalendarDays,
      title: t("Upcoming inspections"),
      href: MaintenanceManagementRoutePaths.UpcomingInspection,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.CheckSquare,
      title: t("Inspections done"),
      href: MaintenanceManagementRoutePaths.InspectionsDone,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Wrench,
      title: t("Repairs done"),
      href: MaintenanceManagementRoutePaths.RepairsDone,
    }),
  );
  links.push(
    SidebarItem.costs(
      `${CostRoutePaths.IndexPageCostsByModule.replace(
        ":pbdModule",
        MaintenanceManagementRoutePaths.HomePage.replace("/", ""),
      )}/?keyName=InventoryItem`,
      t,
    ),
  );
  links.push(SidebarItem.settings(SettingsRoutePaths.MaintenanceManagementHome, t));
  return <SidebarNew app={PbdModule.MaintenanceManagement} items={links} />;
};

export default SidebarMaintenanceManagement;
