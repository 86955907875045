const routePrefix = "/ClaimManagement";

export class ClaimRoutePaths {
  public static HomePage = routePrefix;
  public static IndexPage = `${routePrefix}/Claims/Index`;
  public static IndexDeleted = `${routePrefix}/Claims/Index/?isDeleted=1`;
  public static CreatePage = `${routePrefix}/Claims/Create`;
  public static ChooseTemplate = `${routePrefix}/Create/Choose`;
  public static EditPage = `${routePrefix}/Claims/Edit/:id`;
  public static Create8DReport = `${routePrefix}/EightDReports/Create`;
  public static DetailsPage8DReport = `${routePrefix}/EightDReports/Details/:id`;
  public static IndexPage8DReports = `${routePrefix}/EightDReports`;

  public static EditPageTeamFor8DReport = `${routePrefix}/EightDReports/Team/:id`;
  public static EditPageProblemFor8DReport = `${routePrefix}/EightDReports/Problem/:id`;
  public static EditPageImmediateActionsFor8DReport = `${routePrefix}/EightDReports/ImmediateActions/:id`;
  public static EditPageRootCausesFor8DReport = `${routePrefix}/EightDReports/RootCauses/:id`;
  public static EditPageCorrectiveActionsFor8DReport = `${routePrefix}/EightDReports/CorrectiveActions/:id`;
  public static EditPageVerificationAndResultFor8DReport = `${routePrefix}/EightDReports/VerificationAndResult/:id`;
  public static EditPagePreventiveActionsFor8DReport = `${routePrefix}/EightDReports/PreventiveActions/:id`;
  public static EditPageCongratulationsFor8DReport = `${routePrefix}/EightDReports/Congratulations/:id`;
}
