import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Badge, Button, FormText } from "reactstrap";

import { ISmartViewDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { QualificationMatrixRoutePaths } from "../../../qualificationMatrix/qualificationMatrixRoutePaths";

interface IProps {
  item: ISmartViewDTO;
}

const QualificationMatrixSmartViewButtons: React.FC<IProps> = (props) => {
  const { item } = props;
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <Badge color="primary">{t("Experimental")}</Badge>
        <FormText color="muted">{t("Each of the following views might support different filters")}</FormText>
      </div>
      <Button
        tag={Link}
        size="sm"
        outline
        to={QualificationMatrixRoutePaths.MatrixPageQualification + item.query}
        className="me-2"
      >
        {t("Qualification matrix")}
      </Button>
      <Button
        tag={Link}
        size="sm"
        outline
        to={QualificationMatrixRoutePaths.QualificationRequirementsIndex + item.query}
        className="me-2"
      >
        {t("Qualification requirements")}
      </Button>
      <Button
        tag={Link}
        size="sm"
        outline
        to={QualificationMatrixRoutePaths.IndexPageTenantQualificationsDone + item.query}
        className="me-2"
      >
        {t("Qualification history")}
      </Button>
    </div>
  );
};

export default QualificationMatrixSmartViewButtons;
