export default class Auth {
  static STORAGE_KEY = "token";
  static CUSTOMER_ID = "customerId";
  static Customer_Key = "customerKey";

  static getToken() {
    return window.localStorage.getItem(Auth.STORAGE_KEY);
  }

  static setToken(token: string) {
    window.localStorage.setItem(Auth.STORAGE_KEY, token);
  }

  static removeToken(): void {
    window.localStorage.removeItem(Auth.STORAGE_KEY);
  }

  static getCustomerId() {
    return window.localStorage.getItem(Auth.CUSTOMER_ID);
  }

  static setCustomerId(customerId: string) {
    window.localStorage.setItem(Auth.CUSTOMER_ID, customerId);
  }
  static removeCustomerId(): void {
    return window.localStorage.removeItem(Auth.CUSTOMER_ID);
  }

  static getCustomerKey() {
    return window.localStorage.getItem(Auth.Customer_Key);
  }

  static setCustomerKey(customerKey: string) {
    window.localStorage.setItem(Auth.Customer_Key, customerKey);
  }
  static removeCustomerKey(): void {
    return window.localStorage.removeItem(Auth.Customer_Key);
  }
}
