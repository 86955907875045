import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../../icons/qmBaseIcons";
import { TableAction, TableClickCommand } from "../TableClickCommand";

interface IProps {
  columns: unknown[];
  activeFilters?: unknown;
  availableTableActions: readonly TableAction[];
  onClick?: (dto: TableClickCommand) => void;
}

function TableNoDataRow(props: IProps) {
  const { t } = useTranslation();
  const { columns, availableTableActions, onClick, activeFilters } = props;
  return (
    <tr>
      <td colSpan={columns.length} className="text-center">
        <div>
          <em>
            {t("No data available")}
            {activeFilters != undefined && <span> - {t("You have filters applied!")}</span>}
          </em>
        </div>
        {activeFilters != undefined && availableTableActions.includes("ResetFilter") && onClick && (
          <Button variant="link" onClick={() => onClick({ action: "ResetFilter" })}>
            <span className="fa-stack">
              <qmBaseIcons.Filter />
              <qmBaseIcons.Ban className="fa-stack-2x text-danger" />
            </span>
            {t("Reset filters?")}
          </Button>
        )}
      </td>
    </tr>
  );
}
export default TableNoDataRow;
