import useSWR from "swr";

import { useAPIs } from "../../../../../services/serviceContext";

import CustomSelect from "./customSelect";

interface IProps {
  onChange: (id?: number | number[]) => void;
  selectedIds?: number[];
  isMulti?: boolean;
  isClearable?: boolean;
  isPublished?: boolean;
}

function CustomFormSelect(props: IProps) {
  const { onChange, selectedIds, isMulti, isClearable, isPublished } = props;
  const { customFormsApi } = useAPIs();
  const { data } = useSWR("api/customForms", () => customFormsApi.getAllQuery({ isPublished: isPublished }));

  if (!data) return null;

  return (
    <CustomSelect
      data={data}
      isClearable={isClearable}
      onChange={onChange}
      name="customFormId"
      isMulti={isMulti}
      invalid={false}
      selectedIds={selectedIds}
      onBlur={undefined}
    />
  );
}

export default CustomFormSelect;
