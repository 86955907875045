import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { CellProps, Column } from "react-table";

import { ITagDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../../icons/qmBaseIcons";
import TagsInTable from "../../tags/tagsInTable";

interface IProps {
  tags?: ITagDTO[] | undefined;
}

/**
 * This column requires a property of type tags:TagDTO
 */
export function getTagColumn<T extends IProps>() {
  const column: Column<T> = {
    id: "tags",
    Header: (
      <>
        <qmBaseIcons.Tags /> {i18next.t("Tags")}
      </>
    ),
    accessor: "tags",
    disableSortBy: true,
    Cell: ({ row }: CellProps<T>) => <TagsInTable tags={row.original.tags} />,
  };
  return column;
}

const columnHelper = createColumnHelper<IProps>();

export const tagsColumn = columnHelper.accessor("tags", {
  id: "tags",
  header: () => (
    <>
      <qmBaseIcons.Tags /> {i18next.t("Tags")}
    </>
  ),
  cell: (x) => <TagsInTable tags={x.getValue()} />,
  enableSorting: false,
});
