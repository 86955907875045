import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Button, FormGroup } from "reactstrap";
import * as yup from "yup";

import FormikCustomForm from "@/ClientApp/shared/components/forms/formik/formikCustomForm";
import { useToggle } from "@/hooks/useToggle";
import { nameofFactory } from "@/utils/nameofFactory";

import { EightDReportStepDataValue } from "../../../Models/EightDReports/EightDReportStep";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import { FormikHtmlInputGroup } from "../../shared/components/forms/formik/formikHtmlInput";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";

interface IProps {
  onSubmit: (values: DescriptionFormValues) => Promise<void>;
  toggleEditMode: () => void;
  valueToUpdate: string | undefined;
  onSuccess: () => void;
  heading: string;
  step: EightDReportStepDataValue;
}

export interface DescriptionFormValues {
  description?: string;
}

const ValidationSchema: yup.ObjectSchema<DescriptionFormValues> = yup.object({
  description: yup.string(),
});

const nameof = nameofFactory<DescriptionFormValues>();

function EightDReportDescriptionForm(props: IProps) {
  const { onSubmit, toggleEditMode, step, onSuccess } = props;
  const { t } = useTranslation();
  const [editDescriptionMode, toggleEditDescriptionMode] = useToggle();

  const submitter = useFormikAPISubmitter<DescriptionFormValues>(
    (val) => onSubmit(val),
    [onSubmit],
    () => onSuccess(),
  );

  const initialValues: DescriptionFormValues = { description: props.valueToUpdate || "" };
  return (
    <Formik onSubmit={submitter} initialValues={initialValues} validationSchema={ValidationSchema}>
      {(formikBag) => (
        <FormikCustomForm formikBag={formikBag} onCancel={toggleEditMode}>
          {editDescriptionMode || props.valueToUpdate ? (
            <FormikHtmlInputGroup customTextEditorId={step.step} name={nameof("description")} />
          ) : (
            <FormGroup>
              <Button color="link" onClick={toggleEditDescriptionMode}>
                <qmBaseIcons.Plus /> {t("Add description")}
              </Button>
            </FormGroup>
          )}
        </FormikCustomForm>
      )}
    </Formik>
  );
}
export default EightDReportDescriptionForm;
