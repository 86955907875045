import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { useAppContext } from "@/components/contexts/appContext";
import { useAppNotificationHook } from "@/hooks/useAppNotificationHook";
import { AppUiData } from "../../../../Constants/app-ui-data";
import AppIcon from "../../../appInfo/components/appIcon";
import CounterBadge from "../badges/counterBadge";

const availableAppsForNotification = [PbdModule.GoalManagement, PbdModule.IdeaManagement];

interface IProps {
  app: PbdModule;
}

function SidebarAppHeader(props: IProps) {
  const { app } = props;
  const { t } = useTranslation();
  const navbarItem = AppUiData[app];
  const { meAsUser, currentApp } = useAppContext();
  const [{ appNotifications, loading }] = useAppNotificationHook(currentApp, meAsUser);

  return (
    <div className="py-4">
      {app != PbdModule.Startpage && (
        <div className="d-flex justify-content-center mb-3">
          <AppIcon app={app} as="Link" />
        </div>
      )}

      <div className="d-flex justify-content-center">
        <Link to={navbarItem.homePage || "undefined"} className="text-decoration-none">
          <h5 className="text-center">
            {t(navbarItem.displayNameSidebar ?? navbarItem.pbdModule)}
            {availableAppsForNotification.includes(currentApp) && (
              <CounterBadge count={appNotifications?.totalCount} countTitle={t("Action required")} />
            )}
          </h5>
        </Link>
      </div>
    </div>
  );
}

export default SidebarAppHeader;
