import React from "react";
import ReactAvatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

import { ClaimType } from "@/generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { AvatarSize, getSizeAsPx } from "../../shared/components/tenants/avatarSpan";

interface IProps {
  type: ClaimType;
  shape?: "Rectangle" | "Badge";
  onClick?: () => void;
  size?: AvatarSize;
}

const ClaimTypeAvatar: React.FC<IProps> = (props) => {
  const { type, shape = "Rectangle", onClick, size = "m" } = props;
  const { t } = useTranslation();
  let backgroundColor = "rgba(233, 144, 2, 0.5)";
  let avatar = t("_supplierAvatar");

  const sizeAsPx = getSizeAsPx(size);
  if (type == ClaimType.Intern) {
    avatar = t("_internAvatar");
    backgroundColor = "rgba(0, 140, 186, 0.5)";
  } else if (type == ClaimType.Customer) {
    avatar = t("_customerAvatar");
    backgroundColor = "rgba(240, 65, 36, 0.5)";
  }

  let clickableStyle: React.CSSProperties = {};
  if (onClick != null) {
    clickableStyle = {
      cursor: "pointer",
    };
  }
  switch (shape) {
    case "Badge":
      return (
        <div
          className={`avatarWithNameContainer${onClick != null ? " hoverInput" : ""}`}
          onClick={onClick}
          style={clickableStyle}
        >
          <Badge className="me-1" style={{ backgroundColor: backgroundColor }}>
            {avatar}
          </Badge>
          {onClick && <qmBaseIcons.PencilAlt className="ms-1 me-1" style={{ color: "white" }} />}
        </div>
      );
    default:
      return <ReactAvatar name={avatar} size={sizeAsPx} color={backgroundColor} />;
  }
};

export default ClaimTypeAvatar;
