import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { IdeaManagementRoutePaths } from "./ideaManagementRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.IdeaManagement_Standard, PbdRoles.IdeaManagement_ModuleAdmin],
};

export const ideaLazyRoutes: RouteObject[] = [
  {
    path: IdeaManagementRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageIdeaManagement")),
    ...defaultRouteParams,
  },
  {
    path: IdeaManagementRoutePaths.IndexPage,
    Component: lazy(() => import("./pages/indexPageIdeaManagement")),
    ...defaultRouteParams,
  },
  {
    path: IdeaManagementRoutePaths.CreatePage,
    Component: lazy(() => import("./pages/createPageIdeaManagement")),
    ...defaultRouteParams,
  },
  {
    path: IdeaManagementRoutePaths.DetailsPage,
    Component: lazy(() => import("./pages/detailsPageIdeaManagement")),
    ...defaultRouteParams,
  },
  {
    path: IdeaManagementRoutePaths.DetailsPageNew,
    Component: lazy(() => import("./v2/detailsPageIdeaManagementNew")),
    ...defaultRouteParams,
  },
  {
    path: IdeaManagementRoutePaths.CommentsPage,
    Component: lazy(() => import("../shared/components/comments/pages/indexPageComments")),
    ...defaultRouteParams,
  },
];
