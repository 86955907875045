import { DateTime } from "luxon";
import { nanoid } from "nanoid";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Input, Label } from "reactstrap";

import {
  ICustomField,
  IWorkflowAction,
  IWorkflowCondition,
  IWorkflowRule,
  PbdModule,
  SettingType,
  WorkflowAction,
  WorkflowCondition,
} from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIServices } from "../../../../services/serviceContext";

import { useAppContext } from "@/components/contexts/appContext";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import ActionInput from "./actionInput";
import ConditionInput from "./conditionInput";

const fields = ["Title", "Description", "Type", "SatisfactionVia", "ClaimType"];

const initialValue: IWorkflowRule = {
  id: nanoid(),
  title: "",
  isActive: true,
  conditions: [],
  actions: [],
  createdAt: DateTime.now(),
  createdById: 0,
};

interface IProps {
  customFields: ICustomField[];
  refreshParent: () => void;
  app: PbdModule;
}

function EditFormWorkflow(props: IProps) {
  const { customFields, refreshParent, app } = props;
  const { moduleSettingsService } = useAPIServices();
  const { t } = useTranslation();
  const { meAsUser } = useAppContext();
  const [conditions, setConditions] = React.useState<WorkflowCondition[]>([]);
  const [actions, setActions] = React.useState<WorkflowAction[]>([]);
  const [rule, setRule] = React.useState<IWorkflowRule>(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    const value = event.target.value;
    if (inputName == "title") {
      setRule((prevState) => {
        return { ...prevState, title: value };
      });
    } else if (inputName == "description") {
      setRule((prevState) => {
        return { ...prevState, description: value };
      });
    }
  };

  const handleSave = async () => {
    rule.conditions = conditions;
    rule.actions = actions;
    const resp = await wrapApiCallWithToast(() => moduleSettingsService.save(app, SettingType.WorkflowRule, [rule]));
    if (resp.isOk) {
      refreshParent();
    }
  };

  const handleConditionChange = (item: IWorkflowCondition, index: number) => {
    const copy = [...conditions];
    copy[index] = new WorkflowCondition(item);
    setConditions(copy);
  };

  const handleActionChange = (item: IWorkflowAction, index: number) => {
    const copy = [...actions];
    copy[index] = new WorkflowAction(item);
    setActions(copy);
  };

  const addCondition = () => {
    const c = new WorkflowCondition();
    setConditions((conditions) => conditions.concat(c));
  };

  const addAction = () => {
    const a = new WorkflowAction();
    setActions((actions) => [...actions, a]);
  };

  return (
    <>
      <FormGroup>
        <Label for="title">{t("Title")}</Label>
        <Input type="text" required name="title" id="title" onChange={handleChange} placeholder={t("Rule name...")} />
      </FormGroup>
      <FormGroup>
        <Label for="description">{t("Description")}</Label>
        <Input
          type="textarea"
          name="description"
          id="description"
          onChange={handleChange}
          placeholder={t("Rule description...")}
        />
      </FormGroup>
      <h4>
        {t("Conditions")} ({conditions.length})
      </h4>
      {conditions.map((x, i) => (
        <ConditionInput
          key={i}
          index={i}
          fields={fields}
          customFields={customFields}
          onChange={(item) => handleConditionChange(item, i)}
          currentValue={x}
        />
      ))}

      <FormGroup>
        <Button color="link" onClick={addCondition} disabled={conditions.length > 1}>
          <qmBaseIcons.Plus /> {t("New condition")}
        </Button>
      </FormGroup>
      <h4>
        {t("Actions")} ({actions.length})
      </h4>
      {actions.map((x, i) => (
        <ActionInput key={i} onChange={(item) => handleActionChange(item, i)} currentValue={x} />
      ))}
      <FormGroup>
        <Button color="link" onClick={addAction} disabled={actions.length > 0}>
          <qmBaseIcons.Plus /> {t("New action")}
        </Button>
      </FormGroup>
      <FormGroup>
        <Button
          color="primary"
          onClick={handleSave}
          disabled={(actions.length == 0 && conditions.length == 0) || !hasRole(meAsUser, [PbdRoles.Admin])}
        >
          {t("Save")}
        </Button>
      </FormGroup>
      <pre>{JSON.stringify(rule, null, 2)}</pre>
    </>
  );
}

export default EditFormWorkflow;
