import { Duration } from "luxon";

import { PbdStatus } from "@/generatedCode/pbd-core/pbd-core-api";

export class BaseProcessingTimeDTO<T = unknown> {
  constructor(status: PbdStatus, title: string, formula: string, total: Duration, entries: T[]) {
    this.status = status;
    this.title = title;
    this.formula = formula;
    this.total = total;
    this.entries = entries;
    this.average =
      entries.length > 0 ? Duration.fromMillis(total.as("millisecond") / entries.length) : Duration.fromMillis(0);
  }
  status: PbdStatus;
  title: string;
  formula: string;
  total: Duration;
  average: Duration;
  entries: T[];
}
