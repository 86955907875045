import { Spinner } from "react-bootstrap";

interface IProps {
  color?: string;
  type?: "border" | "grow";
  title?: string;
}

export function LoadingComponent(props: IProps) {
  const { color = "primary", type = "border", title } = props;
  return (
    <div className="d-flex justify-content-center">
      <Spinner variant={color} animation={type} />
      {title && <p>{title}</p>}
    </div>
  );
}
