import { Duration, DurationLikeObject, DurationUnit } from "luxon";
import React from "react";
import { Alert, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FilterElement } from "./availableSearchFilters";

interface IDurationProps {
  item: FilterElement;
  onChange: (dto: FilterElement) => void;
  unit?: DurationUnit;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function FilterItemDurationContent(props: IDurationProps) {
  const { item, onChange, unit = "day" } = props;
  const { t } = useTranslation();

  const handleChange = (value: string | null | undefined) => {
    const copy = { ...item };

    if (value != null) {
      const units = Number.parseInt(value);
      const obj: DurationLikeObject = {};
      obj[unit] = units;
      copy.queryValue = Number.isNaN(units) ? undefined : Duration.fromObject(obj);
    } else {
      copy.queryValue = undefined;
    }

    onChange(copy);
  };

  const value = React.useMemo(() => {
    const val = item.queryValue as Duration | undefined;
    return val?.as(unit).toString() ?? "";
  }, [item.queryValue, unit]);

  //TODO translate unit
  const unitTranslated = `${unit.toString()}(s)`;

  return (
    <Form.Group controlId={item.queryKey} className="mb-3">
      <Form.Label>{t(item.label)}</Form.Label>
      <InputGroup>
        <Form.Control
          type="text"
          pattern="[0-9]*"
          onChange={(e) => handleChange(e.target.value)}
          value={value}
          defaultValue="0"
        />
        <InputGroup.Text>{unitTranslated}</InputGroup.Text>
      </InputGroup>
    </Form.Group>
  );
}

interface IProps {
  item: FilterElement;
  onChange: (dto: FilterElement) => void;
}

const FilterItemDefaultContent: React.FC<IProps> = (props) => {
  const { item, onChange } = props;
  const { t } = useTranslation();

  const handleChange = (value: string | number | string[] | number[] | boolean | Date | null | undefined) => {
    const copy = { ...item };
    copy.queryValue = value;
    onChange(copy);
  };

  switch (item.filterType) {
    case "date":
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t(item.label)}</Form.Label>
          <Form.Control
            type="datetime-local"
            value={item.queryValue as string}
            onChange={(e) => handleChange(e.target.value)}
          />
        </Form.Group>
      );
    case "checkbox":
      return (
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            id={item.queryKey}
            onChange={(e) => handleChange(e.target.checked)}
            label={t(item.label)}
            defaultChecked={item.queryValue as boolean}
          />
        </Form.Group>
      );
    case "duration":
      return <FilterItemDurationContent {...props} />;
    default:
      return (
        <Alert variant="info">
          {t("Unknown filter")} {item.type}
        </Alert>
      );
  }
};
export default FilterItemDefaultContent;
