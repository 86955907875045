import React from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useAppContext } from "@/components/contexts/appContext";
import { useApps } from "@/hooks/useApps";
import { qmBaseIcons } from "../icons/qmBaseIcons";

import AppLauncher from "./appLauncher";
import { RightNavbar } from "./rightNavbar";

const SearchBoxNavbarCmdk = React.lazy(() => import("./searchBoxNavbarCmdk"));

const dotMenuSvg = (
  <svg
    height="32px"
    width="32px"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    fill="#29375F"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <circle cx="71.516" cy="66.616" r="66.616"></circle>
      <circle cx="256.004" cy="66.616" r="66.616"></circle>
      <circle cx="440.484" cy="66.616" r="66.616"></circle>
      <circle cx="71.516" cy="256" r="66.616"></circle>
      <circle cx="256.004" cy="256" r="66.616"></circle>
      <circle cx="440.484" cy="256" r="66.616"></circle>
      <circle cx="71.516" cy="445.384" r="66.616"></circle>
      <circle cx="256.004" cy="445.384" r="66.616"></circle>
      <circle cx="440.484" cy="445.384" r="66.616"></circle>
    </g>
  </svg>
);

interface IProps {
  toggleSidebar: () => void;
  sidebarToggled: boolean;
}

function NavbarComponent(props: IProps) {
  const { toggleSidebar, sidebarToggled } = props;
  const { available, brandIcon } = useApps();
  const {
    productConfig: { helpLinks },
  } = useAppContext();
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <AppLauncher show={show} onClose={handleClose} />
      <Navbar sticky="top" collapseOnSelect expand="lg" className="bg-body shadow px-3 py-0">
        <Button variant="light" onClick={handleShow} className="me-2 bg-transparent" title={"App launcher button"}>
          {dotMenuSvg}
        </Button>
        <Navbar.Brand as={Link} to="/">
          <img src={brandIcon?.href} style={{ maxHeight: "36px" }} alt="Brand icon" />
        </Navbar.Brand>
        <Button variant="primary" onClick={toggleSidebar} id="toggleSidebarButton">
          {sidebarToggled ? <qmBaseIcons.ChevronLeft /> : <qmBaseIcons.ChevronRight />}
        </Button>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {available && (
            <Nav className="mx-auto">
              <SearchBoxNavbarCmdk available={available} helpLinks={helpLinks} />
            </Nav>
          )}
          <Nav>
            <RightNavbar />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
export default NavbarComponent;
