import { IConfig, IssuesClient } from "./qmAdminApi";

const configBase = new IConfig();

export class AdminServiceContextData {
  issuesClient: IssuesClient;
  constructor(adminUrl: string) {
    this.issuesClient = new IssuesClient(configBase, adminUrl);
  }
}
