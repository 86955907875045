import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useAppContext } from "@/components/contexts/appContext";
import { convertRoleToHumanReadable, hasRole } from "../../../../services/Authz/authService";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";

interface IProps {
  roles: string[];
}

function RequiredRolesComponent(props: IProps) {
  const { roles } = props;
  const { meAsUser } = useAppContext();
  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex">
        <div>
          <b className="me-2">{t("Required roles")}:</b>
        </div>
        <div>
          {roles.map((r) => {
            const userHasRole = hasRole(meAsUser, [r]);
            return (
              <Badge
                key={r}
                bg={userHasRole ? "success" : "danger"}
                title={userHasRole ? t("Role available") : t("Role not available")}
                className="me-1"
              >
                {convertRoleToHumanReadable(r, t)} {userHasRole && <qmBaseIcons.CheckCircleSolid />}
              </Badge>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default RequiredRolesComponent;
