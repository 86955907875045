import React from "react";
import { useTranslation } from "react-i18next";
import { GroupBase } from "react-select";
import { AsyncPaginate, AsyncPaginateProps } from "react-select-async-paginate";

import { ISelectOptionDTO } from "./select-option-dto";
import { getSelectStyles } from "./select-theme";

export type BaseSelectAsyncProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Omit<AsyncPaginateProps<Option, Group, void, IsMulti>, "styles" | "theme"> & {
  isInvalid?: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export function BaseSelectAsync<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: React.PropsWithChildren<BaseSelectAsyncProps<Option, IsMulti, Group>>) {
  const { t } = useTranslation();
  const {
    onChange,
    debounceTimeout = 300,
    menuPosition = "fixed",
    children,
    placeholder = t("Please select") + "...",
    isInvalid,
    ...selectProps
  } = props;

  const styles = React.useMemo(() => getSelectStyles<Option, IsMulti, Group>(isInvalid ?? false), [isInvalid]);

  return (
    <React.Fragment>
      <AsyncPaginate
        styles={styles}
        menuPosition={menuPosition}
        debounceTimeout={debounceTimeout}
        onChange={props.onChange}
        placeholder={placeholder}
        {...selectProps}
      />
      {children}
    </React.Fragment>
  );
}

export type BaseSelectAsyncWithDTOProps<
  TDTO,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<TDTO> = GroupBase<TDTO>,
> = Omit<BaseSelectAsyncProps<TDTO, IsMulti, Group>, "getOptionLabel" | "getOptionValue"> & {
  mapToSelectDTO: (dto: TDTO) => ISelectOptionDTO<TDTO>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export function BaseSelectAsyncWithDTO<
  TDTO,
  IsMulti extends boolean = false,
  Group extends GroupBase<TDTO> = GroupBase<TDTO>,
>(props: BaseSelectAsyncWithDTOProps<TDTO, IsMulti, Group>) {
  const { mapToSelectDTO, ...selectProps } = props;

  const getLabel = React.useCallback((val: TDTO) => mapToSelectDTO(val).label, [mapToSelectDTO]);
  const getVal = React.useCallback((val: TDTO) => mapToSelectDTO(val).key.toString(), [mapToSelectDTO]);
  return <BaseSelectAsync {...selectProps} getOptionLabel={getLabel} getOptionValue={getVal} />;
}

export default BaseSelectAsync;
