import { ErrorMessage, FieldHookConfig, FieldProps, getIn, useField } from "formik";
import { Form, InputGroup } from "react-bootstrap";
import { FormFeedback, FormText, Input } from "reactstrap";

import { CustomFormLabel } from "./customFormLabel";

interface FormTextProps {
  formText: string;
}

export const FormikNumberInput = ({
  field,
  form: { touched, errors },
  formText,
  ...props
}: FieldProps<number> & FormTextProps) => (
  <>
    <Input
      invalid={getIn(errors, field.name) && getIn(touched, field.name)}
      {...field}
      {...props}
      value={field.value == undefined || null ? "" : field.value}
      type="number"
    />
    <ErrorMessage component={FormFeedback} {...field} />
    {formText && <FormText color="muted">{formText}</FormText>}
  </>
);

interface IProps {
  label?: string;
  formText?: string;
  /**
   * Use this field for example to render a percent input
   * @example %
   */
  inputGroupText?: string;
  disabled?: boolean;
}

export function FormikNumberInputGroup(props: FieldHookConfig<number> & IProps) {
  const { formText, id, inputGroupText, disabled } = props;
  const [field, meta] = useField(props);
  const localId = id ?? props.name;

  const invalid = meta.error != undefined && meta.touched;
  return (
    <Form.Group className="mb-3" controlId={localId}>
      <CustomFormLabel {...props} />
      {inputGroupText && (
        <InputGroup>
          <InputGroup.Text>{inputGroupText}</InputGroup.Text>
          <Form.Control {...field} type="number" isInvalid={invalid} disabled={disabled} />
          <ErrorMessage {...field}>
            {(msg) => (
              <Form.Control.Feedback type="invalid" className="order-last">
                {msg}
              </Form.Control.Feedback>
            )}
          </ErrorMessage>
        </InputGroup>
      )}
      {!inputGroupText && (
        <>
          <Form.Control {...field} type="number" isInvalid={invalid} disabled={disabled} />
          <ErrorMessage {...field}>
            {(msg) => (
              <Form.Control.Feedback type="invalid" className="order-last">
                {msg}
              </Form.Control.Feedback>
            )}
          </ErrorMessage>
        </>
      )}
      {formText && <Form.Text muted>{formText}</Form.Text>}
    </Form.Group>
  );
}
