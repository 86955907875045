import React from "react";
import { Button, ButtonGroup, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IOpportunityCategoryVariable, IScaleSection, ScaleSection } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import { TableRowClickCommand } from "../../../shared/components/tables/TableRowClickCommand";
import EditFormScaleSection from "./editFormScaleSection";
import { ScaleSectionTable } from "./scaleSectionTable";

interface IProps {
  baseDTO: IOpportunityCategoryVariable;
  opportunityCategoryId: number;
  refreshParent: () => void;
  onCancel: () => void;
}

function ScaleSectionsConnectedCard(props: IProps) {
  const { baseDTO, refreshParent, onCancel, opportunityCategoryId } = props;
  const { t } = useTranslation();
  const { opportunityCategoriesApi } = useAPIs();
  const [itemToUpdate, setItemToUpdate] = React.useState<IScaleSection>();
  const [showForm, setShowForm] = React.useState(false);
  const confirm = useConfirmation();

  function toggleShowForm() {
    setItemToUpdate(undefined);
    setShowForm(!showForm);
  }

  function handleRowClick(command: TableRowClickCommand<IScaleSection>) {
    const { action, row } = command;
    setItemToUpdate(undefined);
    switch (action) {
      case "Select":
        setShowForm(true);
        setItemToUpdate(row);
        break;
      case "Delete":
        confirm({ itemsToDelete: [{ id: row.scaleSectionId, title: row.description }] }).then(async () => {
          await opportunityCategoriesApi.deleteScaleSection(
            opportunityCategoryId,
            baseDTO.variableId,
            row.scaleSectionId,
          );
          refreshParent();
        });
        break;
      default:
        throw Error("Not implemented");
    }
  }

  const handleSuccess = () => {
    setShowForm(false);
    setItemToUpdate(undefined);
    refreshParent();
  };

  const submitRequest = (dto: IScaleSection) =>
    opportunityCategoriesApi.addOrUpdateScaleSection(opportunityCategoryId, baseDTO.variableId, new ScaleSection(dto));

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <Card.Title as="h5"></Card.Title>
        <div>
          <ButtonGroup>
            <Button variant="outline-secondary" onClick={onCancel} size="sm">
              {t("Cancel")}
            </Button>
            <Button variant="outline-secondary" onClick={() => toggleShowForm()} size="sm" title={t("New")}>
              <qmBaseIcons.Plus />
            </Button>
          </ButtonGroup>
        </div>
      </Card.Header>
      <Card.Body>
        {showForm && (
          <EditFormScaleSection
            itemToUpdate={itemToUpdate}
            onSubmit={submitRequest}
            onSuccess={handleSuccess}
            onCancel={toggleShowForm}
          />
        )}
        <ScaleSectionTable tableRows={baseDTO.scaleSections} onRowClick={handleRowClick} showTableToolbar={false} />
      </Card.Body>
    </Card>
  );
}

export default ScaleSectionsConnectedCard;
