import {
  EntityKey,
  IBasicAdminDataSettings,
  ICustomField,
  IOrganisationDTO,
  IOrganisationEditDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { AvailableConnection } from "../../../ClientApp/shared/components/connectionElements/generic/available-connection";
import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { BaseExportService } from "../Base/BaseExportService";
import ExportService, { ExportType } from "../Export/exportService";
import { TenantQueryParameters } from "../Tenants/models/query-parameters";

export default class OrganisationService extends BaseExportService<IOrganisationEditDTO> {
  constructor() {
    super("Contacts");
  }
  mapToExport(x: IOrganisationDTO): ExportType {
    return {
      id: x.id,
      title: x.title,
      description: x.description,
      status: x.status,
      isDeleted: x.isDeleted,
      isCustomer: x.isCustomer,
      isSupplier: x.isSupplier,
      createdAt: x.createdAt,
      lastUpdatedAt: x.lastUpdatedAt,
    };
  }

  //TODO2
  static exportToCsv(items: IOrganisationDTO[], customFields: ICustomField[]) {
    return ExportService.exportCSV("Organisation", items, (x) => {
      const cfMapped = ExportService.getCustomFields(x.customFields ?? [], customFields);
      return {
        id: x.id,
        title: x.title,
        description: x.description,
        status: x.status,
        isDeleted: x.isDeleted,
        isCustomer: x.isCustomer,
        isSupplier: x.isSupplier,
        createdAt: x.createdAt,
        lastUpdatedAt: x.lastUpdatedAt,
        ...cfMapped,
      };
    });
  }

  static isProductOwner(item: { id: number }, basicData: IBasicAdminDataSettings): boolean {
    return basicData.organisationId == item.id;
  }

  static availableConnections(item: { id: number }, basicData: IBasicAdminDataSettings): AvailableConnection[] {
    const connections = [
      new AvailableConnection(EntityKey.ToDo),
      new AvailableConnection(EntityKey.Claim, "default", {
        disableDisconnect: true,
        disableConnect: true,
      }),
    ];
    if (!this.isProductOwner(item, basicData)) {
      connections.push(
        new AvailableConnection<TenantQueryParameters>(
          EntityKey.Tenant,
          "default",
          undefined,
          {
            title: "Employees and contact persons",
          },
          { isEmployee: false },
        ),
      );
    }
    return connections;
  }

  static get availableFilter() {
    return [
      SearchFilterTypes.Tags,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.IsCustomer,
      SearchFilterTypes.IsSupplier,
      SearchFilterTypes.CustomField,
      SearchFilterTypes.Responsible,
    ];
  }
}
