import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "./errors/errorFallback";

interface IProps {
  Component: React.ComponentType;
  SubSidebar: React.ComponentType;
}

export function DefaultLayoutSubsidebar(props: IProps) {
  const { Component, SubSidebar } = props;
  return (
    <Container fluid className="pt-4" id="mainQmBaseContainer">
      <Row>
        <Col md={3}>
          <SubSidebar />
        </Col>
        <Col md={9}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Component />
          </ErrorBoundary>
        </Col>
      </Row>
    </Container>
  );
}
