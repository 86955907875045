type EditorType = "Flow" | "Markdown" | "Bpmn";

interface IBaseEditorContent {
  editorId: EditorType;
  editorVersion?: string;
  content: string;
}

export class EditorContent<T = Record<string, any>> implements IBaseEditorContent {
  constructor(type: EditorType, content: T | string, editorVersion = "notSet") {
    this.editorId = type;
    this.content = typeof content == "string" ? content : JSON.stringify(content);
    this.editorVersion = editorVersion;
  }
  editorId: EditorType;
  editorVersion?: string;
  content: string;
}

export class BpmnEditorContent implements IBaseEditorContent {
  constructor(content?: string, legends?: Record<string, string>, editorVersion = "notSet") {
    this.editorId = "Bpmn";
    this.editorVersion = editorVersion;
    this.content = typeof content == "string" ? content : JSON.stringify(content);
    this.legends = legends;
  }
  editorId: "Bpmn";
  editorVersion?: string | undefined;
  content: string;
  legends?: Record<string, string>;
}
