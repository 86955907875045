import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { HttpVerbs, IOpportunityCategoryDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { useAppContext } from "@/components/contexts/appContext";
import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { useRecycleBinHook } from "@/hooks/useRecycleBinHook";
import { ErrorMessage } from "../../../../Models/Errors/ErrorMessage";
import { SettingsIndexDTO } from "../../../../Models/Settings/SettingsIndexDTO";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import SwrApiHelpers from "../../../../pbdServices/services/Api/swr-api-helpers";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import { TableClickCommand } from "../../../shared/components/tables/TableClickCommand";
import BaseSettingsIndexCard from "../../components/baseSettingsIndexCard";
import { BaseSettingsTable } from "../../components/baseSettingsTable";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function mapDataToSettingsDTO(data?: IOpportunityCategoryDTO[]) {
  if (!data) return undefined;
  const tableData: SettingsIndexDTO[] = [];
  data.forEach((d) => {
    const item = new SettingsIndexDTO({
      id: d.id,
      title: d.title,
      createdAt: d.createdAt,
      color: d.color,
      links: [
        {
          rel: "self",
          href: SettingsRoutePaths.EditPageOpportunityCategories.replace(":id", d.id.toString()),
          method: HttpVerbs.GET,
        },
      ],
    });
    tableData.push(item);
  });
  return tableData;
}

function IndexPageOpportunityCategories() {
  const navigate = useNavigate();
  const { opportunityCategoriesApi } = useAPIs();
  const { showRecycleBin, toggleRecycleBin } = useRecycleBinHook();
  const { meAsUser, setErrorMessage, handleApiError } = useAppContext();
  const [selected, setSelected] = React.useState<SettingsIndexDTO[]>([]);
  const confirm = useConfirmation();
  const { data, isValidating, mutate } = useSWR(
    [`/api/opportunityCategories`, showRecycleBin],
    () => opportunityCategoriesApi.getAllQuery({ isDeleted: showRecycleBin }),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  const handleClick = (command: TableClickCommand) => {
    if (command.action == "Delete") {
      confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
    }
  };

  const submitDeleteRequest = async (items: number[]) => {
    if (!hasRole(meAsUser, [PbdRoles.OpportunityManagement_Settings])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => opportunityCategoriesApi.delete(Number(s)), { handleApiError });
      }
      mutate();
    }
  };

  const tableRows = React.useMemo(() => mapDataToSettingsDTO(data), [data]);

  return (
    <BaseSettingsIndexCard
      data={data}
      cardTitle="Categories"
      pathToCreate={SettingsRoutePaths.CreatePageOpportunityCategories}
      loading={isValidating}
      showRecycleBin={showRecycleBin}
      toggleRecycleBin={toggleRecycleBin}
    >
      <BaseSettingsTable
        tableRows={tableRows}
        onCreateClick={() => navigate(SettingsRoutePaths.CreatePageOpportunityCategories)}
        setSelected={(ids) => setSelected(ids.map((x) => x))}
        availableTableActions={["Delete"]}
        onClick={handleClick}
      />
    </BaseSettingsIndexCard>
  );
}

export default IndexPageOpportunityCategories;
