export default class AuthStore {
  private static _storageKey = "token";

  static getToken() {
    return window.localStorage.getItem(AuthStore._storageKey);
  }

  static setToken(token: string) {
    window.localStorage.setItem(AuthStore._storageKey, token);
  }

  static removeToken(): void {
    window.localStorage.removeItem(AuthStore._storageKey);
  }
}
