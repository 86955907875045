export class CustomFieldApiValue {
  constructor(id: number, objectName: CustomFieldApiObjectName) {
    this.id = id;
    this.objectName = objectName;
  }
  id: number;
  objectName: CustomFieldApiObjectName;
}

export enum CustomFieldApiObjectName {
  Tenant = "Tenant",
  Organisation = "Organisation",
  Custom = "Custom",
}
