import { RouteObject } from "react-router-dom";

import { DevRoutePaths } from "./devRoutePaths";

export const devLazyRoutes: RouteObject[] = [
  {
    path: DevRoutePaths.HomePage,
    async lazy() {
      const { HomePageDev } = await import("./pages/homePageDev");
      return { Component: HomePageDev };
    },
  },
  {
    path: DevRoutePaths.EntityDefinitions,
    async lazy() {
      const { IndexPageEntityDefinitions } = await import("./pages/indexPageEntityDefinitions");
      return { Component: IndexPageEntityDefinitions };
    },
  },
  {
    path: DevRoutePaths.AppSettings,
    async lazy() {
      const { AppSettingsPage } = await import("./pages/appSettingsPage");
      return { Component: AppSettingsPage };
    },
  },
  {
    path: DevRoutePaths.BackgroundServices,
    async lazy() {
      const { BackgroundServicesPage } = await import("./pages/backgroundServicesPage");
      return { Component: BackgroundServicesPage };
    },
  },
  {
    path: DevRoutePaths.FeatureFlags,
    async lazy() {
      const { FeatureFlagPage } = await import("./pages/featureFlagPage");
      return { Component: FeatureFlagPage };
    },
  },
];
