import React from "react";
import { Button } from "react-bootstrap";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  size?: "sm" | "lg";
  title?: string;
  variant?: string;
  disabled?: boolean;
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomDropdownToggleButton = React.forwardRef(
  ({ onClick, size, title, variant = "outline-secondary", disabled }: IProps, ref) => (
    <Button
      variant={variant}
      size={size}
      //@ts-expect-error This is from an example from the docs https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      title={title}
      disabled={disabled}
    >
      <qmBaseIcons.EllipsisVertical />
    </Button>
  ),
);

export default CustomDropdownToggleButton;

export const CustomDropdownToggleButtonPlus = React.forwardRef(
  ({ onClick, size, title, variant = "primary", disabled }: IProps, ref) => (
    <Button
      variant={variant}
      size={size}
      //@ts-expect-error This is from an example from the docs https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      title={title}
      disabled={disabled}
    >
      <qmBaseIcons.Plus />
    </Button>
  ),
);

export const CustomDropdownToggleReactNode = React.forwardRef(
  ({ onClick, size, title, children, variant, disabled }: IProps & { children: React.ReactNode }, ref) => (
    <Button
      variant={variant}
      size={size}
      //@ts-expect-error This is from an example from the docs https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      title={title}
      disabled={disabled}
    >
      {children}
    </Button>
  ),
);
