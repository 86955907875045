import { FormikProps } from "formik";
import React from "react";
import { Alert, Button, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ResendConfirmationDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import { useAPIs } from "../../../services/serviceContext";

interface IProps<TValues> {
  formikBag: FormikProps<TValues>;
}

function LoginValidationSummary<TValues extends { userName?: string; email?: string; invalidToken?: string }>(
  props: IProps<TValues>,
) {
  const { t } = useTranslation();
  const { formikBag } = props;
  const { accountApi } = useAPIs();
  const [submitted, setSubmitted] = React.useState(false);
  const [resendError, setResendError] = React.useState("");

  const handleResend = async (email: string) => {
    const resp = await wrapApiCallWithToast(() => accountApi.resendConfirmation(new ResendConfirmationDTO({ email })), {
      hideOnSuccess: true,
    });
    setSubmitted(true);
    if (resp.isErr) {
      setResendError(resp.error.message);
    }
  };

  return (
    <>
      {submitted && (
        <Alert color={resendError ? "danger" : "success"}>
          {resendError ? (
            <p>{resendError}</p>
          ) : (
            <p>
              {t("The confirmation email has been sent to you again.")} {t("Please check your inbox.")}
            </p>
          )}
        </Alert>
      )}
      {formikBag.errors.invalidToken && (
        <p>
          {t(
            "The error invalid token is usually a problem when link you have opened to come to this place is older than 24 hours.",
          )}
        </p>
      )}
      {!submitted && formikBag.status?.emailNotConfirmed && (
        <FormGroup>
          <div className="d-grid gap-2">
            <Button variant="primary" onClick={() => handleResend(formikBag.values.email)} size="lg">
              {t("Resend confirmation email")}
            </Button>
          </div>
        </FormGroup>
      )}
    </>
  );
}
export default LoginValidationSummary;
