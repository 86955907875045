import { useMemo } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AppWrapperFallbackLoading } from "@/components/ui/appWrapperFallbackLoading";

import { routesConfig } from "./appRoutes";

export function AppRouter() {
  const router = useMemo(() => createBrowserRouter(routesConfig), []);

  return <RouterProvider router={router} fallbackElement={<AppWrapperFallbackLoading title="Starting App..." />} />;
}
