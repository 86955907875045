import { Formik } from "formik";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  AbsencePolicyEditOverwriteDTO,
  IAbsencePolicyDTO,
  IAbsencePolicyEditOverwriteDTO,
  ITenantAbsencePolicyDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { useAPIs } from "../../../../services/serviceContext";
import { nameofFactory } from "../../../../utils/nameofFactory";
import FormikCustomForm from "../../../shared/components/forms/formik/formikCustomForm";
import { FormikNumberInputGroup } from "../../../shared/components/forms/formik/formikNumberInput";
import { BaseModalProps } from "../../../shared/components/modals/baseModalProps";
import AvatarSpanWithName from "../../../shared/components/tenants/avatarSpanWithName";

type LocalDateType = Pick<IAbsencePolicyEditOverwriteDTO, "residualExpirationDate">;
//@ts-expect-error TODO: Fix with better dateSchema
const dateSchema: yup.ObjectSchema<LocalDateType> = yup.object({
  residualExpirationDate: yup.date().notRequired(),
});

const ValidationSchema: yup.ObjectSchema<IAbsencePolicyEditOverwriteDTO> = yup
  .object({
    id: yup.number().required(),
    availableDays: yup.number().transform((val) => (isNaN(val) || val == null || val == undefined ? undefined : val)),
    residualDays: yup.number().transform((val) => (isNaN(val) || val == null || val == undefined ? undefined : val)),
    absencePolicyApproverIds: yup.array().of(yup.number()),
  })
  .concat(dateSchema);

const nameof = nameofFactory<IAbsencePolicyEditOverwriteDTO>();

interface IProps extends BaseModalProps {
  tenant: ITenantAbsencePolicyDTO;
  absencePolicy: IAbsencePolicyDTO;
  onReset: (dto: ITenantAbsencePolicyDTO) => void;
  refreshParent: () => void;
  onCustomizeApprovers: (dto: ITenantAbsencePolicyDTO) => void;
}

function OverwritePolicyPerTenantModal(props: IProps) {
  const { modal, toggle, absencePolicy, tenant, refreshParent, onReset, onCustomizeApprovers } = props;
  const { t } = useTranslation();

  const { absencePoliciesApi } = useAPIs();

  const submitter = useFormikAPISubmitter<IAbsencePolicyEditOverwriteDTO>(
    (val) =>
      absencePoliciesApi.overwritePolicyForTenant(absencePolicy.id, tenant.id, new AbsencePolicyEditOverwriteDTO(val)),
    [absencePoliciesApi, absencePolicy.id, tenant.id],
    () => {
      refreshParent();
      toggle();
    },
  );

  const initialValues: IAbsencePolicyEditOverwriteDTO = {
    id: absencePolicy.id,
    residualDays: tenant.absencePolicyOverwriteableParameters?.residualDays,
    residualExpirationDate: tenant.absencePolicyOverwriteableParameters?.residualExpirationDate,
    availableDays: tenant.absencePolicyOverwriteableParameters?.availableDays,
    absencePolicyApproverIds: tenant.absencePolicyOverwriteableParameters?.approverIds,
  };

  return (
    <Modal show={modal} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Overwrite policy")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <dl>
          <dd>{t("For")}</dd>
          <dt>
            <AvatarSpanWithName tenant={tenant} />
          </dt>
        </dl>

        <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
          {(formikBag) => (
            <FormikCustomForm formikBag={formikBag} onCancel={toggle}>
              <FormikNumberInputGroup
                name={nameof("availableDays")}
                label={t("Available days")}
                formText={t("Leave empty for unlimited")}
              />
              <FormikNumberInputGroup name={nameof("residualDays")} label={t("Residual days")} />
              <FormikNumberInputGroup name={nameof("residualExpirationDate")} label={t("Residual expiration period")} />
            </FormikCustomForm>
          )}
        </Formik>
        <ButtonGroup size="sm">
          <Button variant="link" onClick={() => onReset(tenant)}>
            {t("Reset to default")}
          </Button>
          <Button variant="link" onClick={() => onCustomizeApprovers(tenant)}>
            {t("Customize approvers")}
          </Button>
        </ButtonGroup>
      </Modal.Body>
    </Modal>
  );
}

export default OverwritePolicyPerTenantModal;
