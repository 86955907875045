import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { DateTime } from "luxon";
import React from "react";

import { IHolidays } from "@/generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../../utils/dateTimeLuxonHelpers";
import { BaseTableProps } from "../../../shared/components/tables/v8/base-table-props";
import ReactstrapTable from "../../../shared/components/tables/v8/reactstrapTable";

const columnHelper = createColumnHelper<IHolidays>();

const columns = [
  columnHelper.accessor((x) => x.title, {
    header: () => i18next.t("Title"),
    id: "title",
  }),
  columnHelper.accessor((x) => x.date, {
    header: () => <>{i18next.t("Date")}</>,
    id: "date",
    cell: (x) => (
      <>
        {x.row.original.isRecurring
          ? DateTimeLuxonHelpers.convertUtcToDateOnly(x.row.original.date)
          : DateTimeLuxonHelpers.convertUtcToDate(x.row.original.date)}
      </>
    ),
    // sortingFn:(a,b,c):number=>1
    sortingFn: (rowA, rowB, columnId): number =>
      rowA.getValue<DateTime>(columnId).toMillis() < rowB.getValue<DateTime>(columnId).toMillis() ? 1 : -1,
  }),
];

type IProps = BaseTableProps<IHolidays>;

export const BankHolidayTable: React.FC<IProps> = (props) => {
  return <ReactstrapTable<IHolidays> columns={columns} localStorageStateKey={"Bankholidays"} {...props} />;
};
