import { TFunction } from "i18next";
import { string } from "yup";

import { GlobalQmBaseConstants } from "../../Constants/GlobalQmBaseConstants";

export const requiredStringSchema = (t: TFunction) => string().required(t("This field is required"));

// TODO: Not tested
export const requiredTitleStringSchema = (t: TFunction, maxLength = GlobalQmBaseConstants.MaxTitleLength) =>
  string().required(t("This field is required")).min(2).max(maxLength).label("Title");

export const nullableString = string().ensure();
