import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, CardHeader, CardTitle } from "reactstrap";

import { qmBaseIcons } from "../../icons/qmBaseIcons";
import { LoadingComponent } from "../../loading/loadingComponent";

interface IProps<T> {
  toggleConnectModal?: () => void;
  data?: T[];
  addButtonText?: string;
  canAdd?: boolean;
  title: React.ReactNode;
  linkToIndex?: string;
  children?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ConnectedCardHeader<T>({
  toggleConnectModal,
  data,
  addButtonText = i18next.t("Add"),
  canAdd,
  title,
  linkToIndex,
  ...props
}: React.PropsWithChildren<IProps<T>>) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <CardHeader className="d-flex justify-content-between">
        <CardTitle tag="h5">{title}</CardTitle>
        <div>
          {!data && <LoadingComponent />}
          {data && (
            <React.Fragment>
              {data.length == 0 && toggleConnectModal && (
                <Button color="primary" outline onClick={toggleConnectModal} disabled={!canAdd}>
                  <qmBaseIcons.Plus /> {addButtonText}
                </Button>
              )}
              {data.length > 0 && linkToIndex && (
                <Button outline tag={Link} to={linkToIndex} size="sm" title={t("Show more details")}>
                  <qmBaseIcons.Maximize />
                </Button>
              )}
              {props.children}
            </React.Fragment>
          )}
        </div>
      </CardHeader>
    </React.Fragment>
  );
}

export default ConnectedCardHeader;
