import React from "react";
import { Card } from "react-bootstrap";

import { IQuickAccessLink, PbdModule, SettingType } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIServices } from "../../../services/serviceContext";

import { useTryDelete } from "../../../pbdServices/services/Api/api-hooks";
import { ConnectedCardProps } from "../../shared/components/connectionElements/connectedCardProps";
import ConnectedCardHeader from "../../shared/components/connectionElements/shared/connectedCardHeader";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { TableClickCommand } from "../../shared/components/tables/TableClickCommand";
import { TableRowClickCommand } from "../../shared/components/tables/TableRowClickCommand";
import EditFormQuickAccess from "./editFormQuickAccess";
import QuickAccessTable from "./quickAccessTable";

interface IProps extends ConnectedCardProps<IQuickAccessLink> {
  module: PbdModule;
}

const QuickAccessConnectedCard: React.FC<Omit<IProps, "baseDTO">> = (props) => {
  const { data, refreshParent, module, cardTitle } = props;
  const { moduleSettingsService } = useAPIServices();
  const [showForm, setShowForm] = React.useState(false);
  const [itemToUpdate, setItemToUpdate] = React.useState<IQuickAccessLink>();
  const [selected, setSelected] = React.useState<IQuickAccessLink[]>([]);

  const tryDelete = useTryDelete<IQuickAccessLink, string>(
    (dto) => {
      if (data === undefined) throw new Error("undefined list");
      const dataNew = data.filter((x) => x.id != dto.id);
      return moduleSettingsService.save(module, SettingType.QuickAccessLinks, dataNew);
    },
    [data, module, moduleSettingsService],
    () => refreshParent?.(),
  );

  const handleClick = (command: TableClickCommand) => {
    if (command.action == "Delete") {
      return tryDelete(selected[0]);
    }
    return Promise.resolve();
  };

  const toggle = () => {
    setItemToUpdate(undefined);
    setShowForm(!showForm);
  };

  function onRowEditClick(command: TableRowClickCommand<IQuickAccessLink>) {
    setShowForm(false);
    const { row } = command;
    setTimeout(() => {
      const selected = data?.find((x) => x.id == row.id);
      if (selected) {
        setItemToUpdate(selected);
        setShowForm(true);
      }
    }, 100);
  }

  return (
    <Card className="mb-3">
      <ConnectedCardHeader
        title={
          <span>
            <qmBaseIcons.Bolt style={{ color: "orange" }} /> {cardTitle}
          </span>
        }
        toggleConnectModal={toggle}
        data={data}
        canAdd
      />

      <Card.Body>
        {showForm && data && (
          <EditFormQuickAccess
            refreshParent={() => refreshParent?.()}
            itemToUpdate={itemToUpdate}
            toggle={toggle}
            module={PbdModule.Startpage}
            data={data}
          />
        )}
        {!showForm && data && data.length > 0 && (
          <QuickAccessTable
            tableRows={data}
            setSelected={(ids) => setSelected(ids)}
            availableTableActions={["Delete"]}
            onClick={handleClick}
            onRowClick={onRowEditClick}
            onCreateClick={toggle}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default QuickAccessConnectedCard;
