/* eslint-disable @typescript-eslint/naming-convention */
const routePrefix = "/Profile";

export class ProfileRoutePaths {
  public static HomePage = routePrefix;
  public static UserSettingsPage = `${routePrefix}/UserSettings`;
  public static LanguagePage = `${routePrefix}/Language`;
  public static MyProfile = `${routePrefix}/MyProfile`;
  public static IssuesHomePage = `${routePrefix}/Issues`;
  public static IssuesIndex = `${routePrefix}/Issues/Index`;
  public static IssuesDetails = `${routePrefix}/Issues/Details/:id`;
  public static IssuesCreatePage = `${routePrefix}/Issues/Create`;
  public static Calendar = `${routePrefix}/Calendar`;
  public static Security = `${routePrefix}/Security`;
  public static Permissions = `${routePrefix}/Permissions`;
  public static Notifications = `${routePrefix}/Notifications`;
  public static Watches = `${routePrefix}/Watches`;
}
