import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { CellProps, Column } from "react-table";

import FormattedUserInput from "../../text/formattedUserInput";

interface IProps {
  description?: string;
}

export function getDescriptionColumn<T extends IProps>() {
  const column: Column<T> = {
    id: "description",
    Header: <>{i18next.t("Description")}</>,
    accessor: "description",
    Cell: ({ row }: CellProps<T>) => (
      <>{row.original.description && <FormattedUserInput content={row.original.description} />}</>
    ),
  };
  return column;
}

const columnHelper = createColumnHelper<IProps>();

export const descriptionColumn = columnHelper.accessor("description", {
  id: "description",
  header: () => <>{i18next.t("Description")}</>,
  cell: (x) => (x.row.original.description ? <FormattedUserInput content={x.row.original.description} /> : ""),
});
