import { IRestResponse } from "../../services/restClients/restUtilities";
import { ValidationProblemDetails } from "../../services/restClients/validationProblemDetails";

/**
 *
 */
export class ErrorMessage {
  readonly code: number;
  /**The title of the error that is passed to an i18next function for example 'Bad Request' */
  readonly message: string;
  /**More detailed message that is passed to an i18next function for example 'One or more validation errors occurred.' */
  readonly innerMessage?: string;
  /** The error returned from the server */
  errorToMap?: ValidationProblemDetails;

  static notAllowed() {
    return new ErrorMessage(403);
  }

  constructor(code: 400 | 403 | 404 | 500 | number, innerMessage?: string, errorContent?: ValidationProblemDetails) {
    this.code = code;
    this.innerMessage = innerMessage;
    switch (code) {
      case 400:
        this.message = "Bad request";
        break;
      case 403:
        this.message = "This action is not allowed";
        break;
      case 404:
        this.message = "Not found";
        break;
      case 500:
        this.message = "Internal Server Error";
        break;
      default:
        this.message = "Unknown";
    }
    this.errorToMap = errorContent;
  }

  /**
   * TODO: make from Error only dependent on IRestResponse
   * Use this method to map server errors directly
   * @param data is the exception from the server
   */
  static fromError(data: ValidationProblemDetails | IRestResponse<unknown>) {
    if (this._isValidationProblemDetails(data)) {
      if (data.status != 400 && data.status != 403 && data.status != 404 && data.status != 500) {
        throw Error("Unknown status");
      }
      console.log("Here");
      const result = new ErrorMessage(data.status, data.title);
      result.errorToMap = data;
      return result;
    } else {
      return new ErrorMessage(data.statusCode, data.errorContent?.title, data.errorContent);
    }
  }

  private static _isValidationProblemDetails(
    item: ValidationProblemDetails | IRestResponse<unknown>,
  ): item is ValidationProblemDetails {
    return (item as ValidationProblemDetails).status !== undefined;
  }
}
