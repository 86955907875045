export const crmRoutePrefix = "/Crm";

export class CrmRoutePaths {
  public static HomePage = crmRoutePrefix;
  public static CreatePageSelect = `${crmRoutePrefix}/Create/Select`;
  public static OrganisationEdit = `${crmRoutePrefix}/Organisations/Edit/:id`;
  public static OrganisationEditFn = (id: number) =>
    `${crmRoutePrefix}/Organisations/Edit/:id`.replace(":id", id.toString());
  public static CreatePageOrganisation = `${crmRoutePrefix}/Organisations/Create`;
  public static IndexPageOrganisations = `${crmRoutePrefix}/Organisations/Index`;
  public static IndexPageTenants = `${crmRoutePrefix}/Tenants/Index`;
  public static CreatePageTenant = `${crmRoutePrefix}/Tenants/Create`;
  public static EditPageTenant = `${crmRoutePrefix}/Tenants/Edit/:id`;
  public static EditPageTenantFn = (id: number) => `${crmRoutePrefix}/Tenants/Edit/:id`.replace(":id", id.toString());
  public static IndexPageOrganisationRatings = `${crmRoutePrefix}/Ratings`;
  public static CreatePageOrganisationRatings = `${crmRoutePrefix}/Ratings/Create`;
  public static EditPageOrganisationRatings = `${crmRoutePrefix}/Ratings/Edit/:id`;
  public static EditPageCustomerSatisfaction = `${crmRoutePrefix}/Customers/Satisfaction/Edit/:id`;
  public static EditPageSupplierSatisfaction = `${crmRoutePrefix}/Suppliers/Satisfaction/Edit/:id`;
}
