import { RouteObject } from "react-router-dom";

import PrintArticlePage from "./pages/printArticlePage";
import PrintArticlesBulkPage from "./pages/printArticlesBulkPage";
import PrintAttendeesPage from "./pages/printAttendeesPage";
import PrintClaimPage from "./pages/printClaimPage";
import PrintEightDReportPage from "./pages/printEightDReportPage";
import PrintFormAnswerPage from "./pages/printFormAnswerPage";
import PrintFormPage from "./pages/printFormPage";
import PrintIdeaPage from "./pages/printIdeaPage";
import PrintInventoryInspectionsDonePage from "./pages/printInventoryInspectionsDonePage";
import PrintJobDescription from "./pages/printJobDescription";
import { PrintRoutePaths } from "./printRoutePaths";

export const printRoutesObject: RouteObject[] = [
  {
    path: PrintRoutePaths.Articles,
    element: <PrintArticlePage />,
  },
  {
    path: PrintRoutePaths.Attendees,
    element: <PrintAttendeesPage />,
  },
  {
    path: PrintRoutePaths.Ideas,
    element: <PrintIdeaPage />,
  },
  {
    path: PrintRoutePaths.ArticlesBulk,
    element: <PrintArticlesBulkPage />,
  },
  {
    path: PrintRoutePaths.Forms,
    element: <PrintFormPage />,
  },
  {
    path: PrintRoutePaths.FormAnswers,
    element: <PrintFormAnswerPage />,
  },
  {
    path: PrintRoutePaths.EightDReports,
    element: <PrintEightDReportPage />,
  },
  {
    path: PrintRoutePaths.JobDescriptions,
    element: <PrintJobDescription />,
  },
  {
    path: PrintRoutePaths.Claims,
    element: <PrintClaimPage />,
  },
  {
    path: PrintRoutePaths.InventoryInspectionsDone,
    element: <PrintInventoryInspectionsDonePage />,
  },
];
