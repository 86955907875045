import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { ProfileRoutePaths } from "../profile/profileRoutePaths";
import IssueLayout from "./issueLayout";

/**
 * TODO:
 * Authentication handling not 100% clear for this approach.
 */
export const issueRoutes: RouteObject[] = [
  {
    path: ProfileRoutePaths.IssuesCreatePage,
    Component: lazy(() => import("./pages/createPageIssue")),
  },
  {
    path: ProfileRoutePaths.IssuesIndex,
    Component: lazy(() => import("./pages/indexPageIssues")),
  },
  {
    path: ProfileRoutePaths.IssuesDetails,
    Component: lazy(() => import("./pages/detailsPageIssue")),
  },
];

export const issueRouteObject: RouteObject = {
  path: ProfileRoutePaths.IssuesHomePage,
  element: <IssueLayout />,
  children: issueRoutes,
};
