import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { CategoryCreateDTO, ICategoryCreateDTO, IOpportunityCategoryDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { useRefreshHook } from "@/hooks/useRefreshHook";
import BaseSettingsForm, { hasId } from "../../components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settingsRoutePaths";
import CriticalValuesConnectedCard from "../components/criticalValuesConnectedCard";
import FormulaCard from "../components/formulaCard";
import SummaryCard from "../components/summaryCard";
import VariablesConnectedCard from "../components/variablesConnectedCard";

const EditPageOpportunityCategories = () => {
  const { id } = useParams<{ id?: string }>();
  const { opportunityCategoriesApi } = useAPIs();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<IOpportunityCategoryDTO>();

  const [{ refresh, handleRefresh }] = useRefreshHook();
  const { data: existingOpportunityCategories } = useSWR(`opportunityCategories`, () =>
    opportunityCategoriesApi.getAll(),
  );

  React.useEffect(() => {
    async function getData() {
      if (id) {
        setLoading(true);
        const type = await opportunityCategoriesApi.getById(Number(id));
        setItemToUpdate(type);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    getData();
  }, [id, refresh]);

  async function handleSubmit(itemToCreate: ICategoryCreateDTO) {
    if (id) {
      return opportunityCategoriesApi.edit(Number(id), new CategoryCreateDTO(itemToCreate));
    } else {
      return opportunityCategoriesApi.create(new CategoryCreateDTO(itemToCreate));
    }
  }

  async function toggleDelete() {
    if (!itemToUpdate) throw Error("Missing itemToUpdate");
    if (itemToUpdate.isDeleted) {
      await opportunityCategoriesApi.restore(itemToUpdate.id);
    } else {
      await opportunityCategoriesApi.delete(itemToUpdate.id);
    }
    handleRefresh();
  }

  const handleSuccess = (dto?: unknown) => {
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageOpportunityCategories.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };

  return (
    <>
      {itemToUpdate && <SummaryCard category={itemToUpdate} />}
      {!loading && (
        <BaseSettingsForm
          itemToUpdate={itemToUpdate}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          additionalFields={["Color", "Recurring"]}
          onDelete={toggleDelete}
          onRestore={(x) => opportunityCategoriesApi.restore(x)}
          handleRefresh={handleRefresh}
          existingItems={existingOpportunityCategories}
          formType={"OpportunityCategory"}
        />
      )}

      {itemToUpdate && (
        <Card className="mb-3">
          <VariablesConnectedCard baseDTO={itemToUpdate} refreshParent={handleRefresh} />
          {itemToUpdate.variables && (
            <>
              <FormulaCard itemToUpdate={itemToUpdate} refreshParent={handleRefresh} />
              <CriticalValuesConnectedCard baseDTO={itemToUpdate} refreshParent={handleRefresh} />
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default EditPageOpportunityCategories;
