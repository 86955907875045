import BaseEntityTemplate from "../../Models/EntityTemplates/BaseEntityTemplate";
import { ICategoryEntityTemplate } from "../../Models/EntityTemplates/CategoryEntityTemplate";
import RestUtilities from "../restClients/restUtilities";

export type TemplateNames =
  | "customForm"
  | "ideaCategory"
  | "inventoryInspection"
  | "inventoryCategory"
  | "inventoryStatus"
  | "qualification";

const baseAzureBlobUrl = "https://qmbasesupport.blob.core.windows.net/template-data";

interface EntityTemplateQuery {
  /**2 letter language code. Examples: de, en */
  language?: "de" | "en";
  entity: TemplateNames;
}

export class EntityTemplateService {
  getAll<T extends ICategoryEntityTemplate = ICategoryEntityTemplate>(query: EntityTemplateQuery) {
    return RestUtilities.get<BaseEntityTemplate<T>>(
      `${baseAzureBlobUrl}/${query.entity}/${query.language ?? "de"}/data.json`,
    );
    // return resp.content as TemplateDataDTO[];
  }
}
