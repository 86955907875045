import { createContext, ReactNode, useContext, useMemo } from "react";

import { IConfigApi } from "../pbdServices/services/apiBase/configApi";

import config from "./config";
import { ServiceContextData } from "./serviceContextData";

const ServiceContext = createContext({} as ServiceContextData);

export const useAPIServices = () => useContext(ServiceContext);
export const useAPIs = () => useContext(ServiceContext).apis;
export const useAdminAPIs = () => useContext(ServiceContext).adminApis;
export const useStoreAPIs = () => useContext(ServiceContext).storeApis;
export const useFunctionAppAPIs = () => useContext(ServiceContext).functionAppApis;

export interface IProps {
  value: IConfigApi | ServiceContextData;
  adminUrl?: string;
  storeUrl?: string;
  functionAppUrl?: string;
  children: ReactNode;
}
export function ServiceContextProvider(props: IProps) {
  const {
    value,
    adminUrl = config.adminApi.url,
    storeUrl = config.store.baseUrl.url,
    functionAppUrl = config.azureFunctionApp.baseUrl,
  } = props;

  const services = useMemo(() => {
    if (value instanceof ServiceContextData) return value;

    return new ServiceContextData(value, adminUrl, storeUrl, functionAppUrl);
  }, [value, adminUrl, storeUrl]);

  return <ServiceContext.Provider value={services}>{props.children}</ServiceContext.Provider>;
}
