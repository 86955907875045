export class DashboardLayout {
  constructor() {
    this.columnWidth = 6;
    this.autoRefresh = false;
    this.filters = [];
    this.itemOrder = [];
  }
  columnWidth: 3 | 6 | 12;
  autoRefresh: boolean;
  filters: unknown[];
  /**Order of item Ids that should be rendered. */
  itemOrder: string[];
}
