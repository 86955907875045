import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { ICustomField } from "@/generatedCode/pbd-core/pbd-core-api";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { MaintenanceManagementRoutePaths } from "./maintenanceManagementRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.MaintenanceManagement_Standard, PbdRoles.MaintenanceManagement_ModuleAdmin],
};

export const maintenanceManagementLazyRoutes: RouteObject[] = [
  {
    path: MaintenanceManagementRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageMaintenanceManagement")),
    ...defaultRouteParams,
  },
  {
    path: MaintenanceManagementRoutePaths.IndexPage,
    Component: lazy(() => import("../inventoryItems/pages/indexPageInventoryItems")),
    ...defaultRouteParams,
  },
  {
    path: MaintenanceManagementRoutePaths.CreateInventoryItem,
    Component: lazy(() => import("../inventoryItems/pages/createPageInventoryItem")),
    ...defaultRouteParams,
  },
  {
    path: MaintenanceManagementRoutePaths.EditPageInventoryItem,
    Component: lazy(() => import("../inventoryItems/pages/detailsPageInventoryItem")),
    ...defaultRouteParams,
  },
  {
    path: MaintenanceManagementRoutePaths.UpcomingInspection,
    Component: lazy(() => import("../inventoryInspectionsConnected/pages/indexPageInventoryInspectionsConnected")),
    ...defaultRouteParams,
  },
  {
    path: MaintenanceManagementRoutePaths.InspectionsDone,
    Component: lazy(() => import("../inventoryInspectionsDone/pages/indexPageInventoryInspectionsDone")),
    ...defaultRouteParams,
  },
  {
    path: MaintenanceManagementRoutePaths.InspectionsDoneEdit,
    Component: lazy(() => import("../inventoryInspectionsDone/pages/editPageInventoryInspectionsDone")),
    ...defaultRouteParams,
  },
  {
    path: MaintenanceManagementRoutePaths.CreatePageInspectionsDone,
    Component: lazy(() => import("../inventoryInspectionsDone/pages/createPageInventoryInspectionDone")),
    ...defaultRouteParams,
  },
  {
    path: MaintenanceManagementRoutePaths.RepairsDone,
    Component: lazy(() => import("../inventoryItemRepairs/pages/indexPageInventoryItemRepairs")),
    ...defaultRouteParams,
  },

  {
    path: MaintenanceManagementRoutePaths.RepairsDoneEdit,
    Component: lazy(() => import("../inventoryItemRepairs/pages/editPageInventoryItemRepair")),
    ...defaultRouteParams,
  },
  {
    path: MaintenanceManagementRoutePaths.CreatePageRepair,
    Component: lazy(() => import("../inventoryItemRepairs/pages/createPageInventoryItemRepair")),
    ...defaultRouteParams,
  },
];

export class MaintenanceManagementSettings {
  customFields?: ICustomField[];
}
