/* eslint-disable check-file/filename-naming-convention */
import { NavigateFunction } from "react-router-dom";
import { BareFetcher } from "swr";
import { PublicConfiguration } from "swr/_internal";

import { paths } from "@/config/paths";
import { ApiException } from "@/generatedCode/pbd-core/pbd-core-api";

export default class SwrApiHelpers {
  /**This implements basic error handling to access denied page */
  static getDefaultGetOptions<Data = any, Error = any, Fn extends BareFetcher<any> = BareFetcher<any>>(
    navigate: NavigateFunction,
  ) {
    const options: Partial<PublicConfiguration<Data, Error, Fn>> = {
      onError(err, key, config) {
        if (err && err instanceof ApiException) {
          if (err.status == 403 || err.status == 401) {
            navigate(paths.auth.accessDenied.path);
          } else if (err.status == 404) {
            navigate(paths.error.getHref("404"));
          } else if (err.status == 400) {
            navigate(paths.error.getHref("400"));
          }
        }
      },
    };
    return options;
  }

  static defaultIndexPageOptions<Data = any, Error = any, Fn extends BareFetcher<any> = BareFetcher<any>>() {
    const options: Partial<PublicConfiguration<Data, Error, Fn>> = {
      revalidateOnFocus: false,
    };
    return options;
  }
}
