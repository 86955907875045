import { uniq } from "lodash";

import { CapabilitiesDTO, INotificationEntry, ITenantDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import TenantsConnectedCard from "../../../shared/components/connectionElements/tenants/tenantsConnectedCard";

interface IProps {
  notificationEntry: INotificationEntry;
  data: ITenantDTO[];
  onSave: (item: INotificationEntry) => Promise<void>;
  refreshParent: () => void;
}

function ConnectNotifiersComponent(props: IProps) {
  const { notificationEntry, onSave, refreshParent, data } = props;

  function submitConnectTenant(ids: number[]) {
    const existingEntries = notificationEntry.tenantIds;
    const combinedArray = [...uniq([...existingEntries, ...ids])];
    const itemToUpdateUpdate = { ...notificationEntry };
    itemToUpdateUpdate.tenantIds = combinedArray;
    return onSave(itemToUpdateUpdate);
  }

  function submitDisconnectRequest(id: number) {
    const itemToUpdateUpdate = { ...notificationEntry };
    const tenantsUpdated = itemToUpdateUpdate.tenantIds.filter((x) => x != id);
    itemToUpdateUpdate.tenantIds = tenantsUpdated;
    return onSave(itemToUpdateUpdate);
  }

  const capa = new CapabilitiesDTO();
  capa.canEdit = true;
  return (
    <TenantsConnectedCard
      baseDTO={{ capabilities: capa, id: 0 }}
      onConnectSubmit={submitConnectTenant}
      onDisconnectSubmit={submitDisconnectRequest}
      refreshParent={refreshParent}
      data={data}
    />
  );
}

export default ConnectNotifiersComponent;
