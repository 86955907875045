import { useTranslation } from "react-i18next";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { ProductRoutePaths } from "../productRoutePaths";

const SidebarProducts = () => {
  const { t } = useTranslation();
  const links: SidebarItem[] = [
    SidebarItem.created(ProductRoutePaths.CreatePage, t),
    SidebarItem.overview(ProductRoutePaths.IndexPage, t),
    SidebarItem.deleted(ProductRoutePaths.IndexDeleted, t),
  ];
  return <SidebarNew app={PbdModule.Products} items={links} />;
};

export default SidebarProducts;
