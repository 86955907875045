import { GlobalQmBaseConstants } from "../../Constants/GlobalQmBaseConstants";

export default class UrlService {
  static getAbsoluteUrl(path: string) {
    return `${window.location.protocol}//${window.location.hostname}${path}`;
  }

  static isCloudEnvironment() {
    console.log(window.location.hostname);
    return window.location.hostname.toUpperCase().endsWith(GlobalQmBaseConstants.QmBaseDomain);
  }

  static isOnPrem() {
    return !window.location.hostname.toUpperCase().endsWith(GlobalQmBaseConstants.QmBaseDomain);
  }
}
