import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  item: { id: number; applicationUserId?: string | undefined };
}

function NoUserComponent(props: IProps) {
  const { item } = props;
  const { t } = useTranslation();
  const isUser: boolean = item.applicationUserId ? true : false;
  if (isUser) return null;

  return (
    <span title={t("This person is no user and does not receive notifications.")} className="ms-1">
      <qmBaseIcons.BellSlash color="muted" />
    </span>
  );
}
export default NoUserComponent;
