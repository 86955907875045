import { DateTime } from "luxon";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";

interface IProps {
  createdAt: DateTime;
}

function NewBadge(props: IProps) {
  const { createdAt } = props;
  const { t } = useTranslation();
  const isNew = createdAt > DateTime.now().minus({ hours: GlobalQmBaseConstants.MaxHoursUpdatedBadge });
  if (isNew) {
    return (
      <Badge className="ms-2" pill title={createdAt.toRelative() ?? ""}>
        {t("New")}
      </Badge>
    );
  } else {
    return null;
  }
}

export default NewBadge;
