import useSWRImmutable from "swr/immutable";

import { useAPIs } from "../services/serviceContext";

export function useEmergencyContactHook() {
  const { settingsApi } = useAPIs();

  const swr = useSWRImmutable(["/api/settings", "emergencyContact"], () => settingsApi.getEmergencyContact());
  return swr;
}
