import { FormikProps, FormikValues } from "formik";
import { Form } from "react-bootstrap";

import FormikSubmitButton from "../forms/formik/formikSubmitButton";
import CancelButton from "./cancelButton";

interface IProps<T extends FormikValues> {
  onCancel?: () => void;
  formikBag: FormikProps<T>;
  /**
   * There are  situations where we want to submit directly without changing the form.
   * In this case you use this prop.
   */
  canSubmitDirty?: boolean;
  submitLabel?: string;
  hideSubmitButton?: boolean;
  submitVariant?: "danger";
}

function CancelSubmitFormGroup<T extends FormikValues>({
  onCancel,
  formikBag,
  canSubmitDirty,
  submitLabel,
  hideSubmitButton,
  submitVariant,
}: IProps<T>) {
  return (
    <Form.Group className="mb-3">
      {onCancel && <CancelButton onClick={onCancel} />}
      {!hideSubmitButton && (
        <FormikSubmitButton
          formikBag={formikBag}
          canSubmitDirty={canSubmitDirty}
          label={submitLabel}
          variant={submitVariant}
        />
      )}
    </Form.Group>
  );
}
export default CancelSubmitFormGroup;
