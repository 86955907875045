import { useTranslation } from "react-i18next";

import { ValidationResult } from "../../../../../pbdServices/Models/Shared/validation-result";
import { qmBaseIcons } from "../../icons/qmBaseIcons";

interface IProps {
  warnings?: ValidationResult[];
}

function WarningTableCell(props: IProps) {
  const { t } = useTranslation();
  const { warnings } = props;

  if (!warnings || warnings.length == 0) return null;

  return (
    <qmBaseIcons.Alert
      className="text-warning ms-1"
      title={`${t("Action required")}: ${warnings.map((x) => t(x.description)).join()}`}
    />
  );
}
export default WarningTableCell;
