import { CellProps, ColumnInstance } from "react-table";
import { Input } from "reactstrap";

import { idOfSelectionColumn } from "../reactstrapTable";

export function getSelectionColumn<T extends object>(key: string) {
  //@ts-expect-error currently not working TODO: FIx
  const col: ColumnInstance<T> = {
    id: idOfSelectionColumn,
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox.  Pagination is a problem since this will select all
    // rows even though not all rows are on the current page.  The solution should
    // be server side pagination.  For one, the clients should not download all
    // rows in most cases.  The client should only download data for the current page.
    // In that case, getToggleAllRowsSelectedProps works fine.

    Header: ({ getToggleAllRowsSelectedProps }) => {
      const { indeterminate, ...output } = { ...getToggleAllRowsSelectedProps() };
      return (
        <div>
          <Input
            id={`${key}_selectAll`}
            type="checkbox"
            {...output}
            title="selectAll"
            // onChange={handleSelect}
          />
        </div>
      );
    },
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({ row }: CellProps<T>) => {
      const { indeterminate, ...output } = { ...row.getToggleRowSelectedProps() };
      return (
        <div>
          <Input
            id={`${key}_${row.values.id}`}
            value={row.values.id}
            type="checkbox"
            {...output}
            // onChange={() => handleSelect()}
          />
        </div>
      );
    },
  };
  return col;
}
