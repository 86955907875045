import React from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DepartmentPositionDTO, ITenantMinDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { CustomVariant } from "../../themes/customVariant";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import ProgressBarWithText from "../progress/progressBarWithText";

const deltaTypeValues = ["increase", "moderateIncrease", "decrease", "moderateDecrease", "unchanged"] as const;

export type DeltaType = (typeof deltaTypeValues)[number];

const getColor = (deltaType?: DeltaType): CustomVariant => {
  switch (deltaType) {
    case "increase":
    case "moderateIncrease":
      return "success";
    case "decrease":
    case "moderateDecrease":
      return "danger";
    default:
      return "secondary";
  }
};

const getIcon = (deltaType?: DeltaType) => {
  switch (deltaType) {
    case "increase":
      return <qmBaseIcons.ArrowUp />;
    case "moderateIncrease":
      return <qmBaseIcons.TrendUp />;
    case "decrease":
      return <qmBaseIcons.ArrowDown />;
    case "moderateDecrease":
      return <qmBaseIcons.TrendDown />;
    default:
      return <qmBaseIcons.ArrowRight />;
  }
};

export const getDeltaType = (delta: number): DeltaType => {
  const deltaAbs = Math.abs(delta);
  if (delta < 0) {
    if (deltaAbs > 0.1) {
      return "decrease";
    } else if (deltaAbs > 0.02) {
      return "moderateDecrease";
    } else {
      return "unchanged";
    }
  } else if (delta > 0) {
    if (deltaAbs > 0.1) {
      return "increase";
    } else if (deltaAbs > 0.02) {
      return "moderateIncrease";
    } else {
      return "unchanged";
    }
  } else {
    return "unchanged";
  }
};

export function calculateKpi(dto: DepartmentPositionDTO, tenants: ITenantMinDTO[]): IProps {
  return {
    title: "Occupation status",
    metric: `${tenants.length} Persons`,
    target: dto.standardCapacity?.toString(),
    progress: dto.standardCapacity ? dto.standardCapacity / tenants.length : undefined,
    delta: dto.standardCapacity ? (dto.standardCapacity - tenants.length).toString() : undefined,
    deltaType: "unchanged",
  };
}

interface IProps {
  title: React.ReactNode;
  metric: string | number;
  progress?: number;
  target?: string;
  delta?: string;
  /**
   * Examples:
   * Increase ⬆️ Moderate increase ↗️ Unchanged ➡️
   * */
  deltaType?: DeltaType;
  className?: string;
}

/**
 * Kpi - Key Performance Indicator
 * How to use:
 * - Maximum 6 cards per row -
 */
function KpiCard(props: IProps) {
  const { title, metric, progress, target, delta, deltaType, className } = props;
  const { t } = useTranslation();
  const color = getColor(deltaType);
  const icon = getIcon(deltaType);

  return (
    <Card className={className}>
      <Card.Body>
        <Row>
          <Col xs={8} sm={8} md={10} lg={11}>
            <p className="text-truncate m-0" title={typeof title == "string" ? title : undefined}>
              {title}
            </p>
          </Col>
          <Col xs={4} sm={4} md={2} lg={1} className="d-flex justify-content-end px-2 m-0">
            {delta && (
              <Badge
                className="text-sm p-1 mx-auto text-center"
                bg={color}
                title={deltaType ? t(deltaType) : undefined}
              >
                {icon} {delta}
              </Badge>
            )}
          </Col>
        </Row>
        <p className="lead">{metric}</p>
        {progress && target && <ProgressBarWithText {...props} progress={progress} target={target} />}
      </Card.Body>
    </Card>
  );
}
export default KpiCard;
