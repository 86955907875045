import { ICapabilitiesDTO, IEntityPermissionDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { MeAsUser } from "../../pbdServices/services/UserSettings/models/me-as-user";
import { PbdRoles } from "../Authz/PbdRoles";
import { hasRole } from "../Authz/authService";

export class EntityPermissionService {
  canAccess(meAsUser: MeAsUser, dto: IEntityPermissionDTO, moduleAdminRole: string) {
    // if (!dto.isPrivate) return true;
    if (hasRole(meAsUser, [PbdRoles.Admin])) return true;
    if (hasRole(meAsUser, [moduleAdminRole])) return true;

    if (dto.users.map((x) => x.id).includes(meAsUser.user.id)) return true;
    if (dto.groups.map((x) => x.id).some((r) => meAsUser.groups.map((g) => g.id).includes(r))) return true;

    return false;
  }
}

interface RoleInfo {
  title: string;
  role: keyof ICapabilitiesDTO;
  description: string;
}

export const PermissionLevelOptions: RoleInfo[] = [
  { role: "canAccess", title: "Access", description: "Can access this entry." },
  { role: "canEdit", title: "Edit", description: "Can access this entry. Can edit everything in this entry." },
  {
    role: "canEditCustomFields",
    title: "Custom Fields",
    description: "Can access this entry. Can add, edit and delete Custom Fields.",
  },
  {
    role: "canEditProgressArea",
    title: "Edit progress area",
    description: "Can access this entry. Can add, edit and delete progress area.",
  },
];

export const getPermissionLevels = (roles?: (keyof ICapabilitiesDTO)[]): RoleInfo[] => {
  if (roles) {
    return PermissionLevelOptions.filter((x) => roles.includes(x.role));
  } else {
    return PermissionLevelOptions;
  }
};
