import React from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { useQueryParams, withDefault } from "use-query-params";

import {
  AuditActionType,
  INotificationEntry,
  ITenantDTO,
  NotificationEntry,
  PbdEventType,
  PbdModule,
  SettingType,
  TenantsQueryField,
} from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import { PbdEventTypeQueryParam } from "../../../../utils/queryHelpers";
import GenericAlert from "../../../shared/components/alerts/genericAlert";
import SettingsCardHeader from "../../components/settingsCardHeader";
import { SettingsRoutePaths } from "../../settingsRoutePaths";
import ConnectNotifiersComponent from "../components/connectNotifiersComponent";

const actions = [PbdEventType.Created, PbdEventType.SetCompleted];

const selectedModule = PbdModule.ClaimManagement;
const settingType = SettingType.Notifications;

function IndexPageNotifications() {
  const { t } = useTranslation();
  const { tenantsApi } = useAPIs();
  const { moduleSettingsApi } = useAPIs();
  const [tenants, setTenants] = React.useState<ITenantDTO[]>();
  const [query, setQuery] = useQueryParams({ type: withDefault(PbdEventTypeQueryParam, undefined) });

  const { data, mutate } = useSWR(["/api/moduleSettings/", selectedModule, settingType, query.type], () => {
    const { type } = query;
    if (!type) return null;
    return moduleSettingsApi.getNotificationEntries(selectedModule).then(
      (resp) =>
        resp.find((x) => x.eventType == query.type) ??
        new NotificationEntry({
          action: AuditActionType.Update,
          eventType: type,
          tenantIds: [],
          departmentIds: [],
          departmentPositionIds: [],
        }),
    );
  });

  React.useEffect(() => {
    async function getData() {
      if (data && data.tenantIds.length > 0) {
        const tenantsConnected = await tenantsApi.getAllQuery({
          id: data.tenantIds,
          isEmployee: true,
          fields: [TenantsQueryField.ApplicationUser],
          pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
        });
        setTenants(tenantsConnected.data);
      } else {
        setTenants([]);
      }
    }
    getData();
  }, [data]);

  const handleSubmit = async (item: INotificationEntry) => {
    await moduleSettingsApi.updateNotificationEntry(selectedModule, new NotificationEntry(item));
  };

  return (
    <Card>
      <SettingsCardHeader cardTitle={t("Notifications")} />
      <Card.Body>
        <Card.Text>{t("Never miss any updates. Get E-Mail notifications if something happens!")}</Card.Text>
        <Row>
          <Col md={3}>
            <ListGroup>
              {actions.map((x) => (
                <ListGroup.Item
                  key={x}
                  action
                  as={Link}
                  to={`${SettingsRoutePaths.ClaimNotifications}?type=${x}`}
                  active={query.type == x}
                >
                  {t(x)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col>
            {query.type && data && tenants ? (
              <ConnectNotifiersComponent
                notificationEntry={data}
                data={tenants}
                onSave={handleSubmit}
                refreshParent={() => mutate()}
              />
            ) : (
              <GenericAlert type="info" dismissible={false}>
                {t("Please select the action for which you want to define the notifications")}
              </GenericAlert>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default IndexPageNotifications;
