import React from "react";
import { DecodedValueMap } from "use-query-params";

import { FilterElement, PredefinedFilters, SearchFilterTypes } from "./availableSearchFilters";
import { QueryParamsConfigPbd } from "./queryParamsConfigPbd";

export const useSelectedFilterHook = (query: DecodedValueMap<typeof QueryParamsConfigPbd>) => {
  const [selectedFiltersState, setSelectedFiltersState] = React.useState<FilterElement[]>([]);
  React.useEffect(() => {
    function setFilterValues() {
      const filters: SearchFilterTypes[] = [];
      if (query.customFields != null) {
        filters.push(SearchFilterTypes.CustomField);
      }
      if (query.status != null) {
        filters.push(SearchFilterTypes.Status);
      }
      if (query.tags != null) {
        filters.push(SearchFilterTypes.Tags);
      }

      if (query.createdFrom != null || query.createdTo != null) {
        filters.push(SearchFilterTypes.CreatedAt);
      }
      if (query.claimedFrom != null || query.claimedTo != null) {
        filters.push(SearchFilterTypes.ClaimedAt);
      }
      if (query.deadlineFrom != null || query.deadlineTo != null) {
        filters.push(SearchFilterTypes.Deadline);
      }

      if (query.lastUpdatedFrom != null || query.lastUpdatedTo != null) {
        filters.push(SearchFilterTypes.LastUpdatedAt);
      }

      if (query.claimType != null) {
        filters.push(SearchFilterTypes.ClaimType);
      }

      if (query.responsibleId != null) {
        filters.push(SearchFilterTypes.Responsible);
      }
      if (query.ownerId != null) {
        filters.push(SearchFilterTypes.Owner);
      }
      if (query.createdById != null) {
        filters.push(SearchFilterTypes.CreatedBy);
      }
      if (query.reviewerId != null) {
        filters.push(SearchFilterTypes.ReviewerId);
      }
      if (query.approvedById != null) {
        filters.push(SearchFilterTypes.ApprovedById);
      }
      if (query.contributorId != null) {
        filters.push(SearchFilterTypes.ContributorId);
      }
      if (query.attendeeId != null) {
        filters.push(SearchFilterTypes.Attendees);
      }
      if (query.tenantId) {
        filters.push(SearchFilterTypes.Tenant);
      }
      if (query.organisationId != null) {
        filters.push(SearchFilterTypes.Organisation);
      }
      if (query.inventoryItemId != null) {
        filters.push(SearchFilterTypes.InventoryItem);
      }
      if (query.inventoryCategoryId != null) {
        filters.push(SearchFilterTypes.InventoryCategory);
      }
      if (query.inventoryStatusId != null) {
        filters.push(SearchFilterTypes.InventoryStatus);
      }

      if (query.inventoryInspectionId != null) {
        filters.push(SearchFilterTypes.InventoryInspection);
      }
      if (query.functioning != null) {
        filters.push(SearchFilterTypes.Functioning);
      }
      if (query.lastUpdatedFrom) {
        filters.push(SearchFilterTypes.LastUpdatedAt);
      }
      if (query.lastUpdatedTo) {
        filters.push(SearchFilterTypes.LastUpdatedAt);
      }
      if (query.absentFrom != null || query.absentTo != null) {
        filters.push(SearchFilterTypes.AbsentAt);
      }
      if (query.absentForId != null) {
        filters.push(SearchFilterTypes.AbsentFor);
      }
      if (query.absencePolicyId != null) {
        filters.push(SearchFilterTypes.AbsencePolicy);
      }
      if (query.departmentId != null) {
        filters.push(SearchFilterTypes.Department);
      }
      if (query.departmentPositionId != null) {
        filters.push(SearchFilterTypes.DepartmentPosition);
      }
      if (query.isDeleted != null) {
        filters.push(SearchFilterTypes.IsDeleted);
      }
      if (query.opportunityType != null) {
        filters.push(SearchFilterTypes.OpportunityType);
      }
      if (query.opportunityCategoryId != null) {
        filters.push(SearchFilterTypes.OpportunityCategory);
      }
      if (query.doneFrom != null || query.doneTo != null) {
        filters.push(SearchFilterTypes.DoneAt);
      }
      if (query.auditRequirement != null) {
        filters.push(SearchFilterTypes.AuditRequirement);
      }
      if (query.claimId != null) {
        filters.push(SearchFilterTypes.Claim);
      }
      if (query.qualificationId) {
        filters.push(SearchFilterTypes.Qualification);
      }
      if (query.addressId != null) {
        filters.push(SearchFilterTypes.Address);
      }
      if (query.categoryId != null) {
        filters.push(SearchFilterTypes.Category);
      }
      if (query.goalId != null) {
        filters.push(SearchFilterTypes.Goal);
      }
      if (query.customFormId != null) {
        filters.push(SearchFilterTypes.CustomForm);
      }
      if (query.userId != null) {
        filters.push(SearchFilterTypes.ApplicationUser);
      }
      if (query.isCustomer != null) {
        filters.push(SearchFilterTypes.IsCustomer);
      }
      if (query.isSupplier != null) {
        filters.push(SearchFilterTypes.IsSupplier);
      }
      if (query.projectId != null) {
        filters.push(SearchFilterTypes.Project);
      }
      if (query.supporterId != null) {
        filters.push(SearchFilterTypes.SupporterId);
      }
      if (query.claimIsJustified != null) {
        filters.push(SearchFilterTypes.claimIsJustified);
      }
      if (query.productId != null) {
        filters.push(SearchFilterTypes.ProductId);
      }
      const elements = PredefinedFilters.getList(filters);
      setSelectedFiltersState(elements);
    }
    setFilterValues();
  }, [
    query.claimType,
    query.createdFrom,
    query.createdTo,
    query.deadlineFrom,
    query.deadlineTo,
    query.functioning,
    query.inventoryCategoryId,
    query.inventoryInspectionId,
    query.inventoryStatusId,
    query.organisationId,
    query.responsibleId,
    query.ownerId,
    query.status,
    query.tags,
    query.lastUpdatedFrom,
    query.lastUpdatedTo,
    query.attendeeId,
    query.absentForId,
    query.absencePolicyId,
    query.absentFrom,
    query.absentTo,
    query.departmentId,
    query.departmentPositionId,
    query.isDeleted,
    query.opportunityType,
    query.opportunityCategoryId,
    query.doneFrom,
    query.doneTo,
    query.auditRequirement,
    query.claimId,
    query.tenantId,
    query.qualificationId,
    query.addressId,
    query.categoryId,
    query.createdById,
    query.reviewerId,
    query.approvedById,
    query.goalId,
    query.customFormId,
    query.inventoryItemId,
    query.userId,
    query.isCustomer,
    query.isSupplier,
    query.contributorId,
    query.projectId,
    query.supporterId,
    query.claimIsJustified,
    query.productId,
  ]);

  return { selectedFiltersState };
};
