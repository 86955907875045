import { useCallback, useState } from "react";

/**
 * Hook
// Parameter is the boolean, with default "false" value
 * @param initialState false
 * @returns 
 */
export const useToggle = (initialState = false): [boolean, () => void] => {
  // Initialize the state
  const [state, setState] = useState<boolean>(initialState);
  // Define and memorize toggler function in case we pass down the component,
  // This function change the boolean value to it's opposite value
  const toggle = useCallback((): void => setState((state) => !state), []);
  return [state, toggle] as const;
};
