import clsx from "clsx";
import { GroupBase, StylesConfig } from "react-select";

export function getSelectStyles<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  isInvalid: boolean,
): StylesConfig<Option, IsMulti, Group> {
  return {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    container: (base) => ({
      ...base,
      //border: isInvalid ? "1px solid red" : base.border,
      className: clsx(base.className, "border-danger"),
    }),
  };
}
