import ReactMarkdown, { defaultUrlTransform } from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";

interface IProps {
  content: string;
  includeHtml?: boolean;
}

function CustomMarkdownContainer(props: IProps) {
  const { content, includeHtml } = props;
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkBreaks]}
      rehypePlugins={includeHtml ? [rehypeRaw] : undefined}
      className="markdown-content"
      components={{
        table: ({ node, ...props }) => <table className="table table-bordered" {...props} />,
        a(props) {
          const { node, ...rest } = props;
          if (rest.href?.startsWith("mention://")) {
            return <span>{rest.children}</span>;
          } else {
            return <a href={rest.href}>{rest.href}</a>;
          }
        },
      }}
      urlTransform={(e) => {
        if (e.startsWith("mention://")) {
          return e;
        } else {
          return defaultUrlTransform(e);
        }
      }}
    >
      {content}
    </ReactMarkdown>
  );
}
export default CustomMarkdownContainer;
