import { Dropdown, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { IEmployeeIdeaDetailsDTO, IReviewRequestDTO, PbdStatus } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { DateTimeLuxonHelpers } from "../../../../utils/dateTimeLuxonHelpers";
import { IEmployeeIdeaReviewVm } from "../../../../pbdServices/services/IdeaManagement/models/employeeIdeaReviewVm";
import CustomDropdownToggleButton from "../../../shared/components/buttons/customDropdownToggleButton";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import AvatarSpanWithName from "../../../shared/components/tenants/avatarSpanWithName";
import FormattedUserInput from "../../../shared/components/text/formattedUserInput";
import ReviewRequestBadge from "./reviewRequestBadge";

interface IProps {
  item: IEmployeeIdeaReviewVm;
  idea: IEmployeeIdeaDetailsDTO;
  toggleCustomizeRequestModal?: () => void;
  setSelectedReview?: (item: IReviewRequestDTO) => void;
  setSelected?: (ids: number[]) => void;
  tryToDelete?: (item: IEmployeeIdeaReviewVm) => void;
  publishReview?: (id: number) => void;
  onHistoryClick?: () => void;
}

function ReviewListGroupItem(props: IProps) {
  const { item, idea, setSelected, setSelectedReview, toggleCustomizeRequestModal, tryToDelete, publishReview } = props;
  const { employeeIdeaReviewsApi } = useAPIs();
  const { t } = useTranslation();

  const { data } = useSWR(["/api/reviewRequests", item.id], () => employeeIdeaReviewsApi.getHistoryById(item.id));

  return (
    <ListGroup.Item>
      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex align-items-center">
          <AvatarSpanWithName tenant={item.reviewer} className="me-1" />
          <ReviewRequestBadge item={item} idea={idea} history={data} onHistoryClick={props.onHistoryClick} />
        </div>
        <div>
          {setSelected && setSelectedReview && toggleCustomizeRequestModal && publishReview && tryToDelete && (
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
                size="sm"
                as={CustomDropdownToggleButton}
                disabled={idea.status != PbdStatus.UnderReview}
              />
              <Dropdown.Menu align="end">
                <Dropdown.Item
                  onClick={() => {
                    setSelectedReview({
                      ...item,
                      reviewRequest: "",
                      sendNotification: true,
                      archiveExistingReview: true,
                    });
                    setSelected([item.reviewer.id]);
                    toggleCustomizeRequestModal();
                  }}
                >
                  <qmBaseIcons.Plus /> {t("New review request")}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSelectedReview({ ...item, sendNotification: true, archiveExistingReview: false });
                    setSelected([item.reviewer.id]);
                    toggleCustomizeRequestModal();
                  }}
                >
                  <qmBaseIcons.Pencil /> {t("Customize request")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => publishReview(item.reviewer.id)}>
                  {item.isPublished ? (
                    <>
                      <qmBaseIcons.Private className="me-1" />
                      {t("Mark as private")}
                    </>
                  ) : (
                    <>
                      <qmBaseIcons.Globe className="me-1" />
                      {t("Publish")}
                    </>
                  )}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => tryToDelete(item)}>
                  <qmBaseIcons.Delete /> {t("Delete")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
      <dl>
        <dt>
          {t("Requested")} {DateTimeLuxonHelpers.convertUtcToDateTime(item.createdAt)}{" "}
          {item.lastUpdatedAt && (
            <span>
              {t("Updated")} {DateTimeLuxonHelpers.convertUtcToDateTime(item.lastUpdatedAt)}
            </span>
          )}
        </dt>
        <dd>
          <FormattedUserInput content={item.reviewRequest} />
        </dd>
        {item.answeredAt && (
          <>
            <dt>
              {t("Answered")} {DateTimeLuxonHelpers.convertUtcToDateTime(item.answeredAt)}
            </dt>
            <dd>
              <FormattedUserInput content={item.reviewAnswer} />
            </dd>
          </>
        )}
      </dl>
    </ListGroup.Item>
  );
}
export default ReviewListGroupItem;
