import { Button, Card, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";

interface IProps {
  onChange: (value: PrintOptions) => void;
  availableOptions: (keyof PrintOptions)[];
  initialOptions: PrintOptions;
}

export class PrintOptions {
  constructor() {
    this.showComments = true;
    this.showQrCode = true;
    this.showResponsibleSignature = true;
    this.showJobholderSignature = true;
    this.includeImages = true;
    this.showAttachments = true;
    this.showCustomFields = true;
    this.showCosts = true;
    this.decisionOfAcceptance = true;
    this.decisionOnRealization = true;
    this.implementation = true;
    this.showQualifications = false;
    this.showReviews = false;
    this.showOnlyCompletedSections = false;
    this.showChangeLog = false;
    this.showDefects = false;
    this.showProducts = false;
    this.showTodos = false;
    this.showClaims = false;
    this.showReviewers = false;
  }
  showQrCode: boolean;
  showComments: boolean;
  showResponsibleSignature: boolean;
  showJobholderSignature: boolean;
  includeImages: boolean;
  showAttachments: boolean;
  showCustomFields: boolean;
  showCosts: boolean;
  decisionOfAcceptance: boolean;
  decisionOnRealization: boolean;
  implementation: boolean;
  showQualifications: boolean;
  showReviews: boolean;
  showOnlyCompletedSections: boolean;
  showChangeLog: boolean;
  showDefects: boolean;
  showProducts: boolean;
  showTodos: boolean;
  showClaims: boolean;
  showReviewers: boolean;
}

function PrintoutIncludeOptions(props: IProps) {
  const { onChange, availableOptions, initialOptions } = props;
  const { t } = useTranslation();
  const handlePrint = () => window.print();

  const handleCheckOption = (item: keyof PrintOptions, value: boolean) => {
    const copy = { ...initialOptions, [item]: value };
    onChange(copy);
  };

  return (
    <Container className="d-print-none">
      <Card className="mb-3">
        <Card.Header>
          <Card.Title>{t("Customize printout")}</Card.Title>
        </Card.Header>
        <Card.Body className="d-flex justify-content-center">
          <div>
            <Form.Group className="mb-3">
              <Form.Label>{t("Information to be displayed in the printout")}</Form.Label>
              <div>
                {availableOptions.map((f, i) => (
                  <Form.Check
                    key={i}
                    id={f}
                    label={t(f)}
                    type="checkbox"
                    checked={initialOptions[f]}
                    onChange={(e) => handleCheckOption(f, e.target.checked)}
                  />
                ))}
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Button onClick={handlePrint}>
                <qmBaseIcons.Print /> {t("Print")}
              </Button>
            </Form.Group>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default PrintoutIncludeOptions;
