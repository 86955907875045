import React from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";

import { useTagsHook } from "@/hooks/useTagsHook";
import { ReactSelectDTO } from "../../../../../Models/ReactSelect/reactSelectDTO";
import { LoadingComponent } from "../../loading/loadingComponent";

interface IProps {
  onChange: (id?: string | string[]) => void;
  selectedIds?: string[];
  isMulti?: boolean;
  isClearable?: boolean;
}

const TagSelect: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { onChange, selectedIds, isClearable, isMulti } = props;

  const { data } = useTagsHook();

  const mapped = React.useMemo(() => {
    if (!data) return undefined;
    const mappedOptions = data.map((x) => {
      return { label: x.title, value: x.id.toString() };
    });
    const defaultOptions = mappedOptions.filter((x) => selectedIds?.includes(x.label));
    return { options: mappedOptions, defaultOptions };
  }, [data, selectedIds]);

  const handleChange = (newValue: SingleValue<ReactSelectDTO> | MultiValue<ReactSelectDTO>) => {
    if (newValue == null) {
      onChange(undefined);
    } else if (Array.isArray(newValue)) {
      onChange(newValue.map((x) => x.label));
    } else {
      const option = newValue as ReactSelectDTO;
      onChange(option.value);
    }
  };

  return (
    <React.Fragment>
      {!mapped?.options ? (
        <LoadingComponent />
      ) : (
        <Select
          key={mapped.defaultOptions ? mapped.defaultOptions.map((x) => x.value).join() : "tagSelect"}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPosition="fixed"
          options={mapped.options}
          defaultValue={mapped.defaultOptions}
          isSearchable
          isClearable={isClearable}
          onChange={handleChange}
          placeholder={`${t("Search")}...`}
          name="tagId"
          isMulti={isMulti}
        />
      )}
    </React.Fragment>
  );
};

export default TagSelect;
