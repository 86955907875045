import { Container } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { useToggle } from "@/hooks/useToggle";
import NavbarComponent from "../../ClientApp/shared/components/navbar/navbarComponent";
import { ErrorFallback } from "./errors/errorFallback";

function LayoutWide() {
  const { t } = useTranslation();
  const [sidebarToggled, toggleSidebar] = useToggle();
  return (
    <>
      <NavbarComponent toggleSidebar={toggleSidebar} sidebarToggled={sidebarToggled} />
      <Container fluid className="p-4" id="mainQmBaseContainer">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Outlet />
        </ErrorBoundary>
      </Container>
    </>
  );
}
export default LayoutWide;
