import { useLocation } from "react-router-dom";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import AppService from "../../../../pbdServices/services/Apps/appService";
import SidebarAbsencePlanner from "../../../absencePlanner/components/sidebarAbsencePlanner";
import SidebarAdmin from "../../../admin/components/sidebarAdmin";
import ArticleSidebar from "../../../articles/components/articleSidebar";
import SidebarAudits from "../../../audits/components/sidebarAudits";
import SidebarClaims from "../../../claims/components/sidebarClaims";
import SidebarCrm from "../../../crm/components/sidebarCrm";
import SidebarDefect from "../../../defects/components/sidebarDefect";
import { SidebarDev } from "../../../dev/components/sidebarDev";
import SidebarEmployees from "../../../employees/components/sidebarEmployees";
import SidebarForms from "../../../forms/components/sidebarForms";
import SidebarGoals from "../../../goals/components/sidebarGoals";
import SidebarIdeaManagement from "../../../ideaManagement/components/sidebarIdeaManagement";
import SidebarMaintenanceManagement from "../../../maintenanceManagement/components/sidebarMaintenanceManagement";
import SidebarOpportunities from "../../../opportunities/components/sidebarOpportunities";
import SidebarProducts from "../../../products/components/sidebarProducts";
import SidebarProfile from "../../../profile/components/sidebarProfile";
import SidebarQualificationMatrix from "../../../qualificationMatrix/components/sidebarQualificationMatrix";
import SidebarQualityMonitor from "../../../qualityMonitor/components/sidebarQualityMonitor";
import SidebarSettings from "../../../settings/components/sidebarSettings";
import SidebarStartpage from "../../../startpage/components/sidebarStartpage";
import SidebarStore from "../../../store/components/sidebarStore";
import SidebarTaskManagement from "../../../taskManagement/components/sidebarTaskManagement";
import SidebarTrainings from "../../../trainings/components/sidebarTrainings";

function SidebarComponent() {
  const location = useLocation();
  const app = AppService.getAppByFirstSegmentInUrl(location.pathname);
  switch (app) {
    case PbdModule.Profile:
      return <SidebarProfile />;
    case PbdModule.Products:
      return <SidebarProducts />;
    case PbdModule.QualityMonitor:
      return <SidebarQualityMonitor />;
    case PbdModule.GoalManagement:
      return <SidebarGoals />;
    case PbdModule.TrainingManagement:
      return <SidebarTrainings />;
    case PbdModule.IdeaManagement:
      return <SidebarIdeaManagement />;
    case PbdModule.ClaimManagement:
      return <SidebarClaims />;
    case PbdModule.Store:
      return <SidebarStore />;
    case PbdModule.QualificationMatrix:
      return <SidebarQualificationMatrix />;
    case PbdModule.ProjectAndTaskManagement:
      return <SidebarTaskManagement />;
    case PbdModule.Crm:
      return <SidebarCrm />;
    case PbdModule.DefectManagement:
      return <SidebarDefect />;
    case PbdModule.AuditManagement:
      return <SidebarAudits />;
    case PbdModule.AbsencePlanner:
      return <SidebarAbsencePlanner />;
    case PbdModule.OpportunityManagement:
      return <SidebarOpportunities />;
    case PbdModule.CustomForms:
      return <SidebarForms />;
    case PbdModule.MaintenanceManagement:
      return <SidebarMaintenanceManagement />;
    case PbdModule.Employees:
      return <SidebarEmployees />;
    case PbdModule.Blog:
    case PbdModule.KnowledgeBase:
    case PbdModule.DocumentManagement:
      return <ArticleSidebar />;
    case PbdModule.Settings:
      return <SidebarSettings />;
    case PbdModule.Admin:
      return <SidebarAdmin />;
    case PbdModule.Dev:
      return <SidebarDev />;
    default:
      return <SidebarStartpage />;
  }
}
export default SidebarComponent;
