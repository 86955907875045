export class LocalStorageKeys {
  static AvailableModules = "AvailableModules";
  static GlobalQueryFilter_FunctionsAndQualifications = "GlobalQueryFilter_FunctionsAndQualifications";
  static Faqs = "Faqs";
  static FaqsShowButton = "FaqsShowButton";
  static Issues = "Issues";
  static ErrorForSubmit = "ErrorForSubmit";
  static TourStatus = "TourStatus";
  static StartPageFavorites = "StartPageFavorites";
}

// export type LocalStorageKeys =
//   | "AvailableModules"
//   | "GlobalQueryFilter_FunctionsAndQualifications"
//   | "Faqs"
//   | "FaqsShowButton"
//   | "Issues"
//   | "ErrorForSubmit";
