import { ColumnDef, RowData } from "@tanstack/react-table";

import { idOfSelectionColumn } from "../reactstrapTable";
import { IndeterminateCheckbox } from "./intermediate-checkbox";

export function getSelectionColumn<T extends RowData>(key: string): ColumnDef<T> {
  return {
    id: idOfSelectionColumn,
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          id: `${key}_selectAll`,
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  };
}
