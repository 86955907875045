/* eslint-disable @typescript-eslint/naming-convention */
import { TFunction } from "i18next";
import _ from "lodash";
import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "../../../../Models/Errors/ErrorMessage";
import { ApiErrorMap } from "../../../../pbdServices/services/Api/api-error-map";
import { ApiError } from "../../../../pbdServices/services/Api/models/api-error";
import { BaseModalProps } from "./baseModalProps";
import ErrorMapperDiv from "./errorMapperDiv";

export type QmErrorMessage = LegacyErrorMessage | UnknownErrorMessage | ApiErrorMessage;

export class LegacyErrorMessage {
  kind: "legacy";
  errorMessage: ErrorMessage;

  constructor(err: ErrorMessage) {
    this.kind = "legacy";
    this.errorMessage = err;
  }
}

export class ApiErrorMessage {
  kind: "api";
  apiError: ApiError;

  constructor(apiError: ApiError) {
    this.kind = "api";
    this.apiError = apiError;
  }
}

export class UnknownErrorMessage {
  kind: "unknown";
  error: unknown;

  constructor(err: unknown) {
    this.kind = "unknown";
    this.error = err;
  }
}

interface IErrorMapProps {
  errors: ApiErrorMap;
}

function ApiErrorMapView(props: IErrorMapProps) {
  const { errors } = props;
  const { t } = useTranslation();

  const allErrors = React.useMemo(() => errors.getAllErrors(), [errors]);
  return (
    <div>
      <h5>{t("Further details")}</h5>
      <dl>
        {allErrors.map(([key, errors]) => (
          <React.Fragment key={key}>
            {key && <dt>{key}</dt>}
            <dd>
              {errors.map((d, index) => (
                <div key={index}>{d}</div>
              ))}
            </dd>
          </React.Fragment>
        ))}
      </dl>
    </div>
  );
}

function LegacyErrorView(t: TFunction, errMsg: LegacyErrorMessage) {
  const error = errMsg.errorMessage;
  return (
    <>
      <h6>
        {t("Code")} - {error.code}
      </h6>
      <h5>{t(error.message)}</h5>
      {error.innerMessage && <div>{t(error.innerMessage)}</div>}
      {error.errorToMap && error.code != 500 && <ErrorMapperDiv errorContent={error.errorToMap} />}
      {!error.innerMessage && !error.errorToMap && <p>{t("Sorry, we do not have any further details to share")}</p>}
    </>
  );
}

function ApiErrorView(t: TFunction, errMsg: ApiErrorMessage) {
  const error = errMsg.apiError;
  if (error.kind === "UnknownOrWrongDefinition") return UnknownErrorView(t, error);
  return (
    <>
      <h4>
        {error.status} - {t(error.name)}
      </h4>
      <p>{error.displayMessage}</p>

      {error.kind === "BadRequest" && <ApiErrorMapView errors={error.validationErrors} />}
      {!error.message && error.kind !== "BadRequest" && (
        <p>{t("Sorry, we do not have any further details to share")}</p>
      )}
    </>
  );
}

function UnknownErrorView(t: TFunction, error: unknown) {
  const name = _.isError(error) ? error.name : undefined;
  const message = _.isError(error) ? error.message : undefined;
  return (
    <>
      <h5>{t(name ?? "")}</h5>
      <p>{message ?? t("Sorry, we do not have any further details to share")}</p>
    </>
  );
}

interface IProps extends BaseModalProps {
  error?: QmErrorMessage;
}

function ErrorModal(props: IProps) {
  const { toggle, modal, error } = props;
  const { t } = useTranslation();
  return (
    <>
      <Modal show={modal} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Error")}</Modal.Title>
        </Modal.Header>
        {error && (
          <Modal.Body className="text-center">
            {error.kind == "legacy" && LegacyErrorView(t, error)}
            {error.kind === "api" ? ApiErrorView(t, error) : UnknownErrorView(t, error)}
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}

export default ErrorModal;
