import React from "react";
import useSWR from "swr";

import {
  ICustomField,
  IDefectDTO,
  IEightDReportEditDTO,
  IFileDTO,
  IPurchaseOrderDTO,
  ISalesOrderDTO,
  IToDoDTO,
  PbdModule,
  TenantsQueryField,
} from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../services/serviceContext";

import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { EightDReportStepData } from "../../../Models/EightDReports/EightDReportStep";
import { PrintOptions } from "../../prints/components/printoutIncludeOptions";
import { getContributors } from "../helpers/helperFunctions";
import ActionCard from "./actionCard";
import ClaimCard from "./claimCard";
import CongratulationsCard from "./congratulationsCard";
import TeamCard from "./teamCard";

interface IProps {
  report: IEightDReportEditDTO;
  refreshParent: () => void;
  customFields: ICustomField[];
  customFieldsTodo: ICustomField[];
  printOptions?: PrintOptions;
  connectedTodos: IToDoDTO[] | undefined;
  connectedDefects: IDefectDTO[] | undefined;
  salesOrders: ISalesOrderDTO[] | undefined;
  purchaseOrders: IPurchaseOrderDTO[] | undefined;
  files: IFileDTO[] | undefined;
}

function EightDReportDetailsCard(props: IProps) {
  const {
    report,
    refreshParent,
    customFields,
    customFieldsTodo,
    printOptions,
    connectedTodos,
    connectedDefects,
    salesOrders,
    purchaseOrders,
    files,
  } = props;

  const { tenantsApi } = useAPIs();
  const { data } = useSWR("/api/tenants", () =>
    tenantsApi.getAllQuery({
      id: report.teamMembers?.map((x) => x.tenantId),
      fields: [TenantsQueryField.DepartmentPositions],
      pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
    }),
  );

  const contributors = React.useMemo(() => {
    if (connectedTodos && connectedDefects && data) {
      const contributors = getContributors(report, connectedTodos, connectedDefects, data.data);
      return contributors;
    } else {
      return undefined;
    }
  }, [connectedDefects, connectedTodos, report, data]);

  return (
    <>
      {contributors && (
        <TeamCard
          itemToUpdate={report}
          refreshParent={refreshParent}
          printOptions={printOptions}
          step={EightDReportStepData.Team}
          contributors={contributors}
        />
      )}
      <ClaimCard
        itemToUpdate={report}
        refreshParent={refreshParent}
        claimPurchaseOrders={purchaseOrders}
        claimSalesOrders={salesOrders}
        customFieldsForModule={customFields}
        files={files}
        printOptions={printOptions}
        step={EightDReportStepData.Problem}
      />

      {Object.values(EightDReportStepData)
        .filter((x) => x.app == PbdModule.ToDoManagement || x.app == PbdModule.DefectManagement)
        .map((x) => (
          <ActionCard
            key={x.step}
            step={x}
            itemToUpdate={report}
            refreshParent={refreshParent}
            connectedTodos={connectedTodos}
            connectedDefects={connectedDefects}
            printOptions={printOptions}
            customFieldsTodo={customFieldsTodo}
          />
        ))}
      <CongratulationsCard
        itemToUpdate={report}
        step={EightDReportStepData.Congratulations}
        refreshParent={refreshParent}
        printOptions={printOptions}
      />
    </>
  );
}
export default EightDReportDetailsCard;
