import { useTranslation } from "react-i18next";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { useFeatureFlagHook } from "@/hooks/useFeatureFlagHook";
import { CostRoutePaths } from "../../costs/costRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { TaskManagementRoutePaths } from "../taskManagementRoutePaths";

/**
 * TaskManagement Sidebar
 */
function SidebarTaskManagement() {
  const { data } = useFeatureFlagHook();
  const { t } = useTranslation();
  const links: SidebarItem[] = [
    SidebarItem.created(
      data?.isLegacyProjectsAvailable
        ? TaskManagementRoutePaths.SelectPage
        : TaskManagementRoutePaths.ChooseToDoTemplate,
      t,
    ),
  ];
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.ListSolid,
      title: t("Tasks"),
      href: TaskManagementRoutePaths.IndexTodoPage + "/?status=Open&status=InProgress",
    }),
  );
  if (data?.isLegacyProjectsAvailable) {
    links.push(
      new SidebarItem({
        icon: qmBaseIcons.ListSolid,
        title: t("Projects"),
        href: TaskManagementRoutePaths.IndexProjectsPage + "/?status=Open&status=InProgress",
      }),
    );
  }
  links.push(
    SidebarItem.costs(
      `${CostRoutePaths.IndexPageCostsByModule.replace(
        ":pbdModule",
        TaskManagementRoutePaths.HomePage.replace("/", ""),
      )}/?keyName=ToDo`,
      t,
    ),
  );
  return <SidebarNew app={PbdModule.ProjectAndTaskManagement} items={links} showSmartViews />;
}

export default SidebarTaskManagement;
