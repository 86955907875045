import { countBy, map } from "lodash";

import { BaseIdDTO } from "../Models/BaseClasses/BaseIdDTO";

import { filterMap } from "./filterMap";

type Item<T = any> = Record<string, T>;

type ValueGetter<T = unknown> = (item: T) => string | number;
type ValueGetterString<T = unknown> = (item: T) => string;
type SortingOrder = "ascending" | "descending";

export class ListHelpers {
  static sortBy<T extends Item>(array: T[], key: ValueGetter<T>, order: SortingOrder = "ascending") {
    if (!array) return array;
    if (order === "ascending") {
      return [...array].sort((a, b) => (key(a) > key(b) ? 1 : -1));
    }
    return [...array].sort((a, b) => (key(a) > key(b) ? -1 : 1));
  }

  /**This is for local compare
   * 1. First
   * 10. Tenth
   * 3. Third
   * will be => 1. First, 3. Third, 10. Tenth
   * This seems to be impossible with lodash.
   */
  static sortByString<T>(
    array: T[] | null | undefined,
    key: ValueGetterString<T>,
    order: SortingOrder = "ascending",
  ): T[] {
    if (!array) return [];
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
    if (order == "ascending") {
      return [...array].sort((a, b) => collator.compare(key(a), key(b)));
    }
    return [...array].sort((a, b) => collator.compare(key(b), key(a)));
  }

  /**
   * Return the unique values found in the passed iterable
   */
  static uniq<TElement extends string | number | bigint | boolean | symbol>(
    iterableToGetUniqueValuesOf: Iterable<TElement>,
  ) {
    return [...new Set(iterableToGetUniqueValuesOf)];
  }

  static getDuplicatedExternalIds(items: IHaveExternalId[]) {
    const sortedArray = filterMap(items, (x) => x.externalId).sort();
    const filterEmptyValues = sortedArray.filter((x) => x != "");
    const results: string[] = [];
    for (let i = 0; i < filterEmptyValues.length - 1; i++) {
      if (filterEmptyValues[i + 1] == filterEmptyValues[i]) {
        results.push(filterEmptyValues[i]);
      }
    }
    return results;
  }

  static groupAndSortStringArray(items: string[]): StringCount[] {
    const grouped = countBy(items, (x) => x);
    const data = map(grouped, function (value, key) {
      const obj: StringCount = { key, count: value };
      return obj;
    });
    return data.sort((a, b) => b.count - a.count);
  }

  /**Returns ascending list of entity counts */
  static groupAndSortArrayById<T extends BaseIdDTO>(items: T[]): EntityCount<T>[] {
    const grouped = countBy(items, (x) => x.id);
    const data = map(grouped, function (value, key) {
      const keyAsNumber = Number(key);
      const entity = items.find((x) => x.id == keyAsNumber)!;
      const obj: EntityCount<T> = { key: keyAsNumber, count: value, entity };
      return obj;
    });
    return data.sort((a, b) => b.count - a.count);
  }

  static sumArrayLength(items: (unknown[] | undefined)[]): number {
    return items.reduce((pv, cv) => pv + (cv ? cv.length : 0), 0);
  }

  static sortListWithPrimary<T extends { isPrimary: boolean }[]>(items: T) {
    return items.sort((a, b) => Number(b.isPrimary) - Number(a.isPrimary));
  }

  static toggleListElement<T>(array: T[] | undefined, key: ValueGetterString<T>, itemToToggle: T): T[] {
    if (!array) return [itemToToggle];
    const exists = array.find((x) => key(x) == key(itemToToggle));
    if (exists) {
      return array.filter((x) => key(x) != key(itemToToggle));
    }
    array.push(itemToToggle);
    return array;
  }

  static getListWithEntries<T>(selected: T[], all: T[]) {
    if (selected.length > 0) return selected;

    return all;
  }

  static getUniqueElements<T extends { id: number }>(items: T[]) {
    const results: T[] = [];
    items.forEach((x) => {
      if (!results.find((y) => y.id == x.id)) {
        results.push(x);
      }
    });
    return results;
  }
}

export interface EntityCount<T extends BaseIdDTO> {
  key: number;
  count: number;
  entity: T;
}

interface StringCount {
  key: string;
  count: number;
}

interface IHaveExternalId {
  externalId?: string;
}
