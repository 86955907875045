import React from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ColumnInstance, IdType, TableToggleHideAllColumnProps } from "react-table";

import { qmBaseIcons } from "../../icons/qmBaseIcons";
import { TableAction, TableClickCommand } from "../TableClickCommand";
import { idOfSelectionColumn } from "../reactstrapTable";
import AdvancedTableButtons from "./advancedTableButtons";

interface IProps<T extends object> {
  showAdvancedTableOptions: boolean;
  availableTableActions?: readonly TableAction[];
  numSelected: number;
  defaultHidden: IdType<T>[];
  onClick?: (action: TableClickCommand) => void;
  saveHiddenColumnsState: (hiddenColumns: IdType<T>[]) => void;
  allColumns: ColumnInstance<T>[];
  getToggleHideAllColumnsProps: (
    props?: Partial<TableToggleHideAllColumnProps> | undefined,
  ) => TableToggleHideAllColumnProps;
  tableKey: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention, max-lines-per-function
export function AdvancedTableOptionsRow<T extends object>({
  showAdvancedTableOptions,
  availableTableActions = [],
  numSelected,
  defaultHidden,
  onClick,
  saveHiddenColumnsState,
  allColumns,
  getToggleHideAllColumnsProps,
  tableKey,
}: IProps<T>) {
  const { t } = useTranslation();
  const [columnCustomization, setColumnCustomization] = React.useState(false);
  const toggleColumnCustomization = () => setColumnCustomization(!columnCustomization);

  const resetLocalStorageStateOnClick = () => saveHiddenColumnsState(defaultHidden);

  const { isResetAvailable, noVisibleColumns } = React.useMemo(() => {
    const excludeFilter = allColumns.filter((x) => !x.isVisible && x.id != idOfSelectionColumn);
    const columnsForReset = defaultHidden.filter((x) => !excludeFilter.map((y) => y.id).includes(x));
    const noVisibleColumns = excludeFilter.length == allColumns.length;
    return { noVisibleColumns, isResetAvailable: columnsForReset.length > 0 };
  }, [allColumns, defaultHidden]);

  return (
    <React.Fragment>
      {showAdvancedTableOptions && (
        <div className="d-flex justify-content-between align-items-end mb-3 p-2 bg-light shadow-sm">
          <div>
            <AdvancedTableButtons
              onClick={onClick}
              availableTableActions={availableTableActions}
              numSelected={numSelected}
            />
            {numSelected > 0 && (
              <small>
                <strong>{numSelected}</strong> {t("Selected")}
              </small>
            )}
          </div>
          <div>
            <div className="d-flex justify-content-end">
              <h6>{t("Advance table options")}</h6>
            </div>

            <div className="d-flex justify-content-end">
              <Button
                variant="outline-secondary"
                title={t("Toggle column visibility")}
                onClick={toggleColumnCustomization}
                className="me-2"
                size="sm"
                active={columnCustomization}
              >
                <qmBaseIcons.Column />
              </Button>
              {isResetAvailable && (
                <Button
                  color="link"
                  title={t("Reset to default")}
                  onClick={resetLocalStorageStateOnClick}
                  className="me-2"
                  size="sm"
                >
                  <qmBaseIcons.Undo />
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      {columnCustomization && (
        <Form.Group controlId="columnVisibilityToggles">
          <Form.Label>{t("Visible columns")}</Form.Label>
          <div>
            <Form.Check
              type="checkbox"
              id={`${tableKey}_allColumns`}
              label={t("All")}
              inline
              {...getToggleHideAllColumnsProps()}
            />
            {allColumns
              .filter((x) => x.id != idOfSelectionColumn)
              .map((column) => {
                const columnKey = column.id;
                if (!columnKey) throw Error("Missing column key");
                return (
                  <Form.Check
                    id={`${tableKey}_${columnKey}`}
                    key={column.id}
                    inline
                    label={column.Header}
                    name={columnKey}
                    type="checkbox"
                    {...column.getToggleHiddenProps()}
                  />
                );
              })}
          </div>
        </Form.Group>
      )}
      {noVisibleColumns && (
        <Alert color="info text-center">
          <p>{t("All columns have been hidden")}</p>
          <Button onClick={resetLocalStorageStateOnClick}>
            <qmBaseIcons.Undo /> {t("Reset to default")}
          </Button>
        </Alert>
      )}
    </React.Fragment>
  );
}
export default AdvancedTableOptionsRow;
