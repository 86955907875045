import { DeepKeys } from "@tanstack/react-table";
import Fuse, { IFuseOptions } from "fuse.js";
import { useMemo, useState } from "react";

export interface IFuzzyClient<T> {
  keyword: string;
  result: T[];
  resetSearch: () => void;
  search: (keyword: string) => void;
}

export function useFuzzy<T>(data: T[], options: IFuseOptions<T> & { keys: DeepKeys<T>[] }): IFuzzyClient<T> {
  const [keyword, setKeyword] = useState("");
  const resetSearch = () => setKeyword("");

  const searcher = useMemo(() => {
    const defaultOptions: IFuseOptions<T> = {
      /**Seems to be a good tradeoff between fuzzy and exact search. Tested on sample data 50 names complete name one hit. */
      threshold: 0.4,
    };
    return new Fuse(data, { ...defaultOptions, ...options });
  }, [data, options]);
  const result = keyword ? searcher.search<T>(keyword).map((x) => x.item) : data;

  return {
    keyword,
    resetSearch,
    result,
    search: setKeyword,
  };
}

export const useSearch = useFuzzy;
