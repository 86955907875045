import useSWRImmutable from "swr/immutable";

import { paths } from "@/config/paths";

import { useAPIs } from "../services/serviceContext";
import { ErrorKeys } from "../services/errors/error-keys";

export function useSubscriptionsHook() {
  const { appSettingsApi } = useAPIs();
  const { data, isValidating, error } = useSWRImmutable("/api/appSettings/subscriptions", () =>
    appSettingsApi.getAllSubscriptions(),
  );

  if (error) {
    window.location.replace(paths.error.getHref(ErrorKeys.SubscriptionNotSynced));
  }

  return {
    data,
    loading: !data && isValidating,
  };
}
