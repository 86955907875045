export class BaseTableEntityProps {
  constructor(id: string, title: string, baseUrl: string) {
    this.id = id;
    this.title = title;
    this.linkTo = baseUrl.replace(":id", id);
  }
  id: string;
  title: string;
  /**React link */
  linkTo: string;
}
