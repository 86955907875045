import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { QualificationMatrixRoutePaths } from "./qualificationMatrixRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.QualificationMatrix_ModuleAdmin, PbdRoles.QualificationMatrix_Standard],
};

export const qualificationMatrixLazyRoutes: RouteObject[] = [
  {
    path: QualificationMatrixRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageQualificationMatrix")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.PerfTestPage,
    Component: lazy(() => import("./pages/perfTestPage")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.IndexPageCompanyFunction,
    Component: lazy(() => import("../companyFunctions/pages/indexPageCompanyFunction")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.CreatePageCompanyFunction,
    Component: lazy(() => import("../companyFunctions/pages/createPageCompanyFunction")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.DetailsPageCompanyFunction,
    Component: lazy(() => import("../companyFunctions/pages/detailsPageCompanyFunction")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.MatrixPageCompanyFunction,
    Component: lazy(() => import("../companyFunctions/pages/matrixPageCompanyFunction")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.IndexPageQualification,
    Component: lazy(() => import("../qualifications/pages/indexPageQualification")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.CreatePageQualification,
    Component: lazy(() => import("../qualifications/pages/createPageQualification")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.DetailsPageQualification,
    Component: lazy(() => import("../qualifications/pages/detailsPageQualification")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.MatrixPageQualification,
    Component: lazy(() => import("../qualifications/pages/matrixPageQualification")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.IndexPageJobDescriptions,
    Component: lazy(() => import("../jobDescriptions/pages/indexPageJobDescription")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.DetailsPageJobDescriptions,
    Component: lazy(() => import("../jobDescriptions/pages/detailsPageJobDescription")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.DetailsPageTenantJobDescription,
    Component: lazy(() => import("../jobDescriptions/pages/detailsPageTenantJobDescription")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.ConsolidateQualifications,
    Component: lazy(() => import("./pages/consolidateQualificationsPage")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.DepartmentPositionMatrix,
    Component: lazy(() => import("../departmentPositions/pages/matrixPageDepartmentPosition")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.DepartmentPositionCompanyFunctionMatrix,
    Component: lazy(
      () => import("../departmentPositionCompanyFunction/pages/matrixPageDepartmentPositionCompanyFunction"),
    ),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.DepartmentMatrix,
    Component: lazy(() => import("../departments/pages/matrixPageDepartments")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.CompanyFunctionQualificationMatrix,
    Component: lazy(() => import("../companyFunctionQualifications/pages/matrixPageCompanyFunctionQualification")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.IndexPageTenantQualificationsDone,
    Component: lazy(() => import("../qualificationsDone/pages/indexPageTenantQualificationDone")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.EditPageTenantQualificationDone,
    Component: lazy(() => import("../qualificationsDone/pages/editPageTenantQualificationDone")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.CreatePageTenantQualificationDone,
    Component: lazy(() => import("../qualificationsDone/pages/createPageTenantQualificationDone")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.DetailsPageQualificationRequirementsForTenant,
    Component: lazy(() => import("../qualificationRequirements/pages/detailsPageQualificationRequirementsForTenant")),
    ...defaultRouteParams,
  },
  {
    path: QualificationMatrixRoutePaths.IndexPageTenants,
    Component: lazy(() => import("./pages/indexPageTenantForQualificationVM")),
    ...defaultRouteParams,
  },
];
