/* eslint-disable @typescript-eslint/naming-convention */
import { FormikErrors, FormikProps } from "formik";
import i18next from "i18next";
import { isArray, isObject, isString, startCase } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { formikGlobalErrorKey } from "../../../../../pbdServices/services/Api/api-formik-submitter";
import GenericAlert from "../../alerts/genericAlert";

type FormikErrorType<Values> = FormikErrors<Values> | string | string[];

function mapSingleError<Values>(
  errorList: string[],
  key: string,
  error: FormikErrorType<Values>,
  depth: number,
  keysToExclude: (keyof Values | string)[] | undefined,
) {
  if (depth > 2) return;

  if (keysToExclude?.includes(key)) return;

  if (isString(error)) {
    errorList.push(key == formikGlobalErrorKey ? i18next.t(error) : `${i18next.t(startCase(key))}: ${error}`);
    return;
  }

  if (isArray(error)) {
    for (let i = 0; i < error.length; i++) {
      mapSingleError(errorList, `${key}#${i}`, error[i], depth + 1, keysToExclude);
    }
    return;
  }

  if (isObject(error)) {
    for (const [objKey, val] of Object.entries(error)) {
      const keyPrefix = key.length === 0 ? objKey : `${key}.${objKey}`;
      mapSingleError(errorList, keyPrefix, val as FormikErrorType<Values>, depth + 1, keysToExclude);
    }
  }
}

function errorMapper<Values>(errors: FormikErrors<Values>, keysToExclude: (keyof Values)[] | undefined): string[] {
  const errs: string[] = [];
  mapSingleError(errs, "", errors, 0, keysToExclude);
  return errs;
}

interface IProps<Values> {
  formikBag: Pick<FormikProps<Values>, "touched" | "errors">;
  /**In case you don't want to show some errors in the error summary this array can be used to exclude them.
   * Excluding them by default seems to be to strict. PP 2022-10-22
   */
  keysToExclude?: (keyof Values)[];
}

export function FormikValidationSummary<Values>(props: IProps<Values>) {
  const { formikBag, keysToExclude } = props;
  const { t } = useTranslation();

  const errorsList = React.useMemo(
    () => errorMapper(formikBag.errors, keysToExclude),
    [formikBag.errors, keysToExclude],
  );

  if (errorsList.length > 0 && Object.values(formikBag.touched).length > 0) {
    return (
      <GenericAlert type="danger" heading={t("Errors in this form")}>
        <p>{t("Please review the form fix the errors and try to submit it again.")}</p>
        <hr />
        <ul>
          {errorsList.map((x, i) => (
            <li key={i}>{x}</li>
          ))}
        </ul>
      </GenericAlert>
    );
  }
  return null;
}

export default FormikValidationSummary;
