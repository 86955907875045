/* eslint-disable @typescript-eslint/naming-convention */
import {
  ITenantAsAttendee,
  ITenantQualificationDoneDTO,
  ITenantQualificationRequirementDTO,
  ITrainingAttendeesControllerClient,
  PbdStatus,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { ITenantAsAttendeeVM } from "../../Models/Tenants/TenantAsAttendeeVM";
import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";

export default class TrainingAttendeeService extends BaseExportService<ITenantAsAttendeeVM> {
  private trainingAttendeeApi: ITrainingAttendeesControllerClient;
  constructor(trainingAttendeeApi: ITrainingAttendeesControllerClient) {
    super("Training attendees");
    this.trainingAttendeeApi = trainingAttendeeApi;
  }

  async getAttendanceWithActionRequired(tenantId: number) {
    const resp = await this.trainingAttendeeApi.getAllQuery({ attendeeId: [tenantId] });
    return {
      acceptanceRequired: resp.filter(
        (x) => x.attendanceInfo.training?.status != PbdStatus.Completed && !x.attendanceInfo.acceptedAt,
      ),
      attendanceRequired: resp.filter(
        (x) => x.attendanceInfo.training?.status == PbdStatus.Completed && !x.attendanceInfo.attendanceConfirmedAt,
      ),
      ratingRequired: resp.filter(
        (x) =>
          x.attendanceInfo.training?.status == PbdStatus.Completed &&
          !x.attendanceInfo.ratedAt &&
          x.attendanceInfo.attendedEvent != false &&
          x.attendanceInfo.training.rateableByAttendee,
      ),
      activeTraining: resp.filter((x) => x.attendanceInfo.training?.status != PbdStatus.Completed),
    };
  }

  mapToExport(x: ITenantAsAttendeeVM): ExportType {
    return {
      attendee_id: x.id,
      attendee_fullName: x.fullName,
      attendee_status: x.attendanceInfo.status,
      attendee_transferredQualifications: TrainingAttendeeService._mapTransferredQualifications(x),
      training_id: x.attendanceInfo.training?.id,
      training_title: x.attendanceInfo.training?.title,
      training_plannedAt: x.attendanceInfo.training?.plannedAt,
      training_plannedAtEnd: x.attendanceInfo.training?.plannedAtEnd,
    };
  }

  mapToVM(
    array: ITenantAsAttendee[],
    qualificationsDone: ITenantQualificationDoneDTO[],
    requirements?: ITenantQualificationRequirementDTO[],
  ): ITenantAsAttendeeVM[] {
    return array.map((t) => {
      const qualificationsTransferred = qualificationsDone.filter(
        (x) => x.tenantId == t.id && x.trainingId == t.attendanceInfo.training?.id,
      );

      return {
        ...t,
        qualificationsTransferred,
        qualificationRequirements: requirements?.filter((x) => x.tenantId == t.id),
      };
    });
  }

  private static _mapTransferredQualifications(item: ITenantAsAttendeeVM) {
    const qualifications = item.qualificationsTransferred?.map((x) => {
      return `#${x.qualificationId} ${
        item.attendanceInfo.training?.qualifications?.find((t) => t.id == x.qualificationId)?.title
      }`;
    });
    return qualifications?.join();
  }

  static get availableFilter() {
    return [SearchFilterTypes.Status, SearchFilterTypes.Attendees];
  }
}
