import "bpmn-js-color-picker/colors/color-picker.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "bpmn-js/dist/assets/bpmn-js.css";
import "bpmn-js/dist/assets/diagram-js.css";
import React, { useState } from "react";
import { Card } from "react-bootstrap";

import GenericAlert from "../../shared/components/alerts/genericAlert";

import { CustomBpmnEvent } from "./bpmnModels";

const CustomBpmnEditorInner = React.lazy(() => import("./customBpmnEditorInner"));

interface IProps {
  diagramXML?: string;
  legends?: Record<string, string>;
  onChange: (xml: string) => void;
  onClick: (event?: CustomBpmnEvent) => void;
}

export function CustomBpmnEditor(props: IProps) {
  const [error, setError] = useState<unknown>();

  return (
    <Card className="mb-3">
      <Card.Body>
        {error != undefined && <GenericAlert type="danger">{JSON.stringify(error)}</GenericAlert>}
        <CustomBpmnEditorInner {...props} onError={(x) => setError(x)} />
      </Card.Body>
    </Card>
  );
}
