import { Edge, MarkerType, Node } from "reactflow";

import { IFlow } from "./pbd-flow-dto";

export const defaultNodeStyle: React.CSSProperties = { backgroundColor: "white" };

export const defaultEdgeMarkerEnd = {
  type: MarkerType.ArrowClosed,
  width: 20,
  height: 20,
};

const edges: Edge[] = [
  {
    id: "el-2",
    source: "start",
    target: "process",
    markerEnd: defaultEdgeMarkerEnd,
  },
  {
    id: "el-3",
    source: "process",
    target: "end",
    label: "The end is near",
    markerEnd: defaultEdgeMarkerEnd,
  },
];

const nodes: Node[] = [
  {
    width: 150,
    height: 40,
    id: "start",
    data: {
      label: "Start",
    },
    position: {
      x: 217,
      y: 80,
    },
    type: "customInput",
    style: defaultNodeStyle,
  },
  {
    width: 150,
    height: 40,
    id: "process",
    data: {
      label: "Process",
    },
    position: {
      x: 219,
      y: 200,
    },
    type: "customProcess",
    style: defaultNodeStyle,
  },
  {
    width: 150,
    height: 40,
    id: "end",
    data: {
      label: "End",
    },
    position: {
      x: 223,
      y: 315,
    },
    type: "customOutput",
    style: defaultNodeStyle,
  },
];

export const defaultFlowData: IFlow = {
  nodes,
  edges,
};
