import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label } from "reactstrap";
import * as yup from "yup";

import { ITenantMinDTO, TenantsQueryField } from "@/generatedCode/pbd-core/pbd-core-api";
import { nameofFactory } from "@/utils/nameofFactory";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import FormikDebugInfo from "../forms/formik/formikDebugInfo";
import { FormikHtmlInputGroup } from "../forms/formik/formikHtmlInput";
import { FormikTextInputGroup } from "../forms/formik/formikTextInput";
import FormikValidationSummary from "../forms/formik/formikValidationSummary";
import TenantSelectAsync from "../inputControl/select/tenantSelectAsync";

// //@ts-expect-error TODO: Fix with better typings Not needed in non strict mode
const tenantSchema: yup.ObjectSchema<ITenantMinDTO> = yup.object({
  id: yup.number().required(),
  externalId: yup.string().notRequired(),
  fullName: yup.string().required(),
  initial: yup.string(),
  profilePictureId: yup.number().nullable(),
});

//@ts-expect-error TODO: Fix with better typings
const ValidationSchema: yup.ObjectSchema<SendNotificationForm> = yup.object({
  to: yup
    .string()
    .email()
    .when("toTenants", {
      is: (x: unknown) => x && Array.isArray(x),
      then: (s) => s.notRequired(),
      otherwise: (s) => s.required(),
    }),
  subject: yup.string().required().min(2).max(250),
  message: yup.string().required(),
  toTenants: yup.array(tenantSchema.required()),
});

export interface SendNotificationForm {
  subject: string;
  message: string;
  to?: string;
  toTenants?: ITenantMinDTO[];
}

interface IProps {
  selected?: ITenantMinDTO[];
  onSubmit: (values: SendNotificationForm) => Promise<unknown>;
  defaultValues?: SendNotificationForm;
  onSuccessfulSubmit: () => void;
}

const nameof = nameofFactory<SendNotificationForm>();

function NotificationForm(props: IProps) {
  const { defaultValues, onSuccessfulSubmit, onSubmit, selected } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<SendNotificationForm, unknown>(
    (val) => onSubmit(val),
    [onSubmit, onSuccessfulSubmit],
    onSuccessfulSubmit,
  );

  const initialValues: SendNotificationForm = {
    to: defaultValues?.to ?? "",
    subject: defaultValues?.subject ?? "",
    message: defaultValues?.message ?? "",
    toTenants: selected,
  };
  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
      {(formikBag) => (
        <Form>
          <FormikDebugInfo formikBag={formikBag} />
          {selected ? (
            <FormGroup>
              <Label for="toTenants">{t("Recipient")}</Label>
              <TenantSelectAsync
                selected={selected}
                isMulti
                onChange={(x) => formikBag.setFieldValue("toTenants", x)}
                query={{ isApplicationUser: true, fields: [TenantsQueryField.ApplicationUser] }}
              />
            </FormGroup>
          ) : (
            <FormikTextInputGroup name="to" label={t("Recipient")} />
          )}
          <FormikTextInputGroup name="subject" />
          <FormikHtmlInputGroup name={nameof("message")} />
          <FormGroup>
            <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
              {t("Submit")}
            </Button>
          </FormGroup>
          <FormikValidationSummary formikBag={formikBag} />
        </Form>
      )}
    </Formik>
  );
}

export default NotificationForm;
