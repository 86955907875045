import { Link } from "react-router-dom";

interface IProps {
  titleColumnBaseLink?: string;
  id: number | string;
  title: string;
  renderAsLink: boolean;
  /**React link */
  linkTo?: string;
}

function LinkRenderer(props: IProps) {
  const { titleColumnBaseLink, id, title, renderAsLink, linkTo } = props;

  if (renderAsLink) {
    if (linkTo) {
      return <Link to={linkTo}>{title}</Link>;
    } else if (titleColumnBaseLink) {
      return <Link to={titleColumnBaseLink.replace(":id", id.toString())}>{title}</Link>;
    } else {
      console.warn("Render as link but Missing titleColumnBaseLink");
      return <span>{title}</span>;
    }
  } else {
    return <span>{title}</span>;
  }
}
export default LinkRenderer;
