import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ICategoryDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { wrapApiCallWithToast } from "../../../../../pbdServices/services/Api/api-wrapper";
import CategoryTable from "../../../../categories/components/categoryTable";
import { SettingsRoutePaths } from "../../../../settings/settingsRoutePaths";
import { TableClickCommand } from "../../tables/TableClickCommand";
import { ConnectedCardProps } from "../connectedCardProps";
import ConnectedCardHeader from "../shared/connectedCardHeader";
import ConnectAuditTypesModal from "./connectAuditTypesModal";

type IProps = ConnectedCardProps<ICategoryDTO> & { children?: React.ReactNode };

function AuditTypesConnectedCard(props: IProps) {
  const { t } = useTranslation();
  const { refreshParent, data, baseDTO, cardTitle = t("Audit categories"), onDisconnectSubmit } = props;
  const confirm = useConfirmation();
  const [connectionModal, setConnectionModal] = React.useState(false);
  const [selected, setSelected] = React.useState<{ id: number }[]>([]);

  function toggleConnectModal() {
    setConnectionModal(!connectionModal);
  }

  const handleTableClick = async (command: TableClickCommand) => {
    const { action } = command;
    switch (action) {
      case "Delete":
        confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => x.id)));
        break;
      default:
        throw Error("Not implemented");
    }
  };

  const submitDeleteRequest = async (dto: number[]) => {
    if (!onDisconnectSubmit) throw Error("Missing disconnect");
    for (const i of dto) {
      const resp = await wrapApiCallWithToast(() => onDisconnectSubmit(i));
    }
    refreshParent?.();
  };

  return (
    <Card className="mb-3">
      <ConnectedCardHeader
        title={
          <span>
            <i className="icon-qmBase-ProjectAndTaskManagement" /> {cardTitle}
          </span>
        }
        toggleConnectModal={toggleConnectModal}
        data={data}
        canAdd={baseDTO.capabilities?.canEdit || baseDTO.capabilities?.canConnectTask}
      />
      {data && data.length > 0 && (
        <Card.Body>
          {props.children}
          <CategoryTable
            tableRows={data}
            columnsVisibleDefault={["title"]}
            titleColumnBaseLink={SettingsRoutePaths.EditPageAuditTypes}
            setSelected={setSelected}
            availableTableActions={["Delete"]}
            onClick={handleTableClick}
            onCreateClick={toggleConnectModal}
          />
        </Card.Body>
      )}
      {data && refreshParent && props.onConnectSubmit && (
        <ConnectAuditTypesModal
          modal={connectionModal}
          toggle={toggleConnectModal}
          connectedElements={data}
          onSubmit={props.onConnectSubmit}
          // Needs to be handled via logic
          canSubmit={true}
          refreshParent={refreshParent}
        />
      )}
    </Card>
  );
}

export default AuditTypesConnectedCard;
