import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { useJwtExternalHook } from "@/hooks/useJwtExternalHook";
import GenericAlert from "../shared/components/alerts/genericAlert";

function IssueLayout() {
  const { t } = useTranslation();
  const { isLoggedIn, errorMessage } = useJwtExternalHook();
  return (
    <>
      {isLoggedIn ? <Outlet /> : <h1>{t("Establishing connection to ticket system")}</h1>}
      {errorMessage && <GenericAlert type="warning">{errorMessage}</GenericAlert>}
    </>
  );
}
export default IssueLayout;
