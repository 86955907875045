import { IConfig, PendingSurveysClient, SurveysClient } from "./azureFunctionApi";

const configBase = new IConfig();

export class FunctionAppServiceContextData {
  surveysClient: SurveysClient;
  pendingSurveysClient: PendingSurveysClient;
  constructor(functionAppUrl: string) {
    this.surveysClient = new SurveysClient(configBase, functionAppUrl);
    this.pendingSurveysClient = new PendingSurveysClient(configBase, functionAppUrl);
  }
}
