import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { ProfileRoutePaths } from "./profileRoutePaths";

export const profileLazyRoutes: RouteObject[] = [
  {
    path: ProfileRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageProfile")),
  },
  {
    path: ProfileRoutePaths.MyProfile,
    Component: lazy(() => import("./pages/myProfilePage")),
  },
  {
    path: ProfileRoutePaths.LanguagePage,
    Component: lazy(() => import("./pages/languagePage")),
  },
  {
    path: ProfileRoutePaths.UserSettingsPage,
    Component: lazy(() => import("./pages/userSettingsIndexPage")),
  },
  {
    path: ProfileRoutePaths.Calendar,
    Component: lazy(() => import("./pages/calendarPage")),
  },
  {
    path: ProfileRoutePaths.Watches,
    Component: lazy(() => import("./pages/watchesPage")),
  },
  {
    path: ProfileRoutePaths.Notifications,
    Component: lazy(() => import("./pages/notificationsIndexPage")),
  },
  {
    path: ProfileRoutePaths.Security,
    Component: lazy(() => import("./pages/securityPage")),
  },
];
