import useSWR from "swr";

import { useAppContext } from "@/components/contexts/appContext";
import SettingsService from "../services/Settings/settingsService";

const settingsService = new SettingsService();

export function usePrintLogo() {
  const { productConfig } = useAppContext();
  const { data, isValidating } = useSWR(`/api/settings/printLogo`, () =>
    settingsService.getValueByKey("Global_PrintLogo"),
  );

  let printLogoUrl = data;
  if (!isValidating && !printLogoUrl) {
    printLogoUrl = productConfig.iconUrl;
  }

  return {
    printLogoUrl,
  };
}
