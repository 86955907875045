import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Handle, NodeProps, NodeResizer, Position } from "reactflow";

import { FlowChartHelpers } from "../../../../../utils/flowChartHelpers";
import { NodeOrEdgeData } from "../models/pbd-flow-utils";

function ParallelogramNode({ data, selected }: NodeProps<NodeOrEdgeData>) {
  const { t } = useTranslation();
  return (
    <>
      <NodeResizer isVisible={selected} keepAspectRatio />
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
      <Handle type="source" position={Position.Left} />
      <Handle type="source" position={Position.Right} />

      <div>
        <div style={FlowChartHelpers.getStylesForParallelogram(data)}>
          <div style={{ transform: "skew(-20deg)" }}>{data.label}</div>
        </div>
      </div>
    </>
  );
}
export default memo(ParallelogramNode);
