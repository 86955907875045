import { FocusEventHandler } from "react";
import useSWR from "swr";

import { useAPIs } from "../../../../../services/serviceContext";

import { LoadingComponent } from "../../loading/loadingComponent";
import CustomSelect from "./customSelect";

interface IProps {
  onChange: (id?: number | number[]) => void;
  selectedIds?: number[];
  isMulti?: boolean;
  isClearable?: boolean;
  invalid?: boolean;
  onBlur?: FocusEventHandler;
  inputId?: string;
}

function InventoryCategorySelect(props: IProps) {
  const { inputId = "inventoryCategoryId" } = props;
  const { inventoryCategoriesApi } = useAPIs();
  const { data } = useSWR("/api/inventoryCategories", () => inventoryCategoriesApi.getAll());

  if (!data) return <LoadingComponent />;

  return <CustomSelect data={data} name={inputId} {...props} />;
}

export default InventoryCategorySelect;
