import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import { createQmIcon } from "../../icons/icon-base";
import { TableAction, TableClickCommand } from "../TableClickCommand";
import { tableActionUiData } from "../tableClickCommandData";

interface IProps {
  onClick: ((action: TableClickCommand) => void) | undefined;
  availableTableActions: readonly TableAction[];
  numSelected: number;
}

const excludedBatchAction: TableAction[] = ["ResetFilter", "SetAsPrimary"];
//TODO: add danger color
const FallbackIcon = createQmIcon("far fa-circle", "danger");

function AdvancedTableButtons(props: IProps) {
  const { onClick, availableTableActions, numSelected } = props;
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const { toDisplay, displayMode } = React.useMemo(() => {
    const toDisplay = availableTableActions.filter((x) => !excludedBatchAction.includes(x));
    const displayMode = toDisplay.length < 4 ? "Full" : toDisplay.length > 6 ? "Dropdown" : "Condensed";
    return { toDisplay, displayMode };
  }, [availableTableActions]);

  return (
    <>
      {onClick &&
        displayMode != "Dropdown" &&
        toDisplay.map((x) => {
          const uiData = tableActionUiData[x];
          const disabled = uiData?.requiresSelection && numSelected == 0;
          const Icon = uiData?.Icon ?? FallbackIcon;
          return (
            <Button
              key={x}
              size="sm"
              outline
              className="me-2"
              onClick={() => onClick({ action: x })}
              title={disabled ? t("Please select at least one item") : t(uiData?.title ?? "undefined " + x)}
              disabled={disabled}
            >
              <Icon />
              {displayMode == "Full" && <span className="ms-1">{t(uiData?.title ?? "undefined " + x)}</span>}
            </Button>
          );
        })}
      {onClick && displayMode == "Dropdown" && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="me-2">
          <DropdownToggle outline caret size="sm">
            {t("Batch actions")}
          </DropdownToggle>
          <DropdownMenu>
            {toDisplay.map((x) => {
              const uiData = tableActionUiData[x];
              const disabled = uiData?.requiresSelection && numSelected == 0;
              const Icon = uiData?.Icon ?? FallbackIcon;
              return (
                <DropdownItem
                  key={x}
                  disabled={disabled}
                  title={disabled ? t("Please select at least one item") : t(uiData?.title ?? "undefined " + x)}
                  onClick={() => onClick({ action: x })}
                >
                  <Icon /> {t(uiData?.title ?? "undefined " + x)}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
}
export default AdvancedTableButtons;
