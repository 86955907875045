const routePrefix = "/Store";

export class StoreRoutePaths {
  public static HomePage = routePrefix;
  public static BuyerPackageIndex = `${routePrefix}/Buyer/Packages_Legacy`;
  public static PackagesIndex = `${routePrefix}/Packages/Index`;
  public static PackageDetails = `${routePrefix}/Packages/Details/:id`;
  public static PackageCreate = `${routePrefix}/Packages/Create`;
  public static StoreItemsIndex = `${routePrefix}/StoreItems/Index`;
  public static StoreItemDetails = `${routePrefix}/StoreItems/Details/:id`;
}
