interface IProps {
  columns: unknown[];
}

function TableLoadingSkeleton({ columns }: IProps) {
  return (
    <tr data-testid="tableLoadingIndicator">
      <td colSpan={columns.length} className="placeholder-glow">
        <span className="placeholder col-12" />
        <span className="placeholder col-12" />
        <span className="placeholder col-12" />
      </td>
    </tr>
  );
}
export default TableLoadingSkeleton;
