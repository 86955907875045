import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ButtonLoadingSpinner({ className = "me-1" }: { className?: string }) {
  const { t } = useTranslation();
  return (
    <>
      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className={className} />
      <span className="visually-hidden">{t("Loading...")}</span>
    </>
  );
}
export default ButtonLoadingSpinner;
