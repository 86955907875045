import { HeaderGroup } from "react-table";

import { qmBaseIcons } from "../../icons/qmBaseIcons";

interface IProps<T extends object> {
  column: HeaderGroup<T>;
}

export function TableSortIcon<T extends object>({ column }: IProps<T>) {
  if (column.isSorted) {
    if (column.isSortedDesc) {
      return <qmBaseIcons.SortDown className="ms-1 text-info" />;
    } else {
      return <qmBaseIcons.SortUp className="ms-1 text-info" />;
    }
  } else if (column.canSort) {
    return <qmBaseIcons.Sort className="ms-1 text-body-tertiary" />;
  } else {
    return null;
  }
}
export default TableSortIcon;
