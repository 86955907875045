import i18next from "i18next";

import { IAppsControllerClient } from "@/generatedCode/pbd-core/pbd-core-api";

import { IEntityDefinitionVM } from "./entityDefinitionVM";

export default class EntityDefinitionService {
  #api: IAppsControllerClient;
  constructor(claimsApi: IAppsControllerClient) {
    this.#api = claimsApi;
  }

  async getAll(): Promise<IEntityDefinitionVM[]> {
    const items = await this.#api.getEntityDefinitions();
    return items
      .map((x) => {
        return { ...x, entityKeyI18n: i18next.t(`entityKeys::${x.entityKey}`) };
      })
      .toSorted((a, b) => a.entityKeyI18n.localeCompare(b.entityKeyI18n));
  }
}
