export class StartpageRoutePaths {
  // public static StartPage_Old = "";
  public static StartPage = "/";
  public static Contact = "/Home/Contact";
  public static StatusPage = "/Home/Status";
  public static SearchPage = "/Home/Search";
  public static RoutingRulesDetailsPage = "/Home/RoutingRules/Details/:id";
  public static RoutingRulesIndexPage = "/Home/RoutingRules/Index";
  public static Tour = `/Home/Tour/:moduleName`;
  /**Could be 404 or SubscriptionNotSynced or something else. */
  public static ErrorPage = "/Home/Error/:code";
}
