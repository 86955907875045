import StoreService from "../../pbdServices/services/Store/storeService";

import { IConfig, PackagesClient, StoreItemsClient } from "./storeApi";

const configBase = new IConfig();

export class StoreServiceContextData {
  packagesClient: PackagesClient;
  storeItemsClient: StoreItemsClient;
  storeService: StoreService;
  constructor(storeUrl: string) {
    this.packagesClient = new PackagesClient(configBase, storeUrl);
    this.storeItemsClient = new StoreItemsClient(configBase, storeUrl);
    this.storeService = new StoreService(new PackagesClient(configBase, storeUrl));
  }
}
