import i18next from "i18next";
import * as yup from "yup";

import { ITimeIntervalDTO, TimeIntervalDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { isFirstIntervalGreaterThanSecond } from "../../pbdServices/services/DateTime/calculateDates";
import { timeIntervalSchema } from "./timeIntervalSchema";

export const validationWarningTimeInterval = yup.mixed().test(
  "custom-context",
  () => i18next.t("Warning interval should be less than monitoring interval"),
  function () {
    const parent = this.parent as { monitoringInterval?: ITimeIntervalDTO; warningTimeInterval?: ITimeIntervalDTO };
    const monitoring: ITimeIntervalDTO | undefined = parent.monitoringInterval;
    const warning: ITimeIntervalDTO | undefined = parent.warningTimeInterval;
    return isFirstIntervalGreaterThanSecond(monitoring, warning);
  },
);

interface IMonitoringProps {
  isRecurring: boolean;
  monitoringInterval?: TimeIntervalDTO;
  useWarningTime: boolean;
  warningTimeInterval?: TimeIntervalDTO;
}

//@ts-expect-error TODO: Fix with better typings
export const monitoringValidationSchema: yup.ObjectSchema<IMonitoringProps> = yup.object({
  isRecurring: yup.boolean().required(),
  //   monitoringInterval:monitoringSchema,
  monitoringInterval: yup.object().when("isRecurring", {
    is: true,
    then: () => timeIntervalSchema.required(),
    otherwise: (s) => s.optional(),
  }),
  useWarningTime: yup.boolean().required(),
  warningTimeInterval: yup.object().when("useWarningTime", {
    is: true,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    then: () => validationWarningTimeInterval,
    otherwise: (s) => s.optional(),
  }),
});
