import { IApplicationGroupPermission } from "../../Models/Identity/ApplicationGroupPermission";
import { IApplicationUserPermission } from "../Users/models/application-user-permission";
import { Collaborator } from "./models/collaborator";

export default class CollaboratorService {
  static mapToList(users?: IApplicationUserPermission[], groups?: IApplicationGroupPermission[]): Collaborator[] {
    if (!users && !groups) return [];
    let list: Collaborator[] = [];
    if (users) {
      list = list.concat(users.map((u) => new Collaborator("User", u)));
    }
    if (groups) {
      list = list.concat(groups.map((g) => new Collaborator("Group", g)));
    }
    return list;
  }

  //   static isUser(item:Collaborator):item is ApplicationUserPermission{
  // return (item as ApplicationUserPermission).ty
  //   }
}
