import i18next from "i18next";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Column } from "react-table";

import { IOpportunityCategoryBoundary } from "@/generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import BaseTableProps from "../../../shared/components/tables/BaseTableProps";
import { ReactstrapTable } from "../../../shared/components/tables/reactstrapTable";

type IProps = BaseTableProps<IOpportunityCategoryBoundary>;

export function BoundariesTable(props: IProps) {
  const { onRowClick } = props;

  const columns = React.useMemo<Column<IOpportunityCategoryBoundary>[]>(
    () => [
      {
        id: "id",
        Header: <>{i18next.t("ID")}</>,
        accessor: "boundaryId",
        Cell: ({ row }) => <>{row.original.boundaryId}</>,
      },
      {
        id: "title",
        Header: <>{i18next.t("Title")}</>,
        accessor: "boundaryName",
        Cell: ({ row }) => <>{row.original.boundaryName}</>,
      },
      {
        id: "boundaryValue",
        Header: <>{i18next.t("Value")}</>,
        accessor: "boundaryValue",
        Cell: ({ row }) => <>{row.original.boundaryValue}</>,
      },
      {
        id: "type",
        Header: <>{i18next.t("Type")}</>,
        accessor: "type",
        Cell: ({ row }) => <>{row.original.type}</>,
      },
      {
        id: "boundaryLevel",
        Header: <>{i18next.t("Log level")}</>,
        accessor: "boundaryLevel", // accessor is the "key" in the data
        Cell: ({ row }) => <>{row.original.boundaryLevel}</>,
      },
      {
        Header: <></>,
        accessor: "boundaryId", // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ row }) => (
          <>
            {onRowClick && (
              <ButtonGroup>
                <Button variant="link" onClick={() => onRowClick({ row: row.original, action: "Select" })}>
                  <qmBaseIcons.Pencil /> {i18next.t("Edit")}
                </Button>
                <Button variant="link" onClick={() => onRowClick({ row: row.original, action: "Delete" })}>
                  <qmBaseIcons.Delete />
                </Button>
              </ButtonGroup>
            )}
          </>
        ),
      },
    ],
    [],
  );

  return (
    <ReactstrapTable<IOpportunityCategoryBoundary>
      columns={columns}
      columnsVisibleDefault={["title", "value"]}
      localStorageStateKey={"BoundariesTable"}
      activeFilters={{}}
      {...props}
    />
  );
}
