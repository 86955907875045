import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IDepartmentPositionVM } from "../../../pbdServices/services/DepartmentPositions/models/department-position-vm";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";

interface IProps {
  data: IDepartmentPositionVM;
}

function InfoCell(props: IProps) {
  const { t } = useTranslation();
  const { data } = props;
  const capacityUndercut = data.minimumCapacity && data.tenants ? data.minimumCapacity > data.tenants.length : false;
  const capacityExceeded = data.maximumCapacity && data.tenants ? data.maximumCapacity < data.tenants.length : false;
  return (
    <span>
      {capacityUndercut && (
        <Badge bg="warning" className="me-1">
          {t("Capacity undercut")}
        </Badge>
      )}
      {capacityExceeded && (
        <Badge bg="warning" className="me-1">
          {t("Capacity exceeded")}
        </Badge>
      )}
      {data.circularRelation && (
        <>
          <qmBaseIcons.Danger className=" text-danger" /> {t("Circular relationship found")}
          <br />
        </>
      )}

      {data.parentDepartmentPositionCount != null && data.parentDepartmentPositionCount > 1 && (
        <>
          <qmBaseIcons.Danger className=" text-danger" />{" "}
          {t("More than one parent leads to problems in the visualization of organization chart")}
          <br />
        </>
      )}

      {data.parentDepartmentPositionCount == 0 && data.childDepartmentPositionCount == 0 && (
        <>
          <qmBaseIcons.Danger className=" text-danger" /> {t("This position has no relation to other positions")}
          <br />
        </>
      )}
    </span>
  );
}
export default InfoCell;
