import { Form as FormFormik, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { Gender, IAccountRequest } from "@/generatedCode/pbd-core/pbd-core-api";

import { useExternalLoginHook } from "@/hooks/useExternalLoginHook";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import { nullableString } from "../../../services/validation/stringSchemas";
import { nameofFactory } from "../../../utils/nameofFactory";
import StringHelpers from "../../../utils/stringHelpers";
import { FormikSelectInputGroup } from "../../shared/components/forms/formik/formikSelectInput";
import FormikSubmitButton from "../../shared/components/forms/formik/formikSubmitButton";
import { FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../shared/components/forms/formik/formikValidationSummary";

const nameof = nameofFactory<IAccountRequest>();

const validationSchema: yup.ObjectSchema<IAccountRequest> = yup.object({
  salutation: yup.mixed<Gender>().oneOf(Object.values(Gender)).required(),
  firstName: yup.string().required().min(2).max(250),
  lastName: yup.string().required().min(2).max(250),
  description: nullableString,
});

interface IProps {
  onSubmit: (dto: IAccountRequest) => Promise<void>;
  onSuccess: () => void;
}

function AccountRequestForm(props: IProps) {
  const { t } = useTranslation();
  const { onSubmit, onSuccess } = props;
  const { data } = useExternalLoginHook();

  const submitter = useFormikAPISubmitter<IAccountRequest>(onSubmit, [onSubmit], onSuccess);

  const initialValues: IAccountRequest = { salutation: Gender.NotSet, firstName: "", lastName: "", description: "" };

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={validationSchema}>
      {(formikBag) => (
        <FormFormik>
          <FormikSelectInputGroup
            name={nameof("salutation")}
            options={Object.values(Gender).map((x) => {
              return { labelI18nKey: `GenderKey_${x}`, value: x };
            })}
            label={t("Salutation")}
          />
          <FormikTextInputGroup name={nameof("firstName")} label={t("First name")} />
          <FormikTextInputGroup name={nameof("lastName")} label={t("Last name")} />
          <FormikTextInputGroup
            name={nameof("description")}
            label={
              StringHelpers.isNullOrWhitespace(data?.accountRequestDescriptionHint)
                ? undefined
                : data?.accountRequestDescriptionHint
            }
            textarea
          />
          <FormikValidationSummary formikBag={formikBag} keysToExclude={["firstName", "lastName"]} />
          <div className="d-grid gap-2 mb-3">
            <FormikSubmitButton formikBag={formikBag} canSubmitDirty label={t("Request")} />
          </div>
        </FormFormik>
      )}
    </Formik>
  );
}
export default AccountRequestForm;
