import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  AddressCreateDTO,
  AddressEditDTO,
  IAddressCreateDTO,
  IAddressDetailsDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { useRefreshHook } from "@/hooks/useRefreshHook";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import { SettingsRoutePaths } from "../../settingsRoutePaths";
import PlacesConnectedAlert from "../components/placesConnectedAlert";
import PlacesForm from "../components/placesForm";
import RoomsTable from "../components/roomsTable";

function EditPagePlaces() {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { addressesApi } = useAPIs();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<IAddressDetailsDTO>();
  const [{ refresh, handleRefresh }] = useRefreshHook();
  const confirm = useConfirmation();

  React.useEffect(() => {
    async function getData() {
      if (id) {
        const address = await addressesApi.getById(Number(id));
        setItemToUpdate(address);
      }
      setLoading(false);
    }
    getData();
  }, [addressesApi, id, refresh]);

  const handleSubmit = async (itemToCreate: IAddressCreateDTO) => {
    if (id == null) {
      await addressesApi.create(new AddressCreateDTO(itemToCreate));
    } else {
      if (!itemToUpdate) throw new Error("Missing data");
      await addressesApi.edit(
        itemToUpdate.id,
        new AddressEditDTO({ ...itemToCreate, id: itemToUpdate.id, isPrimary: itemToUpdate.isPrimary }),
      );
    }
    navigate(SettingsRoutePaths.IndexPagePlaces);
  };

  async function submitHardDelete() {
    if (itemToUpdate) {
      const resp = await wrapApiCallWithToast(() => addressesApi.delete(itemToUpdate.id));
      if (resp.isOk) {
        navigate(SettingsRoutePaths.IndexPagePlaces);
      }
    }
  }

  const handleDeleteClick = async () => {
    if (itemToUpdate) {
      confirm({ itemsToDelete: [itemToUpdate] }).then(() => submitHardDelete());
    }
  };

  return (
    <>
      {!loading && (
        <PlacesForm
          onSubmit={handleSubmit}
          onDelete={handleDeleteClick}
          itemToUpdate={itemToUpdate}
          backToIndexUrl={SettingsRoutePaths.IndexPagePlaces}
        >
          {itemToUpdate && (
            <>
              <RoomsTable address={itemToUpdate} reloadParent={handleRefresh} />
              <PlacesConnectedAlert item={itemToUpdate} />
            </>
          )}
        </PlacesForm>
      )}
    </>
  );
}

export default EditPagePlaces;
