import { ErrorMessage, FieldHookConfig, FieldProps, useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { FormFeedback, FormText, Input, Label } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";

interface FormTextProps {
  id?: string;
  type: InputType;
  formText?: React.ReactNode;
  label?: React.ReactNode;
}

/**
 * id is a required prop
 * @param param0
 * @returns
 */
export const FormikCheckboxInput = ({
  field,
  formText,
  form: { touched, errors },
  ...props
}: FieldProps & FormTextProps) => (
  <div>
    <Input
      invalid={!!(touched[field.name] && errors[field.name])}
      checked={field.value}
      aria-label={field.name}
      {...field}
      {...props}
      type="checkbox"
    />
    {props.label && (
      <>
        {" "}
        <Label for={field.name} check className="me-1">
          {props.label}
        </Label>
      </>
    )}
    <ErrorMessage component={FormFeedback} {...field} />
    {formText && <FormText color="muted">{formText}</FormText>}
  </div>
);

export function FormikCheckbox(props: FieldHookConfig<boolean> & Omit<FormTextProps, "type">) {
  const { formText, label, id } = props;
  const [field, meta] = useField(props);
  const localId = id ?? props.name;
  const invalid = meta.error != undefined && meta.touched;

  return (
    <Form.Group className="mb-3" controlId={localId}>
      <Form.Check
        {...field}
        label={label}
        id={localId}
        checked={field.value}
        value={undefined}
        disabled={props.disabled}
        isInvalid={invalid}
        feedback={meta.error}
        feedbackType={invalid ? "invalid" : undefined}
      />
      {formText && <Form.Text muted>{formText}</Form.Text>}
    </Form.Group>
  );
}

interface CheckboxItem {
  id: string;
  label: string;
}

export function FormikCheckboxArray(
  props: FieldHookConfig<string[]> & Omit<FormTextProps, "type"> & { items: CheckboxItem[] },
) {
  const { formText, label, id, items } = props;
  const [field] = useField(props);
  const localId = id ?? props.name;
  return (
    <Form.Group className="mb-3" controlId={localId}>
      <Form.Label>{label}</Form.Label>
      {items.map((x) => (
        <Form.Check
          {...field}
          key={x.id}
          label={x.label}
          id={`${localId}_${x.id}`}
          // id={localId}
          checked={field.value.includes(x.id)}
          value={x.id}
          disabled={props.disabled}
        />
      ))}
      <ErrorMessage component={Form.Control.Feedback} {...field} />
      {formText && <Form.Text muted>{formText}</Form.Text>}
    </Form.Group>
  );
}
