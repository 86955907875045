import { Formik } from "formik";
import { TFunction } from "i18next";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { IEntityFieldTemplateAddOrUpdateRequest, IEntityFieldTemplateDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import { nullableString, requiredStringSchema } from "../../../services/validation/stringSchemas";
import { nameofFactory } from "../../../utils/nameofFactory";
import { FormikCheckbox, FormikCheckboxArray } from "../../shared/components/forms/formik/formikCheckboxInput";
import FormikCustomForm from "../../shared/components/forms/formik/formikCustomForm";
import { FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";

const nameof = nameofFactory<IEntityFieldTemplateAddOrUpdateRequest>();

const getValidationSchema = (t: TFunction) => {
  const ValidationSchema: yup.ObjectSchema<IEntityFieldTemplateAddOrUpdateRequest> = yup.object({
    title: requiredStringSchema(t),
    id: yup.string().required(),
    content: nullableString,
    isShared: yup.boolean().required(),
    fieldKeys: yup.array().of(yup.string().required()).required(),
  });
  return ValidationSchema;
};

interface IProps {
  onCancel: () => void;
  onSubmit: (dto: IEntityFieldTemplateAddOrUpdateRequest) => Promise<void>;
  onSuccess: () => void;
  itemToUpdate?: IEntityFieldTemplateDTO;
  fieldKeys?: string[];
}

function EntityFieldTemplateForm(props: IProps) {
  const { t } = useTranslation();
  const { onCancel, onSubmit, itemToUpdate, fieldKeys, onSuccess } = props;
  const initialValues: IEntityFieldTemplateAddOrUpdateRequest = {
    id: itemToUpdate?.id ?? nanoid(),
    title: itemToUpdate?.title ?? "",
    content: itemToUpdate?.content ?? "",
    isShared: itemToUpdate?.isShared ?? false,
    fieldKeys: itemToUpdate?.fieldKeys ?? [],
  };

  const submitter = useFormikAPISubmitter<IEntityFieldTemplateAddOrUpdateRequest>(
    (val) => onSubmit(val),
    [onSubmit],
    onSuccess,
  );
  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getValidationSchema(t)}>
      {(formikBag) => (
        <FormikCustomForm onCancel={onCancel} formikBag={formikBag}>
          <FormikTextInputGroup name={nameof("title")} />
          <FormikTextInputGroup name={nameof("content")} />
          {fieldKeys && (
            <FormikCheckboxArray
              label={t("Select the fields where the template should be used")}
              name={nameof("fieldKeys")}
              items={fieldKeys.map((x) => {
                return { id: x, label: x };
              })}
            />
          )}
          <FormikCheckbox type="switch" name={nameof("isShared")} label={t("Share with organization")} />
        </FormikCustomForm>
      )}
    </Formik>
  );
}
export default EntityFieldTemplateForm;
