import React, { ReactNode } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IDepartmentPositionDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { wrapApiCallWithToast } from "../../../../../pbdServices/services/Api/api-wrapper";
import { DepartmentPositionTable } from "../../../../departmentPositions/components/departmentPositionTable";
import { TableAction, TableClickCommand } from "../../tables/TableClickCommand";
import { TableRowClickCommand } from "../../tables/TableRowClickCommand";
import { ConnectedCardProps } from "../connectedCardProps";
import ConnectedCardHeader from "../shared/connectedCardHeader";

import ConnectDepartmentPositionsModal from "./connectDepartmentPositionsModal";
import CreateDepartmentPositionModal from "./createDepartmentPositionModal";

interface IProps extends ConnectedCardProps<IDepartmentPositionDTO> {
  onRowClick?: (command: TableRowClickCommand<IDepartmentPositionDTO>) => void;
  showCreateModal?: boolean;
  cardBody?: ReactNode;
  availableTableActions?: TableAction[];
}

function DepartmentPositionsConnectedCard(props: IProps) {
  const { t } = useTranslation();
  const {
    refreshParent,
    data,
    baseDTO,
    onRowClick,
    columnsVisibleDefault = ["title"],
    showCreateModal,
    onConnectSubmit,
    onDisconnectSubmit,
    cardBody,
    cardTitle = t("Positions"),
    availableTableActions = ["Delete"],
  } = props;
  const [connectionModal, setConnectionModal] = React.useState(false);
  const confirm = useConfirmation();
  const [selected, setSelected] = React.useState<IDepartmentPositionDTO[]>([]);

  const toggleConnectModal = () => setConnectionModal(!connectionModal);

  const handleTableClick = async (command: TableClickCommand) => {
    const { action } = command;
    switch (action) {
      case "Delete":
        await confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => x.id)));
        break;
      default:
        throw Error("Not implemented");
    }
  };

  const submitDeleteRequest = async (dto: number[]) => {
    if (onDisconnectSubmit) {
      for (const i of dto) {
        await wrapApiCallWithToast(() => onDisconnectSubmit(i));
      }
      refreshParent?.();
    }
  };

  const handleSuccess = () => {
    setConnectionModal(false);
    refreshParent?.();
  };

  const canAdd = baseDTO.capabilities?.canEdit && onConnectSubmit != undefined;

  return (
    <>
      <Card className="mb-3">
        <ConnectedCardHeader
          title={cardTitle}
          toggleConnectModal={canAdd ? toggleConnectModal : undefined}
          data={data}
          canAdd={canAdd}
        />
        {cardBody}
        {data && data.length > 0 && (
          <Card.Body>
            <DepartmentPositionTable
              tableRows={data}
              columnsVisibleDefault={columnsVisibleDefault}
              setSelected={setSelected}
              availableTableActions={availableTableActions}
              onClick={handleTableClick}
              onCreateClick={canAdd ? toggleConnectModal : undefined}
              onCreateButtonTitle={t("Add position")}
              onRowClick={onRowClick}
              {...props}
            />
          </Card.Body>
        )}
      </Card>
      {showCreateModal ? (
        <CreateDepartmentPositionModal
          showModal={connectionModal}
          toggleModal={toggleConnectModal}
          departmentId={baseDTO.id}
          onSuccess={handleSuccess}
        />
      ) : (
        <ConnectDepartmentPositionsModal
          modal={connectionModal}
          toggle={toggleConnectModal}
          connectedElements={data}
          onSubmit={onConnectSubmit}
          canSubmit={true}
          refreshParent={() => refreshParent?.()}
        />
      )}
    </>
  );
}

export default DepartmentPositionsConnectedCard;
