import { uniqBy } from "lodash";

import {
  IInventoryInspectionDTO,
  IInventoryInspectionsConnectedControllerClient,
  IInventoryInspectionsConnectedDTO,
  IInventoryInspectionsControllerClient,
  InventoryInspectionDTO,
  InventoryInspectionsConnectedDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";
import InspectionsFilteredResult from "./models/inspectionsFilteredResult";
import { IInventoryInspectionsConnectedVM } from "./models/inventoryInspectionsConnectedVM";
import { InventoryInspectionsConnectedQueryParameters } from "./models/query-parameters";
import UpcomingInspections from "./models/upcomingInspections";

export default class InventoryInspectionsConnectedService extends BaseExportService<IInventoryInspectionsConnectedDTO> {
  private inventoryInspectionsConnectedApi: IInventoryInspectionsConnectedControllerClient;
  private inventoryInspectionsApi: IInventoryInspectionsControllerClient;

  constructor(
    api: IInventoryInspectionsConnectedControllerClient,
    inventoryInspectionsApi: IInventoryInspectionsControllerClient,
  ) {
    super("Upcoming inspections");
    this.inventoryInspectionsConnectedApi = api;
    this.inventoryInspectionsApi = inventoryInspectionsApi;
  }

  async getAllMappedWithInspections(query: InventoryInspectionsConnectedQueryParameters) {
    const inspections = await this.inventoryInspectionsApi.getAll();
    return this.getAllMapped(query, inspections);
  }

  async getAllMapped(
    query: InventoryInspectionsConnectedQueryParameters,
    inventoryInspectionTypes: IInventoryInspectionDTO[],
  ): Promise<IInventoryInspectionsConnectedVM[]> {
    const items = await this.inventoryInspectionsConnectedApi.getAllQuery(query);
    return items.map((x) => {
      const inspectionType = inventoryInspectionTypes.find((f) => f.id == x.inventoryInspectionId);
      return {
        ...x,
        inventoryInspection: inspectionType
          ? new InventoryInspectionDTO(inspectionType)
          : this.deletedInspectionType(x.inventoryInspectionId),
      };
    });
  }

  deletedInspectionType = (id: number) => {
    const item = new InventoryInspectionDTO();
    item.id = id;
    item.title = `Deleted type #${id}`;
    return item;
  };

  getUpcomingInspections(items: InventoryInspectionsConnectedDTO[]): UpcomingInspections {
    const resp = new UpcomingInspections();
    const filteredItems = items.filter((x) => x.nextInspection && !x.inventoryItem?.excludeFromMonitoring);
    resp.segments.Overdue = new InspectionsFilteredResult("Overdue", filteredItems);
    resp.segments.ThisWeek = new InspectionsFilteredResult("ThisWeek", filteredItems);
    resp.segments.ThisMonth = new InspectionsFilteredResult("ThisMonth", filteredItems);
    resp.segments.ThisQuarter = new InspectionsFilteredResult("ThisQuarter", filteredItems);
    const totalEntries = resp.segments.Overdue.items
      .concat(resp.segments.ThisWeek.items)
      .concat(resp.segments.ThisMonth.items)
      .concat(resp.segments.ThisQuarter.items);

    resp.totalEntries = uniqBy(totalEntries, (x) => x.id);
    return resp;
  }

  mapToExport(x: IInventoryInspectionsConnectedDTO): ExportType {
    return {
      id: x.id,
      inventoryInspection_id: x.inventoryInspectionId,
      inventoryInspection_title: x.inventoryInspection?.title,
      inventoryItem_id: x.inventoryItem?.id,
      inventoryItem_title: x.inventoryItem?.title,
      inventoryItem_number: x.inventoryItem?.inventoryNumber,
      inventoryItem_model: x.inventoryItem?.model,
      inventoryItem_manufacturer: x.inventoryItem?.manufacturer,
      inventoryItem_serialNumber: x.inventoryItem?.serialNumber,
      inventoryItem_place: x.inventoryItem?.address?.fullAddress,
      inventoryItem_room: x.inventoryItem?.addressRoom?.title,
      createdAt: x.createdAt,
      nextInspection: x.nextInspection,
    };
  }
}
