import { Handle, NodeProps, NodeResizer, Position } from "reactflow";

import { FlowChartHelpers } from "../../../../../utils/flowChartHelpers";
import FormattedUserInput from "../../text/formattedUserInput";
import { NodeOrEdgeData } from "../models/pbd-flow-utils";

function CustomProcessNode({ data, selected }: NodeProps<NodeOrEdgeData>) {
  return (
    <>
      <NodeResizer isVisible={selected} />
      <Handle type="target" position={Position.Top} id="t" />
      <div style={FlowChartHelpers.getStylesForCustomNodes(data)}>
        <FormattedUserInput content={data.label} />
      </div>
      <Handle type="source" position={Position.Bottom} id="b" />
      <Handle type="source" position={Position.Left} id="s" />
      <Handle type="source" position={Position.Right} id="e" />
    </>
  );
}

export default CustomProcessNode;
