import { Button, Collapse, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { useLocalStorage } from "@/hooks/useLocalStorage";
import { useSmartViewsHook } from "@/hooks/useSmartViewsHook";
import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import { PaginationHelpers } from "../../../../pbdServices/services/pagination/pagination-helpers";
import SmartViewService from "../../../../pbdServices/services/smart-views/smart-view-service";
import StringHelpers from "../../../../utils/stringHelpers";
import CollapseChevron from "../icons/collapseChevron";
import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  onToggleModal: () => void;
  app: PbdModule;
}

function ListGroupItemSmartViews(props: IProps) {
  const { t } = useTranslation();
  const { app, onToggleModal } = props;
  const { data, mutate, activeSmartViewId } = useSmartViewsHook(app);
  const [isOpen, setIsOpen] = useLocalStorage<boolean>("sidebarSmartViews", false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <ListGroup.Item className="bg-body-tertiary">
      <Button onClick={toggle} variant="link" className="text-decoration-none text-start ps-0 link-body-emphasis">
        <CollapseChevron isOpen={isOpen} /> <qmBaseIcons.SmartView /> {t("Smart Views")}
      </Button>
      <Collapse in={isOpen}>
        <div>
          {data &&
            PaginationHelpers.paginate(data.allVisible, GlobalQmBaseConstants.MinEntriesForCollapse).map((x) => (
              <div className="d-grid gap-2" key={x.id}>
                <Button
                  variant={activeSmartViewId != x.id ? "link" : "primary"}
                  as={Link as any}
                  to={SmartViewService.linkTo(app, x)}
                  className="text-decoration-none text-start"
                  active={activeSmartViewId == x.id}
                >
                  {x.isShared && <qmBaseIcons.Globe className="me-1 text-secondary" title={t("Public Smart View")} />}
                  {StringHelpers.truncate(x.name, 27)}
                </Button>
              </div>
            ))}
          <div>
            <Button variant="link" size="sm" onClick={onToggleModal}>
              {t("View all")}
            </Button>
          </div>
        </div>
      </Collapse>
    </ListGroup.Item>
  );
}
export default ListGroupItemSmartViews;
