import { useTranslation } from "react-i18next";
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from "reactstrap";
import useSWR from "swr";

import { useAPIs } from "../../../services/serviceContext";

import SwrApiHelpers from "../../../pbdServices/services/Api/swr-api-helpers";
import { RoutingRuleTable } from "../components/routingRuleTable";

function IndexPageRoutingRules() {
  const { t } = useTranslation();
  const { moduleSettingsApi } = useAPIs();
  const { data } = useSWR(
    "/api/moduleSettings/routingRules",
    () => moduleSettingsApi.getRoutingRuleItems(),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem active>{t("Routing rules")}</BreadcrumbItem>
      </Breadcrumb>
      <hr />
      <Card className="mb-3">
        <CardBody>
          <RoutingRuleTable
            // onCreateClick={() => navigate(SettingsRoutePaths.RoutingRulesCreatePage)}
            tableRows={data}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default IndexPageRoutingRules;
