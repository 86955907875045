import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWRImmutable from "swr/immutable";

import { useAPIs } from "../../../services/serviceContext";

import { AdminRoutePaths } from "../../admin/adminRoutePaths";
import { IModuleSettingsTableProps, ModuleSettingsTable } from "../components/moduleSettingsTable";

const IndexPageModuleSettings = () => {
  const { t } = useTranslation();
  const { moduleSettingsApi } = useAPIs();

  const { data } = useSWRImmutable("/api/moduleSettingsRaw", async () => {
    const resp = await moduleSettingsApi.getAll();
    const mapped: IModuleSettingsTableProps[] = resp.map((x) => {
      return {
        ...x,
        id: `${x.module}_${x.type}`,
        title: `${x.module}_${x.type}`,
        linkTo: AdminRoutePaths.DetailsPageModuleSettings.replace(":pbdModule", x.module).replace(":type", x.type),
      };
    });
    return mapped;
  });

  return (
    <>
      <h2>{t("Module settings")}</h2>
      <hr />
      <Card className="mb-3">
        <Card.Body>
          <ModuleSettingsTable tableRows={data} />
        </Card.Body>
      </Card>
    </>
  );
};
export default IndexPageModuleSettings;
