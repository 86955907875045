import { useTranslation } from "react-i18next";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { AuditRoutePaths } from "../auditRoutePaths";

/**
 * Audits sidebar
 * */
const SidebarAudits = () => {
  const { t } = useTranslation();
  const links: SidebarItem[] = [
    SidebarItem.created(AuditRoutePaths.CreatePage, t),
    SidebarItem.overview(AuditRoutePaths.IndexPage + "/?status=Open&status=InProgress", t),
  ];
  links.push(
    new SidebarItem({
      title: t("Audit matrix"),
      icon: qmBaseIcons.AuditMatrix,
      href: AuditRoutePaths.MatrixPage,
    }),
  );
  links.push(SidebarItem.settings(SettingsRoutePaths.AuditManagementHome, t));

  return <SidebarNew app={PbdModule.AuditManagement} items={links} />;
};

export default SidebarAudits;
