import React from "react";
import { Card, CardBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAPIs } from "../../../services/serviceContext";

import { AdminRoutePaths } from "../../admin/adminRoutePaths";
import CreateFormCostTemplate from "../components/createFormCostTemplate";

const CreatePageCostTemplate: React.FC = () => {
  const { t } = useTranslation();
  const { costTemplatesApi } = useAPIs();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Card className="mb-3">
        <CardBody>
          <CreateFormCostTemplate
            onSubmit={(dto) => costTemplatesApi.create(dto)}
            onSuccess={(dto) => navigate(AdminRoutePaths.EditCostTemplate.replace(":id", dto.id))}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default CreatePageCostTemplate;
