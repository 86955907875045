/* eslint-disable @typescript-eslint/naming-convention */
import { CrmRoutePaths } from "../crm/crmRoutePaths";
import { EmployeeRoutePaths } from "../employees/employeeRoutePaths";

export default class TenantRoutePaths {
  public static EditPageFn = (dto: { id: number; isEmployee: boolean }): string => {
    if (dto.isEmployee) {
      return EmployeeRoutePaths.EditPage.replace(":id", dto.id.toString());
    } else {
      return CrmRoutePaths.EditPageTenantFn(dto.id);
    }
  };
}
