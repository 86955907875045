import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import EditPageDepartmentPosition from "../departmentPositions/pages/editPageDepartmentPosition";
import IndexPageDepartmentPositions from "../departmentPositions/pages/indexPageDepartmentPositions";
import { PbdRouteModel } from "../router/pbdRouteModel";
import DetailsPageRoutingRules from "../routingRules/pages/detailsPageRoutingRules";
import IndexPageRoutingRules from "../routingRules/pages/indexPageRoutingRules";
import CreatePageAbsencePolicy from "./absencePolicy/pages/createPageAbsencePolicy";
import EditPageAbsencePolicy from "./absencePolicy/pages/editPageAbsencePolicy";
import IndexPageAbsencePolicy from "./absencePolicy/pages/indexPageAbsencePolicy";
import EditPageAbsenceType from "./absenceTypes/pages/editPageAbsenceType";
import IndexPageAbsenceType from "./absenceTypes/pages/indexPageAbsenceType";
import EditPageAuditRequirements from "./auditRequirements/pages/editPageAuditRequirements";
import IndexPageAuditRequirements from "./auditRequirements/pages/indexPageAuditRequirements";
import EditPageAuditTypes from "./auditTypes/pages/editPageAuditTypes";
import IndexPageAudiTypes from "./auditTypes/pages/indexPageAuditTypes";
import IndexPageClaimDeadline from "./claimDeadline/pages/claimDeadlineIndexPage";
import IndexPageConnectionCategories from "./connectionCategories/pages/indexPageConnectionCategories";
import EditPageCostCategories from "./costCategories/pages/editPageCostCategories";
import IndexPageCostCategories from "./costCategories/pages/indexPageCostCategories";
import EditPageCostCenters from "./costCenters/pages/editPageCostCenters";
import IndexPageCostCenters from "./costCenters/pages/indexPageCostCenters";
import IndexPageCurrencies from "./currencies/pages/indexPageCurrencies";
import CreatePageCustomField from "./customFields/pages/createPageCustomField";
import EditPageCustomFields from "./customFields/pages/editPageCustomFields";
import IndexPageCustomFields from "./customFields/pages/indexPageCustomFields";
import EditPageDefectCategories from "./defectCategories/pages/editPageDefectCategories";
import IndexPageDefectCategories from "./defectCategories/pages/indexPageDefectCategories";
import EditPageDepartments from "./departments/pages/editPageDepartments";
import IndexPageDepartments from "./departments/pages/indexPageDepartments";
import EditPageEmployeeIdeaCategories from "./employeeIdeaCategories/pages/editPageEmployeeIdeaCategories";
import IndexPageEmployeeIdeaCategories from "./employeeIdeaCategories/pages/indexPageEmployeeIdeaCategories";
import EditPageFileCategories from "./fileCategory/editPageFileCategories";
import IndexPageFileCategories from "./fileCategory/indexPageFileCategories";
import EditPageInventoryCategories from "./inventoryCategories/pages/editPageInventoryCategories";
import IndexPageInventoryCategories from "./inventoryCategories/pages/indexPageInventoryCategories";
import EditPageInventoryInspections from "./inventoryInspections/pages/editPageInventoryInspections";
import IndexPageInventoryInspections from "./inventoryInspections/pages/indexPageInventoryInspections";
import EditPageInventoryStatus from "./inventoryStatus/pages/editPageInventoryStatus";
import IndexPageInventoryStatus from "./inventoryStatus/pages/indexPageInventoryStatus";
import IndexPageNotifications from "./notifications/pages/indexPageNotifications";
import EditPageOpportunityCategories from "./opportunityCategories/pages/editPageOpportunityCategories";
import IndexPageOpportunityCategories from "./opportunityCategories/pages/indexPageOpportunityCategories";
import HomePageAppSettings from "./pages/homePageAppSettings";
import HomePageArticleSettings from "./pages/homePageArticleSettings";
import EditPagePlaces from "./places/pages/editPagePlaces";
import IndexPagesPlaces from "./places/pages/indexPagePlaces";
import { SettingLayoutWithSubsidebar } from "./settingLayoutWithSubsidebar";
import { SettingsRoutePaths } from "./settingsRoutePaths";
import EditPageTags from "./tags/pages/editPageTags";
import IndexPageTags from "./tags/pages/indexPageTags";
import EditPageTrainingTypes from "./trainingTypes/pages/editPageTrainingTypes";
import IndexPageTrainingTypes from "./trainingTypes/pages/indexPageTrainingTypes";
import EditPageUnits from "./units/pages/editPageUnits";
import IndexPageUnits from "./units/pages/indexPageUnits";
import IndexPageWorkflows from "./workflows/pages/indexPageWorkflows";
import CreatePageBankHolidays from "./workingHoursAndBankHolidays/pages/createPageBankHolidays";
import CreatePageWorkingHours from "./workingHoursAndBankHolidays/pages/createPageWorkingHours";

const homePageRoles = [
  PbdRoles.Settings_Standard,
  PbdRoles.Settings_ModuleAdmin,
  PbdRoles.DocumentManagement_Settings,
  PbdRoles.TrainingManagement_Settings,
  PbdRoles.AuditManagement_Settings,
  PbdRoles.ClaimManagement_Settings,
  PbdRoles.MaintenanceManagement_Settings,
  PbdRoles.OpportunityManagement_Settings,
  PbdRoles.QualificationMatrix_Settings,
  PbdRoles.IdeaManagement_Settings,
];

const homePageRouteParams: Partial<PbdRouteModel> = {
  roles: homePageRoles,
};

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.Settings_Standard, PbdRoles.Settings_ModuleAdmin],
};

const IdeaManagementRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.Admin, PbdRoles.IdeaManagement_Settings],
};

const DocumentManagementRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.Admin, PbdRoles.DocumentManagement_Settings],
};

const ClaimManagementRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.Admin, PbdRoles.ClaimManagement_Settings],
};

const TrainingManagementRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.Admin, PbdRoles.TrainingManagement_Settings],
};

const AuditManagementRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.Admin, PbdRoles.AuditManagement_Settings],
};

const MaintenanceManagementRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.Admin, PbdRoles.MaintenanceManagement_Settings],
};

export const settingRoutesConfig: RouteObject[] = [
  {
    path: SettingsRoutePaths.Home,
    Component: lazy(() => import("./pages/homePageSettings")),
    ...homePageRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPagesUnits,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageUnits} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageUnits,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageUnits} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageUnits,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageUnits} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageCostCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageCostCategories} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageCostCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageCostCategories} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageCostCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageCostCategories} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageFileCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageFileCategories} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageFileCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageFileCategories} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageFileCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageFileCategories} />,
    ...defaultRouteParams,
  },

  {
    path: SettingsRoutePaths.IndexPageCostCenters,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageCostCenters} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageCostCenters,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageCostCenters} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageCostCenters,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageCostCenters} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageCurrencies,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageCurrencies} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPagePlaces,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPagesPlaces} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePagePlaces,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPagePlaces} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPagePlaces,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPagePlaces} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageTags,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageTags} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageTags,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageTags} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageTags,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageTags} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageEmployeeIdeaCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageEmployeeIdeaCategories} />,
    ...IdeaManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageEmployeeIdeaCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageEmployeeIdeaCategories} />,
    ...IdeaManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageEmployeeIdeaCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageEmployeeIdeaCategories} />,
    ...IdeaManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.DocumentManagementHome,
    Component: () => <SettingLayoutWithSubsidebar Component={HomePageArticleSettings} />,
    ...DocumentManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.RoutingRulesIndexPage,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageRoutingRules} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.RoutingRulesCreatePage,
    Component: () => <SettingLayoutWithSubsidebar Component={DetailsPageRoutingRules} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.RoutingRulesEditPage,
    Component: () => <SettingLayoutWithSubsidebar Component={DetailsPageRoutingRules} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.AppSettingsHomePage,
    Component: () => <SettingLayoutWithSubsidebar Component={HomePageAppSettings} />,
    ...homePageRoles,
  },
  {
    path: SettingsRoutePaths.ClaimDeadlines,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageClaimDeadline} />,
    ...ClaimManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.ClaimNotifications,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageNotifications} />,
    ...ClaimManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageTrainingTypes,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageTrainingTypes} />,
    ...TrainingManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageTrainingTypes,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageTrainingTypes} />,
    ...TrainingManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageTrainingTypes,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageTrainingTypes} />,
    ...TrainingManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageWorkflows,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageWorkflows} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageCustomFields,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageCustomFields} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageCustomFields,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageCustomFields} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageCustomFields,
    Component: () => <SettingLayoutWithSubsidebar Component={CreatePageCustomField} />,
    ...defaultRouteParams,
  },
  //
  {
    path: SettingsRoutePaths.IndexPageAuditRequirements,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageAuditRequirements} />,
    ...AuditManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageAuditRequirements,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageAuditRequirements} />,
    ...AuditManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageAuditRequirements,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageAuditRequirements} />,
    ...AuditManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageAuditTypes,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageAudiTypes} />,
    ...AuditManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageAuditTypes,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageAuditTypes} />,
    ...AuditManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageAuditTypes,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageAuditTypes} />,
    ...AuditManagementRouteParams,
  },
  //
  {
    path: SettingsRoutePaths.IndexPageAbsenceType,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageAbsenceType} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageAbsenceType,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageAbsenceType} />,
    ...defaultRouteParams,
  },

  {
    path: SettingsRoutePaths.EditPageAbsenceType,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageAbsenceType} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageAbsencePolicy,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageAbsencePolicy} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageAbsencePolicy,
    Component: () => <SettingLayoutWithSubsidebar Component={CreatePageAbsencePolicy} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageAbsencePolicy,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageAbsencePolicy} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageBankHolidays,
    Component: () => <SettingLayoutWithSubsidebar Component={CreatePageBankHolidays} />,
    ...defaultRouteParams,
  },
  // {
  //   path: SettingsRoutePaths.CreatePageWorkingHours,
  //     //   Component: () => <SettingLayoutWithSubsidebar Component={CreatePageWorkingHours_Legacy} />,
  //   ...defaultRouteParams,
  // },
  {
    path: SettingsRoutePaths.CreatePageWorkingHours,
    Component: () => <SettingLayoutWithSubsidebar Component={CreatePageWorkingHours} />,
    ...defaultRouteParams,
  },

  {
    path: SettingsRoutePaths.IndexPageConnectionCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageConnectionCategories} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageInventoryStatus,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageInventoryStatus} />,
    ...defaultRouteParams,
  },

  {
    path: SettingsRoutePaths.EditPageInventoryStatus,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageInventoryStatus} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageInventoryStatus,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageInventoryStatus} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageDefectCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageDefectCategories} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageDefectCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageDefectCategories} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageDefectCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageDefectCategories} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageOpportunityCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageOpportunityCategories} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageOpportunityCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageOpportunityCategories} />,
    ...defaultRouteParams,
  },

  {
    path: SettingsRoutePaths.CreatePageOpportunityCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageOpportunityCategories} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageInventoryCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageInventoryCategories} />,
    ...MaintenanceManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageInventoryCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageInventoryCategories} />,
    ...MaintenanceManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageInventoryCategories,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageInventoryCategories} />,
    ...MaintenanceManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageInventoryInspections,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageInventoryInspections} />,
    ...MaintenanceManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageInventoryInspections,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageInventoryInspections} />,
    ...MaintenanceManagementRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageInventoryInspections,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageInventoryInspections} />,
    ...MaintenanceManagementRouteParams,
  },
  //
  {
    path: SettingsRoutePaths.IndexPageDepartments,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageDepartments} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageDepartment,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageDepartments} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageDepartment,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageDepartments} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.IndexPageDepartmentPosition,
    Component: () => <SettingLayoutWithSubsidebar Component={IndexPageDepartmentPositions} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.CreatePageDepartmentPositions,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageDepartmentPosition} />,
    ...defaultRouteParams,
  },
  {
    path: SettingsRoutePaths.EditPageDepartmentPositions,
    Component: () => <SettingLayoutWithSubsidebar Component={EditPageDepartmentPosition} />,
    ...defaultRouteParams,
  },
];
