import { Badge, Button, ButtonGroup, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { EightDReportTeamMemberDTO } from "../../../Models/EightDReports/EightDReportTeamMemberDTO";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import AvatarSpanWithName from "../../shared/components/tenants/avatarSpanWithName";

interface IProps {
  items: EightDReportTeamMemberDTO[];
  isEdit?: boolean;
  onRemove: (dto: EightDReportTeamMemberDTO) => void;
  onAdd: (dto: EightDReportTeamMemberDTO) => void;
  onMarkAsTeamLeader: (dto: EightDReportTeamMemberDTO) => void;
}

function TeamTable(props: IProps) {
  const { t } = useTranslation();
  const { items, isEdit, onRemove, onMarkAsTeamLeader, onAdd } = props;

  return (
    <ListGroup>
      {items
        .sort((x) => (x.isTeamLeader ? -1 : 1))
        .filter((x) => (isEdit ? true : x.isMember || x.isTeamLeader))
        .map((x) => (
          <ListGroup.Item key={x.tenant.id} className="d-flex justify-content-between">
            <div className="d-flex">
              <AvatarSpanWithName tenant={x.tenant} className="me-1" showPosition />
              {x.isTeamLeader && (
                <div>
                  <Badge bg="primary">{t("Team leader")}</Badge>
                </div>
              )}
            </div>
            {isEdit && (
              <div>
                <ButtonGroup size="sm">
                  {!x.isTeamLeader && (
                    <Button variant="outline-secondary" onClick={() => onMarkAsTeamLeader(x)}>
                      {t("Mark as team leader")}
                    </Button>
                  )}
                  {x.isMember && (
                    <Button variant="outline-danger" onClick={() => onRemove(x)}>
                      <qmBaseIcons.Delete />
                    </Button>
                  )}
                  {!x.isMember && (
                    <Button variant="outline-danger" onClick={() => onAdd(x)}>
                      <qmBaseIcons.Plus /> {t("Add to team")}
                    </Button>
                  )}
                </ButtonGroup>
              </div>
            )}
          </ListGroup.Item>
        ))}
    </ListGroup>
  );
}
export default TeamTable;
