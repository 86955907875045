import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { useAPIs } from "../../../../services/serviceContext";

import { useAppContext } from "@/components/contexts/appContext";
import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { useRecycleBinHook } from "@/hooks/useRecycleBinHook";
import { ErrorMessage } from "../../../../Models/Errors/ErrorMessage";
import { SettingsIndexDTO } from "../../../../Models/Settings/SettingsIndexDTO";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import SwrApiHelpers from "../../../../pbdServices/services/Api/swr-api-helpers";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import { mapCategoryDTOToSettingsDTO } from "../../../categories/categoryMapper";
import { TableClickCommand } from "../../../shared/components/tables/TableClickCommand";
import BaseSettingsIndexCard from "../../components/baseSettingsIndexCard";
import { BaseSettingsTable } from "../../components/baseSettingsTable";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

const IndexPageCostCenters = () => {
  const navigate = useNavigate();
  const { showRecycleBin, toggleRecycleBin } = useRecycleBinHook();
  const { meAsUser, setErrorMessage, handleApiError } = useAppContext();
  const [selected, setSelected] = React.useState<SettingsIndexDTO[]>([]);
  const { costCentersApi } = useAPIs();
  const confirm = useConfirmation();
  const { data, isValidating, mutate } = useSWR(
    [`/api/costCenters`, showRecycleBin],
    () =>
      costCentersApi.getAllQuery({
        isDeleted: showRecycleBin,
      }),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  const tableRows = React.useMemo(
    () => mapCategoryDTOToSettingsDTO(SettingsRoutePaths.EditPageCostCenters, data),
    [data],
  );

  const handleClick = (command: TableClickCommand) => {
    if (command.action == "Delete") {
      if (selected) {
        confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
      }
    }
  };
  const submitDeleteRequest = async (items: number[]) => {
    if (!hasRole(meAsUser, [PbdRoles.Settings_Standard])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => costCentersApi.delete(Number(s)), { handleApiError });
      }
      mutate();
    }
  };

  return (
    <BaseSettingsIndexCard
      data={data}
      cardTitle="Cost centers"
      pathToCreate={SettingsRoutePaths.CreatePageCostCenters}
      loading={isValidating}
      showRecycleBin={showRecycleBin}
      toggleRecycleBin={toggleRecycleBin}
    >
      <BaseSettingsTable
        tableRows={tableRows}
        onCreateClick={() => navigate(SettingsRoutePaths.CreatePageCostCenters)}
        setSelected={(ids) => setSelected(ids.map((x) => x))}
        availableTableActions={["Delete"]}
        onClick={handleClick}
      />
    </BaseSettingsIndexCard>
  );
};

export default IndexPageCostCenters;
