import {
  IEvaluationDTO,
  IOpportunityCategoryDTO,
  IOpportunityEvaluationCreateDTO,
  VariableDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { BaseExportService } from "../Base/BaseExportService";
import { calculateFormula, convertFormula } from "../Calculator/getMinAndMaxResult";
import { ExportType } from "../Export/exportService";
import { IOpportunityEvaluationFormVM } from "./models/opportunity-evaluation-form-vm";

export default class OpportunityEvaluationService extends BaseExportService<IEvaluationDTO> {
  constructor() {
    super("Opportunity evaluations");
  }

  mapFormDtoToApiDTO(
    dto: IOpportunityEvaluationFormVM,
    category: IOpportunityCategoryDTO,
  ): IOpportunityEvaluationCreateDTO {
    const mapped: IOpportunityEvaluationCreateDTO = {
      ...dto,
      result: this.getResult(dto, category),
      variables: Object.keys(dto.formVariables).map((x) => new VariableDTO({ id: x, value: dto.formVariables[x] })),
    };
    return mapped;
  }

  getVariableValues(values: IOpportunityEvaluationFormVM, category: IOpportunityCategoryDTO) {
    const variables: Record<string, number> = {};

    Object.keys(values.formVariables).forEach((x) => {
      const variable = category.variables?.find((c) => c.variableId == x);
      if (!variable) throw Error("Missing data");
      variables[variable.variableName] = values.formVariables[x];
    });
    return variables;
  }

  getResult(values: IOpportunityEvaluationFormVM, category: IOpportunityCategoryDTO) {
    if (!category.formulaInfo) throw Error("Missing data");
    const convertedFormula = convertFormula(category.formulaInfo.formula);
    const variableValues = this.getVariableValues(values, category);
    return calculateFormula(convertedFormula, variableValues);
  }

  mapToExport(x: IEvaluationDTO): ExportType {
    return {
      id: x.id,
      title: x.opportunity?.title,
      category: x.opportunity?.category.title,
      responsible: x.opportunity?.responsible?.fullName,
      createdAt: x.createdAt,
      evaluatedAt: x.evaluatedAt,
      result: x.result,
    };
  }
}
