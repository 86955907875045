import {
  FillOutBy,
  ICustomFormLinkDTO,
  IFillOutInstruction,
  ITenantAsAttendee,
  ITenantAttendeeDTO,
  ITenantMinDTO,
  PbdStatus,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { IHaveTags } from "../../ClientApp/settings/tags/components/tagList";
import { ServiceResult } from "../../Models/ServiceResults/ServiceResult";

export type BaseDTOProps = "responsible" | "id" | "status" | "title";

export default class CustomFormConnectionService {
  static statusAllowed(instruction: IFillOutInstruction, currentStatus: PbdStatus) {
    if (instruction.connectedObjectStatusConstraints.includes(currentStatus)) {
      return true;
    }
    return false;
  }

  static tenantCanFillOut(
    instruction: IFillOutInstruction,
    tenant: ITenantMinDTO,
    responsible: ITenantMinDTO,
    attendees: ({ id: number } | ITenantAttendeeDTO)[],
  ) {
    if (instruction.whoCanFillOut == FillOutBy.Responsible && responsible.id == tenant.id) {
      return true;
    }
    if (instruction.whoCanFillOut == FillOutBy.Everyone) {
      return true;
    }
    if (instruction.whoCanFillOut == FillOutBy.Attendee && attendees.map((x) => x.id).includes(tenant.id)) {
      return true;
    }
    return false;
  }

  static canFillOut(
    customFormLink: ICustomFormLinkDTO,
    tenantToCheck: ITenantMinDTO,
    attendees: ITenantAsAttendee[] | ITenantAttendeeDTO[],
    baseDTO: IHaveTags,
  ) {
    if (!customFormLink.connectionType?.data) throw Error("Missing data");
    if (!baseDTO.status) throw Error("Missing status");
    if (!baseDTO.responsible) throw Error("Missing responsible");
    const statusAllowedResp = this.statusAllowed(customFormLink.connectionType.data, baseDTO.status);
    const tenantAllowedResp = this.tenantCanFillOut(
      customFormLink.connectionType.data,
      tenantToCheck,
      baseDTO.responsible,
      attendees,
    );
    const result = new ServiceResult();
    if (!statusAllowedResp) {
      result.addError("statusError", "The current status is not allowed");
    }
    if (!tenantAllowedResp) {
      result.addError("tenantError", "The current person is not allowed");
    }
    return result;
  }
}
