import useSWRImmutable from "swr/immutable";

import { GlobalQmBaseConstants } from "../Constants/GlobalQmBaseConstants";
import { useAPIs } from "../services/serviceContext";

export function useTagsHook() {
  const { tagsApi } = useAPIs();
  const { data, mutate } = useSWRImmutable("/api/tags", () =>
    tagsApi
      .getAllQuery({ pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration, sortBy: "Name" })
      .then((x) => x.data),
  );
  return { data, mutate };
}
