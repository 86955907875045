export enum StatusString {
  Open = "Open",
  InProgress = "InProgress",
  Completed = "Completed",
  SelfSignIn = "SelfSignIn",
  PendingInvitation = "PendingInvitation",
  PendingAttendanceConfirmation = "PendingAttendanceConfirmation",
  PendingRating = "PendingRating",
  UnderReview = "UnderReview",
  Submitted = "Submitted",
  Approved = "Approved",
  ReturnedToSender = "ReturnedToSender",
  Active = "Active",
  Inactive = "Inactive",
  NotApproved = "NotApproved",
  Accepted = "Accepted",
  NotAccepted = "NotAccepted",
  Attended = "Attended",
  NotAttended = "NotAttended",
  Withdrawn = "Withdrawn",
}
