import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Handle, NodeProps, NodeResizer, Position } from "reactflow";

import { FlowChartHelpers } from "../../../../../utils/flowChartHelpers";
import { NodeOrEdgeData } from "../models/pbd-flow-utils";

function RhombusNode({ data, selected }: NodeProps<NodeOrEdgeData>) {
  const { t } = useTranslation();
  return (
    <>
      <NodeResizer isVisible={selected} keepAspectRatio />
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
      <Handle type="source" position={Position.Left} />
      <Handle type="source" position={Position.Right} />

      <div>
        {/* <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.5757 1.42426C11.81 1.18995 12.1899 1.18995 12.4243 1.42426L22.5757 11.5757C22.81 11.81 22.8101 12.1899 22.5757 12.4243L12.4243 22.5757C12.19 22.81 11.8101 22.8101 11.5757 22.5757L1.42426 12.4243C1.18995 12.19 1.18995 11.8101 1.42426 11.5757L11.5757 1.42426Z"
            stroke="currentColor"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <text>Test</text>
        </svg> */}
        <div style={FlowChartHelpers.getStylesForCustomRhombus(data)}>
          <div style={{ transform: "rotate(-45deg)" }}>{data.label}</div>
        </div>
      </div>
    </>
  );
}
export default memo(RhombusNode);
