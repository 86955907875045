import i18next from "i18next";
import { countBy, map } from "lodash";
import { DateTime, DateTimeUnit, Duration, DurationLikeObject, DurationObjectUnits, Interval } from "luxon";

import { ITimeIntervalDTO, TimeInterval } from "@/generatedCode/pbd-core/pbd-core-api";

import StringHelpers from "./stringHelpers";

type GroupByDateType = "month" | "day";
export type DateFormats = "dddd" | "dd" | "MMMM YYYY" | "DD.MM" | "MM-DD" | "YYYY-MM-DD-HH-mm" | "YYYY-MM-DD";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DateTimeLuxonHelpers {
  const formatOptsDe: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "numeric" };

  export function getDuration(val: number, unit: DateTimeUnit) {
    switch (unit) {
      case "millisecond":
        return Duration.fromObject({ milliseconds: val });
      case "second":
        return Duration.fromObject({ seconds: val });
      case "minute":
        return Duration.fromObject({ minutes: val });
      case "hour":
        return Duration.fromObject({ hours: val });
      case "day":
        return Duration.fromObject({ days: val });
      case "week":
        return Duration.fromObject({ weeks: val });
      case "month":
        return Duration.fromObject({ months: val });
      case "quarter":
        return Duration.fromObject({ quarters: val });
      case "year":
        return Duration.fromObject({ years: val });
      /*default:
        throw new Error("Invalid offset");*/
    }
  }
  export function getRoundedTime(cleanedDuration: DurationObjectUnits): DurationObjectUnits {
    const roundUpMilisecondsBool = cleanedDuration.milliseconds && cleanedDuration.milliseconds > 30;
    const roundDownMilisecondsBool =
      cleanedDuration.milliseconds &&
      cleanedDuration.seconds &&
      cleanedDuration.milliseconds < 30 &&
      cleanedDuration.seconds > 0;

    const roundUpSecondsBool = cleanedDuration.seconds && cleanedDuration.seconds > 30;
    const roundSecondsDownBool =
      cleanedDuration.seconds && cleanedDuration.minutes && cleanedDuration.minutes < 30 && cleanedDuration.minutes > 0;

    const roundMinutesUpBool = cleanedDuration.minutes && cleanedDuration.minutes > 30;
    const roundMinutesDownBool =
      cleanedDuration.minutes && cleanedDuration.hours && cleanedDuration.minutes < 30 && cleanedDuration.hours > 0;

    if (roundUpMilisecondsBool) {
      cleanedDuration.milliseconds = undefined;
      cleanedDuration.seconds = cleanedDuration.seconds ? cleanedDuration.seconds + 1 : 1;
    }

    if (roundDownMilisecondsBool) cleanedDuration.milliseconds = undefined;

    if (roundUpSecondsBool) {
      cleanedDuration.seconds = undefined;
      cleanedDuration.minutes = cleanedDuration.minutes ? cleanedDuration.minutes + 1 : 1;
    }
    if (roundSecondsDownBool) cleanedDuration.seconds = undefined;
    if (roundMinutesUpBool) {
      cleanedDuration.minutes = undefined;
      cleanedDuration.hours = cleanedDuration.hours ? cleanedDuration.hours + 1 : 1;
    }
    if (roundMinutesDownBool) cleanedDuration.minutes = undefined;

    return cleanedDuration;
  }

  export function toHumanDuration(durationInput: Duration, minDuration: keyof DurationLikeObject = "seconds"): string {
    // Better Duration.toHuman support https://github.com/moment/luxon/issues/1134
    let duration: DurationObjectUnits;
    if (minDuration == "seconds") {
      duration = durationInput.shiftTo("days", "hours", "minutes", "seconds").toObject();
      if ("seconds" in duration && duration.seconds) {
        duration.seconds = Math.round(duration.seconds);
      }
    } else {
      duration = durationInput.shiftTo("days", "hours", "minutes").toObject();
      if ("minutes" in duration && duration.minutes) {
        duration.minutes = Math.round(duration.minutes);
      }
    }

    const cleanedDuration = Object.fromEntries(
      Object.entries(duration).filter(([_k, v]) => v !== 0),
    ) as DurationObjectUnits;

    if (Object.keys(cleanedDuration).length === 0) {
      cleanedDuration.seconds = 0;
    }
    return Duration.fromObject(cleanedDuration).toHuman();
  }

  export function getDateInterval(from: DateTime, to: DateTime): Interval {
    return from.startOf("day").until(to.endOf("day"));
  }

  export function getBetween(start: DateTime, end: DateTime, unit: DateTimeUnit = "day"): DateTime[] {
    const result = [];

    const offset = getDuration(1, unit);
    let dt = start.startOf(unit);
    while (!(dt > end)) {
      result.push(dt);
      dt = dt.plus(offset);
    }

    return result;
  }

  export function getDaysBetween(start: DateTime, end: DateTime): DateTime[] {
    return getBetween(start, end, "day");
  }

  /**
   * This function converts a valid utc date to a local date for de-DE DD.MM.YYYY
   * @param date
   */
  export function convertUtcToDate(date?: DateTime): string {
    if (date === undefined) return "";
    return date.toLocaleString(formatOptsDe);
  }

  /**
   * This functions converts a valid utc date to a local date and time for de-DE DD.MM.YYYY HH:MM:ss
   * @param date expects a valid utc date
   * @param formatted Should the time be in <small>?
   */
  export function convertUtcToDateTime(date: DateTime, formatted = false, timeFormat: "LTS" | "LT" = "LTS"): string {
    const dateFmt = date.toLocaleString(formatOptsDe);
    const timeFmt = date.toLocaleString(timeFormat === "LT" ? DateTime.TIME_SIMPLE : DateTime.TIME_WITH_SECONDS);
    if (formatted) {
      return `${dateFmt} <small>${timeFmt}</small>`;
    } else {
      return `${dateFmt} ${timeFmt}`;
    }
  }

  /**
   * This function only shows the date without the year
   */
  export function convertUtcToDateOnly(date?: DateTime): string {
    if (date === undefined) return "";
    return date.toLocaleString({ day: "2-digit", month: "2-digit" });
  }

  /**
   * This function can be used for special formats
   */
  export function convertUtcToDateFormattedSpecial(
    date?: DateTime,
    format: Omit<Intl.DateTimeFormatOptions, "DATE_SHORT"> = DateTime.DATETIME_MED,
  ): string {
    if (date === undefined) return "";
    return date.toLocaleString(format);
  }

  /**
   * Converts a date to the same of the current year
   * @param date 1985-11-26
   * @returns CurrentYear-11-26
   */
  export function transformDateToCurrentYear(date: DateTime): DateTime {
    const currentYear = DateTime.now().year;

    const obj = date.toObject();

    return DateTime.fromObject(
      {
        ...obj,
        year: currentYear,
      },
      { zone: "utc" },
    );
  }

  export function convertUtcToTime(date: DateTime, isMilitarytimeFormat: boolean): string {
    const fmt = isMilitarytimeFormat ? DateTime.TIME_SIMPLE : DateTime.TIME_24_SIMPLE;
    return date.toLocaleString(fmt);
  }

  /**
   * This function is used for the migration from the legacy api to the new nswag api
   * @param date can either be DateTime or string (as ISO string)
   */
  export function convertUtcToTimeFromUnknown(date: DateTime | Date | string): string {
    if (date instanceof DateTime) {
      return convertUtcToDate(date);
    } else if (date instanceof Date) {
      return convertUtcToDate(DateTime.fromJSDate(date));
    } else {
      return convertUtcToDate(DateTime.fromISO(date));
    }
  }

  /**
   * This function is used for the migration from the legacy api to the new nswag api
   * @param date can either be DateTime or string (as ISO string)
   */
  export function convertFromUnknownToDateTime(date: DateTime | Date | string): DateTime {
    if (date instanceof DateTime) {
      return date;
    } else if (date instanceof Date) {
      return DateTime.fromJSDate(date);
    } else {
      return DateTime.fromISO(date);
    }
  }

  export function convertFromUnknownToDate(date: DateTime | Date | string): Date {
    if (date instanceof DateTime) {
      return date.toJSDate();
    } else if (date instanceof Date) {
      return date;
    } else {
      return DateTime.fromISO(date).toJSDate();
    }
  }

  export function convertUtcToWeekday(date: DateTime, format: "dddd" | "dd" = "dddd"): string {
    const fmt = format === "dd" ? "ccc" : "cccc";
    return date.toFormat(fmt);
  }

  export function convertUtcFromNow(date: DateTime) {
    //TODO2 right format
    return date.toRelative();
  }

  export function addTimeSpan(date: DateTime, timeSpan: string) {
    if (StringHelpers.isNullOrWhitespace(timeSpan)) {
      throw Error("missing timeSpan");
    } else {
      const duration = Duration.fromISO(timeSpan);
      return date.plus(duration);
    }
  }

  export function conditionallyReturnFromNowOrDateTime(date: DateTime) {
    const moreThanOneDayAgo = date.diffNow().get("days") > 1;
    return moreThanOneDayAgo ? convertUtcToDateTime(date) : convertUtcFromNow(date);
  }

  export function groupArrayByDate<T>(
    array: T[],
    groupByGetter: (x: T) => DateTime,
    groupBy: GroupByDateType = "day",
    removeYear?: boolean,
  ) {
    let fmt = "";
    if (groupBy === "day") {
      fmt = removeYear ? "MM-dd" : "yyyy-MM-Ddd";
    } else {
      fmt = removeYear ? "MM" : "yyyy-MM";
    }

    const groupedByDateData = countBy(array, (x) => {
      return groupByGetter(x).toFormat(fmt);
    });
    const dataGrouped = map(groupedByDateData, (value, key) => {
      return {
        key: key,
        value: value,
      };
    });

    return dataGrouped;
  }

  export function groupDateArrayByDate(array: DateTime[], groupBy: GroupByDateType, removeYear?: boolean) {
    return groupArrayByDate(array, (d) => d, groupBy, removeYear);
  }

  export function getLocalizedTimeSpanString(data: ITimeIntervalDTO) {
    if (!data.value) {
      return "Error incorrect interval. Please update this item.";
    }
    return i18next.t(`${data.type}WithCount`, { count: data.value });
  }

  export function getISODateForQuery(date: DateTime) {
    return date.toISODate();
  }

  /**This must be used for export. It will return an empty string if date is null */
  export function getISODateForExport(date?: DateTime) {
    return date?.toISODate().concat("Z") ?? "";
  }

  export function getReadableDuration(duration: Duration | string, unit: TimeInterval = TimeInterval.Hour) {
    if (typeof duration == "string") {
      const objectCreated = getReadableDurationAsObject(duration, unit);
      return i18next.t(objectCreated.key, { count: objectCreated.count });
    }
    const durationNumber = getDurationAsNumber(duration, unit);
    const rounded = Math.round(durationNumber * 100) / 100;
    switch (unit) {
      case TimeInterval.Day:
        return i18next.t("DayWithCount", { count: rounded });
      case TimeInterval.Hour:
        return i18next.t("HourWithCount", { count: rounded });
      case TimeInterval.Minute:
        return i18next.t("MinuteWithCount", { count: rounded });
      default:
        throw Error("Not implemented exception");
    }
  }

  export function getReadableDurationAsObject(
    durationAsString: string,
    unit: TimeInterval = TimeInterval.Hour,
  ): { key: string; count: number } {
    const duration = getDurationFromAspDuration(durationAsString);
    const durationNumber = getDurationAsNumber(duration, unit);
    const rounded = Math.round(durationNumber * 100) / 100;
    switch (unit) {
      case TimeInterval.Day:
        return { key: "DayWithCount", count: rounded };
      case TimeInterval.Hour:
        return { key: "HourWithCount", count: rounded };
      case TimeInterval.Minute:
        return { key: "MinuteWithCount", count: rounded };
      default:
        throw Error("Not implemented exception");
    }
  }

  export function getDurationAsNumberFromString(duration: string, unit: TimeInterval = TimeInterval.Hour) {
    return getDurationAsNumber(Duration.fromISO(duration), unit);
  }

  export function getDurationAsNumber(duration: Duration, unit: TimeInterval = TimeInterval.Hour) {
    switch (unit) {
      case TimeInterval.Day:
        return duration.as("days");
      case TimeInterval.Hour:
        return duration.as("hours");
      case TimeInterval.Minute:
        return duration.as("minutes");
      default:
        throw Error("Not implemented exception");
    }
  }

  export function getDurationFromAspDuration(durationAsString: string): Duration {
    let duration: Duration | undefined;
    if (durationAsString.includes(".")) {
      // Here we expect d.HH.MM.SS
      const dayAndTime = durationAsString.split(".");

      duration = Duration.fromISOTime(dayAndTime[1]);
      duration = duration.plus({ days: Number(dayAndTime[0]) });
    } else {
      duration = Duration.fromISOTime(durationAsString);
    }
    return duration;
  }

  export function checkDateIsBetweenRange(
    date: DateTime,
    maxRange: number,
    minRange = 0,
    unitOfTime: keyof DurationLikeObject = "days",
  ) {
    const curYrDate = transformDateToCurrentYear(date);
    const diffObj = curYrDate.diffNow();
    const diff = diffObj.as(unitOfTime);
    return minRange <= diff && diff <= maxRange;
  }

  export function inPast(date: DateTime) {
    return date.diffNow().valueOf() < 0;
  }

  export function inFuture(date: DateTime) {
    return date.diffNow().valueOf() > 0;
  }

  export function isWeekend(date: DateTime) {
    switch (date.weekday) {
      case 6:
      case 7:
        return true;
      default:
        return false;
    }
  }

  export function containsDate(day: DateTime, arr: DateTime[]) {
    return arr.find((d) => isSameDate(day, d)) !== undefined;
  }

  export function containsDay(day: DateTime, arr: DateTime[]) {
    return arr.find((d) => isSameDayMonth(day, d)) !== undefined;
  }

  export function isSameDate(a: DateTime, b: DateTime) {
    return a.startOf("day").equals(b.startOf("day"));
  }

  export function isSameDayMonth(a: DateTime, b: DateTime) {
    const na = a.startOf("day");
    const nb = b.startOf("day");

    return na.month === nb.month && na.day === nb.day;
  }

  export function getBirthday(birthday: DateTime, year?: number): DateTime | undefined {
    const mapYear = year ?? DateTime.now().year;
    const day = DateTime.fromObject({
      year: mapYear,
      month: birthday.month,
      day: birthday.day,
    });

    return day.isValid ? day : undefined;
  }

  export function getAge(birthday: DateTime): number {
    return -birthday.diffNow("years").years;
  }

  export const EndDate = DateTime.fromISO("3000-01-01");

  export function intervalFromOpenBoundaries(from?: DateTime, to?: DateTime): Interval {
    const start = from ?? DateTime.fromMillis(0);
    const end = to ?? EndDate;

    return Interval.fromDateTimes(start, end);
  }

  export function getProcessingTime(items: { start: DateTime; end: DateTime }[]) {
    const totalProcessingTimeDur = items.reduce((pv, cv) => {
      return pv.plus(cv.end.diff(cv.start));
    }, Duration.fromMillis(0));
    return totalProcessingTimeDur;
  }

  export function getNextInspection(
    currentInspection: DateTime,
    monitoringInterval: ITimeIntervalDTO & { isRecurring: boolean },
  ) {
    if (!monitoringInterval.isRecurring) return undefined;
    if (!monitoringInterval.timeSpanISO) return undefined;
    return currentInspection.plus(Duration.fromISO(monitoringInterval.timeSpanISO));
  }

  export function getWarningTimeNextInspection(
    currentInspection: DateTime,
    monitoringInterval: ITimeIntervalDTO & { isRecurring: boolean },
    warningIntervalInterval: ITimeIntervalDTO & { useWarningTime: boolean },
  ) {
    const nextInspection = getNextInspection(currentInspection, monitoringInterval);
    if (!nextInspection) return undefined;
    if (!warningIntervalInterval.useWarningTime) return undefined;
    if (!warningIntervalInterval.timeSpanISO) return undefined;
    return nextInspection.minus(Duration.fromISO(warningIntervalInterval.timeSpanISO));
  }
}

export function qmDate(date: DateTime | Date): DateTime {
  return DateTimeLuxonHelpers.convertFromUnknownToDateTime(date);
}
