type LocalType = Record<string, unknown>;

/**This trims all whitespaces from the input
 * input: values["description"] = "       "
 * output: values["description"] = """
 */
export function trimWhiteSpaces<T extends LocalType>(values: T) {
  for (const [key, value] of Object.entries(values)) {
    if (typeof value == "string") {
      //@ts-expect-error TODO: Fix with better typings
      values[key] = value.trim();
    }
  }
}
