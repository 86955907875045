import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { AccountRoutePaths } from "../accountRoutePaths";
import CardFooterAccount from "../components/cardFooterAccount";
import LoginWith2FaForm from "../components/loginWith2FaForm";

function LoginWith2FaPage() {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams({
    returnUrl: withDefault(StringParam, undefined),
  });
  return (
    <Card className="mb-3">
      <Card.Body>
        <h1>{t("Login with 2 FA authentication")}</h1>
        <hr />
        <p>{t("Your login is protected with an authenticator app. Enter your authenticator code below")}</p>
        <LoginWith2FaForm />
        <p>
          {t("Don't have access to your authenticator device?")}{" "}
          <Link to={`${AccountRoutePaths.LoginWithRecoveryCodePage}?returnUrl=${query.returnUrl}`}>
            {t("You can log in with a recovery code")}
          </Link>
        </p>
        <CardFooterAccount />
      </Card.Body>
    </Card>
  );
}

export default LoginWith2FaPage;
