import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { CategoryCreateDTO, ICategoryCreateDTO, ICategoryDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { useRefreshHook } from "@/hooks/useRefreshHook";
import { ICategoryEntityTemplate } from "../../../../Models/EntityTemplates/CategoryEntityTemplate";
import EntityTemplatesButton from "../../../shared/components/entityTemplates/entityTemplatesButton";
import BaseSettingsForm, { hasId } from "../../components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

const EditPageEmployeeIdeaCategories: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { employeeIdeaCategoriesApi } = useAPIs();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<ICategoryDTO>();
  const navigate = useNavigate();
  const [{ refresh, handleRefresh }] = useRefreshHook();

  const { data: existingEmployeeIdeaCategories } = useSWR(`/api/employeeIdeaCategories`, () =>
    employeeIdeaCategoriesApi.getAll(),
  );

  React.useEffect(() => {
    async function getData() {
      if (id) {
        setLoading(true);
        const category = await employeeIdeaCategoriesApi.getEmployeeIdeaCategoryItem(Number(id));
        setItemToUpdate(category);
      }
      setLoading(false);
    }
    getData();
  }, [id, refresh]);

  async function handleSubmit(itemToCreate: ICategoryCreateDTO) {
    if (id) {
      return employeeIdeaCategoriesApi.edit(Number(id), new CategoryCreateDTO(itemToCreate));
    } else {
      return employeeIdeaCategoriesApi.create(new CategoryCreateDTO(itemToCreate));
    }
  }

  async function handleDelete() {
    if (!itemToUpdate) throw Error("Missing itemToUpdate");
    if (itemToUpdate.isDeleted) {
      await employeeIdeaCategoriesApi.restore(itemToUpdate.id);
    } else {
      await employeeIdeaCategoriesApi.delete(itemToUpdate.id);
    }
    handleRefresh();
  }

  const handleTemplateClick = (dto: ICategoryEntityTemplate) => {
    const itemToCreate: ICategoryCreateDTO = {
      title: dto.title,
      description: dto.description,
      color: dto.color ?? undefined,
      isRecurring: false,
    };
    return employeeIdeaCategoriesApi.create(new CategoryCreateDTO(itemToCreate));
  };

  const handleSuccess = (dto?: unknown) => {
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageEmployeeIdeaCategories.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };

  const entityTemplateButton = (
    <EntityTemplatesButton onSelect={handleTemplateClick} templateName={"ideaCategory"} onSuccess={handleSuccess} />
  );

  return (
    <React.Fragment>
      {!loading && (
        <BaseSettingsForm
          itemToUpdate={itemToUpdate}
          onCancel={() => navigate(SettingsRoutePaths.IndexPageEmployeeIdeaCategories)}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          additionalFields={["Color"]}
          onDelete={handleDelete}
          onRestore={(tId) => employeeIdeaCategoriesApi.restore(tId)}
          handleRefresh={handleRefresh}
          entityTemplateComponent={entityTemplateButton}
          existingItems={existingEmployeeIdeaCategories}
          formType={"EmployeeIdeaCategory"}
        />
      )}
    </React.Fragment>
  );
};

export default EditPageEmployeeIdeaCategories;
