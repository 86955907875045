import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { Column } from "react-table";

import { IEntityTemplateDTO, PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";
import { AdminRoutePaths } from "../adminRoutePaths";

interface IProps extends BaseTableProps<IEntityTemplateDTO> {
  module: PbdModule;
}

function TemplateTable(props: IProps) {
  const { columnsVisibleDefault = ["title"], module } = props;

  const columns = React.useMemo<Column<IEntityTemplateDTO>[]>(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id",
        hide: true,
        Cell: ({ row }) => (
          <Link to={AdminRoutePaths.EditPageTemplates.replace(":pbdModule", module).replace(":id", row.original.id)}>
            #{row.original.id}
          </Link>
        ),
      },
      {
        id: "title",
        Header: <>{i18next.t("Title")}</>,
        accessor: "title",
        Cell: ({ row }) => (
          <>
            <Link to={AdminRoutePaths.EditPageTemplates.replace(":pbdModule", module).replace(":id", row.original.id)}>
              {row.original.title}
            </Link>
          </>
        ),
      },
    ],
    [],
  );

  return (
    <ReactstrapTable<IEntityTemplateDTO>
      columns={columns}
      columnsVisibleDefault={columnsVisibleDefault}
      localStorageStateKey={window.location.pathname}
      {...props}
    />
  );
}

export default TemplateTable;
