import { DateTime } from "luxon";
import React from "react";
import useSWRImmutable from "swr/immutable";

import { useAppContext } from "@/components/contexts/appContext";
import AdminTokenStore from "../pbdServices/services/Authz/adminTokenStore";
import JsonWebTokenHandler from "../pbdServices/services/Authz/jsonWebTokenHandler";
import { useAPIServices, useAPIs } from "../services/serviceContext";

export interface JwtHookResponse {
  loginExpiration?: DateTime;
  isLoggedIn?: boolean;
  customerKey?: string;
  errorMessage?: string;
}

export const useJwtExternalHook = (): JwtHookResponse => {
  const { billingProps } = useAppContext();
  const { appSettingsApi } = useAPIs();
  const { settingsService } = useAPIServices();
  const { data: serverProps, mutate } = useSWRImmutable("/serverSettings", async () => {
    const resp = await appSettingsApi.getServerSettings();
    if (resp.tokens.jwt) {
      AdminTokenStore.setToken(resp.tokens.jwt);
    }
    return resp;
  });

  const jwtVal = React.useMemo((): JwtHookResponse => {
    if (serverProps?.tokens.jwt) {
      const expiration = JsonWebTokenHandler.getExpirationDate(serverProps.tokens.jwt);
      return {
        isLoggedIn: (expiration && expiration > DateTime.now()) ?? false,
        loginExpiration: expiration,
        customerKey: serverProps.tokens.customerKey,
      };
    }
    return { errorMessage: serverProps?.tokens.jwtErrorMessage };
  }, [serverProps?.tokens.jwt]);

  React.useMemo(() => {
    if (billingProps && serverProps) {
      settingsService.setCustomerId(billingProps.customerId);
      settingsService.setCustomerKey(serverProps.tokens.customerKey);
    }
  }, [billingProps, serverProps]);

  return jwtVal;
};
