import React from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import { useAppContext } from "@/components/contexts/appContext";
import UrlService from "../../../services/Urls/urlService";

interface IProps {
  url: string;
}

function QrCodeContainer(props: IProps) {
  const { productConfig } = useAppContext();
  const { t } = useTranslation();
  const { url } = props;
  return (
    <React.Fragment>
      <div className="text-center">
        <h6>{t("Learn more here")}</h6>
        <div>
          <QRCode value={UrlService.getAbsoluteUrl(url)} width={"100%"} />
        </div>
        {t("Printed via")} {productConfig.productDisplayName}
      </div>
    </React.Fragment>
  );
}
export default QrCodeContainer;
