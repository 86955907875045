import React from "react";

import { PrintOptions } from "../../ClientApp/prints/components/printoutIncludeOptions";

export interface PrintContextInterface {
  options: PrintOptions;
}

export function createCtx<PrintContextInterface>() {
  const ctx = React.createContext<PrintContextInterface | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (!c) throw new Error("PrintContextInterface must be inside a Provider with a value");
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

const [usePrintContext, CtxProvider] = createCtx<PrintContextInterface>();

interface Props {
  options: PrintOptions;
  children: React.ReactNode;
}

const PrintContextProvider = ({ children, options }: Props) => {
  return <CtxProvider value={{ options }}>{children}</CtxProvider>;
};

export { PrintContextProvider, usePrintContext };
