import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { requiredStringSchema } from "../../../../services/validation/stringSchemas";
import CancelSubmitFormGroup from "../buttons/cancelSubmitFormGroup";
import { FormikTextInputGroup } from "../forms/formik/formikTextInput";

export interface CommentForm {
  comment: string;
}

const getValidation = (t: TFunction) => {
  const schema: yup.ObjectSchema<CommentForm> = yup.object({
    comment: requiredStringSchema(t),
  });
  return schema;
};

interface IProps {
  onSubmit: (values: CommentForm) => Promise<void>;
  onCancel?: () => void;
  itemToUpdate?: { content?: string };
}

function AddCommentForm(props: IProps) {
  const { onSubmit, itemToUpdate, onCancel } = props;
  const { t } = useTranslation();
  const submitter = useFormikAPISubmitter<CommentForm>((val) => onSubmit(val), [onSubmit]);

  const initialValues: CommentForm = { comment: itemToUpdate?.content ?? "" };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitter}
      validationSchema={getValidation(t)}
      // Make sure validation is only triggered if user tries to submit
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikBag) => (
        <Form>
          <FormikTextInputGroup textarea name="comment" placeholder={`${t("Leave a comment")}...`} hideLabel rows={5} />
          <CancelSubmitFormGroup formikBag={formikBag} onCancel={onCancel} />
        </Form>
      )}
    </Formik>
  );
}

export default AddCommentForm;
