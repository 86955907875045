import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { CellProps, Column } from "react-table";

import { TenantConnectedToCompanyFunction } from "../../../Models/Tenants/TenantConnectedToCompanyFunction";
import { EmployeeRoutePaths } from "../../employees/employeeRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { ProgressBar } from "../../shared/components/progress/progressBar";
import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { getTagColumn } from "../../shared/components/tables/columns/tagsColumn";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";
import AvatarSpanWithName from "../../shared/components/tenants/avatarSpanWithName";

type IProps = BaseTableProps<TenantConnectedToCompanyFunction>;

export const TenantConnectedToCompanyFunctionTable: React.FC<IProps> = (props) => {
  const {
    columnsVisibleDefault = ["fullName", "department", "fitness"],
    titleColumnBaseLink = EmployeeRoutePaths.EditPage,
    localStorageStateKey = "TenantDTO",
  } = props;

  const columns = React.useMemo<Column<TenantConnectedToCompanyFunction>[]>(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id", // accessor is the "key" in the data
        Cell: ({ row }) => (
          <>
            <Link to={titleColumnBaseLink.replace(":id", row.original.id.toString())}>#{row.original.id}</Link>
          </>
        ),
      },
      {
        id: "fullName",
        Header: <>{i18next.t("Name")}</>,
        accessor: (x) => x.fullName,
        Cell: ({ row }: CellProps<TenantConnectedToCompanyFunction>) => (
          <AvatarSpanWithName
            linkTo={titleColumnBaseLink.replace(":id", row.original.id.toString())}
            tenant={row.original}
          />
        ),
      },
      getTagColumn(),
      {
        id: "department",
        Header: <>{i18next.t("Department")}</>,
        accessor: (x) => x.primaryDepartmentPosition?.department.title,
      },
      {
        id: "position",
        Header: <>{i18next.t("Position")}</>,
        accessor: (x) => x.primaryDepartmentPosition?.title,
      },
      {
        id: "fitness",
        Header: <>{i18next.t("Fitness")}</>,
        accessor: (x) => x.fitness,
        sortType: "basic",
        Cell: ({ row }: CellProps<TenantConnectedToCompanyFunction>) => (
          <div className="d-flex">
            <div className="flex-grow-1">
              <ProgressBar currentValue={row.original.fitness ?? 0} maxValue={1} />
            </div>
            {row.original.isManuallyConnected && (
              <qmBaseIcons.HandLizard
                className="ms-2"
                style={{ color: "green" }}
                title={i18next.t("Manually connected")}
              />
            )}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <ReactstrapTable<TenantConnectedToCompanyFunction>
        columns={columns}
        columnsVisibleDefault={columnsVisibleDefault}
        localStorageStateKey={localStorageStateKey}
        {...props}
      />
    </React.Fragment>
  );
};
