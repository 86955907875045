import React from "react";
import { MultiValue, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";

import { CategoryDTO, ICategoryDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../services/serviceContext";

import { getLabel, getValue } from "./reactSelectHelpers";

interface IProps {
  onChange: (id?: ICategoryDTO | ICategoryDTO[]) => void;
  selectedIds?: number[];
  isRequired?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
}

const loadOptions = async (inputValue: string, query: (qs: { q: string }) => Promise<ICategoryDTO[]>) => {
  return query({ q: inputValue });
};

const OpportunityCategorySelect: React.FC<IProps> = (props) => {
  const { onChange } = props;
  const { opportunityCategoriesApi } = useAPIs();

  function handleChange(newValue: MultiValue<ICategoryDTO> | SingleValue<ICategoryDTO>) {
    if (newValue == null) {
      onChange(undefined);
    } else {
      const selected = newValue as ICategoryDTO | ICategoryDTO[];
      if (Array.isArray(selected)) {
        onChange(selected);
      } else {
        const cat = new CategoryDTO();
        cat.title = selected.title;
        cat.id = selected.id;
        onChange(cat);
      }
    }
  }

  return (
    <AsyncSelect<ICategoryDTO>
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
      })}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPosition="fixed"
      cacheOptions
      defaultOptions
      onChange={handleChange}
      getOptionLabel={getLabel}
      getOptionValue={(x) => getValue(x)}
      loadOptions={(val, cb) => loadOptions(val, (qs) => opportunityCategoriesApi.getAllQuery(qs))}
    />
  );
};

export default OpportunityCategorySelect;
