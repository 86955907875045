import React from "react";
import { CardText } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { IArticleDTO, PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { useAPIs } from "../../../services/serviceContext";
import { ArticleRoutePaths } from "../../articles/articleRoutePaths";

function NotFoundContent() {
  const location = useLocation();
  const { t } = useTranslation("errorCodes");
  const { articlesApi } = useAPIs();
  const [articles, setArticles] = React.useState<IArticleDTO[]>([]);
  React.useEffect(() => {
    async function getData() {
      if (location.pathname.includes(PbdModule.DocumentManagement)) {
        // check for document
        const qs = location.search.split("slugId=");
        const slugId = qs.length > 0 ? qs[1] : qs[0];

        const data = await articlesApi.getAllQuery({
          module: PbdModule.DocumentManagement,
          take: 3,
          // searchString: slugId,
          slugId: slugId,
        });
        setArticles(data);
      }
    }
    getData();
  }, [location, location.pathname]);

  if (articles.length == 0) return null;

  return (
    <>
      <CardText>{t("Is there any chance you were looking for one these?")}</CardText>
      <ul>
        {articles.map((x) => (
          <li key={x.id}>
            <Link to={ArticleRoutePaths.DetailsPage(x.module).replace(":id", x.id.toString())}>{x.title}</Link>
          </li>
        ))}
      </ul>
    </>
  );
}

export default NotFoundContent;
