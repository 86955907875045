import clsx from "clsx";
import React from "react";

export type IconColor = "primary" | "secondary" | "warning" | "danger" | "info" | "dark" | "muted" | "success";
export type IconSize = "xxs" | "xs" | "sm" | "lg" | "xl" | "xxl";

function iconSizeToClass(iconSize: IconSize): string {
  switch (iconSize) {
    case "xxs":
      return "fa-2xs";
    case "xxl":
      return "fa-2xl";
    default:
      return `fa-${iconSize}`;
  }
}

function iconColorToClass(color: IconColor) {
  return `text-${color}`;
}

interface IconProps {
  id?: string;
  className?: string;
  iconClassName?: string;
  size?: IconSize;
  color?: IconColor;
  title?: string;
  style?: React.CSSProperties;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function BaseIcon(props: React.PropsWithChildren<IconProps>) {
  const { className, iconClassName, size, color, title, style, id } = props;

  const pClassName = React.useMemo(() => {
    const sizeClass = size ? iconSizeToClass(size) : undefined;
    const colorClass = color ? iconColorToClass(color) : undefined;

    return clsx(iconClassName, sizeClass, colorClass, className);
  }, [className, color, iconClassName, size]);

  return (
    <i className={pClassName} title={title} style={style} id={id}>
      {props.children}
    </i>
  );
}

export type IconComponent = React.FC<React.PropsWithChildren<IconProps>>;

export function createQmIcon(iconClassName: string, color?: IconColor): IconComponent {
  return (props: React.PropsWithChildren<IconProps>) =>
    BaseIcon({ iconClassName, color: color ?? props.color, ...props });
}
