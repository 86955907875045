import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../../icons/qmBaseIcons";

import ActiveFilterBadge from "./activeFilterBadge";
import { FilterKey, IFilterProps } from "./filterKey";

const excludedFilterProps = ["page", "pageSize", "sortBy", "orderBy"];

interface IProps extends IFilterProps {
  toggleFilter: () => void;
}

export function ActiveFilters(props: IProps) {
  const { toggleFilter, activeFilters, onDelete } = props;
  const { t } = useTranslation();

  if (!activeFilters || Object.keys(activeFilters).length == 0) return null;

  const tagSeparator = activeFilters.allTags ? t(" and ") : t(", ");

  const populatedActiveFilters = Object.entries(activeFilters).filter(
    (x) => x[1] != undefined && x[1] != null && !excludedFilterProps.includes(x[0]),
  );

  if (populatedActiveFilters.length == 0) return null;

  return (
    <>
      <small className="text-body-secondary mb-1">
        <qmBaseIcons.Filter /> {t("Some filters are already applied")}
      </small>
      <div className="d-flex gap-2">
        {populatedActiveFilters.map(([k, v]) => (
          <ActiveFilterBadge
            key={k}
            // TODO: Typescript is not good in inferring object properties. Might be worth checking in the future again https://github.com/microsoft/TypeScript/issues/35101 2023-05-20 PP
            filterKey={k as FilterKey}
            onClose={onDelete}
            values={v}
            separator={tagSeparator}
          />
        ))}
        <Button variant="link" size="sm" onClick={toggleFilter}>
          {t("Change")}
        </Button>
      </div>
    </>
  );
}
