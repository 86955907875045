import { SWRConfiguration } from "swr";

import { ApiException } from "@/generatedCode/pbd-core/pbd-core-api";

import { paths } from "./paths";

export const swrConfig: SWRConfiguration = {
  loadingTimeout: 15000,
  errorRetryCount: 3,
  onLoadingSlow: (key) => {
    console.log(`SWR Loading slow Key: ${key}`);
  },
  onError: (err, key) => {
    console.log(`SWR Error Key: ${key} ${JSON.stringify(err)}`);

    if (err && err instanceof ApiException) {
      if (err.status == 401) {
        window.location.href = `${window.location.pathname}&expired=1`;
      }
      if (err.status == 429) {
        window.location.href = paths.error.getHref("429");
      }
    }
  },
  onErrorRetry(err) {
    if (err && err instanceof ApiException) {
      // Never retry on 404.
      if (err.status === 404) return;
      // Never retry on 401
      if (err.status == 401) return;
    }
  },
};
