import {
  EightDReportStep,
  IDefectDTO,
  IEightDReportEditDTO,
  ITenantDTO,
  IToDoDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { EightDReportTeamMemberDTO } from "../../../Models/EightDReports/EightDReportTeamMemberDTO";
import { TenantsMockData } from "../../../pbdServices/__tests__/config/mockData/tenantMockData";
import { ListHelpers } from "../../../utils/listHelpers";

export const get8dProgress = (data: IEightDReportEditDTO) => {
  const dimensions = 8;
  let currentValue = 0;
  if (data.isTeamReady) currentValue++;
  if (data.isProblemReady) currentValue++;
  if (data.isImmediateActionReady) currentValue++;
  if (data.isRootCauseReady) currentValue++;
  if (data.isCorrectiveActionsReady) currentValue++;
  if (data.isVerificationAndResultReady) currentValue++;
  if (data.isPreventiveActionsReady) currentValue++;
  if (data.isCongratulationsReady) currentValue++;
  if (!data.isDone) {
    currentValue = currentValue - 0.1;
  }
  return currentValue / dimensions;
};

export const isDimensionReady = (report: IEightDReportEditDTO, actionType: EightDReportStep) => {
  let isReady = false;
  switch (actionType) {
    case EightDReportStep.Team: {
      isReady = report.isTeamReady;
      break;
    }
    case EightDReportStep.Problem: {
      isReady = report.isProblemReady;
      break;
    }
    case EightDReportStep.ImmediateActions: {
      isReady = report.isImmediateActionReady;
      break;
    }
    case EightDReportStep.RootCauses: {
      isReady = report.isRootCauseReady;
      break;
    }
    case EightDReportStep.CorrectiveActions: {
      isReady = report.isCorrectiveActionsReady;
      break;
    }
    case EightDReportStep.VerificationAndResult: {
      isReady = report.isVerificationAndResultReady;
      break;
    }
    case EightDReportStep.PreventiveActions: {
      isReady = report.isPreventiveActionsReady;
      break;
    }
    case EightDReportStep.Congratulations: {
      isReady = report.isCongratulationsReady;
      break;
    }
    default: {
      throw new Error("type not found");
    }
  }
  return isReady;
};

export const getContributors = (
  data: IEightDReportEditDTO,
  todos: IToDoDTO[],
  defect: IDefectDTO[],
  tenants: ITenantDTO[],
) => {
  let contributors: EightDReportTeamMemberDTO[] = [];

  const groupedContributors = getGroupedContributorsFromReport(data, todos, defect, tenants);
  contributors = contributors.concat(groupedContributors);
  //@ts-expect-error TODO: Fix with better typings
  const notGroupedContributors = getNotGroupedContributorsFromReport(contributors, data.teamMembers);
  contributors = contributors.concat(notGroupedContributors);

  return contributors;
};

const getNotGroupedContributorsFromReport = (
  grouped: EightDReportTeamMemberDTO[],
  teamMembers: EightDReportTeamMemberDTO[],
) => {
  const employeesWithoutContributors: EightDReportTeamMemberDTO[] = [];
  teamMembers.map((r) => {
    const indexId = grouped.findIndex((x) => x.tenant.id == r.tenant.id);
    if (indexId == -1) {
      employeesWithoutContributors.push({
        id: r.id,
        tenant: r.tenant,
        numberOfMentions: 0,
        isMember: true,
        isTeamLeader: r.isTeamLeader,
      });
    }
  });
  return employeesWithoutContributors;
};

const getGroupedContributorsFromReport = (
  data: IEightDReportEditDTO,
  todos: IToDoDTO[],
  defect: IDefectDTO[],
  tenants: ITenantDTO[],
) => {
  const contributors: EightDReportTeamMemberDTO[] = [];
  const todoResp = todos.filterMap((x) => x.responsible);
  const defectResp = defect.filterMap((x) => x.responsible);
  const persons = todoResp.concat(...defectResp);
  if (data.claim?.responsible) {
    persons.push(data.claim.responsible);
  }
  const grouped = ListHelpers.groupAndSortArrayById(persons);
  for (const element of grouped) {
    const existingTeamMember = data.teamMembers?.find((x) => x.tenantId == element.key);
    const tenant = tenants.find((x) => x.id == element.key);
    contributors.push({
      id: element.entity.id,
      tenant: tenant ?? TenantsMockData.getDeletedPlaceholder(element.key),
      numberOfMentions: element.count,
      isMember: existingTeamMember ? true : false,
      isTeamLeader: existingTeamMember?.isTeamLeader ?? false,
    });
  }

  return contributors;
};
