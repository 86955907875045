import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Label } from "reactstrap";
import * as yup from "yup";

import {
  CustomFieldApiConfiguration,
  CustomFieldApiConfigurationType,
  CustomFieldType,
  ICustomField,
  ICustomFieldApiConfiguration,
  PbdModule,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { useAppContext } from "@/components/contexts/appContext";
import { CustomFieldApiObjectName } from "../../../../Models/CustomFields/CustomField";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { useAPIServices, useAPIs } from "../../../../services/serviceContext";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import { nameofFactory } from "../../../../utils/nameofFactory";
import RequiredRolesComponent from "../../../admin/roles/components/requiredRolesComponent";
import AppUnderDevelopmentAlert from "../../../shared/components/alerts/appUnderDevelopmentAlert";
import FormikCustomForm from "../../../shared/components/forms/formik/formikCustomForm";
import FormikDebugInfo from "../../../shared/components/forms/formik/formikDebugInfo";
import { FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";

const ValidationSchema: yup.ObjectSchema<ICustomFieldApiConfiguration> = yup.object({
  type: yup.mixed<CustomFieldApiConfigurationType>().oneOf(Object.values(CustomFieldApiConfigurationType)).required(),
  description: yup.string(),
  apiUrl: yup.string().required().min(2).max(250),
  objectName: yup.mixed<CustomFieldApiObjectName>().oneOf(Object.values(CustomFieldApiObjectName)),
  mappingConfiguration: yup.string().required().min(2).max(250),
});

interface IProps {
  itemToUpdate: ICustomField;
  module: PbdModule;
}

const nameof = nameofFactory<ICustomFieldApiConfiguration>();

const CustomFieldApiConfigCard: React.FC<IProps> = (props) => {
  const { itemToUpdate, module } = props;
  const { t } = useTranslation();
  const { customFieldsApi } = useAPIs();
  const { customFieldService } = useAPIServices();
  const { meAsUser } = useAppContext();

  const submitter = useFormikAPISubmitter<ICustomFieldApiConfiguration>(
    (values) => customFieldsApi.editApiConfiguration(module, itemToUpdate.id, new CustomFieldApiConfiguration(values)),
    [customFieldsApi, itemToUpdate.id, module],
    () => {
      toast.success(t("Saved"));
    },
  );

  if (itemToUpdate.type != CustomFieldType.Api) return null;

  const initialValues: ICustomFieldApiConfiguration = {
    type: itemToUpdate.apiConfig?.type ?? CustomFieldApiConfigurationType.Custom,
    apiUrl: itemToUpdate.apiConfig?.apiUrl ?? "",
    objectName: itemToUpdate.apiConfig?.objectName ?? CustomFieldApiObjectName.Custom,
    mappingConfiguration: itemToUpdate.apiConfig?.mappingConfiguration ?? "",
  };

  return (
    <Card>
      <CardHeader>
        <div className="d-flex justify-content-between">
          <CardTitle tag="h5">{t("Api field configuration")}</CardTitle>
          <RequiredRolesComponent roles={[PbdRoles.Admin]} />
        </div>
      </CardHeader>
      <CardBody>
        <AppUnderDevelopmentAlert />
        <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
          {(formikBag) => (
            <FormikCustomForm formikBag={formikBag}>
              <FormGroup>
                <Label>{t("Predefined fields")}</Label>
                <div>
                  {Object.entries(customFieldService.apiFieldConfigs).map(([key, config]) => (
                    <Button
                      key={key}
                      className="me-1"
                      active={formikBag.values.type == config.type}
                      outline={formikBag.values.type != config.type}
                      onClick={() => formikBag.setValues(config)}
                    >
                      {t(config.type)}
                    </Button>
                  ))}
                </div>
              </FormGroup>

              {hasRole(meAsUser, [PbdRoles.Dev]) && (
                <div>
                  <FormikDebugInfo formikBag={formikBag} />
                  <FormikTextInputGroup label={t("ID")} name={nameof("type")} />
                  <FormikTextInputGroup name="description" />
                  <FormikTextInputGroup label={t("Api url")} name={nameof("apiUrl")} />
                  <FormikTextInputGroup label={t("Object name")} name={nameof("objectName")} />
                  <FormikTextInputGroup label={t("Mapping configuration")} name={nameof("mappingConfiguration")} />
                </div>
              )}
            </FormikCustomForm>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default CustomFieldApiConfigCard;
