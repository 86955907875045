import React from "react";
import { useTranslation } from "react-i18next";

import { ExternalIdSettings } from "../../../../Models/Settings/ExternalIdSettings";

interface IProps {
  externalId?: string;
  externalIdSettings?: ExternalIdSettings;
}

const ExternalIdComponent: React.FC<IProps> = (props) => {
  const { externalId, externalIdSettings } = props;
  const { t } = useTranslation();
  if (externalIdSettings && !externalIdSettings.isUsed) {
    return <React.Fragment />;
  }
  return (
    <span>
      <b>{externalIdSettings?.displayName ?? t("External ID")}</b>: {externalId ?? t("Unset")}
    </span>
  );
};

export default ExternalIdComponent;
