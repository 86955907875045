import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";

import config from "../services/config";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: config.applicationInsightsConnectionString,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: ["help.qmbase.com"],
    samplingPercentage: 5,
  },
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.data = env.data || {};
  env.data["ms-appName"] = "PBD.Core.Frontend-react";
  // env.data["ms-user"] = "<frontend-auth-user>";
  // env.data["ms-userid"] = "<frontend-auth-userid>";
});

export { appInsights, reactPlugin };
