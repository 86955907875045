import React, { FocusEventHandler } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";

import { ReactSelectDTO } from "../../../../../pbdServices/Models/ReactSelect/reactSelectDTO";
import { CustomOption } from "./customOption";

interface SelectDTO<T extends number | string = number> {
  id: T;
  title: string;
}

interface IProps<T extends string | number> {
  key?: string;
  data: SelectDTO<T>[];
  isClearable?: boolean;
  isMulti?: boolean;
  onBlur?: FocusEventHandler;
  selectedIds?: T[];
  name: string;
  invalid?: boolean;
  onChange: (id?: T | T[]) => void;
  placeholder?: string;
}

function CustomSelect<T extends string | number = number>(props: IProps<T>) {
  const { t } = useTranslation();
  const { key, data, isClearable, isMulti, onBlur, selectedIds, name, invalid, onChange, placeholder } = props;

  const ids = key ?? props.selectedIds ? props.selectedIds : ["0"];

  const sortedData = React.useMemo(() => {
    return data.map((x) => new ReactSelectDTO(x)).sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  function handleChange(
    newValue: MultiValue<ReactSelectDTO<T> | undefined> | SingleValue<ReactSelectDTO<T> | undefined>,
  ) {
    if (newValue == undefined) {
      onChange(undefined);
    } else {
      if (Array.isArray(newValue)) {
        onChange((newValue as ReactSelectDTO<T>[]).map((x) => x.value));
      } else {
        const option = newValue as ReactSelectDTO<T>;
        onChange(option.value);
      }
    }
  }

  const defaultValue = selectedIds
    ? selectedIds
        .filter((x) => x != 0)
        .map((x) => sortedData.find((f) => f.value == x) ?? new ReactSelectDTO({ id: x, title: t("Deleted value") }))
    : undefined;

  return (
    <Select
      key={JSON.stringify(ids)}
      styles={{
        control: (base) => ({
          ...base,
          border: 0,
          // This line disables the blue border
          boxShadow: "none",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      menuPosition="fixed"
      options={sortedData}
      isSearchable
      isClearable={isClearable}
      onChange={handleChange}
      placeholder={placeholder ?? `${t("Search by name")}...`}
      name={name}
      inputId={name}
      isMulti={isMulti}
      onBlur={onBlur}
      defaultValue={defaultValue}
      className={invalid ? "form-control is-invalid p-0 pr-3" : "form-control p-0 pr-3"}
      // eslint-disable-next-line @typescript-eslint/naming-convention
      components={{ Option: CustomOption }}
    />
  );
}

export default CustomSelect;
