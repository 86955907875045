import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ITenantMinDTO, TenantsQueryField } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import { TenantServiceInMemory } from "../../../../pbdServices/services/Tenants/tenantServiceInMemory";
import GenericAlert from "../alerts/genericAlert";
import NotificationForm, { SendNotificationForm } from "./notificationForm";

interface IProps {
  titleI18n?: string;
  showModal: boolean;
  handleToggle: () => void;
  defaultValues?: SendNotificationForm & { footer?: React.ReactNode };
  onSubmit: (values: SendNotificationForm) => Promise<void>;
  onSuccess: () => void;
  children?: React.ReactNode;
}

function SendNotificationModal(props: IProps) {
  const { t } = useTranslation();
  const { titleI18n = "Send notification", onSubmit, handleToggle, defaultValues, showModal, onSuccess } = props;
  const [tenantsToNotify, setTenantsToNotify] = React.useState<ITenantMinDTO[]>();
  const { tenantsApi } = useAPIs();

  React.useEffect(() => {
    async function getData() {
      if (defaultValues?.toTenants && showModal) {
        const uniqueIds = TenantServiceInMemory.groupByOccurrence(defaultValues.toTenants).filterMap(
          (x) => x.tenant.id,
        );
        const resp = await tenantsApi.getAllQuery({
          id: uniqueIds,
          isApplicationUser: true,
          fields: [TenantsQueryField.ApplicationUser],
          pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
        });
        setTenantsToNotify(resp.data);
      }
    }
    getData();
  }, [defaultValues, showModal]);

  const showForm = showModal && (!defaultValues?.toTenants || tenantsToNotify);

  const tenantsWithoutAccount =
    defaultValues?.toTenants &&
    tenantsToNotify &&
    TenantServiceInMemory.groupByOccurrence(defaultValues.toTenants).filter(
      (x) => x.tenant && !tenantsToNotify.map((t) => t.id).includes(x.tenant.id),
    );

  return (
    <div>
      <Modal show={showModal} onHide={handleToggle} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t(titleI18n)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tenantsWithoutAccount && tenantsWithoutAccount.length > 0 && (
            <GenericAlert heading={t("Only users can be notified")} dismissible={false}>
              {t("The following persons can not be notified because they are not registered as users.")}
              <ul>
                {tenantsWithoutAccount.map((x) => (
                  <li key={x.tenant.id}>{x.tenant.fullName}</li>
                ))}
              </ul>
            </GenericAlert>
          )}
          {showForm && (
            <NotificationForm
              {...props}
              onSubmit={onSubmit}
              selected={tenantsToNotify}
              defaultValues={defaultValues}
              onSuccessfulSubmit={onSuccess}
            />
          )}
          {props.children}
          {defaultValues?.footer}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SendNotificationModal;
