import { isEqual } from "lodash";

import {
  ConnectAttendeeDTO,
  ConnectQualificationsDTO,
  ConnectToDosDTO,
  CostEditDTO,
  CustomFieldDTO,
  EntityKey,
  ICostDTO,
  ILinkedResource,
  IQualificationDTO,
  ITenantAsAttendee,
  IToDoDTO,
  ITrainingDTO,
  ITrainingEditDTO,
  ITrainingTypesControllerClient,
  ITrainingsControllerClient,
  LinkedResourceCreateDTO,
  PbdStatus,
  TrainingCreateDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import {
  IPrerequisitesReturnType,
  IPrerequisitesService,
  IPrerequisitesWrapper,
} from "../../../ClientApp/prerequisitesModals/prerequisitesModal";
import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import CostService, { WithCosts } from "../Costs/costService";
import ExportService from "../Export/exportService";
import ToDoService from "../ToDos/todoService";
import { MeAsUser } from "../UserSettings/models/me-as-user";
import ConnectionService from "../connectionService/connectionService";
import { CopyForm, CopyIncludedOption } from "../copy/copyService";

import { AcceptanceStatus } from "./models/acceptance-status";
import { TrainingKpis } from "./models/training-kpis";

export default class TrainingService implements IPrerequisitesService {
  #trainingsTypesApi: ITrainingTypesControllerClient;
  #trainingsApi: ITrainingsControllerClient;
  constructor(trainingsTypesApi: ITrainingTypesControllerClient, trainingsApi: ITrainingsControllerClient) {
    this.#trainingsTypesApi = trainingsTypesApi;
    this.#trainingsApi = trainingsApi;
  }
  async getAllPrerequisites(): Promise<IPrerequisitesWrapper> {
    const trainingsTypes = await this.#trainingsTypesApi.getAll();
    const checks: IPrerequisitesReturnType[] = [
      {
        id: "trainingsTypes",
        title: "Trainings Types",
        status: trainingsTypes.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
        route: SettingsRoutePaths.TrainingsManagementHome,
      },
    ];
    const resp: IPrerequisitesWrapper = {
      checks,
      actionRequired: checks.find((x) => x.status != PbdStatus.Completed) != undefined,
    };
    return resp;
  }

  exportToCsv(items: WithCosts<ITrainingDTO>[]) {
    ExportService.exportCSV("Training", items, (x) => ({
      id: x.id,
      title: x.title,
      status: x.status,
      category: x.category?.title,
      responsible: x.responsible?.fullName,
      createdAt: x.createdAt,
      plannedAt: x.plannedAt,
      costs: CostService.convertCostSumToString(x.costSums, true),
      costDetails: CostService.convertCostDetailsToString(x.costs, true),
      tags: x.tags?.map((x) => x.title).join(),
    }));
  }

  isDefaultQualifications(connectedQualifications: IQualificationDTO[], defaultQualifications: IQualificationDTO[]) {
    return isEqual(
      connectedQualifications.map((x) => x.id),
      defaultQualifications.map((x) => x.id),
    );
  }

  static getInitialValues(item: ITrainingDTO): ITrainingEditDTO {
    return {
      id: item.id,
      title: item.title,
      description: item.description ?? undefined,
      maximumNumberOfAttendees: item.maximumNumberOfAttendees,
      rateableByAttendee: item.rateableByAttendee,
      attendeesCanSelfRegister: item.attendeesCanSelfRegister,
      categoryId: item.category?.id ?? 0,
    };
  }

  static mapCosts(array: ITrainingDTO[], costs: ICostDTO[]) {
    return CostService.mapWithCosts(array, costs);
  }

  static mapAttendees<T extends { id: number }>(items: T[], attendees: ITenantAsAttendee[]) {
    return items.map((x) => {
      return {
        ...x,
        attendees: attendees.filter((a) => a.attendanceInfo.trainingId == x.id),
      };
    });
  }

  static getKpis(all: ITrainingDTO[], connectedTodos: IToDoDTO[], costs: ICostDTO[], totalUrl?: string) {
    const withCosts = CostService.mapWithCosts(all, costs);
    const kpis = new TrainingKpis(withCosts, totalUrl);
    kpis.connectedTodos = ToDoService.getKpis(connectedTodos);
    return kpis;
  }

  static canEditAttendees(meAsUser: MeAsUser, tenantAttendee: ITenantAsAttendee) {
    const meIsAuthorized =
      hasRole(meAsUser, [PbdRoles.Admin]) ||
      hasRole(meAsUser, [PbdRoles.Trainings_ModuleAdmin]) ||
      meAsUser.tenant.id == tenantAttendee.id ||
      meAsUser.tenant.id == tenantAttendee.attendanceInfo.training?.responsible?.id;

    return meIsAuthorized;
  }

  static disableChangeAttendeeStatus(
    meAsUser: MeAsUser,
    tenantAttendee: ITenantAsAttendee,
    isConfirmedOrAttended?: boolean,
  ) {
    const meIsAuthorized =
      hasRole(meAsUser, [PbdRoles.Admin]) ||
      hasRole(meAsUser, [PbdRoles.Trainings_ModuleAdmin]) ||
      meAsUser.tenant.id == tenantAttendee.id ||
      meAsUser.tenant.id == tenantAttendee.attendanceInfo.training?.responsible?.id;

    // If the I am not authorized, the button will be disabled.
    if (!meIsAuthorized) {
      return true;
    }

    // If the training attendee is confirmed or attended, the button will be disabled.
    return isConfirmedOrAttended ? true : false;
  }

  static getAttendanceStatus(
    training: ITrainingDTO,
    attendance: ITenantAsAttendee,
  ): {
    options: AcceptanceStatus[];
    label: string;
    responseRequired: boolean;
    otherOptions: AcceptanceStatus[];
    ratingRequired?: boolean;
    virtualStatus: PbdStatus;
  } {
    if (training.status == PbdStatus.Completed) {
      return {
        label: "Did you participate?",
        responseRequired: !attendance.attendanceInfo.attendanceConfirmedAt,
        options: [this.possibleAttendanceStatus().Attended, this.possibleAttendanceStatus().NotAttended],
        otherOptions: [this.possibleAttendanceStatus().Open],
        ratingRequired:
          attendance.attendanceInfo.status == PbdStatus.Attended &&
          training.rateableByAttendee &&
          attendance.attendanceInfo.ratedAt == undefined,
        virtualStatus: attendance.attendanceInfo.attendanceConfirmedAt
          ? attendance.attendanceInfo.status
          : PbdStatus.Open,
      };
    }
    return {
      label: "Do you accept this invitation?",
      responseRequired: !attendance.attendanceInfo.acceptedAt,
      options: [this.possibleAttendanceStatus().Accepted, this.possibleAttendanceStatus().NotAccepted],
      otherOptions: [this.possibleAttendanceStatus().Open],
      virtualStatus: attendance.attendanceInfo.status,
    };
  }

  static canTransferQualifications(
    meAsUser: MeAsUser,
    training: ITrainingDTO,
    qualifications: IQualificationDTO[],
    attendees: ITenantAsAttendee[],
  ): boolean {
    if (training.status != PbdStatus.Completed) return false;

    if (
      (training.responsible?.id == meAsUser.tenant.id ||
        hasRole(meAsUser, [PbdRoles.Admin, PbdRoles.Trainings_ModuleAdmin])) &&
      qualifications.length > 0 &&
      attendees.filter((x) => !x.attendanceInfo.isQualificationsTransferred).length > 0
    ) {
      return true;
    }
    return false;
  }

  static possibleAttendanceStatus(): AcceptanceStatusType {
    return {
      Open: {
        status: PbdStatus.Open,
        label: "Reset",
        color: "danger",
        outline: true,
        description: "Delete all infos about your attendance status",
      },
      Accepted: {
        status: PbdStatus.Accepted,
        label: "Yes",
        color: "success",
        description: "You plan to attend this event",
      },
      NotAccepted: {
        status: PbdStatus.NotAccepted,
        label: "No",
        outline: true,
        color: "danger",
        description: "You will not attend this event",
      },
      Attended: {
        status: PbdStatus.Attended,
        label: "Yes",
        color: "success",
        description: "You have attended this event",
      },
      NotAttended: {
        status: PbdStatus.NotAttended,
        label: "No",
        outline: true,
        color: "danger",
        description: "You have not attended this event",
      },
    };
  }

  async #copyQualifications(item: ITrainingDTO, copy: ITrainingDTO) {
    if (copy.qualifications && item.qualifications) {
      for (const qualification of copy.qualifications) {
        const q = item.qualifications.find((x) => x.id == qualification.id);
        if (!q) {
          await this.#trainingsApi.deleteQualification(copy.id, qualification.id);
        }
      }

      const excludedQualification = item.qualifications
        .filter((x) => !copy.qualifications?.find((y) => y.id == x.id))
        .map((x) => x.id);

      if (excludedQualification.length > 0) {
        const dto = new ConnectQualificationsDTO({
          qualificationIds: excludedQualification,
        });
        await this.#trainingsApi.addQualifications(copy.id, dto);
      }
    }
  }

  async copyAttendees(copy: ITrainingDTO, attendees: ITenantAsAttendee[]) {
    const attendeesIds = new ConnectAttendeeDTO({
      tenantIds: attendees.map((x) => x.id),
    });
    await this.#trainingsApi.addAttendee(copy.id, attendeesIds);
  }

  async copyTodos(itemId: number, copy: ITrainingDTO) {
    const todos = await this.#trainingsApi.getToDosByTrainingId(itemId);
    if (todos.length > 0) {
      const todoIds = new ConnectToDosDTO({
        toDoIds: todos.map((x) => x.id),
      });

      await this.#trainingsApi.addToDo(copy.id, todoIds);
    }
  }

  async copyCustomField(customFields: CustomFieldDTO[], resp: ITrainingDTO) {
    for (const field of customFields) {
      if (!resp.customFields?.find((x) => x.id == field.id)) {
        await this.#trainingsApi.addCustomField(resp.id, field);
      } else {
        await this.#trainingsApi.editCustomField(resp.id, field.id, field);
      }
    }
  }

  async copyCosts(itemId: number, copy: ITrainingDTO) {
    const costs = await this.#trainingsApi.getCosts(itemId);
    const costDtos = costs.map(
      (x) =>
        new CostEditDTO({
          title: x.title,
          description: x.description,
          value: x.value,
          category: x.category,
          costCenter: x.costCenter,
          duration: Number(x.duration),
          id: x.id,
          costUnit: x.costUnit,
          currency: x.currency,
        }),
    );

    for (const cost of costDtos) {
      await this.#trainingsApi.addCost(copy.id, cost);
    }
  }

  async copy(form: CopyForm, item: ITrainingDTO, responsibleId: number, attendees?: ITenantAsAttendee[]) {
    const itemToCreate = new TrainingCreateDTO({
      title: form.title,
      description: item.description,
      plannedAt: item.plannedAt,
      categoryId: item.category?.id ?? 0,
      responsibleId: item.responsible?.id ?? responsibleId,
      attendeesCanSelfRegister: item.attendeesCanSelfRegister,
      maximumNumberOfAttendees: item.maximumNumberOfAttendees,
      rateableByAttendee: item.rateableByAttendee,
    });

    const resp = await this.#trainingsApi.create(itemToCreate);

    if (form.options.attendees && attendees) {
      await this.copyAttendees(resp, attendees);
    }

    if (form.options.todos) {
      await this.copyTodos(item.id, resp);
    }

    if (form.options.requiredQualification) {
      await this.#copyQualifications(item, resp);
    }

    if (form.options.customFields && item.customFields && item.customFields.length > 0) {
      await this.copyCustomField(item.customFields, resp);
    }

    if (form.options.costs) {
      await this.copyCosts(item.id, resp);
    }

    if (form.options.documents) {
      const linkedResources = await this.#trainingsApi.getConnectedLinkedResourcesLegacy(item.id);
      await this.#copyDocuments(resp.id, linkedResources);
    }

    return resp;
  }

  async #copyDocuments(itemId: number, linkedResource: ILinkedResource[]): Promise<void> {
    const ids = ConnectionService.mustGetIdsByKeyAsNumber(linkedResource, EntityKey.Article);
    if (!ids || ids.length == 0) return undefined;
    await this.#trainingsApi.connectLinkedResourceLegacy(
      itemId,
      ids.map((x) => new LinkedResourceCreateDTO({ entityKey: EntityKey.Article, linkedResourceId: x.toString() })),
    );
  }

  static get availableFilter() {
    return [
      SearchFilterTypes.Responsible,
      SearchFilterTypes.Status,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.Tags,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.Qualification,
      SearchFilterTypes.Attendees,
      SearchFilterTypes.Category,
      SearchFilterTypes.PlannedAt,
      SearchFilterTypes.Department,
      SearchFilterTypes.DepartmentPosition,
      SearchFilterTypes.CustomField,
    ];
  }

  static get copyModalOptions(): CopyIncludedOption[] {
    return ["attendees", "todos", "requiredQualification", "customFields", "costs", "documents"];
  }
}

type AcceptanceStatusType = Record<
  PbdStatus.Open | PbdStatus.Accepted | PbdStatus.NotAccepted | PbdStatus.Attended | PbdStatus.NotAttended,
  AcceptanceStatus
>;
