import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { FormGroup, ListGroup, ListGroupItem } from "reactstrap";

import { IArticleChangelogSettings } from "@/generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { nameofFactory } from "../../../../utils/nameofFactory";
import { BaseFormProps } from "../../../shared/components/forms/formik/BaseFormProps";
import { FormikCheckboxInput } from "../../../shared/components/forms/formik/formikCheckboxInput";
import FormikDebugInfo from "../../../shared/components/forms/formik/formikDebugInfo";
import FormikSubmitButton from "../../../shared/components/forms/formik/formikSubmitButton";
import { FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";

const nameof = nameofFactory<IArticleChangelogSettings>();

interface IProps extends BaseFormProps<IArticleChangelogSettings> {
  itemToUpdate: IArticleChangelogSettings;
}

function ArticleChangelogForm(props: IProps) {
  const { t } = useTranslation();
  const { onSubmit, itemToUpdate, onSuccess } = props;

  const submitter = useFormikAPISubmitter<IArticleChangelogSettings>((val) => onSubmit(val), [onSubmit], onSuccess);

  return (
    <>
      <h4>{t("Changelog")}</h4>
      <hr />
      <Formik
        initialValues={itemToUpdate}
        onSubmit={submitter}
        // validationSchema={ValidationSchema}
      >
        {(formikBag) => (
          <Form>
            <FormikDebugInfo formikBag={formikBag} />
            <ListGroup className="mb-3">
              {Object.keys(itemToUpdate)
                .filter((x) => ["isEnabled"].includes(x))
                .map((x) => (
                  <ListGroupItem key={x}>
                    <Field
                      name={x}
                      id={x}
                      value={itemToUpdate[x as keyof IArticleChangelogSettings]}
                      component={FormikCheckboxInput}
                      label={t(x)}
                    />
                  </ListGroupItem>
                ))}
              <ListGroupItem>
                <FormikTextInputGroup label={t("Template")} name={nameof("changelogPlaceholder")} textarea />
              </ListGroupItem>
            </ListGroup>

            <FormGroup>
              <FormikSubmitButton formikBag={formikBag} />
            </FormGroup>
            <FormikValidationSummary formikBag={formikBag} />
          </Form>
        )}
      </Formik>
    </>
  );
}
export default ArticleChangelogForm;
