import { ErrorMessage, FieldHookConfig, FieldProps, getIn, useField } from "formik";
import { DateTime } from "luxon";
import React from "react";
import { Form } from "react-bootstrap";
import { FormFeedback, FormText, Input } from "reactstrap";

import { DateTimeLuxonHelpers } from "../../../../../utils/dateTimeLuxonHelpers";

import { IBaseFormElementProps } from "./baseFormElementProps";
import { CustomFormLabel } from "./customFormLabel";

interface DateInputProps {
  minDate?: Date;
  maxDate?: Date;
}

export const FormikLuxonDateInput = ({
  field,
  form: { touched, errors },
  formText,
  ...props
}: FieldProps<DateTime | string | undefined | null> & DateInputProps & { formText?: string }) => {
  const handleChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);

    // Do we need to adjust dates to submit utc dates?
    // console.log("change");
    // if (e?.target.value && DateTime.fromISO(e.target.value)) {
    //   const dateToUtc = DateTime.fromISO(e.target.value).toUTC();
    //   console.log(dateToUtc.toISODate());
    //   console.log(DateTime.fromISO(e.target.value).toISODate());
    //   setFieldValue(field.name, dateToUtc);
    // } else {
    //   field.onChange(e);
    // }
  };

  return (
    <React.Fragment>
      <Input
        type="date"
        aria-label={field.name}
        invalid={getIn(errors, field.name) && getIn(touched, field.name)}
        min={props.minDate?.toISOString()}
        max={props.maxDate?.toISOString()}
        {...field}
        onChange={handleChange}
        {...props}
        value={field.value ? DateTimeLuxonHelpers.convertFromUnknownToDateTime(field.value).toISODate() : undefined}
      />
      <ErrorMessage component={FormFeedback} className="order-last" {...field} />
      {formText && <FormText color="muted">{formText}</FormText>}
    </React.Fragment>
  );
};

export function FormikLuxonDateInputGroup(
  props: FieldHookConfig<DateTime | string | undefined | null> & IBaseFormElementProps & DateInputProps,
) {
  const { formText, id } = props;
  const [field, meta] = useField(props);
  const localId = id ?? props.name;

  const invalid = meta.error != undefined && meta.touched;

  return (
    <Form.Group className="mb-3" controlId={localId}>
      <CustomFormLabel {...props} />
      <Form.Control
        {...field}
        isInvalid={invalid}
        type="date"
        value={field.value ? DateTimeLuxonHelpers.convertFromUnknownToDateTime(field.value).toISODate() : undefined}
        min={props.minDate?.toISOString()}
        max={props.maxDate?.toISOString()}
      />
      <ErrorMessage {...field}>
        {(msg) => (
          <Form.Control.Feedback type="invalid" className="order-last">
            {msg}
          </Form.Control.Feedback>
        )}
      </ErrorMessage>
      {formText && <Form.Text muted>{formText}</Form.Text>}
    </Form.Group>
  );
}
