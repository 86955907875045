import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { FormRoutePaths } from "./formRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.CustomForms_ModuleAdmin, PbdRoles.CustomForms_Standard],
};

export const customFormLazyRoutes: RouteObject[] = [
  {
    path: FormRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageForms")),
    ...defaultRouteParams,
  },
  {
    path: FormRoutePaths.IndexPageCustomFormAnswers,
    Component: lazy(() => import("./customFormAnswers/pages/indexPageCustomFormAnswers")),
    ...defaultRouteParams,
  },
  {
    path: FormRoutePaths.DetailsPageCustomFormAnswer,
    Component: lazy(() => import("./customFormAnswers/pages/detailsPageCustomFormAnswer")),
    ...defaultRouteParams,
  },
  {
    path: FormRoutePaths.CreatePageCustomFormAnswer,
    Component: lazy(() => import("./customFormAnswers/pages/createPageCustomFormAnswer")),
    ...defaultRouteParams,
  },
  {
    path: FormRoutePaths.CreatePageCustomForm,
    Component: lazy(() => import("./customForms/pages/createPageCustomForm")),
    ...defaultRouteParams,
  },
  {
    path: FormRoutePaths.DetailsPageCustomForm,
    Component: lazy(() => import("./customForms/pages/detailsPageCustomForm")),
    ...defaultRouteParams,
  },
  {
    path: FormRoutePaths.IndexPageCustomForm,
    Component: lazy(() => import("./customForms/pages/indexPageCustomForm")),
    ...defaultRouteParams,
  },
];
