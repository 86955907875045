import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AdminRoutePaths } from "../adminRoutePaths";

const SubsidebarCustomization = () => {
  const { t } = useTranslation();
  return (
    <>
      <h3>{t("Customization")}</h3>
      <ListGroup>
        <ListGroupItem as={Link} to={AdminRoutePaths.CustomizationsLogoPage.replace(":id", "Navbar")} action>
          {t("Navigation bar logo")}
        </ListGroupItem>
        <ListGroupItem as={Link} to={AdminRoutePaths.CustomizationsLogoPage.replace(":id", "Print")} action>
          {t("Print logo")}
        </ListGroupItem>
      </ListGroup>
    </>
  );
};

export default SubsidebarCustomization;
