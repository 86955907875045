import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import EditFormDepartmentPosition from "./editFormDepartmentPosition";

interface IProps {
  showModal: boolean;
  toggleModal: () => void;
  onSuccess: () => void;
  departmentId: number;
}

function CreateDepartmentPositionModal(props: IProps) {
  const { showModal, toggleModal, onSuccess, departmentId } = props;
  const { t } = useTranslation();
  return (
    <Modal show={showModal} onHide={toggleModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Create")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditFormDepartmentPosition onSuccess={onSuccess} departmentId={departmentId} />
      </Modal.Body>
    </Modal>
  );
}
export default CreateDepartmentPositionModal;
