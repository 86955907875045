import { DateTime } from "luxon";

import {
  AbsenceRequestDTO,
  ApplicationUserDTO,
  ArticleDTO,
  ClaimDTO,
  EmployeeIdeaDTO,
  EntityKey,
  IAbsenceRequestDTO,
  IApplicationUserDTO,
  IArticleDTO,
  IAuditDTO,
  IClaimDTO,
  ICompanyFunctionDTO,
  ICustomFormAnswerDTO,
  ICustomFormDTO,
  IDefectDTO,
  IDepartmentPositionDTO,
  IEmployeeIdeaDTO,
  IGoalDTO,
  IInventoryItemDTO,
  ILinkedResourceMinDTO,
  IOpportunityDTO,
  IOrganisationDTO,
  IProductDTO,
  IQualificationDTO,
  ITenantDTO,
  IToDoDTO,
  ITrainingDTO,
  InventoryItemDTO,
  OrganisationDTO,
  PbdModule,
  PbdStatus,
  ProductDTO,
  TenantDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";

export interface IConnectedItem {
  id: number;
  status: PbdStatus;
}

export interface IConnectedInput {
  tasks: IToDoDTO[] | undefined;
  trainings: ITrainingDTO[] | undefined;
  claims: IClaimDTO[] | undefined;
}

export interface ILinkedResourceVm extends ILinkedResourceMinDTO {
  title?: string;
  /**
   * We have entities that are in different apps. For example **Article** this will help to identify them.
   */
  app?: PbdModule;
}

type CombinedDTO =
  | IAbsenceRequestDTO
  | IApplicationUserDTO
  | IArticleDTO
  | IAuditDTO
  | IClaimDTO
  | ICompanyFunctionDTO
  | ICustomFormDTO
  | ICustomFormAnswerDTO
  | IDefectDTO
  | IDepartmentPositionDTO
  | IEmployeeIdeaDTO
  | IGoalDTO
  | IInventoryItemDTO
  | IOrganisationDTO
  | IOpportunityDTO
  | IProductDTO
  | IQualificationDTO
  | ITenantDTO
  | IToDoDTO
  | ITrainingDTO;

export class LinkedResourceVm implements ILinkedResourceVm {
  constructor(dto: CombinedDTO) {
    this.id = `${dto.entityKey}|${dto.id.toString()}`;
    this.entityId = dto.id.toString();
    this.entity = dto.entityKey;
    if (isApplicationUser(dto)) {
      this.title = dto.userName;
    } else if (isTenant(dto)) {
      this.title = dto.fullName;
    } else {
      this.title = dto.title;
    }
    if (isArticle(dto)) {
      this.app = dto.module;
    }
  }

  id: string;
  entity: EntityKey;
  entityId: string;
  title?: string;
  app?: PbdModule;
}

interface ILinkedResourceDetails {
  id: string;
  title: string;
  entityKey: EntityKey;
  status?: PbdStatus;
  deadline?: DateTime;
  description?: string;
}

export class LinkedResourceDetails implements ILinkedResourceDetails {
  constructor(dto: CombinedDTO, entityKey: EntityKey) {
    this.id = dto.id.toString();
    if (isApplicationUser(dto)) {
      this.title = dto.userName;
    } else if (isTenant(dto)) {
      this.title = dto.fullName;
      this.description = dto.fullName;
    } else {
      this.title = dto.title ?? GlobalQmBaseConstants.MissingTitle;
    }
    this.entityKey = entityKey;
    if (isAudit(dto)) {
      this.status = dto.status;
      this.deadline = dto.plannedAt;
      this.description = dto.description;
    }
    if (isGoal(dto) || isClaim(dto)) {
      this.status = dto.status;
      this.deadline = dto.deadline;
      this.description = dto.description;
    }
    if (isDefect(dto) || isAbsenceRequest(dto) || isOrganisation(dto) || isProduct(dto)) {
      this.status = dto.status;
      this.description = dto.description;
    }
    if (isQualification(dto) || isInventoryItem(dto)) {
      this.description = dto.description;
    }

    if (isEmployeeIdea(dto)) {
      this.status = dto.status;
    }

    if (isTenant(dto)) {
      this.description = dto.description;
    }
  }
  id: string;
  title: string;
  entityKey: EntityKey;
  status?: PbdStatus;
  deadline?: DateTime;
  description?: string;
}

function isQualification(arg: CombinedDTO): arg is IQualificationDTO {
  return (arg as IQualificationDTO).entityKey == EntityKey.Qualification;
}

function isAudit(arg: CombinedDTO): arg is IAuditDTO {
  return (arg as IAuditDTO).entityKey == EntityKey.Audit;
}

function isGoal(arg: CombinedDTO): arg is IGoalDTO {
  return (arg as IGoalDTO).entityKey == EntityKey.Goal;
}

function isDefect(arg: CombinedDTO): arg is IDefectDTO {
  return (arg as IDefectDTO).entityKey == EntityKey.Defect;
}

function isApplicationUser(arg: CombinedDTO): arg is IApplicationUserDTO {
  return arg instanceof ApplicationUserDTO;
}

function isTenant(arg: CombinedDTO): arg is ITenantDTO {
  return arg instanceof TenantDTO;
}

function isAbsenceRequest(arg: CombinedDTO): arg is IAbsenceRequestDTO {
  return arg instanceof AbsenceRequestDTO;
}

function isClaim(arg: CombinedDTO): arg is IClaimDTO {
  return arg instanceof ClaimDTO;
}

function isEmployeeIdea(arg: CombinedDTO): arg is IEmployeeIdeaDTO {
  return arg instanceof EmployeeIdeaDTO;
}

function isInventoryItem(arg: CombinedDTO): arg is IInventoryItemDTO {
  return arg instanceof InventoryItemDTO;
}

function isOrganisation(arg: CombinedDTO): arg is IOrganisationDTO {
  return arg instanceof OrganisationDTO;
}

function isProduct(arg: CombinedDTO): arg is IProductDTO {
  return arg instanceof ProductDTO;
}

function isArticle(arg: CombinedDTO): arg is IArticleDTO {
  return arg instanceof ArticleDTO;
}
