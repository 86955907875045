import { ErrorMessage, FieldHookConfig, FieldProps, getIn, useField } from "formik";
import { isString } from "lodash";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormFeedback, FormText, Input } from "reactstrap";

import { IBaseFormElementProps } from "./baseFormElementProps";
import { CustomFormLabel } from "./customFormLabel";

interface FormTextProps {
  formText?: string;
  multiple?: boolean;
}

export const FormikSelectInput = ({
  field,
  form: { touched, errors },
  formText,
  ...props
}: FieldProps<string> & FormTextProps) => (
  <div>
    <Input invalid={getIn(errors, field.name) && getIn(touched, field.name)} {...field} {...props} type="select" />
    <ErrorMessage component={FormFeedback} className="order-last" {...field} />
    {formText && <FormText color="muted">{formText}</FormText>}
  </div>
);

export function FormikSelectInputGroup(
  props: FieldHookConfig<string> &
    IBaseFormElementProps & {
      hideLabel?: boolean;
      textarea?: boolean;
      /**
       * Use the array of objects to provide custom keys for translation
       */
      options: string[] | { value: string; labelI18nKey: string }[];
    },
) {
  const { formText, id, hideLabel, options } = props;
  const [field, meta] = useField(props);
  const { t } = useTranslation();

  const localId = id ?? props.name;

  const invalid = meta.error != undefined && meta.touched;

  const isArray = Array.isArray(options);

  return (
    <Form.Group className="mb-3" controlId={localId}>
      {!hideLabel && <CustomFormLabel {...props} />}
      <Form.Select {...field} isInvalid={invalid} autoComplete={props.autoComplete}>
        {options.map((x) => {
          if (isString(x)) {
            return (
              <option key={x} value={x}>
                {t(x)}
              </option>
            );
          }
          return (
            <option key={x.value} value={x.value}>
              {t(x.labelI18nKey)}
            </option>
          );
        })}
      </Form.Select>
      <ErrorMessage {...field}>
        {(msg) => (
          <Form.Control.Feedback type="invalid" className="order-last">
            {msg}
          </Form.Control.Feedback>
        )}
      </ErrorMessage>
      {formText && <Form.Text muted>{formText}</Form.Text>}
    </Form.Group>
  );
}
