import { useParams } from "react-router";
import invariant from "tiny-invariant";

/**
 * A hook to typecheck and null check useParams.
 * We do this because react-router can never be sure of where we are calling useParams from.
 * Even if we are sure IN THIS TIME, in the future, a route component could be deleted or a param name could change
 * This gives us a test for that case
 * https://github.com/remix-run/react-router/issues/8200#issuecomment-1193097483
 */
export function useTypedParams<T extends string, U extends string>(parameterNames: T[]): Record<T, U> {
  const params = useParams();
  const typedParams: Record<string, U> = {};
  parameterNames.forEach((paramName) => {
    const currentParam = params[paramName];
    invariant(currentParam, `${paramName} not found in useParams. Check the parent route to make sure nothing changed`);
    typedParams[paramName] = currentParam as U;
  });
  return typedParams;
}
