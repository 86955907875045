import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";

interface IProps {
  itemToUpdate?: unknown;
  onDelete?: () => void;
  disableDeleteButton?: boolean;
}

function BaseSettingsFormHeader(props: IProps) {
  const { itemToUpdate, onDelete, disableDeleteButton } = props;
  const { t } = useTranslation();
  return (
    <Card.Header>
      <div className="d-flex justify-content-between">
        <Card.Title as="h5">{itemToUpdate ? <>{t("Edit")}</> : t("New")}</Card.Title>
        <div>
          {itemToUpdate != null && onDelete && (
            <Button
              onClick={onDelete}
              variant="outline-secondary"
              disabled={disableDeleteButton}
              className="m-1"
              title={t("Recycle bin")}
            >
              <qmBaseIcons.Delete />
            </Button>
          )}
        </div>
      </div>
    </Card.Header>
  );
}

export default BaseSettingsFormHeader;
