import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";

import { qmBaseIcons } from "../icons/qmBaseIcons";

type SortDirection = "asc" | "desc";

interface SortObject {
  createdAt?: DateTime;
  count?: number;
  title?: string;
}

export class SortOptionInternal<T = SortObject> {
  /**
   * Unique id
   */
  id: Extract<keyof T, string>;
  /**
   * label to be translated
   */
  title: string;
  sortKey?: keyof T;
  constructor(id: Extract<keyof T, string>, title: string) {
    this.id = id;
    this.title = title;
  }
}

const defaultSortOptions: SortOptionInternal[] = [
  {
    id: "title",
    title: "Alphabetical",
  },
  {
    id: "count",
    title: "Used",
  },
  {
    id: "createdAt",
    title: "Created",
  },
];

function getSortOptions(keys: string[]) {
  return defaultSortOptions.filter((x) => keys.includes(x.id));
}

export class SortOption<T extends SortObject> {
  constructor(id: Extract<keyof T, string>, direction: SortDirection) {
    this.id = id;
    this.direction = direction;
  }
  id: Extract<keyof T, string>;
  direction: SortDirection;
  minimumItemsForSort?: number;
  static fromInternal(item: SortOptionInternal, direction: SortDirection): SortOption<SortObject> {
    return new SortOption(item.id, direction);
  }

  static isSortButtonVisible(itemsToSort: unknown[] | undefined, minimumItemsForSort: number): boolean {
    return itemsToSort != undefined && itemsToSort.length >= minimumItemsForSort;
  }
}

type SortButtonBaseType = SortObject;

export interface ISortButtonProps<T extends SortButtonBaseType> {
  sortOptions?: SortOption<T>[];
  onClick: (sortBy: SortOption<T>) => void;
  /**
   * default query:   Used to search for the default sorting state in the local storage
   */
  defaultSorting?: SortOption<T>;
  sortKeys: Extract<keyof T, string>[];
  // sortKeys: Leaves<T>;
  size?: "sm";
  color?: string;
  minimumItemsForSort?: number;
  itemsFromList: T[] | undefined;
}

function SortDropdownButton<T extends SortButtonBaseType>(props: ISortButtonProps<T>) {
  const { defaultSorting, sortKeys, size, color, minimumItemsForSort = 2, itemsFromList, onClick } = props;
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<SortOption<T> | undefined>(defaultSorting);

  const availableOptions = React.useMemo(() => getSortOptions(sortKeys), [sortKeys]);

  const onSortButtonClick = (sortBy: SortOption<T>) => {
    setSelected(sortBy);
    onClick(sortBy);
  };

  if (!SortOption.isSortButtonVisible(itemsFromList, minimumItemsForSort)) return null;

  return (
    <UncontrolledButtonDropdown size={size} color={color}>
      <DropdownToggle caret color={color}>
        {t("Sort")}
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem header>{t("Sort by")}</DropdownItem>
        <DropdownItem divider />
        {availableOptions.map((x) => (
          <React.Fragment key={x.id}>
            <DropdownItem onClick={() => onSortButtonClick(SortOption.fromInternal(x, "asc"))}>
              {selected && selected.id == x.id && selected.direction == "asc" && <qmBaseIcons.Check />} {t(x.title)}{" "}
              {t("Ascending")} <qmBaseIcons.SortUp />
            </DropdownItem>
            <DropdownItem onClick={() => onSortButtonClick(SortOption.fromInternal(x, "desc"))}>
              {selected && selected.id == x.id && selected.direction == "desc" && <qmBaseIcons.Check />} {t(x.title)}{" "}
              {t("Descending")} <qmBaseIcons.SortDown />
            </DropdownItem>
          </React.Fragment>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
}
export default SortDropdownButton;
