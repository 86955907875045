import i18next from "i18next";
import React from "react";
import { Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CellProps, Column } from "react-table";

import { DateTimeLuxonHelpers } from "../../../utils/dateTimeLuxonHelpers";
import { NumberHelpers } from "../../../utils/numberHelpers";
import { SettingsIndexDTO } from "../../../Models/Settings/SettingsIndexDTO";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";
import { useFilteredColumnsMemo } from "../../shared/components/tables/tableHelperFunctions";

interface IProps extends BaseTableProps<SettingsIndexDTO> {
  toggleSetWeightForQualifications?: (id: number) => void;
}

export function BaseSettingsTable(props: IProps) {
  const {
    toggleSetWeightForQualifications,
    columnsVisibleDefault = ["title"],
    columnsToExclude = ["qualificationWeighting"],
    includeTitleLink = true,
  } = props;

  const columns = React.useMemo<Column<SettingsIndexDTO>[]>(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id", // accessor is the "key" in the data
        Cell: ({ row }) => {
          const linkAvailable = row.original.links.find((x) => x.rel == "self");
          if (!linkAvailable) throw Error("Link is missing");
          if (includeTitleLink) {
            return <Link to={linkAvailable.href}>#{row.original.id}</Link>;
          } else {
            return <>#{row.original.id}</>;
          }
        },
      },
      {
        id: "title",
        Header: <>{i18next.t("Title")}</>,
        accessor: "title",
        Cell: ({ row }) => {
          const linkAvailable = row.original.links.find((x) => x.rel == "self");
          if (!linkAvailable) throw Error("Link is missing");
          if (includeTitleLink) {
            return (
              <Link to={linkAvailable.href}>
                {row.original.color && <qmBaseIcons.Circle className="me-2" style={{ color: row.original.color }} />}
                {row.original.backgroundColor ? (
                  <Badge
                    className="me-2"
                    style={{ backgroundColor: row.original.backgroundColor, color: row.original.color }}
                  >
                    {row.original.title}
                  </Badge>
                ) : (
                  <>
                    {" "}
                    {row.original.title}{" "}
                    {row.original.localTags?.map((x) => (
                      <Badge key={x} className="me-1">
                        {x}
                      </Badge>
                    ))}
                  </>
                )}
              </Link>
            );
          } else {
            return (
              <>
                {row.original.color && <qmBaseIcons.Circle className="me-2" style={{ color: row.original.color }} />}
                {row.original.backgroundColor ? (
                  <Badge
                    className="me-2"
                    style={{ backgroundColor: row.original.backgroundColor, color: row.original.color }}
                  >
                    {row.original.title}
                  </Badge>
                ) : (
                  <> {row.original.title}</>
                )}
              </>
            );
          }
        },
      },
      {
        id: "createdAt",
        Header: <>{i18next.t("Created")}</>,
        accessor: (x) => x.createdAt?.toMillis(),
        Cell: ({ row }: CellProps<SettingsIndexDTO>) => (
          <>{row.original.createdAt && DateTimeLuxonHelpers.convertUtcToDate(row.original.createdAt)}</>
        ),
      },
      {
        id: "qualificationWeighting",
        Header: <>{i18next.t("Weighting")}</>,
        accessor: (x) => x.qualificationWeighting,
        Cell: ({ row }: CellProps<SettingsIndexDTO>) => (
          <>
            {toggleSetWeightForQualifications && (
              <div className="d-flex">
                <div className="flex-grow-1">
                  {NumberHelpers.convertToFractionNumber(row.original.qualificationWeighting ?? 1)}
                </div>
                <div>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={(x) => {
                      toggleSetWeightForQualifications(Number(row.original.id));
                    }}
                  >
                    <qmBaseIcons.WeightHanging />
                  </Button>
                </div>
              </div>
            )}
          </>
        ),
      },
    ],
    [],
  );

  const filteredColumns = useFilteredColumnsMemo(columns, columnsToExclude);

  return (
    <ReactstrapTable<SettingsIndexDTO>
      columns={filteredColumns}
      localStorageStateKey={"SettingsIndexDTO"}
      columnsVisibleDefault={columnsVisibleDefault}
      {...props}
    />
  );
}
