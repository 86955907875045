import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { QualityMonitorRoutePaths } from "./qualityMonitorRoutePaths";

export const dashboardLazyRoutes: RouteObject[] = [
  {
    path: QualityMonitorRoutePaths.All,
    Component: lazy(() => import("./pages/overviewQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.Employees,
    Component: lazy(() => import("./pages/employeesQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.ProjectsAndTasks,
    Component: lazy(() => import("./pages/projectsAndTaskQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.Trainings,
    Component: lazy(() => import("./pages/trainingsQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.Claims,
    Component: lazy(() => import("./pages/claimsQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.EmployeeIdeas,
    lazy: async () => {
      const { EmployeeIdeasQmonitor } = await import("./pages/employeeIdeasQmonitor");
      return { Component: EmployeeIdeasQmonitor };
    },
  },
  {
    path: QualityMonitorRoutePaths.ArticlesDashboard,
    Component: lazy(() => import("./pages/articlesQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.Crm,
    Component: lazy(() => import("./pages/crmQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.Goals,
    Component: lazy(() => import("./pages/goalsQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.Audits,
    Component: lazy(() => import("./pages/auditsQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.Costs,
    Component: lazy(() => import("./pages/costsQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.Maintenance,
    lazy: async () => {
      const { MaintenanceQmonitor } = await import("./pages/maintenanceQmonitor");
      return { Component: MaintenanceQmonitor };
    },
  },
  {
    path: QualityMonitorRoutePaths.Opportunities,
    Component: lazy(() => import("./pages/opportunitiesQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.CustomForms,
    Component: lazy(() => import("./pages/customFormsQMonitor")),
  },
  {
    path: QualityMonitorRoutePaths.IndexPageDashboard,
    Component: lazy(() => import("../dashboards/pages/indexPageDashboard")),
  },
  {
    path: QualityMonitorRoutePaths.DetailsPageDashboard,
    Component: lazy(() => import("../dashboards/pages/detailsPageDashboard")),
  },
  {
    path: QualityMonitorRoutePaths.CreatePageDashboard,
    Component: lazy(() => import("../dashboards/pages/createPageDashboard")),
  },
  {
    path: QualityMonitorRoutePaths.CreateChartPage,
    Component: lazy(() => import("./pages/createPageDashboardItem")),
  },
];
