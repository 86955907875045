import { Axios, AxiosHeaders, AxiosRequestHeaders } from "axios";
import { isString } from "lodash";
import { PDFDocument } from "pdf-lib";

import { IFileDTO, PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import ExportService from "../Export/exportService";
import { IConfigApi } from "../apiBase/configApi";

const BlobsApiUrl = "/api/blobs";

export const MimePdf = "application/pdf";

type TableName = "ToDos" | "Projects" | "Audits";

export default class FileService {
  axiosInstance: Axios;
  config: IConfigApi;
  baseUrl: string;

  constructor(config: IConfigApi, axiosInstance: Axios) {
    this.config = config;
    this.axiosInstance = axiosInstance;
    //TODO2 Maybe move this up to serviceContext data with the default case
    this.baseUrl = this.config.baseUrl ?? `${window.location.protocol}//${window.location.hostname}`;
  }

  getHostUrl() {
    return this.baseUrl;
  }

  getAbsoluteDownloadURL(file: string) {
    //TODO2 handle path combining properly
    return `${this.baseUrl}/${file}`;
  }

  getFileDownloadURL(fileId: number) {
    return `${this.baseUrl}/${BlobsApiUrl}/${fileId}/download`;
  }

  getAcceptHeaders(accept?: string | string[]): AxiosRequestHeaders | undefined {
    if (accept) {
      return new AxiosHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Accept: isString(accept) ? accept : accept.join(";"),
      });
    } else {
      return undefined;
    }
  }

  downloadFile(id: number, accept?: string | string[]): Promise<ArrayBuffer> {
    const headers = this.getAcceptHeaders(accept);

    return this.axiosInstance
      .get(`/api/blobs/${id}/download`, {
        //TODO2: maybe use blob
        responseType: "arraybuffer",
        headers,
      })
      .then((resp) => resp.data as ArrayBuffer);
  }

  async getPdfDocument(fileId: number) {
    const buf = await this.downloadFile(fileId, MimePdf);
    const doc = await PDFDocument.load(buf, { ignoreEncryption: true });
    return doc;
  }

  saveByteArray(fileName: string, byte: Uint8Array) {
    const blob = new Blob([byte], { type: MimePdf });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  exportToCsv(tableRows: IFileDTO[]) {
    return ExportService.exportCSV("Files", tableRows, (x) => ({
      id: x.id,
      title: x.title,
      createdAt: x.createdAt,
      createdById: x.createdById,
    }));
  }

  /**This helper can be used to create the url required for the text editor to do the updates */
  static getUploadUrlForField(module: PbdModule, tableName: TableName, fieldName: "description") {
    return `${BlobsApiUrl}/images/${module}/${tableName}/${fieldName}`;
  }

  static getDownloadName(file: { title: string }) {
    return file.title.endsWith(".msg") ? file.title : undefined;
  }
}
