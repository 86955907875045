import { TFunction } from "i18next";

import { IExternalIdSetting } from "@/generatedCode/pbd-core/pbd-core-api";

export const preferExternalId = (extIdSetting?: IExternalIdSetting): boolean =>
  (extIdSetting?.isUsed ?? false) && (extIdSetting?.hideInternalId ?? false);

export const getExternalIdName = (externalIdSetting: IExternalIdSetting, t: TFunction) => {
  return externalIdSetting.displayName ?? t("External ID");
};
