export const NodeTypesArray = [
  "input",
  "output",
  "process",
  "customInput",
  "customOutput",
  "customProcess",
  "circle",
  "rhombus",
  "diamond",
  "parallelogram",
  "database",
] as const;
export type NodeTypes = (typeof NodeTypesArray)[number];
export type EditFor = "Node" | "Edge";

export const EdgeTypesArray = ["default", "step", "straight"];
export type EdgeTypes = (typeof EdgeTypesArray)[number];

// export class FlowNode {
//   label: string;
//   fontcolor?: string;
//   backgroundColor?: string;
//   borderColor?: string;
// }

export type FlowItem = "Node" | "Edge";

export interface NodeOrEdgeData {
  id: string;
  label?: string;
  fontColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  kind: FlowItem;
  type?: NodeTypes | EdgeTypes;
  isAnimated?: boolean;
}

export const VisibleNodeTypes = NodeTypesArray.filter((x) => !x.startsWith("custom"));
