import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IEmployeeIdeaDetailsDTO, IEmployeeIdeaReviewDTO, PbdStatus } from "@/generatedCode/pbd-core/pbd-core-api";

import DeadlineComponent from "../../../shared/components/dateTime/deadlineComponent";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";

interface IProps {
  item: IEmployeeIdeaReviewDTO;
  idea: IEmployeeIdeaDetailsDTO;
  history?: IEmployeeIdeaReviewDTO[];
  onHistoryClick?: () => void;
}

function ReviewRequestBadge(props: IProps) {
  const { item, idea, history, onHistoryClick } = props;
  const { t } = useTranslation();
  return (
    <>
      {item.answeredAt == null ? (
        <>
          {idea.status == PbdStatus.UnderReview ? (
            <Badge bg="secondary" className="me-1">
              <qmBaseIcons.Clock /> {t("Pending")}
            </Badge>
          ) : (
            <Badge bg="secondary" className="me-1">
              <qmBaseIcons.Lock /> {t("Adding review only possible during review stage")}
            </Badge>
          )}
        </>
      ) : (
        <>
          {item.isApproved ? (
            <Badge bg="success" className="me-1">
              <qmBaseIcons.Check title={t("Accepted")} /> {t("Accepted")}
            </Badge>
          ) : (
            <Badge bg="danger" className="me-1">
              <qmBaseIcons.Alert title={t("Not approved")} /> {t("Not approved")}
            </Badge>
          )}
        </>
      )}
      {item.deadline && idea.status == PbdStatus.UnderReview && (
        <DeadlineComponent deadline={item.deadline} className="me-1" />
      )}
      <Badge className="me-1" bg={item.isPublished ? "info" : undefined}>
        {item.isPublished ? (
          <>
            <qmBaseIcons.Globe className="me-1" />
            {t("Published")}
          </>
        ) : (
          <>
            <qmBaseIcons.Private className="me-1" />
            {t("Private")}
          </>
        )}
      </Badge>
      {history && history.length > 0 && (
        <Badge
          bg="secondary"
          style={{ cursor: "pointer" }}
          pill
          title={t("Older reviews available")}
          onClick={onHistoryClick}
        >
          <qmBaseIcons.History /> ({history.length})
        </Badge>
      )}
    </>
  );
}
export default ReviewRequestBadge;
