import React from "react";
import { useTranslation } from "react-i18next";
import { GroupBase, MultiValue, SingleValue } from "react-select";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import { Badge, FormText } from "reactstrap";

import { IOrganisationDTO, IOrganisationMinDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../services/serviceContext";

import { GlobalQmBaseConstants } from "../../../../../Constants/GlobalQmBaseConstants";
import { OrganisationQueryParameters } from "../../../../../pbdServices/services/Organisations/models/organisation-query-parameters";
import { Additional } from "./models/additional";
import { getLabel, getValue } from "./reactSelectHelpers";

type TypeForTransfer = IOrganisationMinDTO | IOrganisationMinDTO[];

type LocalType = IOrganisationMinDTO | IOrganisationDTO;

interface IProps {
  onChange: (item?: TypeForTransfer) => void;
  selected?: TypeForTransfer;
  organisationType?: "Customer" | "Supplier";
  isClearable?: boolean;
  isMulti?: boolean;
  inputId?: string;
  isReadonly?: boolean;
}

export function OrganisationSelect(props: IProps) {
  const { organisationType, inputId = "organisationId" } = props;
  const [selected, setSelected] = React.useState<TypeForTransfer>();
  const { t } = useTranslation();
  const { organisationsApi } = useAPIs();

  React.useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  function handleChange(value?: MultiValue<IOrganisationMinDTO> | SingleValue<IOrganisationMinDTO>) {
    if (!value) {
      setSelected(undefined);
      props.onChange(undefined);
    } else {
      const selected = value as TypeForTransfer;
      if (Array.isArray(selected)) {
        setSelected(selected);
      } else {
        setSelected([selected]);
      }
      props.onChange(selected);
    }
  }

  function loadingMessage(obj: { inputValue: string }) {
    return t("Loading...");
  }

  const loadOptions: LoadOptions<LocalType, GroupBase<LocalType>, Additional> = async (
    search,
    loadedOptions,
    additional,
  ) => {
    const query: OrganisationQueryParameters = {};
    query.pageSize = GlobalQmBaseConstants.DefaultPageSize;
    query.page = additional?.page;
    query.q = search;
    query.sortBy = "Name";

    const resp = await organisationsApi.getAllQuery(query);
    return {
      options: resp.data,
      hasMore: resp.pagination.hasNext,
      additional: {
        page: (additional?.page ?? 0) + 1,
      },
    };
  };

  return (
    <>
      <AsyncPaginate
        inputId={inputId}
        key={organisationType}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPosition="fixed"
        debounceTimeout={GlobalQmBaseConstants.DebounceMs}
        value={selected}
        loadOptions={loadOptions}
        additional={{
          page: 1,
        }}
        defaultOptions
        onChange={handleChange}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        loadingMessage={loadingMessage}
        placeholder={t("Please select") + "..."}
        isClearable={props.isClearable}
        isMulti={props.isMulti}
        isDisabled={props.isReadonly}
      />
      {props.organisationType && (
        <FormText color="muted">
          {t("Filtered by")} <Badge>{t(props.organisationType)}</Badge>
        </FormText>
      )}
    </>
  );
}
