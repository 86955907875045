interface Item {
  /**
   * This can be null in case of creatable
   */
  id?: number;
  title?: string;
}

export const getLabel = (option: Item & { label?: string; externalId?: string }) => {
  let result = "";

  if (option.id) {
    result += `#${option.id} `;
  }

  if (option.title) {
    result += option.title;
  } else if (option.label) {
    result += option.label;
  }

  if (option.externalId) {
    result += ` (Ext. Id: ${option.externalId})`;
  }

  return result;
};

export const getValue = (option: Item) => option.id?.toString() ?? "Missing ID";
