import { useTranslation } from "react-i18next";

import { ExternalIdSettings } from "../../../../Models/Settings/ExternalIdSettings";

interface IProps {
  id: number | string;
  externalIdSettings?: ExternalIdSettings;
}

function IdComponent(props: IProps) {
  const { id, externalIdSettings } = props;
  const { t } = useTranslation();
  if (externalIdSettings?.hideInternalId) return null;

  return (
    <small className="text-muted font-weight-light" title={t("ID")}>
      #{id}
    </small>
  );
}

export default IdComponent;
