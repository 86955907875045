import ExportService, { ExportType } from "../Export/exportService";

export abstract class BaseExportService<T> {
  defaultExportName: string;
  abstract mapToExport(x: T): ExportType;

  constructor(defaultExportName: string) {
    this.defaultExportName = defaultExportName;
  }

  public exportToCsv(items: T[], name?: string) {
    const exportName = name ?? this.defaultExportName;
    ExportService.exportCSV(exportName, items, (x) => this.mapToExport(x));
  }
}
