import toast from "react-hot-toast";

import { ApiError } from "../../../../pbdServices/services/Api/models/api-error";
import { toastSharedOptions } from "./toastShared";

/**This brings the standard error toast we use in top-center */
export function toastError(message: string, apiError?: ApiError) {
  const messageDetails = apiError ? ` ${apiError.displayMessage}` : "";
  toast.error(message + messageDetails, toastSharedOptions);
}
