import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FilterElement } from "./availableSearchFilters";
import FilterItemContent from "./filterItemContent";

interface IProps {
  item: FilterElement;
  onChange: (dto: FilterElement) => void;
}

const FilterItem = (props: IProps) => {
  const { item, onChange } = props;
  const { t } = useTranslation();

  const handleRemove = React.useCallback(() => {
    //@ts-expect-error goes away in strict mode
    const copy = { ...item, queryValue: null };
    onChange(copy);
  }, [item, onChange]);
  return (
    <>
      <FilterItemContent item={item} onChange={onChange} />
      <Button size="sm" variant="outline-danger" onClick={handleRemove}>
        {t("Remove")}
      </Button>
    </>
  );
};
export default FilterItem;
