import { ValidationResult } from "./validation-result";

export class ValidationResultDescriber {
  static requiredCustomFieldMissing() {
    return new ValidationResult("RequiredCustomFieldMissing");
  }
  static deadlineExpired() {
    return new ValidationResult("DeadlineExpired");
  }

  static monitoringIntervalExpired() {
    return new ValidationResult("MonitoringIntervalExpired");
  }
  static externalIdMissing() {
    return new ValidationResult("ExternalIdMissing");
  }

  static duplicatedEmail() {
    return new ValidationResult("DuplicatedEmail");
  }

  static missingBirthday() {
    return new ValidationResult("MissingBirthday");
  }

  static emailConfirmationFailed() {
    return new ValidationResult("EmailConfirmationFailed");
  }

  static missingOptions() {
    return new ValidationResult("MissingOptions");
  }

  static mixedInspections() {
    return new ValidationResult("MixedInspections");
  }

  static missingAccess() {
    return new ValidationResult("MissingAccess");
  }

  static missingInventoryItem() {
    return new ValidationResult("MissingInventoryItem");
  }

  static missingInspection() {
    return new ValidationResult("MissingInspection");
  }
}
