import { Field, Form as FormFormik, Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BooleanParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import * as yup from "yup";

import { ILoginWith2faDTO, LoginWith2faDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import { useAPIs } from "../../../services/serviceContext";
import { nameofFactory } from "../../../utils/nameofFactory";
import CancelButton from "../../shared/components/buttons/cancelButton";
import { FormikCheckboxInput } from "../../shared/components/forms/formik/formikCheckboxInput";
import { FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";

const ValidationSchema: yup.ObjectSchema<ILoginWith2faDTO> = yup.object({
  twoFactorCode: yup.string().required().min(6).max(8),
  rememberMachine: yup.boolean().required(),
  rememberMe: yup.boolean().required(),
});

interface IProps {
  onCancel?: () => void;
}

const nameof = nameofFactory<ILoginWith2faDTO>();

function LoginWith2FaForm(props: IProps) {
  const { onCancel } = props;
  const { t } = useTranslation();
  const { accountApi } = useAPIs();
  const [query] = useQueryParams({
    rememberMe: withDefault(BooleanParam, undefined),
    returnUrl: withDefault(StringParam, undefined),
  });

  const submitter = useFormikAPISubmitter<ILoginWith2faDTO>(
    (values) => accountApi.loginWith2FA(new LoginWith2faDTO(values)),
    [accountApi],
    () => {
      let urlAfterLogin = query.returnUrl ? query.returnUrl : "/";
      if (urlAfterLogin.toLowerCase().includes("error")) {
        urlAfterLogin = "/";
      }
      const href = urlAfterLogin;
      window.location.href = href;
    },
  );

  const initialValues: ILoginWith2faDTO = {
    twoFactorCode: "",
    rememberMachine: false,
    rememberMe: query.rememberMe ?? false,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
      {(formikBag) => (
        <FormFormik>
          {formikBag.submitCount > 0 && (
            <ul className="text-danger">
              {Object.values(formikBag.errors).map((x, i) => (
                <li key={i}>{t(x)}</li>
              ))}
            </ul>
          )}
          <FormikTextInputGroup className="mb-3" label={t("Code")} name={nameof("twoFactorCode")} />
          <Form.Group controlId="rememberMachine" className="mb-3">
            <Field
              name="rememberMachine"
              component={FormikCheckboxInput}
              id="rememberMachine"
              label={t("Remember this machine?")}
            />
          </Form.Group>
          <Form.Group>
            <Button
              color="primary"
              type="submit"
              className="me-1"
              disabled={formikBag.isSubmitting || !formikBag.isValid}
            >
              {t("Submit")}
            </Button>
            {onCancel && <CancelButton onClick={onCancel} />}
          </Form.Group>
        </FormFormik>
      )}
    </Formik>
  );
}

export default LoginWith2FaForm;
