import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { EntityKey, PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { useAppContext } from "@/components/contexts/appContext";
import FeatureFlagService from "../../../pbdServices/services/FeatureFlags/featureFlagService";
import { MeAsUser } from "../../../pbdServices/services/UserSettings/models/me-as-user";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { ProfileRoutePaths } from "../profileRoutePaths";

const getLink = (t: TFunction, meAsUser: MeAsUser) => {
  const links: SidebarItem[] = [];
  links.push(
    new SidebarItem({
      title: t("My profile"),
      icon: qmBaseIcons.User,
      href: ProfileRoutePaths.MyProfile,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Account security"),
      icon: qmBaseIcons.UserShield,
      href: ProfileRoutePaths.Security,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Language"),
      icon: qmBaseIcons.Language,
      href: ProfileRoutePaths.LanguagePage,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Settings"),
      icon: qmBaseIcons.UserGear,
      href: ProfileRoutePaths.UserSettingsPage,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Issues"),
      icon: qmBaseIcons.Bug,
      href: ProfileRoutePaths.IssuesIndex,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Calendar"),
      icon: qmBaseIcons.CalendarPlus,
      href: ProfileRoutePaths.Calendar,
    }),
  );
  if (FeatureFlagService.isWatchingAvailable(meAsUser, EntityKey.ToDo)) {
    links.push(
      new SidebarItem({
        title: t("Watches"),
        icon: qmBaseIcons.Eye,
        href: ProfileRoutePaths.Watches,
      }),
    );
  }
  if (FeatureFlagService.isNotificationAvailable()) {
    links.push(
      new SidebarItem({
        title: t("Notifications"),
        icon: qmBaseIcons.Notification,
        href: ProfileRoutePaths.Notifications,
      }),
    );
  }
  return links;
};

function SidebarProfile() {
  const { t } = useTranslation();
  const { meAsUser } = useAppContext();
  const mappedLinks = getLink(t, meAsUser);
  return <SidebarNew app={PbdModule.Profile} items={mappedLinks} />;
}

export default SidebarProfile;
