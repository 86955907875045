import { entries } from "lodash";
import { useTranslation } from "react-i18next";

import { HealthStatus, IHealthReportDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import GenericAlert from "../../shared/components/alerts/genericAlert";

interface IProps {
  data: IHealthReportDTO;
}

function HealthAlert({ data }: IProps) {
  const { t } = useTranslation();

  if (data.status == HealthStatus.Healthy) return null;

  return (
    <GenericAlert
      type={data.status == HealthStatus.Unhealthy ? "danger" : "warning"}
      heading={t("We detected problems with your configuration")}
    >
      <ul>
        {entries(data.entries)
          .filter((x) => x[1].status != HealthStatus.Healthy)
          .map((x) => (
            <li key={x[0]}>
              {x[1].status}:{x[0]} {x[1].description}
            </li>
          ))}
      </ul>
    </GenericAlert>
  );
}
export default HealthAlert;
