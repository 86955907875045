import i18next from "i18next";

import { ApiException } from "@/generatedCode/pbd-core/pbd-core-api";

import JsonHelpers from "../../Json/jsonHelpers";
import { ApiErrorMap } from "../api-error-map";

/**
 * Code 400
 */
export class ApiValidationError extends ApiException implements IApiBaseError {
  kind: "BadRequest";
  validationErrors: ApiErrorMap;

  constructor(base: ApiException) {
    super(base.message, base.status, base.response, base.headers, base.result);
    this.kind = "BadRequest";
    this.validationErrors = new ApiErrorMap(base.result ?? JSON.parse(base.response));
    this.stack = base.stack;
  }
  get displayMessage() {
    return i18next.t("Bad request");
  }
}

/**
 * Code: 401
 */
export class ApiUnauthorizedError extends ApiException implements IApiBaseError {
  kind: "Unauthorized";

  constructor(base: ApiException) {
    super(base.message, base.status, base.response, base.headers, base.result);
    this.kind = "Unauthorized";
    this.stack = base.stack;
  }

  get displayMessage() {
    return i18next.t("Unauthorized");
  }
}

/**
 * Code: 403
 */
export class ApiForbiddenError extends ApiException implements IApiBaseError {
  kind: "Forbidden";

  constructor(base: ApiException) {
    super(base.message, base.status, base.response, base.headers, base.result);
    this.kind = "Forbidden";
    this.stack = base.stack;
  }

  get displayMessage() {
    return i18next.t("Forbidden");
  }
}

/**
 * Code: 404
 */
export class ApiNotFoundError extends ApiException implements IApiBaseError {
  kind: "NotFound";

  constructor(base: ApiException) {
    super(base.message, base.status, base.response, base.headers, base.result);
    this.kind = "NotFound";
    this.stack = base.stack;
  }

  get displayMessage() {
    return i18next.t("Not found");
  }
}

/**
 * Code: 422
 */
export class ApiUnprocessableEntityError extends ApiException implements IApiBaseError {
  kind: "UnprocessableEntity";
  messageExtension: string;
  constructor(base: ApiException) {
    super(base.message, base.status, base.response, base.headers, base.result);
    if (isProblemDetailsLocal(base.response)) {
      const parsed = JsonHelpers.parse<ProblemDetailsLocal>(base.response);
      this.messageExtension = parsed.detail;
    } else {
      this.messageExtension = "";
    }
    this.kind = "UnprocessableEntity";
    this.stack = base.stack;
  }

  get displayMessage() {
    return i18next.t("Unprocessable entity") + " " + this.messageExtension;
  }
}

/**
 * This error must not happen and is just the last resort to handle errors.
 */
export class ApiUnknownServerError extends ApiException implements IApiBaseError {
  kind: "Unknown";

  constructor(base: ApiException) {
    super(base.message, base.status, base.response, base.headers, base.result);
    this.kind = "Unknown";
    this.stack = base.stack;
  }

  get displayMessage() {
    return i18next.t("Internal Server Error, please retry later");
  }
}

/**
 * This error must also not happen. It could be used in a place where the swagger definition for a API is wrong.
 */
export class ApiUnknownError extends Error implements IApiBaseError {
  kind: "UnknownOrWrongDefinition";
  error: Error;

  constructor(err: Error) {
    super();
    this.kind = "UnknownOrWrongDefinition";
    this.error = err;
    this.name = err.name;
    this.message = err.message;
    this.stack = err.stack;
    this.cause = err.cause;
  }

  get displayMessage() {
    return i18next.t("Unknown Error, please retry later");
  }
}
interface IApiBaseError extends Error {
  /**This can be used to identify the error */
  kind: ErrorKind;
  /**Extended message with possible solutions for the user. */
  displayMessage: string;
}

type ErrorKind =
  | "BadRequest"
  | "Unauthorized"
  | "Forbidden"
  | "NotFound"
  | "UnprocessableEntity"
  | "Unknown"
  | "UnknownOrWrongDefinition";

// class ApiBaseError extends ApiException {
//   kind: ErrorKind;
//   constructor(base: ApiException) {
//     super(base.message, base.status, base.response, base.headers, base.result);
//   }
// }

export function apiExceptionToApiError(err: ApiException): ApiError {
  const code = err.status;
  switch (code) {
    case 400:
      //TODO: maybe validate that err.result is actually IValidationProblemDetails
      return new ApiValidationError(err);
    case 401:
      return new ApiUnauthorizedError(err);
    case 403:
      return new ApiForbiddenError(err);
    case 404:
      return new ApiNotFoundError(err);
    case 422:
      return new ApiUnprocessableEntityError(err);
    default:
      return new ApiUnknownError(err);
  }
}

export type ApiError =
  | ApiValidationError
  | ApiUnknownError
  | ApiUnauthorizedError
  | ApiForbiddenError
  | ApiUnknownServerError
  | ApiNotFoundError
  | ApiUnprocessableEntityError;

function isProblemDetailsLocal(obj: unknown): obj is ProblemDetailsLocal {
  if (typeof obj == "string") {
    const parsed = JSON.parse(obj);
    return parsed.status == 422;
  } else {
    return false;
  }
}

interface ProblemDetailsLocal {
  title: string;
  detail: string;
  status: number;
}
