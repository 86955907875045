import _ from "lodash";

import {
  ApplicationGroupDTO,
  BaseEntityPermissionWithId,
  EntityPermissionAddOrUpdateDTO,
  IEntityPermissionDTO,
  IGroupsControllerClient,
  IUsersControllerClient,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { ApplicationUserMockData } from "../../../ClientApp/__tests__/config/mockData/applicationUserMockData";
import { SelectedType } from "../../../ClientApp/access/components/models/selectedType";
import { ItemWithPermission } from "../../../ClientApp/access/pages/detailsPageAccess";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import { ListHelpers } from "../../../utils/listHelpers";
import { AuthService } from "../Authz/authService";
import CollaboratorService from "../Collaborators/collaboratorService";
import { MeAsUser } from "../UserSettings/models/me-as-user";
import { IDuplicatedPermission } from "./models/duplicatedPermission";
import { GroupCountPermission } from "./models/groupCountPermission";

export class EntityPermissionService {
  private usersApi: IUsersControllerClient;

  private groupsApi: IGroupsControllerClient;

  constructor(usersApi: IUsersControllerClient, groupsApi: IGroupsControllerClient) {
    this.usersApi = usersApi;
    this.groupsApi = groupsApi;
  }

  async getAllForAccess(permission: IEntityPermissionDTO) {
    const userIds = permission.users.map((x) => x.id);
    const users = userIds.length > 0 ? await this.usersApi.getAllQuery({ id: userIds }) : [];
    const usersMapped = permission.users.map((x) => {
      const user = users.find((f) => f.id == x.id) ?? ApplicationUserMockData.getPlaceholderUser(x.id);
      return { ...x, ...user };
    });
    const groupIds = permission.groups.map((x) => x.id);
    const groups = groupIds.length > 0 ? await this.groupsApi.getAllQuery({ id: groupIds }) : [];
    const groupsMapped = permission.groups.map((x) => {
      const group =
        groups.find((f) => f.id == x.id) ??
        new ApplicationGroupDTO({ id: x.id, name: "Deleted group", isSelfCheckInEnabled: false });
      return { ...x, ...group };
    });
    const collaborators = CollaboratorService.mapToList(usersMapped, groupsMapped);
    return { users, collaborators, groups };
  }

  canAccess(authService: AuthService, dto: IEntityPermissionDTO, moduleAdminRole: string) {
    if (authService.hasRole([PbdRoles.Admin])) return true;
    if (authService.hasRole([moduleAdminRole])) return true;

    const id = authService.user.user.id;
    const groups = authService.user.groups;

    //Find userId
    if (dto.users.find((x) => x.id === id) !== undefined) return true;

    //Find groupId
    if (dto.groups.map((x) => x.id).some((r) => r && groups.map((g) => g.id).includes(r))) return true;

    return false;
  }

  canAccess2(meAsUser: MeAsUser, dto: IEntityPermissionDTO, moduleAdminRole: string) {
    if (hasRole(meAsUser, [PbdRoles.Admin])) return true;
    if (hasRole(meAsUser, [moduleAdminRole])) return true;

    if (dto.users.map((x) => x.id).includes(meAsUser.user.id)) return true;
    if (dto.groups.map((x) => x.id).some((r) => meAsUser.groups.map((g) => g.id).includes(r))) return true;

    return false;
  }

  /**This mapping is required to deal with scenarios that either take data from legacy or from new auto generated apis */
  mapToAddOrUpdate(isPrivate: boolean, old?: IEntityPermissionDTO): EntityPermissionAddOrUpdateDTO {
    if (old?.groups) {
      for (let index = 0; index < old.groups.length; index++) {
        const element = old.groups[index];
        if (element instanceof BaseEntityPermissionWithId) {
          // Do nothing
        } else {
          old.groups[index] = new BaseEntityPermissionWithId(element);
        }
      }
    }

    if (old?.roles) {
      for (let index = 0; index < old.roles.length; index++) {
        const element = old.roles[index];
        if (element instanceof BaseEntityPermissionWithId) {
          // Do nothing
        } else {
          old.roles[index] = new BaseEntityPermissionWithId(element);
        }
      }
    }

    if (old?.users) {
      for (let index = 0; index < old.users.length; index++) {
        const element = old.users[index];
        if (element instanceof BaseEntityPermissionWithId) {
          // Do nothing
        } else {
          old.users[index] = new BaseEntityPermissionWithId(element);
        }
      }
    }

    return new EntityPermissionAddOrUpdateDTO({
      isPrivate: isPrivate,
      id: old?.id,
      groups: old?.groups ?? [],
      roles: old?.roles ?? [],
      users: old?.users ?? [],
      extendExisting: false,
    });
  }
  checkAccess(meAsUser: MeAsUser, item: ItemWithPermission, moduleAdminRoleName: string) {
    if (hasRole(meAsUser, [PbdRoles.Admin, moduleAdminRoleName])) {
      return true;
    } else if (item.responsible?.id == meAsUser.tenant.id) {
      return true;
    } else if (item.owner?.id == meAsUser.tenant.id) {
      return true;
    } else {
      return false;
    }
  }

  getDuplicates(items: IEntityPermissionDTO[]): IDuplicatedPermission[] {
    const duplicates: IDuplicatedPermission[] = [];
    const mapped = items
      .filter((x) => x.type?.app && x.type.keyValue)
      .map((x) => {
        return { ...x, externalId: `${x.type?.app}_${x.type?.keyValue}`, app: x.type?.app, keyValue: x.type?.keyValue };
      });
    const dupes = ListHelpers.groupAndSortStringArray(mapped.map((x) => x.externalId));
    // const dupes = ListHelpers.getDuplicatedExternalIds(mapped);
    for (const element of dupes.filter((x) => x.count > 1)) {
      const duplicatedItem = mapped.find((x) => x.externalId == element.key);
      if (duplicatedItem) {
        const duplicatedContent = items.filter(
          (x) => x.type?.app == duplicatedItem.app && x.type?.keyValue == duplicatedItem.keyValue,
        );
        duplicates.push({ key: element.key, duplicatedItems: duplicatedContent });
      }
    }
    return duplicates;
  }

  groupAndCount(dto: SelectedType[]) {
    const groups = dto.filterMap((x) => x.entityPermission?.groups).reduce((pv, cv) => pv.concat(cv), []);
    const users = dto.filterMap((x) => x.entityPermission?.users).reduce((pv, cv) => pv.concat(cv), []);
    const groupCount = _(groups)
      .groupBy((x) => x.id)
      .map(function (items, id) {
        return new GroupCountPermission(items, "Group");
      })
      .value();

    const userCount = _(users)
      .groupBy((x) => x.id)
      .map(function (items, id) {
        return new GroupCountPermission(items, "User");
      })
      .value();

    return { groupCount, userCount, total: groupCount.concat(userCount) };
  }
}
