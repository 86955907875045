import { ICategoryDTO, PbdStatus } from "@/generatedCode/pbd-core/pbd-core-api";

import StringHelpers from "../../../../utils/stringHelpers";

// export default class AvailableSearchFilters {
//     SearchFilters: SearchFilterTypes[] = [SearchFilterTypes.Status,Search];
// }

// export class SearchFilter {
//     title: SearchFilterTypes;
//     // value: string[];
// }
export enum SearchFilterTypes {
  Status = "Status",
  Tags = "Tags",
  CreatedAt = "CreatedAt",
  CreatedFrom = "CreatedFrom",
  CreatedTo = "CreatedTo",
  ClaimedAt = "ClaimedAt",
  LastUpdatedAt = "LastUpdatedAt",
  LastUpdatedFrom = "LastUpdatedFrom",
  LastUpdatedTo = "LastUpdatedTo",
  AbsentAt = "AbsentAt",
  DoneAt = "DoneAt",
  PlannedAt = "PlannedAt",
  Deadline = "Deadline",
  ClaimType = "ClaimType",
  Organisation = "Organisation",
  Responsible = "Responsible",
  Owner = "Owner",
  Attendees = "Attendees",
  Tenant = "Tenant",
  Claim = "Claim",
  InventoryCategory = "InventoryCategory",
  InventoryStatus = "InventoryStatus",
  InventoryInspection = "InventoryInspection",
  Functioning = "Functioning",
  AbsentFor = "AbsentFor",
  AbsencePolicy = "AbsencePolicy",
  Department = "Department",
  DepartmentPosition = "Position",
  IsDeleted = "IsDeleted",
  IsEmployee = "IsEmployee",
  OpportunityType = "OpportunityType",
  OpportunityCategory = "OpportunityCategory",
  CustomField = "CustomField",
  AuditRequirement = "AuditRequirement",
  Qualification = "Qualification",
  Address = "Address",
  Category = "Category",
  CreatedBy = "CreatedBy",
  ReviewerId = "ReviewerId",
  ApprovedById = "ApprovedById",
  Goal = "Goal",
  CustomForm = "CustomForm",
  InventoryItem = "InventoryItem",
  IsCustomer = "IsCustomer",
  IsSupplier = "IsSupplier",
  ApplicationUser = "ApplicationUser",
  /**Check whether tenant is also user */
  IsApplicationUser = "IsApplicationUser",
  ContributorId = "ContributorId",
  Recent = "Recent",
  NextInspectionAt = "NextInspectionAt",
  Project = "Project",
  SupporterId = "SupporterId",
  IsEmailConfirmed = "IsEmailConfirmed",
  IsQualificationRequired = "IsQualificationRequired",
  ApplicationGroup = "ApplicationGroup",
  PublishedAt = "PublishedAt",
  OnlyLatestRevision = "OnlyLatestRevision",
  claimIsJustified = "claimIsJustified",
  ProductId = "ProductId",
}

export type FilterType = "date" | "duration" | "checkbox" | "custom";

export class FilterElement {
  constructor({
    label,
    type,
    queryKey,
    statusOptions,
    description,
    filterType,
  }: {
    label: string;
    type: SearchFilterTypes;
    queryKey?: string;
    statusOptions?: PbdStatus[];
    description?: string;
    filterType?: FilterType;
  }) {
    this.label = label;
    this.queryKey = queryKey ?? StringHelpers.lowercaseFirstLetter(type);
    this.type = type;
    this.statusOptions = statusOptions;
    this.description = description;
    this.filterType = filterType ?? "custom";
  }
  type: SearchFilterTypes;
  label: string;
  /**can be used to overwrite the default values */
  statusOptions?: PbdStatus[];
  /**must be set if type of filter is category */
  categoryOptions?: ICategoryDTO[];
  /**This can be used to override the default query parameter.
   */
  queryKey: string;
  queryValue?: unknown;
  description?: string;
  filterType: FilterType;
}

export class PredefinedFilters {
  public static definedFilters: FilterElement[] = [
    new FilterElement({
      label: "Status",
      type: SearchFilterTypes.Status,
      statusOptions: [PbdStatus.Open, PbdStatus.InProgress, PbdStatus.Completed],
    }),
    new FilterElement({ label: "Tags", type: SearchFilterTypes.Tags }),
    new FilterElement({ label: "Created", type: SearchFilterTypes.CreatedAt }),
    new FilterElement({ label: "Created from", type: SearchFilterTypes.CreatedFrom, filterType: "date" }),
    new FilterElement({ label: "Created to", type: SearchFilterTypes.CreatedTo, filterType: "date" }),
    new FilterElement({ label: "Claimed at", type: SearchFilterTypes.ClaimedAt, filterType: "date" }),
    new FilterElement({ label: "Updated", type: SearchFilterTypes.LastUpdatedAt }),
    new FilterElement({ label: "Updated from", type: SearchFilterTypes.LastUpdatedFrom, filterType: "date" }),
    new FilterElement({ label: "Updated to", type: SearchFilterTypes.LastUpdatedTo, filterType: "date" }),
    new FilterElement({ label: "Next inspection", type: SearchFilterTypes.NextInspectionAt, filterType: "date" }),
    new FilterElement({ label: "Absent date", type: SearchFilterTypes.AbsentAt }),
    new FilterElement({ label: "Done", type: SearchFilterTypes.DoneAt }),
    new FilterElement({ label: "Planned", type: SearchFilterTypes.PlannedAt }),
    new FilterElement({ label: "Deadline", type: SearchFilterTypes.Deadline }),
    new FilterElement({ label: "Claim type", type: SearchFilterTypes.ClaimType }),
    new FilterElement({ label: "Organisation", type: SearchFilterTypes.Organisation }),
    new FilterElement({ label: "Responsible", type: SearchFilterTypes.Responsible }),
    new FilterElement({ label: "Owner", type: SearchFilterTypes.Owner }),
    new FilterElement({ label: "Attendees", type: SearchFilterTypes.Attendees }),
    new FilterElement({ label: "Persons", type: SearchFilterTypes.Tenant }),
    new FilterElement({ label: "Inventory category", type: SearchFilterTypes.InventoryCategory }),
    new FilterElement({ label: "Inventory status", type: SearchFilterTypes.InventoryStatus }),
    new FilterElement({ label: "Inventory inspection", type: SearchFilterTypes.InventoryInspection }),
    new FilterElement({ label: "Functioning", type: SearchFilterTypes.Functioning }),
    new FilterElement({ label: "Absent for", type: SearchFilterTypes.AbsentFor }),
    new FilterElement({ label: "Policy", type: SearchFilterTypes.AbsencePolicy }),
    new FilterElement({ label: "Department", queryKey: "departmentId", type: SearchFilterTypes.Department }),
    new FilterElement({ label: "Product", type: SearchFilterTypes.ProductId }),

    new FilterElement({
      label: "Position",
      queryKey: "departmentPositionId",
      type: SearchFilterTypes.DepartmentPosition,
    }),
    new FilterElement({ label: "Deleted", type: SearchFilterTypes.IsDeleted }),
    new FilterElement({
      label: "Only employees",
      type: SearchFilterTypes.IsEmployee,
      description: "Shows only persons that are marked as employees",
      filterType: "checkbox",
    }),
    new FilterElement({ label: "Type", type: SearchFilterTypes.OpportunityType }),
    new FilterElement({ label: "Category", type: SearchFilterTypes.OpportunityCategory }),
    new FilterElement({ label: "Custom Field", type: SearchFilterTypes.CustomField }),
    new FilterElement({ label: "Audit requirement", type: SearchFilterTypes.AuditRequirement }),
    new FilterElement({ label: "Claim", type: SearchFilterTypes.Claim }),
    new FilterElement({ label: "Qualification", type: SearchFilterTypes.Qualification }),
    new FilterElement({ label: "Addresses", type: SearchFilterTypes.Address }),
    new FilterElement({ label: "Category", type: SearchFilterTypes.Category }),
    new FilterElement({ label: "Created by", type: SearchFilterTypes.CreatedBy }),
    new FilterElement({ label: "Review by", type: SearchFilterTypes.ReviewerId }),
    new FilterElement({ label: "Approve by", type: SearchFilterTypes.ApprovedById }),
    new FilterElement({ label: "Goal", type: SearchFilterTypes.Goal }),
    new FilterElement({ label: "Form", type: SearchFilterTypes.CustomForm }),
    new FilterElement({ label: "Inventory item", type: SearchFilterTypes.InventoryItem }),
    new FilterElement({
      label: "Customer",
      type: SearchFilterTypes.IsCustomer,
      description: "Shows only contacts that are marked as Customers",
    }),
    new FilterElement({
      label: "Supplier",
      type: SearchFilterTypes.IsSupplier,
      description: "Shows only contacts that are marked as suppliers",
    }),
    new FilterElement({ label: "User", type: SearchFilterTypes.ApplicationUser }),
    new FilterElement({ label: "Only users", type: SearchFilterTypes.IsApplicationUser, filterType: "checkbox" }),
    new FilterElement({ label: "Contributor", type: SearchFilterTypes.ContributorId }),
    new FilterElement({ label: "Recent", type: SearchFilterTypes.Recent, filterType: "duration" }),
    new FilterElement({ label: "Project", type: SearchFilterTypes.Project }),
    new FilterElement({ label: "Supporter", type: SearchFilterTypes.SupporterId }),
    new FilterElement({ label: "IsEmailConfirmed", type: SearchFilterTypes.IsEmailConfirmed }),
    new FilterElement({
      label: "Qualifications required",
      type: SearchFilterTypes.IsQualificationRequired,
      description: "Shows only persons that require these qualifications",
      filterType: "checkbox",
    }),
    new FilterElement({ label: "Group", type: SearchFilterTypes.ApplicationGroup }),
    new FilterElement({ label: "Published at", type: SearchFilterTypes.PublishedAt }),
    new FilterElement({ label: "Only latest revision", type: SearchFilterTypes.OnlyLatestRevision }),
    new FilterElement({ label: "Justified", type: SearchFilterTypes.claimIsJustified }),
  ];

  static getList(availableFilters: SearchFilterTypes[]) {
    return PredefinedFilters.definedFilters.filter((x) => availableFilters.includes(x.type));
  }

  static getByType(type: SearchFilterTypes) {
    return PredefinedFilters.definedFilters.find((x) => x.type == type);
  }
}
