import { ErrorMessage, FieldHookConfig, useField } from "formik";
import { Form } from "react-bootstrap";

import { IBaseFormElementProps } from "./baseFormElementProps";
import { CustomFormLabel } from "./customFormLabel";

export function FormikInputGroupWrapper(
  props: FieldHookConfig<string> & IBaseFormElementProps & { children: React.ReactNode },
) {
  const { formText, id, children } = props;
  const [field, meta] = useField(props);

  const localId = id ?? props.name;

  const invalid = meta.error != undefined && meta.touched;

  return (
    <Form.Group className="mb-3" controlId={localId}>
      <CustomFormLabel {...props} />
      {children}
      <ErrorMessage {...field}>
        {(msg) => (
          <Form.Control.Feedback type="invalid" className="order-last">
            {msg}
          </Form.Control.Feedback>
        )}
      </ErrorMessage>
      {formText && <Form.Text muted>{formText}</Form.Text>}
    </Form.Group>
  );
}
