import { Alert, Button } from "react-bootstrap";
import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import { useToggle } from "@/hooks/useToggle";
import { ProfileRoutePaths } from "../../../ClientApp/profile/profileRoutePaths";
import { qmBaseIcons } from "../../../ClientApp/shared/components/icons/qmBaseIcons";
import { CachedLocalStorageItem } from "../../../stores/CachedLocalStorageItem";
import { LocalStorageKeys } from "../../../stores/localStorageKeys";
import { LocalStorageHelpers } from "../../../utils/localStorageHelpers";

export interface ErrorInfo {
  errorMessage: string;
  componentStack: string;
}

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const [details, toggleDetails] = useToggle();
  const { t } = useTranslation();

  const submitError = () => {
    const localData: CachedLocalStorageItem<ErrorInfo> = {
      lastUpdatedAt: new Date(),
      data: { errorMessage: error?.message ?? "", componentStack: error?.stack ?? "" },
    };
    LocalStorageHelpers.writeStorage(LocalStorageKeys.ErrorForSubmit, localData);
    const url = `${window.location.protocol}//${window.location.host}${ProfileRoutePaths.IssuesCreatePage}`;
    const win = window.open(
      `${url}?url=${window.location.host + window.location.pathname + window.location.search}`,
      "_blank",
    );
    if (win) {
      win.focus();
    } else {
      throw Error("Window must not be null");
    }
  };

  return (
    <Alert variant="danger" onClose={() => resetErrorBoundary()} dismissible>
      <h4 className="alert-heading text-center">
        <qmBaseIcons.SadTear /> {t("Error")} - {t("Something went wrong")}
      </h4>
      <hr />
      <div className="text-center">
        <img src="/images/undraw_QA_engineers_dg5p.svg" alt="qaEngineer" style={{ width: "20%" }} />
      </div>
      <div className="d-grid gap-2 m-4">
        <Button variant="primary" size="lg" onClick={() => window.location.reload()}>
          {t("Reload this page and try it again")}
        </Button>
      </div>
      <div className="text-center">
        <h5>{t("If reloading this page does not help please view the details and submit a bug report.")}</h5>
        <pre>{error?.message}</pre>
        <p>
          <Button variant="link" onClick={toggleDetails}>
            {t("More details")}
          </Button>
        </p>

        {details && (
          <span>
            <hr />
            Name:
            <pre>{error?.name}</pre>
            <hr />
            Stack:
            <pre>{error?.stack}</pre>
            <Button onClick={submitError}>{t("Submit error")}</Button>
            <hr />
          </span>
        )}
      </div>
    </Alert>
  );
}
