import { Formik } from "formik";
import { Card, CloseButton, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  ArticleRevisionDTO,
  ArticleRevisionType,
  IArticleDetailsDTO,
  IArticleRevisionCreateDTO,
  IArticleRevisionDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import ArticleRevisionService from "../../../pbdServices/services/Articles/articleRevisionService";
import ArticleService from "../../../pbdServices/services/Articles/articleService";
import { nameofFactory } from "../../../utils/nameofFactory";
import FormikCustomForm from "../../shared/components/forms/formik/formikCustomForm";
import { FormikHtmlInputGroup } from "../../shared/components/forms/formik/formikHtmlInput";
import { FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";

import { sleep } from "@/utils/sleep";
import { CustomBpmnEditor } from "./bpmnEditor";
import { CustomBpmnBusinessObject } from "./bpmnModels";

type LocalForm = IArticleRevisionCreateDTO & { selected?: CustomBpmnBusinessObject; legends?: Record<string, string> };

const nameof = nameofFactory<LocalForm>();

interface IProps {
  onSubmit: (dto: IArticleRevisionCreateDTO) => Promise<ArticleRevisionDTO | null>;
  onCancel: () => void;
  onSuccess: (dto: IArticleRevisionDTO | null) => void;
  articleRevision?: IArticleRevisionDTO;
  fileToUpload?: File;
  article: IArticleDetailsDTO;
}

function EditBpmnForm(props: IProps) {
  const { t } = useTranslation();
  const { article, articleRevision, onSubmit, onCancel, onSuccess } = props;

  const initialValues: LocalForm = {
    title: articleRevision?.title ?? article.title,
    articleId: article.id,
    content: ArticleService.deserializeBpmn(articleRevision?.content ?? article.content).content,
    type: ArticleRevisionType.Bpmn,
    legends: ArticleService.deserializeBpmn(articleRevision?.content ?? article.content).legends,
  };

  const submitter = useFormikAPISubmitter<LocalForm, ArticleRevisionDTO | null>(
    (val) =>
      onSubmit({
        ...val,
        content: ArticleService.serializeBpmnContent(val.content, val.legends),
        title: ArticleService.sanitizeArticleTitle(val),
      }),
    [onSubmit],
    onSuccess,
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitter}
      validationSchema={ArticleRevisionService.ValidationSchema}
    >
      {(formikBag) => (
        <FormikCustomForm formikBag={formikBag} onCancel={onCancel}>
          <div>
            <FormikTextInputGroup name={nameof("title")} />
            <Row>
              <Col md={formikBag.values.selected ? 9 : 12}>
                <CustomBpmnEditor
                  diagramXML={formikBag.values.content}
                  legends={formikBag.values.legends}
                  onChange={(xml) => formikBag.setFieldValue(nameof("content"), xml)}
                  onClick={async (e) => {
                    await formikBag.setFieldValue(nameof("selected"), undefined);
                    if (e) {
                      await sleep(250);
                      await formikBag.setFieldValue(nameof("selected"), e.element.businessObject);
                    }
                  }}
                />
              </Col>
              {formikBag.values.selected && (
                <Col>
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <Card.Title>{t("Description")}</Card.Title>
                      <div>
                        <CloseButton onClick={() => formikBag.setFieldValue(nameof("selected"), undefined)} />
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <FormikHtmlInputGroup
                        name={`${nameof("legends")}.${formikBag.values.selected.id}`}
                        label={t("Description")}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </FormikCustomForm>
      )}
    </Formik>
  );
}
export default EditBpmnForm;
