import i18next from "i18next";
import React from "react";
import { Button } from "react-bootstrap";
import { CellProps, Column } from "react-table";

import { IDepartmentPositionVM } from "../../../pbdServices/services/DepartmentPositions/models/department-position-vm";
import { EmployeeRoutePaths } from "../../employees/employeeRoutePaths";
import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { getIdColumn } from "../../shared/components/tables/columns/idColumn";
import { getTitleColumn } from "../../shared/components/tables/columns/titleColumn";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";
import { useFilteredColumnsMemo } from "../../shared/components/tables/tableHelperFunctions";
import InfoCell from "./infoCell";

type IProps = BaseTableProps<IDepartmentPositionVM>;

export function DepartmentPositionTable(props: IProps) {
  const {
    columnsVisibleDefault = ["title", "department"],
    onRowClick,
    columnsToExclude,
    includeTitleLink = true,
    titleColumnBaseLink = window.location.pathname.startsWith("/Employees")
      ? EmployeeRoutePaths.DetailsPageDepartmentPosition
      : SettingsRoutePaths.EditPageDepartmentPositions,
  } = props;

  const columns = React.useMemo<Column<IDepartmentPositionVM>[]>(
    () => [
      getIdColumn(includeTitleLink, titleColumnBaseLink),
      getTitleColumn(includeTitleLink, titleColumnBaseLink),
      {
        id: "department",
        Header: <>{i18next.t("Department")}</>,
        accessor: (x) => x.department.title,
      },
      {
        id: "childDepartmentPositions",
        Header: <>{i18next.t("Subordinated positions")}</>,
        accessor: (x) => x.childDepartmentPositions?.length,
      },
      {
        id: "parentDepartmentPositionCount",
        Header: <>{i18next.t("Parent positions")}</>,
        accessor: (x) => x.parentDepartmentPositionCount,
      },

      {
        id: "requiredCompanyFunctionCount",
        Header: <>{i18next.t("Connected company functions count")}</>,
        accessor: (x) => x.requiredCompanyFunctionsCount,
      },
      {
        id: "requiredCompanyFunctions",
        Header: <>{i18next.t("Connected company functions")}</>,
        accessor: "requiredCompanyFunctions",
        disableSortBy: true,
        Cell: ({ row }: CellProps<IDepartmentPositionVM>) => (
          <>{row.original.requiredCompanyFunctions?.map((x) => x.title).join(", ")}</>
        ),
      },
      {
        id: "minimumCapacity",
        Header: <>{i18next.t("Minimum capacity")}</>,
        accessor: (x) => x.minimumCapacity,
      },
      {
        id: "standardCapacity",
        Header: <>{i18next.t("Standard capacity")}</>,
        accessor: (x) => x.standardCapacity,
      },
      {
        id: "maximumCapacity",
        Header: <>{i18next.t("Maximum capacity")}</>,
        accessor: (x) => x.maximumCapacity,
      },
      {
        id: "isPrimary",
        Header: <>{i18next.t("Primary")}</>,
        accessor: (x) => x.isPrimary,
        Cell: ({ row }: CellProps<IDepartmentPositionVM>) => (
          <>
            {row.original.isPrimary ? (
              <qmBaseIcons.Star style={{ color: "purple" }} title={i18next.t("Primary")} />
            ) : (
              <React.Fragment>
                {onRowClick ? (
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    title={i18next.t("Set as primary")}
                    onClick={() => onRowClick({ row: row.original, action: "SetAsPrimary" })}
                  >
                    <qmBaseIcons.Star />
                  </Button>
                ) : (
                  <qmBaseIcons.Star />
                )}
              </React.Fragment>
            )}
          </>
        ),
      },
      {
        id: "infoColumn",
        Header: <>{i18next.t("Details")}</>,
        accessor: (x) => x,
        disableSortBy: true,
        Cell: ({ row }: CellProps<IDepartmentPositionVM>) => <InfoCell data={row.original} />,
      },
    ],
    [],
  );
  const filteredColumns = useFilteredColumnsMemo(columns, columnsToExclude);

  return (
    <ReactstrapTable<IDepartmentPositionVM>
      columns={filteredColumns}
      columnsVisibleDefault={columnsVisibleDefault}
      localStorageStateKey={"DepartmentPositionDTO"}
      {...props}
    />
  );
}
