import {
  IClaimDTO,
  IClaimsControllerClient,
  IDefectCategoriesControllerClient,
  IDefectDTO,
  IProductDTO,
  IProductsControllerClient,
  IToDoDTO,
  IToDosControllerClient,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { PrintOptions } from "../../../ClientApp/prints/components/printoutIncludeOptions";

export type CommonType = IToDoDTO | IClaimDTO | IDefectDTO | IProductDTO;

export interface IApiDataBundleExtension<T = CommonType> {
  dataArray: T[];
  title: string;
  printOptionId: keyof PrintOptions;
}
export default class PrintService {
  claimsApi: IClaimsControllerClient;
  toDosApi: IToDosControllerClient;
  productsApi: IProductsControllerClient;
  defectsApi: IDefectCategoriesControllerClient;
  constructor(
    claimsApi: IClaimsControllerClient,
    toDosApi: IToDosControllerClient,
    productsApi: IProductsControllerClient,
    defectsApi: IDefectCategoriesControllerClient,
  ) {
    this.claimsApi = claimsApi;
    this.toDosApi = toDosApi;
    this.productsApi = productsApi;
    this.defectsApi = defectsApi;
  }
  async getConnectedDataForPrint(id: string): Promise<IApiDataBundleExtension[]> {
    const todos = await this.claimsApi.getConnectedToDos(Number(id));
    const claims = await this.claimsApi.getChildClaimsForClaim(Number(id));
    const defects = await this.claimsApi.getDefectsForClaim(Number(id));
    const products = await this.claimsApi.getProductsForClaim(Number(id));

    const result: IApiDataBundleExtension[] = [
      { dataArray: todos, title: "ToDos", printOptionId: "showTodos" },
      { dataArray: claims, title: "Claims", printOptionId: "showClaims" },
      { dataArray: defects, title: "Defects", printOptionId: "showDefects" },
      { dataArray: products, title: "Products", printOptionId: "showProducts" },
    ];
    return result;
  }
}
