import RestUtilities from "../../services/restClients/restUtilities";

const prefix = "/Account";
export class AccountRoutePaths {
  public static Home = prefix;
  public static Login = `${prefix}/Login`;
  public static LoginFn = (userName?: string) => `${prefix}/Login/?userName=${userName}`;
  public static Register = `${prefix}/Register`;
  public static Request = `${prefix}/Request`;
  public static ForgotPassword = `${prefix}/ForgotPassword`;
  public static ForgotPasswordFn = (userName: string | undefined) =>
    `${prefix}/ForgotPassword/${RestUtilities.getQueryString({ userName })}`;
  public static ResetPassword = `${prefix}/ResetPassword`;
  public static ResetPasswordFn = (emailOrUserName: string) => `${prefix}/ResetPassword/?userName=${emailOrUserName}`;
  public static Goodbye = `${prefix}/Goodbye/`;
  public static LoginProvider = `${prefix}/LoginProviders`;
  public static ExternalLogin = `${prefix}/ExternalLogin`;
  public static LinkLogin = `${prefix}/LinkLogin`;
  public static ConfirmEmail = `${prefix}/ConfirmEmail`;
  public static ConfirmChangeEmail = `${prefix}/ConfirmChangeEmail`;
  public static ExternalLoginCallback = `${prefix}/ExternalLoginCallback`;
  public static LoginWith2FaPage = `${prefix}/LoginWith2FaPage`;
  public static LoginWithRecoveryCodePage = `${prefix}/LoginWithRecoveryCodePage`;
  public static ErrorPage = `${prefix}/Error/:code`;
  public static ActivateProfilePage = `${prefix}/Activate`;
  public static PrivacyPage = `${prefix}/Privacy`;
}
