import { Badge, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAppContext } from "@/components/contexts/appContext";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole, isInPreview, isInReadOnly } from "../../../../services/Authz/authService";
import { AccountRoutePaths } from "../../../account/accountRoutePaths";
import { DevRoutePaths } from "../../../dev/devRoutePaths";
import { ProfileRoutePaths } from "../../../profile/profileRoutePaths";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import AvatarSpanWithName from "../tenants/avatarSpanWithName";

export function LoggedInDropdownEntries() {
  const { meAsUser } = useAppContext();
  const { t } = useTranslation();

  return (
    <>
      <NavDropdown.Item as={Link} to={ProfileRoutePaths.MyProfile}>
        <div>
          <small className="text-muted mb-1">{t("Signed in as")}</small>
          <AvatarSpanWithName tenant={meAsUser.tenant} includePopover={false} />
          {isInPreview(meAsUser) && (
            <>
              {t("You are in")} <Badge bg="success">Preview</Badge> {t("Mode")}
            </>
          )}
          {isInReadOnly(meAsUser) && (
            <div>
              {t("You are in")} <Badge bg="warning">Read only</Badge> {t("Mode")}
            </div>
          )}
        </div>
        <qmBaseIcons.UserGear /> {t("Profile")}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item as={Link} to={AccountRoutePaths.Goodbye}>
        <qmBaseIcons.SignOut /> {t("Log off")}
      </NavDropdown.Item>
      {hasRole(meAsUser, [PbdRoles.Dev]) && (
        <>
          <NavDropdown.Divider />
          <NavDropdown.Item as={Link} to={DevRoutePaths.HomePage}>
            <qmBaseIcons.Revision color="warning" /> <Badge bg="warning">DEV</Badge>
          </NavDropdown.Item>
        </>
      )}
    </>
  );
}
