import { TFunction } from "i18next";
import { sortBy } from "lodash";
import { DateTime } from "luxon";
import * as yup from "yup";
import { boolean, date, mixed, number, object, ObjectSchema, string } from "yup";

import {
  Gender,
  ICapabilitiesDTO,
  IExternalIdSetting,
  ITenantCreateDTO,
  ITenantDTO,
  ITenantMinDTO,
  TenantCreateDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";
import { ControllerContextData } from "@/generatedCode/pbd-core/pbd-core-api-services";

import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { TableAction, TableClickCommand } from "../../../ClientApp/shared/components/tables/TableClickCommand";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { nullableString } from "../../../services/validation/stringSchemas";
import { filterMap } from "../../../utils/filterMap";
import { ValidationResultDescriber } from "../../Models/Shared/validation-result-describer";
import { WithWarnings } from "../../Models/Shared/with-warnings";
import { BaseExportService } from "../Base/BaseExportService";
import ExportService, { ExportType } from "../Export/exportService";

import { TenantQueries, TenantQueryParameters } from "./models/query-parameters";
import { ITenantConnectedToCompanyFunction } from "./models/tenant-connected-to-company-function";
import { ITenantConnectedToDepartmentPosition } from "./models/tenant-connected-to-department-position";
import { ITenantConnectedToQualification } from "./models/tenant-connected-to-qualification";
import { ITenantForBirthdayVM } from "./models/tenant-for-birthday-vm";
import { tenantFormatService } from "./tenantFormatService";

type LocalDateType = Pick<ITenantCreateDTO, "birthday">;
type TenantApis = Pick<ControllerContextData, "tenantsApi" | "accountApi" | "departmentPositionsApi">;
export default class TenantService extends BaseExportService<ITenantDTO> {
  apis: TenantApis;

  constructor(apis: TenantApis) {
    super("Tenants");
    this.apis = apis;
  }

  /**This must only be used to create a tenant and connect it to an user account */
  createMyProfile(tenant: ITenantCreateDTO) {
    return this.apis.accountApi.createTenant(new TenantCreateDTO(tenant));
  }

  async getAllQuery(query: TenantQueryParameters) {
    const resp = await this.apis.tenantsApi.getAllQuery({
      ...query,
      pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
    });
    return resp.data;
  }

  async getBirthdays(
    query: TenantQueryParameters = { isEmployee: true },
    take?: number,
  ): Promise<ITenantForBirthdayVM[]> {
    //TODO2 query backend with birthday instead of doing this in the frontend
    const tenants = (
      await this.apis.tenantsApi.getAllQuery({
        ...query,
        pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
      })
    ).data;
    const tenantsWithBirthday = filterMap(tenants, (t) => {
      if (t.birthday === undefined || !t.publishBirthday) return undefined;

      const currentYear = DateTime.now().year;
      const birthday = t.birthday.set({ year: currentYear });
      if (!birthday.isValid) return undefined;

      return {
        ...t,
        birthday,
      };
    });

    sortBy(tenantsWithBirthday, (x) => x.birthday);
    return take ? tenantsWithBirthday.slice(0, take) : tenantsWithBirthday;
  }

  async getAdminsExcludeQmBaseUser() {
    let adminResp = (
      await this.apis.tenantsApi.getAllQuery({
        roleName: PbdRoles.Admin,
        pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
      })
    ).data;
    if (adminResp.length > 1) {
      adminResp = adminResp.filter((x) => x.email?.toUpperCase() != GlobalQmBaseConstants.SupportMail);
    }
    return adminResp;
  }

  mapToExport(x: ITenantDTO): ExportType {
    return {
      id: x.id,
      externalId: x.externalId,
      firstName: x.firstName ?? "",
      lastName: x.lastName ?? "",
      email: x.email ?? "",
      createdAt: x.createdAt.toISO(),
      lastUpdatedAt: x.lastUpdatedAt?.toISO() ?? "",
      primaryDepartment: x.primaryDepartmentPosition?.department.title ?? "",
      secondaryPositions: tenantFormatService(x).secondaryPositions(),
      primaryDepartmentPosition: x.primaryDepartmentPosition?.title ?? "",
      telephone: x.telephone ?? "",
      jobStartDate: x.jobStartDate,
      jobEndDate: x.jobEndDate,
    };
  }

  async exportToCsvFromCommand(command: TableClickCommand, query?: TenantQueryParameters): Promise<void> {
    if (!command.isAllSelected && command.selected == undefined) {
      throw Error("Wrong data");
    }

    const pageSize = command.isAllSelected
      ? GlobalQmBaseConstants.DefaultPageSize_DuringMigration
      : command.selected!.length;

    const ids = !command.isAllSelected ? command.selected?.map((x) => Number(x)) : undefined;
    const resp = await this.apis.tenantsApi.getAllQuery({ ...TenantQueries.tenantIndex, ...query, id: ids, pageSize });
    this.exportToCsv(resp.data);
  }

  exportTenantConnectedToCompanyFunction(items: ITenantConnectedToCompanyFunction[]) {
    ExportService.exportCSV("TenantConnectedToCompanyFunction", items, (x) => ({
      id: x.id,
      name: x.fullName,
      department: x.primaryDepartmentPosition?.department.title ?? "",
      position: x.primaryDepartmentPosition?.title ?? "",
      fitness: x.fitness,
      isManuallyConnected: x.isManuallyConnected,
    }));
  }

  exportConnectedDTOToCsv(
    items: (
      | ITenantMinDTO
      | ITenantDTO
      | ITenantConnectedToQualification
      | ITenantConnectedToCompanyFunction
      | ITenantConnectedToDepartmentPosition
    )[],
    type:
      | "TenantDTO"
      | "TenantConnectedToQualification"
      | "TenantConnectedToCompanyFunction"
      | "TenantConnectedToDepartmentPosition",
  ) {
    if (type == "TenantConnectedToCompanyFunction") {
      this.exportTenantConnectedToCompanyFunction(items as ITenantConnectedToCompanyFunction[]);
      return;
    } else if (type == "TenantDTO") {
      this.exportToCsv(items as ITenantDTO[]);
      return;
    } else if (type == "TenantConnectedToQualification") {
      this.exportToCsv(items as ITenantConnectedToQualification[]);
      return;
    }
    throw Error("Not implemented");
  }

  static createWarningOnClient(items: WithWarnings<ITenantDTO>[], extId?: IExternalIdSetting) {
    const duplicatedEmails = TenantService.findDuplicatedEmails(items);
    for (const item of items) this.#createWarningsOnClient(item, duplicatedEmails, extId);
  }

  static normMail(email?: string) {
    return email?.toLowerCase() ?? "";
  }

  static #createWarningsOnClient(
    item: WithWarnings<ITenantDTO>,
    duplicatedEmails: Set<string>,
    extId?: IExternalIdSetting,
  ) {
    item.warnings = [];
    if (!item.externalId && extId?.isRequired) {
      item.warnings.push(ValidationResultDescriber.externalIdMissing());
    }
    if (!item.birthday) {
      item.warnings.push(ValidationResultDescriber.missingBirthday());
    }
    if (item.email && duplicatedEmails.has(this.normMail(item.email))) {
      item.warnings.push(ValidationResultDescriber.duplicatedEmail());
    }
  }

  static findDuplicatedEmails(items: ITenantDTO[]) {
    const set = new Set<string>();
    const sortedArr = items
      .map((x) => TenantService.normMail(x.email))
      .filter((x) => x != "")
      .sort();
    for (let i = 0; i < sortedArr.length - 1; i++) {
      if (sortedArr[i + 1] == sortedArr[i]) {
        set.add(sortedArr[i]);
      }
    }
    return set;
  }

  static isIntegration(item: ITenantMinDTO | ITenantDTO | undefined) {
    if (!item) {
      return false;
    } else if (this.isTenant(item)) {
      return item.isInterface;
    }
    return item.fullName.includes("Interface");
  }

  static isTenant(item: ITenantDTO | ITenantMinDTO): item is ITenantDTO {
    return (item as ITenantDTO).isInterface !== undefined;
  }

  static validationSchemaCreateMyProfile(t: TFunction) {
    const schema = this.validationSchema(t);
    const emailRequiredSchema: ObjectSchema<Pick<ITenantCreateDTO, "email">> = object({
      email: string().email().label(t("Email")).ensure(),
    });
    const combinedSchema = schema.concat(emailRequiredSchema);
    return combinedSchema;
  }

  static validationSchema(t: TFunction, externalIdSettings?: IExternalIdSetting): ObjectSchema<ITenantCreateDTO> {
    // @ts-expect-error FIX with better date typing
    const dateSchema: ObjectSchema<LocalDateType> = object({
      birthday: date().notRequired().nullable().default(undefined),
    });
    let validationSchema: ObjectSchema<ITenantCreateDTO> = yup
      .object({
        gender: mixed<Gender>().oneOf(Object.values(Gender)).required(),
        firstName: string().required().min(2).max(250).label(t("First name")),
        lastName: string().required().min(2).max(250).label(t("Last name")),
        email: string().email().label(t("Email")).ensure(),
        telephone: nullableString,
        publishBirthday: boolean().required(),
        description: string(),
        isEmployee: boolean().required(),
        externalId: nullableString,
        organisationId: number(),
        organisationExternalId: nullableString,
        employeeId: nullableString,
      })
      .concat(dateSchema);

    if (externalIdSettings?.isRequired) {
      const externalIdRequiredSchema: yup.ObjectSchema<Pick<ITenantCreateDTO, "externalId">> = yup.object({
        externalId: yup.string().required(),
      });
      validationSchema = validationSchema.concat(externalIdRequiredSchema);
    }
    return validationSchema;
  }
  static get availableFilter() {
    return [
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.CreatedFrom,
      SearchFilterTypes.Tags,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.Department,
      SearchFilterTypes.DepartmentPosition,
      SearchFilterTypes.Organisation,
    ];
  }

  static get availableFiltersConnectModal() {
    return [
      SearchFilterTypes.LastUpdatedFrom,
      SearchFilterTypes.LastUpdatedTo,
      SearchFilterTypes.CreatedFrom,
      SearchFilterTypes.CreatedTo,
      SearchFilterTypes.Tags,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.Department,
      SearchFilterTypes.DepartmentPosition,
      SearchFilterTypes.IsEmployee,
      SearchFilterTypes.IsApplicationUser,
    ];
  }
  static PermissionLevels: (keyof ICapabilitiesDTO)[] = ["canAccess", "canEdit"];

  static get IndexEmployeeTableActions(): TableAction[] {
    return ["ResetFilter", "Export", "Delete", "RemoveFromEmployees", "CreateUser"];
  }
}
