import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { ListGroup, ListGroupItem, ListGroupItemHeading } from "reactstrap";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { SettingsRoutePaths } from "../../settingsRoutePaths";

const CrmSubsidebar: React.FC = (props) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <ListGroup flush>
        <ListGroupItem>
          <ListGroupItemHeading>{t("CRM")}</ListGroupItemHeading>
        </ListGroupItem>
      </ListGroup>

      <ListGroup flush>
        <ListGroupItem>
          <ListGroupItemHeading>{t("Organisations")}</ListGroupItemHeading>
        </ListGroupItem>
        <ListGroupItem
          action
          tag={Link}
          to={SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.Organisations)}
          active={pathname.startsWith(
            SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.Organisations).toLowerCase(),
          )}
        >
          {t("Custom Fields")}
        </ListGroupItem>
      </ListGroup>
    </React.Fragment>
  );
};

export default CrmSubsidebar;
