import { AnnotationOptions } from "chartjs-plugin-annotation";
import i18next from "i18next";

import { IGroupedResult } from "../pbdServices/services/Address/addressService";

export class ChartHelpers {
  static getChartAnnotations(labelContent: string, value: number): AnnotationOptions {
    return {
      borderColor: "green",
      borderDash: [6, 6],
      scaleID: "y",
      label: {
        content: labelContent,
        display: true,
      },
      type: "line",
      value,
    };
  }

  static consolidateDataForDisplay(array: IGroupedResult[], take = 5) {
    const entriesToDisplay = array.slice(0, take);
    const totalCount = array.reduce((pv, cv) => pv + cv.count, 0);
    const otherCount = totalCount - entriesToDisplay.reduce((pv, cv) => pv + cv.count, 0);
    if (otherCount > 0) {
      entriesToDisplay.push({
        key: i18next.t("Other"),
        count: otherCount,
        percentage: otherCount / totalCount,
      });
    }
    return entriesToDisplay;
  }
}
