import _ from "lodash";

export default class JsonHelpers {
  static tryParse(value?: string): [unknown, boolean] {
    if (!_.isString(value)) return [undefined, false];

    try {
      const obj = JSON.parse(value);
      return [obj, true];
    } catch {
      return [undefined, false];
    }
  }

  static stringify<T>(value: T) {
    return JSON.stringify(value);
  }

  static isJson(value: string | unknown): boolean {
    if (typeof value != "string") return false;
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  }

  static parse<T>(value: string, defaultValue?: T) {
    const [obj, isJson] = JsonHelpers.tryParse(value);

    if (isJson) return obj as T;
    if (defaultValue) return defaultValue;
    throw Error("No valid json");
  }

  static parseUnknown<T>(value: unknown) {
    return JsonHelpers.parse<T>(value as string);
  }

  static parseToCamelCase<T>(value: string) {
    if (this.isJson(value)) {
      return JSON.parse(value, this.toCamelCase) as T;
    } else {
      throw Error("No valid json");
    }
  }

  static toCamelCase(key: string, value: any) {
    if (_.isObject(value)) {
      const obj = value as Record<string, unknown>;

      // return _.mapKeys(value, (v, k) => _.camelCase(k));

      // for (const k in Object.keys(value)) {
      //   const key = k as string;
      //   if (/^[A-Z]/.test(key) && _.has(value, key)) {
      //     obj[_.camelCase(key)] = obj[key];
      //     delete obj[key];
      //   }
      // }
    }
    if (value && typeof value === "object") {
      // const obj = value as Record<string, unknown>;
      for (const k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  }
}
