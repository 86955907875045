import { uniq } from "lodash";

import { IPagedResponse } from "./pagedResponse";

export interface PagedResultParams2 {
  page?: number;
  pageSize?: number;
}

export interface PagedResultParams {
  id: number[];
  pageSize?: number;
}

export class PaginationHelpers {
  /**This function splits up a large query into smaller chunks.
   * Goal is to prevent urls in the query that exceed the maximum length. */
  static async getAllPaged<T>(
    callbackFunc: (params: PagedResultParams) => Promise<T[]>,
    ids: number[],
    pageSize = 100,
  ): Promise<T[]> {
    let response: T[] = [];
    const uniqueIds = Array.from(uniq(ids).values());

    for (let i = 0; i < uniqueIds.length; i += pageSize) {
      const ids = uniqueIds.slice(i, i + pageSize);
      const result = await callbackFunc({
        id: ids,
      });

      response = [...response, ...result];
    }

    return response;
  }

  static async getAllPaged2<T, TQuery extends PagedResultParams2>(
    callbackFunc: (params: TQuery) => Promise<IPagedResponse<T>>,
    baseQuery?: TQuery,
    pageSize = 100,
  ): Promise<T[]> {
    let allData: T[] = [];
    let hasMore = false;
    let page = 1;
    do {
      // code block to be executed
      const result = await callbackFunc({ ...baseQuery, pageSize: pageSize, page: page });
      allData = [...allData, ...result.data];
      hasMore = result.pagination.hasNext;
      page++;
      // if (page > 3) {
      //   break;
      // }
    } while (hasMore);
    return allData;
  }

  static async getAllPaged3<T>(
    callbackFunc: (params: PagedResultParams) => Promise<IPagedResponse<T>>,
    ids: number[],
    pageSize = 100,
  ): Promise<T[]> {
    let response: T[] = [];
    const uniqueIds = Array.from(uniq(ids).values());

    for (let i = 0; i < uniqueIds.length; i += pageSize) {
      const ids = uniqueIds.slice(i, i + pageSize);
      const result = await callbackFunc({
        id: ids,
        pageSize: ids.length,
      });

      response = [...response, ...result.data];
    }

    return response;
  }

  /**
   * human-readable page numbers usually start with 1, so we reduce 1 in the first argument
   */
  static paginate<T>(array: T[], pageSize: number, pageNumber = 1) {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }
}
