import { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { useAPIs } from "../../../../../services/serviceContext";

import CustomSelect from "./customSelect";

interface IProps {
  onChange: (id?: number | number[]) => void;
  selectedIds?: number[];
  isMulti?: boolean;
  isClearable?: boolean;
  displayParentsOnly?: boolean;
}

function AuditRequirementSelect(props: IProps) {
  const { onChange, selectedIds, isMulti, isClearable, displayParentsOnly = false } = props;
  const { t } = useTranslation();
  const [includeSubRequirements, setIncludeSubRequirements] = useState(false);
  const { auditRequirementsApi } = useAPIs();
  const combinedBool = !includeSubRequirements && displayParentsOnly;
  const { data } = useSWR(["api/auditRequirements", `displayParentsOnly=${combinedBool}`], () =>
    auditRequirementsApi
      .getAll()
      .then((resp) => resp.filter((x) => (combinedBool ? x.parentAuditRequirementId == null : true))),
  );

  if (!data) return null;

  return (
    <>
      <CustomSelect
        data={data}
        isClearable={isClearable}
        onChange={onChange}
        name="customFormId"
        isMulti={isMulti}
        invalid={false}
        selectedIds={selectedIds}
        onBlur={undefined}
      />
      <Form.Check
        label={t("Include sub requirements")}
        onChange={(x) => setIncludeSubRequirements(x.currentTarget.checked)}
      />
    </>
  );
}

export default AuditRequirementSelect;
