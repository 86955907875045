import { FileCategoryDTO, HttpVerbs } from "@/generatedCode/pbd-core/pbd-core-api";

import { SettingsIndexDTO } from "../../Models/Settings/SettingsIndexDTO";

export function mapFileCategoryDTOToSettingsDTO(settingsRoute: string, data?: FileCategoryDTO[]) {
  if (!data) return undefined;

  return data.map((d) => {
    const item = new SettingsIndexDTO({
      id: d.id,
      title: d.title,
      links: [
        {
          rel: "self",
          href: settingsRoute.replace(":id", d.id.toString()),
          method: HttpVerbs.GET,
        },
      ],
    });
    return item;
  });
}
