import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import {
  FileCategoryCreateDTO,
  FileCategoryEditDTO,
  IFileCategoryCreateDTO,
  IFileCategoryDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../services/serviceContext";

import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import BaseSettingsFormHeader from "../components/editPage/baseSettingsFormHeader";
import { SettingsRoutePaths } from "../settingsRoutePaths";
import FileCategoriesForm from "./Component/fileCategoriesForm";

function EditPageFileCategories() {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { fileCategoriesApi } = useAPIs();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<IFileCategoryDTO>();
  const confirm = useConfirmation();

  React.useEffect(() => {
    async function getData() {
      if (id) {
        const category = await fileCategoriesApi.getById(Number(id));
        setItemToUpdate(category);
      }
      setLoading(false);
    }
    getData();
  }, [id]);

  async function handleSubmit(itemToCreate: IFileCategoryCreateDTO) {
    if (id) {
      await fileCategoriesApi.edit(Number(id), new FileCategoryEditDTO(itemToCreate));
    } else {
      await fileCategoriesApi.create(new FileCategoryCreateDTO(itemToCreate));
    }
    navigate(SettingsRoutePaths.IndexPageFileCategories);
  }

  const tryToDelete = (dto: IFileCategoryDTO) => {
    void confirm({ itemsToDelete: [dto] }).then(() => submitDeleteRequest(dto));
  };

  const submitDeleteRequest = async (dto: IFileCategoryDTO) => {
    await fileCategoriesApi.delete(dto.id);
    navigate(SettingsRoutePaths.IndexPageFileCategories);
  };

  return (
    <React.Fragment>
      {!loading && (
        <Card>
          <BaseSettingsFormHeader
            itemToUpdate={itemToUpdate}
            onDelete={itemToUpdate ? () => tryToDelete(itemToUpdate) : undefined}
          />
          <Card.Body>
            <FileCategoriesForm
              onSubmit={handleSubmit}
              itemToUpdate={itemToUpdate}
              onCancel={() => navigate(SettingsRoutePaths.IndexPageFileCategories)}
            />
          </Card.Body>
        </Card>
      )}
    </React.Fragment>
  );
}

export default EditPageFileCategories;
