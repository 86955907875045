import React from "react";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  type: "info" | "secondary" | "warning" | "danger" | "success";
}

const AlertIcon: React.FC<IProps> = (props) => {
  const { type } = props;
  switch (type) {
    case "success":
      return <qmBaseIcons.CheckCircle />;
    case "info":
    case "secondary":
      return <qmBaseIcons.InfoCircle />;
    case "warning":
      return <qmBaseIcons.Alert />;
    case "danger":
      return <qmBaseIcons.Danger />;
    default:
      throw Error("Not implemented");
  }
};
export default AlertIcon;
