import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, CardHeader, CardTitle } from "reactstrap";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";

interface IProps {
  pathToCreate?: string;
  recycleBinActive?: boolean;
  toggleRecycleBin?: () => void;
  /**Translated card title */
  cardTitle: string;
}

function SettingsCardHeader(props: IProps) {
  const { pathToCreate, recycleBinActive, toggleRecycleBin, cardTitle } = props;
  const { t } = useTranslation();
  return (
    <CardHeader>
      <div className="d-flex justify-content-between">
        <CardTitle tag="h5">{cardTitle}</CardTitle>
        <div>
          {pathToCreate && (
            <Button tag={Link} to={pathToCreate} color="outline-primary" title={t("New")}>
              <qmBaseIcons.Plus />
            </Button>
          )}

          {toggleRecycleBin && (
            <Button
              active={recycleBinActive}
              onClick={toggleRecycleBin}
              color="outline-secondary"
              className="m-1"
              title={t("Recycle bin")}
            >
              <qmBaseIcons.Delete />
            </Button>
          )}
        </div>
      </div>
    </CardHeader>
  );
}

export default SettingsCardHeader;
