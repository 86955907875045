import useSWRImmutable from "swr/immutable";

import { useAPIs } from "../services/serviceContext";

export const useExternalLoginHook = () => {
  const { externalLoginsApi } = useAPIs();
  const { data, mutate, isValidating } = useSWRImmutable(
    "/api/account/configuration",
    () => externalLoginsApi.getConfiguration(),
    { revalidateOnFocus: false },
  );

  return { data, mutate, isValidating };
};
