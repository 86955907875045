import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  className?: string;
}

function AccessDeniedBadge({ className }: IProps) {
  const { t } = useTranslation();
  return (
    <Badge bg="danger" className={className} title={t("Access denied")}>
      <qmBaseIcons.Lock />
    </Badge>
  );
}
export default AccessDeniedBadge;
