import React from "react";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../icons/qmBaseIcons";
import GenericAlert from "./genericAlert";

function AppUnderDevelopmentAlert(props: { children?: React.ReactNode }) {
  const { t } = useTranslation();
  return (
    <GenericAlert type="info">
      <qmBaseIcons.Wrench /> {t("This app is under active development. Some functions might not work as expected.")}
      {props.children}
    </GenericAlert>
  );
}

export default AppUnderDevelopmentAlert;
