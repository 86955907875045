import { t } from "i18next";

import { PbdRoles } from "../../../../../services/Authz/PbdRoles";
import RequiredRolesComponent from "../../../../admin/roles/components/requiredRolesComponent";
import CountCleanUpButton from "../cleanUpButton";
import NullCleanUpButton from "../nullCleanUpButton";

export interface ICleanUpButton {
  name: string;
  cleanUpPossible: boolean;
  onClickCleanUp: () => void;
  disabled: boolean;
}

interface IProps {
  name: string;
  availableAuthority: boolean;
  unconnectedCleanUpPossible: boolean;
  onClickUnconnectedCleanUp: () => Promise<void>;
  unconnectedDisabled: boolean;
  unusableCleanUpPossible: boolean;
  onClickUnusableCleanUp: () => void;
  unusableDisabled: boolean;
}

function EntryCleanUpComponent(props: IProps) {
  const {
    name,
    availableAuthority,
    unconnectedCleanUpPossible,
    onClickUnconnectedCleanUp,
    unconnectedDisabled,
    unusableCleanUpPossible,
    onClickUnusableCleanUp,
    unusableDisabled,
  } = props;

  return (
    <>
      {availableAuthority && (
        <>
          <div className="d-flex justify-content-between">
            <h4>{t("Administrative actions")}</h4>
            <RequiredRolesComponent roles={[PbdRoles.Admin]} />
          </div>
          <hr />
          <CountCleanUpButton
            name={name}
            onClickCleanUp={onClickUnconnectedCleanUp}
            cleanUpPossible={unconnectedCleanUpPossible}
            disabled={unconnectedDisabled}
          />
          <NullCleanUpButton
            name={name}
            onClickCleanUp={onClickUnusableCleanUp}
            cleanUpPossible={unusableCleanUpPossible}
            disabled={unusableDisabled}
          />
        </>
      )}
    </>
  );
}

export default EntryCleanUpComponent;
