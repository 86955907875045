import { useTranslation } from "react-i18next";

import { ClaimType } from "../../../../../Models/Enums/ClaimType";
import CustomSelect from "./customSelect";

interface IProps {
  onChange: (id?: ClaimType | ClaimType[]) => void;
  selectedIds?: ClaimType[];
  isMulti?: boolean;
  isClearable?: boolean;
}

const claimTypeList = Object.values(ClaimType);

function ClaimTypeSelect(props: IProps) {
  const { onChange, selectedIds, isMulti, isClearable } = props;

  const { t } = useTranslation();

  return (
    <CustomSelect<ClaimType>
      data={claimTypeList.map((x) => {
        return {
          title: t(x),
          id: x,
        };
      })}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={onChange}
      name="claimType"
      selectedIds={selectedIds}
      invalid={false}
    />
  );
}

export default ClaimTypeSelect;
