import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import TenantService from "../../../pbdServices/services/Tenants/tenantService";
import { FilterElement, SearchFilterTypes } from "../../shared/components/genericSearchFilter/availableSearchFilters";
import FilterComponent from "../../shared/components/genericSearchFilter/filterComponent";
import { getAvailableFilters } from "../../shared/components/search/genericSearchComponent";

interface IProps {
  app?: PbdModule.Employees | PbdModule.TenantManagement;
  onChange?: (dto: FilterElement[]) => void;
  defaultValue?: Record<string, unknown>;
  additionalFilters?: SearchFilterTypes[];
  filterFilter?: SearchFilterTypes[];
}

function TenantFilterComponent(props: IProps) {
  const { additionalFilters = [], filterFilter } = props;
  return (
    <FilterComponent
      availableFilters={getAvailableFilters(TenantService.availableFiltersConnectModal.concat(additionalFilters))
        .filter((x) => (props.app == PbdModule.Employees ? x.type != SearchFilterTypes.IsEmployee : true))
        .filter((x) => (filterFilter ? filterFilter.includes(x.type) : true))}
      {...props}
    />
  );
}
export default TenantFilterComponent;
