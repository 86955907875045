import React from "react";
import { Form, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

import { useAppContext } from "@/components/contexts/appContext";
import { ProfileRoutePaths } from "../../../profile/profileRoutePaths";
import { StartpageRoutePaths } from "../../../startpage/startpageRoutePaths";
import { qmBaseIcons } from "../icons/qmBaseIcons";

export const HelpDropdownEntries: React.FC = () => {
  const { t } = useTranslation();
  const [launchTour, setLaunchTour] = useQueryParam("launchTour", withDefault(BooleanParam, undefined));
  const {
    productConfig: { helpLinks },
  } = useAppContext();

  const handleTourClick = () => setLaunchTour(true);

  return (
    <React.Fragment>
      <NavDropdown.Item as="a" href={helpLinks.homePage.url} target="_blank" rel="noopener">
        <div>
          <qmBaseIcons.InfoCircle /> {t("Help")} <qmBaseIcons.ExternalLink />
        </div>
        <Form.Text color="muted">{t("Visit our help center to learn more about our software")}</Form.Text>
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={handleTourClick}>
        <qmBaseIcons.Directions /> {t("Launch guided tour")}
      </NavDropdown.Item>
      <NavDropdown.Item
        as="a"
        target="_blank"
        href={ProfileRoutePaths.IssuesCreatePage + `/?url=${window.location.href}`}
      >
        <qmBaseIcons.FaceSmile /> {t("Send us feedback")}
      </NavDropdown.Item>
      <NavDropdown.Item as="a" href={helpLinks.changelog.url} target="_blank" rel="noopener">
        🎉 {t("Software updates")} <qmBaseIcons.ExternalLink />
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to={StartpageRoutePaths.Contact}>
        👋 {t("Get in touch with us")}
      </NavDropdown.Item>
    </React.Fragment>
  );
};
