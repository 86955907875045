import { FormikHelpers, FormikValues } from "formik";
import React, { DependencyList } from "react";

import { ErrorValue } from "./api-error-map";
import { ApiCall1, wrapApiCall } from "./api-wrapper";
import { ApiError, ApiValidationError } from "./models/api-error";
import { trimWhiteSpaces } from "./submitterHelpers";

export type APIFormikSubmitterFn<Values extends FormikValues, Resp> = (values: Values) => Promise<Resp>;
export type APIFormikSuccessFn<Resp> = (resp: Resp) => Promise<void> | void;

export type FormikSubmitFn<Values extends FormikValues> = (
  values: Values,
  formikHelpers: FormikHelpers<Values>,
) => Promise<unknown>;

export const formikGlobalErrorKey = "formikGlobalErrors";

//TODO clear global errs before submit

function validationErrorsToFormikForm<Values>(
  actions: Pick<FormikHelpers<Values>, "setFieldError" | "setStatus">,
  err: ApiValidationError,
) {
  const globalErrs: ErrorValue[] = [];

  err.validationErrors.partitionErrors(
    (key, val) => actions.setFieldError(key, val.message),
    (val) => globalErrs.push(val),
    (key, val) => actions.setStatus({ [key]: val.message }),
  );

  const globalErrStr = globalErrs.length > 0 ? globalErrs.map((err) => err.message).join(", ") : undefined;
  actions.setFieldError(formikGlobalErrorKey, globalErrStr);
}

function errorsToFormikForm<Values>(actions: FormikHelpers<Values>, err: Exclude<ApiError, ApiValidationError>) {
  const msg = err.kind === "Unknown" ? err.message : err.displayMessage;
  actions.setFieldError(formikGlobalErrorKey, msg);
}

export function useFormikAPISubmitterWrapped<Values extends FormikValues = FormikValues, Resp = void>(
  onSubmit: ApiCall1<Resp, Values>,
  deps: DependencyList,
  onSuccess?: APIFormikSuccessFn<Resp> | undefined,
): FormikSubmitFn<Values> {
  const submit: FormikSubmitFn<Values> = React.useCallback(
    async (values, actions) => {
      trimWhiteSpaces(values);
      const apiResult = await onSubmit(values);
      actions.setSubmitting(false);

      if (apiResult.isOk) {
        if (onSuccess) {
          await onSuccess(apiResult.value);
        }
        // Good idea -> See formikSubmitButton.tsx for usage?
        actions.setStatus({ success: true, submittedValues: values });
        // Resetting seems to have to many side effects

        return;
      }

      if (apiResult.isErr) {
        const err = apiResult.error;
        if (err.kind === "BadRequest") {
          validationErrorsToFormikForm(actions, err);
        } else {
          errorsToFormikForm(actions, err);
        }

        return;
      }
    },
    //TODO: add custom eslint hook for this deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps],
  );

  return submit;
}

export function useFormikAPISubmitter<Values extends FormikValues = FormikValues, Resp = void>(
  onSubmit: APIFormikSubmitterFn<Values, Resp>,
  deps: DependencyList,
  onSuccess?: APIFormikSuccessFn<Resp> | undefined,
): FormikSubmitFn<Values> {
  const wrapped = React.useCallback(
    (values: Values) => wrapApiCall(() => onSubmit(values)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps],
  );
  return useFormikAPISubmitterWrapped(wrapped, [wrapped], onSuccess);
}
