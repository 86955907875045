import Cookies from "js-cookie";

export function getCurrentCulture(): string {
  const cookieValue = Cookies.get(".AspNetCore.Culture");
  if (cookieValue) {
    const cultureArray = cookieValue.split("=");
    //en-US, de, en-GB, etc
    const cultureString = cultureArray[cultureArray.length - 1];
    return cultureString;
  } else {
    const language = window.navigator.language;
    if (language === "de" || language == "de-DE" || language === "de-de") {
      Cookies.set(".AspNetCore.Culture", "c=de-DE|uic=de-DE", { expires: 365 });
    } else if (language === "en") {
      Cookies.set(".AspNetCore.Culture", "c=en-US|uic=en-US", { expires: 365 });
    } else if (language === "tr") {
      Cookies.set(".AspNetCore.Culture", "c=tr-TR|uic=tr-TR", { expires: 365 });
    } else {
      Cookies.set(".AspNetCore.Culture", "c=en-US|uic=en-US", { expires: 365 });
    }
    return language;
  }
}
