import { debounce } from "lodash";
import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  placeholder: string;
  doSearch: (criteria: string) => void;
  showFilterButton?: boolean;
  onFilterButtonClick?: () => void;
  extraBoxFilterComponent?: boolean;
  defaultValue?: string;
  autoFocus?: boolean;
  size?: "sm" | "lg";
}

function SearchCriteria(props: IProps) {
  const {
    showFilterButton,
    extraBoxFilterComponent,
    placeholder,
    onFilterButtonClick,
    doSearch,
    defaultValue = "",
    autoFocus,
    size,
  } = props;
  // State for search status (whether there is a pending API request)

  const onChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    doSearch(event.target.value);
  }, 200);

  if (showFilterButton) {
    return (
      <>
        {extraBoxFilterComponent ? (
          <Form.Group className="mb-3">
            <InputGroup size="lg">
              <Form.Control
                id="searchBox"
                name={"searchBox"}
                autoComplete="off"
                type="search"
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                autoFocus={autoFocus}
                role="searchbox"
              />
              <Button onClick={onFilterButtonClick}>
                <qmBaseIcons.Search />
              </Button>
            </InputGroup>
          </Form.Group>
        ) : (
          <InputGroup size={size}>
            <Form.Control
              type="search"
              placeholder={placeholder}
              defaultValue={defaultValue}
              onChange={onChange}
              autoFocus={autoFocus}
            />
            <Button color="primary" onClick={onFilterButtonClick}>
              <qmBaseIcons.Search />
            </Button>
          </InputGroup>
        )}
      </>
    );
  } else {
    return (
      <InputGroup>
        <InputGroup.Text>
          <qmBaseIcons.Search />
        </InputGroup.Text>
        <Form.Control
          type="search"
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
          role="searchbox"
          size={size}
          autoFocus={autoFocus}
        />
      </InputGroup>
    );
  }
}

export default SearchCriteria;
