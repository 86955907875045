import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { AppVM } from "../../pbdServices/Models/App/AppVM";
import RestUtilities from "../restClients/restUtilities";

export interface RouteModel {
  name?: string;
  template: string;
  httpMethod: string;
}

export default class ModuleService {
  getRoutesPerModule(routeBase: string) {
    return RestUtilities.get<RouteModel[]>(`/api/modules/routes/?routeBase=${routeBase}`);
  }

  static isAvailable(items: AppVM[], app: PbdModule) {
    const element = items.find((x) => x.type == app);
    if (element?.showElement) return true;
    return false;
  }
}
