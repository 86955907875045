import { IEntityPermissionDTO, MapImportToTable } from "@/generatedCode/pbd-core/pbd-core-api";

const routePrefix = "/Admin";

export class AdminRoutePaths {
  public static readonly HomePage = routePrefix;
  public static readonly HomePageUsers = `${routePrefix}/Users`;
  public static readonly UsersIndex = `${routePrefix}/Users/Index`;
  public static readonly UsersCreate = `${routePrefix}/Users/Create`;
  public static readonly UsersCreateBulk = `${routePrefix}/Users/CreateBulk`;
  public static readonly UsersDetails = `${routePrefix}/Users/Details/:id`;
  public static readonly UsersDetailsFn = (dto: { id: string }) =>
    `${routePrefix}/Users/Details/:id`.replace(":id", dto.id);

  public static readonly AccountRequestsIndex = `${routePrefix}/AccountRequests/Index`;
  public static readonly AccountRequestsDetails = `${routePrefix}/AccountRequests/Details/:id`;

  public static readonly HomePagePermissions = `${routePrefix}/Permissions`;
  public static readonly AllowedDomains = `${routePrefix}/Permissions/AllowedDomains`;
  public static readonly PermissionsPerUser = `${routePrefix}/Permissions/PermissionsPerUser`;
  public static readonly PermissionsPerUserPerModule = `${routePrefix}/Permissions/PermissionsPerUser/:module`;
  public static readonly IndexPageEntityPermission = `${routePrefix}/Permissions/EntityPermissions/Index`;
  public static readonly DetailsPageEntityPermission = `${routePrefix}/Permissions/EntityPermissions/Details/:id`;
  public static readonly DetailsPageEntityPermissionFn = (dto: Pick<IEntityPermissionDTO, "id">) =>
    this.DetailsPageEntityPermission.replace(":id", dto.id);

  public static readonly SetTenantInSpecialRole = `${routePrefix}/GettingStarted/SetTenantInSpecialRole/:specialRole`;
  public static readonly SetProductOwner = `${routePrefix}/GettingStarted/SetProductOwner`;
  public static readonly IndexPageCustomization = `${routePrefix}/Customization`;
  public static readonly ConfigurationsIndex = `${routePrefix}/Configurations`;
  public static readonly GeneralConfiguration = `${routePrefix}/Configurations/General`;
  public static readonly CreateCostTemplate = `${routePrefix}/Configurations/General/CostTemplates/Create`;
  public static readonly EditCostTemplate = `${routePrefix}/Configurations/General/CostTemplates/Edit/:id`;
  public static readonly EditCostTemplateItem = `${routePrefix}/Configurations/General/CostTemplates/Edit/:costTemplateId/Items/:id`;
  public static readonly CreateCostTemplateItem = `${routePrefix}/Configurations/General/CostTemplates/Edit/:costTemplateId/Items/Create`;
  public static readonly ConfigurationsStartpage = `${routePrefix}/Configurations/Startpage`;
  public static readonly ArticleConfigurationPage = `${routePrefix}/Configurations/Articles/:pbdModule`;
  public static readonly EmployeeConfiguration = `${routePrefix}/Configurations/Employees`;
  public static readonly CrmConfiguration = `${routePrefix}/Configurations/Crm`;
  public static readonly IdeaManagementConfiguration = `${routePrefix}/Configurations/IdeaManagement`;
  public static readonly AppConfigurationPage = `${routePrefix}/Configurations/:pbdModule`;
  public static readonly HomePageIntegrations = `${routePrefix}/Integrations`;
  public static readonly DetailsPageIntegrations = `${routePrefix}/Integrations/Details/:id`;
  public static readonly CreatePageIntegrations = `${routePrefix}/Integrations/Create`;
  public static readonly DetailsPageInternalIntegrations = `${routePrefix}/Integrations/Details/Internal/:id`;
  public static readonly HomePageBilling = `${routePrefix}/Billing`;
  public static readonly EmailIndex = `${routePrefix}/Email`;
  public static readonly ImportHomePage = `${routePrefix}/Import`;
  public static readonly ImportHomePageFn = (dto: MapImportToTable) => `${routePrefix}/Import/?importTo=${dto}`;
  public static readonly ImportDetailsPage = `${routePrefix}/Import/Details/:id`;
  public static readonly ArticleImportPage = `${routePrefix}/Import/Details/Article`;
  public static readonly AuditTrail = `${routePrefix}/AuditTrail`;
  public static readonly AuditTrailDetails = `${routePrefix}/AuditTrail/Details/:id`;
  public static readonly AuditTrailDetailsFn = (id: number) =>
    `${routePrefix}/AuditTrail/Details/:id`.replace(":id", id.toString());
  // public static readonly IndexPageCosts = CostRoutePaths.IndexPageCostsByModule.replace(
  //   ":pbdModule",
  //   PbdModule.Admin.toString(),
  // );
  public static readonly DetailsPageCosts = `${routePrefix}/Costs/Details/:id`;
  public static readonly IndexPageFiles = `${routePrefix}/Files`;
  public static readonly DetailsPageFiles = `${routePrefix}/Files/Details/:id`;
  public static readonly IndexPageBackup = `${routePrefix}/Backup`;
  public static readonly IndexPageRoles = `${routePrefix}/Roles`;
  public static readonly DetailsPageRoles = `${routePrefix}/Roles/Details/:id`;
  public static readonly CreatePageRoles = `${routePrefix}/Roles/Create`;
  public static readonly IndexPageGroups = `${routePrefix}/Groups`;
  public static readonly CreatePageGroups = `${routePrefix}/Groups/Create`;
  public static readonly DetailsPageGroups = `${routePrefix}/Groups/Details/:id`;
  public static readonly IndexPageSyncHistory = `${routePrefix}/SyncHistory/Index`;
  public static readonly DetailsPageSyncHistory = `${routePrefix}/SyncHistory/Details/:id`;
  public static readonly CustomizationsLogoPage = `${routePrefix}/Customization/Logo/:id`;
  public static readonly CreatePageTemplates = `${routePrefix}/Configurations/:pbdModule/Templates/Create`;
  public static readonly EditPageTemplates = `${routePrefix}/Configurations/:pbdModule/Templates/Edit/:id`;
  public static readonly IndexPageModuleSettings = `${routePrefix}/ModuleSettings/Index`;
  public static readonly DetailsPageModuleSettings = `${routePrefix}/ModuleSettings/:pbdModule/:type`;

  public static readonly IndexPageConnections = `${routePrefix}/Connections`;
  public static readonly DetailsPageConnection = `${routePrefix}/Connections/Details/:id`;
  public static readonly ContactPage = `${routePrefix}/Contact`;
  public static readonly PrivacyPage = `${routePrefix}/Privacy`;
}
