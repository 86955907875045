import React from "react";
import { Card, CardBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CustomizationIndex: React.FC = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <h3>&nbsp;</h3>
      <Card>
        <CardBody className="text-center">
          <p className="lead">{t("Please select")}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CustomizationIndex;
