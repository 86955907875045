export class ProductNames {
  public static qmBase = "qmBase";
  public static CareNavigator = "CareNavigator";
  public static DocuPro = "DocuPro";
  public static imsBase = "imsBase";
  public static Kroschke = "Kroschke";

  // static getLogoUrl(productName: string) {
  //   console.log(productName);
  //   switch (productName) {
  //     case this.CareNavigator:
  //       return "https://qmbasesupport.blob.core.windows.net/public-icons/CareNavigator/navbarLogo.png";
  //     case this.DocuPro:
  //       return "https://qmbasesupport.blob.core.windows.net/public-icons/DocuPro/navbarLogo.png";
  //     case this.imsBase:
  //       return "https://qmbasesupport.blob.core.windows.net/public-icons/imsBase/navbarLogo.png";
  //     case this.Kroschke:
  //       return "https://qmbasesupport.blob.core.windows.net/public-icons/kroschke/ksi_logo.png";
  //     default:
  //       return "https://qmbasesupport.blob.core.windows.net/public-icons/qmBase/navbarLogo.png";
  //   }
  // }
}
