import React from "react";
import { Badge } from "react-bootstrap";

interface IProps {
  count?: number;
  countTitle?: string;
  className?: string;
  color?: "primary" | "info" | "danger" | "warning" | "light";
  onClick?: React.MouseEventHandler | undefined;
}

function CounterBadge(props: IProps) {
  const { count, countTitle, className, color, onClick } = props;
  if (!count) return null;
  if (count == 0) return null;
  return (
    <Badge title={countTitle} bg={color} className={className ?? "ms-1"} pill onClick={onClick}>
      {count > 99 ? "99+" : count}
    </Badge>
  );
}
export default CounterBadge;
