import { camelCase } from "lodash";

import { IValidationProblemDetails } from "@/generatedCode/pbd-core/pbd-core-api";

import { mapNullable } from "../../../utils/filterMap";

type ErrorList = [string, string[]][];

export interface ErrorValue {
  message: string;
  type: string;
}

//TODO: Test with swagger
export class ApiErrorMap {
  validationProblemDetails: IValidationProblemDetails;
  errorMap: Map<string, string[]>;

  constructor(validationProblemDetails: IValidationProblemDetails) {
    this.validationProblemDetails = validationProblemDetails;
    this.errorMap = new Map(
      this.getKeys().map((key) => [ApiErrorMap.normalizeKey(key), this.validationProblemDetails.errors?.[key] ?? []]),
    );
  }

  static normalizeKey(key: string): string {
    return camelCase(key);
  }

  static isGlobalError(key: string): boolean {
    return key.length === 0 || key.startsWith("#");
  }

  static isStatusError(key: string): boolean {
    const statusErrorList = ["emailNotConfirmed"];
    return statusErrorList.includes(key);
  }

  getKeys(): string[] {
    return Object.keys(this.validationProblemDetails.errors ?? {});
  }

  getError(key: string): string | undefined {
    const normKey = ApiErrorMap.normalizeKey(key);
    const errs = this.errorMap.get(normKey);
    return mapNullable(errs, (errs) => errs[0]);
  }

  getErrors(key: string): string[] | undefined {
    return this.errorMap.get(ApiErrorMap.normalizeKey(key));
  }

  getFieldErrors(): ErrorList {
    const result: ErrorList = [];
    for (const [key, errs] of this.errorMap.entries()) {
      if (!ApiErrorMap.isGlobalError(key)) result.push([key, errs]);
    }
    return result;
  }

  getGlobalErrors(): ErrorList {
    const result: ErrorList = [];
    for (const [key, errs] of this.errorMap.entries()) {
      if (ApiErrorMap.isGlobalError(key)) result.push([key, errs]);
    }
    return result;
  }

  getStatusErrors(): ErrorList {
    const result: ErrorList = [];
    for (const [key, errs] of this.errorMap.entries()) {
      if (ApiErrorMap.isStatusError(key)) result.push([key, errs]);
    }
    return result;
  }

  getAllErrors() {
    return Array.from(this.errorMap.entries());
  }

  /**
   *
   * @param setFormError Form errors are directly mapped to fields
   * @param setGlobalError Global errors start with # or have no key
   * @param setStatusError Status errors are errors that should persists even if the form values are changed again. This is a rare edge case.
   */
  partitionErrors(
    setFormError: (key: string, err: ErrorValue) => void,
    setGlobalError: (err: ErrorValue) => void,
    setStatusError: (key: string, error: ErrorValue) => void,
  ) {
    this.getFieldErrors().forEach((err) =>
      setFormError(err[0], {
        type: "server",
        message: err[1].join(". "),
      }),
    );

    this.getGlobalErrors().forEach((err) =>
      setGlobalError({
        type: "server",
        message: err[1].join(". "),
      }),
    );

    this.getStatusErrors().forEach((err) =>
      setStatusError(err[0], {
        type: "server",
        message: err[1].join(". "),
      }),
    );
  }
}
