import RestUtilities from "@/services/restClients/restUtilities";

export const paths = {
  home: {
    path: "/",
    getHref: () => "/",
  },

  error: {
    path: "/Error",
    getHref: (code?: string | number) => `/Error/${RestUtilities.getQueryString({ code })}`,
  },

  auth: {
    accessDenied: {
      path: "/Home/AccessDenied",
      getHref: (roles?: string[], path?: string) =>
        `/Home/AccessDenied/${RestUtilities.getQueryString({ requiredRoles: roles, returnUrl: path })}`,
    },
  },

  app: {
    root: {
      path: "/Home",
      getHref: () => "/Home",
    },
  },
} as const;
