import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";

interface IProps {
  show: boolean;
  toggleEditMode: () => void;
}

function FilloutSectionPlaceholder(props: IProps) {
  const { show, toggleEditMode } = props;
  const { t } = useTranslation();
  if (!show) return null;
  return (
    <React.Fragment>
      <div className="text-center">
        <Button color="primary" onClick={toggleEditMode}>
          <qmBaseIcons.PencilAlt /> {t("Fill out this section")}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default FilloutSectionPlaceholder;
