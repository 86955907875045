import { Form, Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IProps<T extends object> {
  data: T[];
  totalRowCount?: number;
  pageIndex: number;
  pageSize: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageCount: number;
}

export function TablePagination<T extends object>(props: IProps<T>) {
  const {
    data,
    totalRowCount,
    pageIndex,
    pageSize,
    gotoPage,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
  } = props;
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between">
      <div>
        <small className="text-muted">
          {data.length == 0 ? 0 : pageIndex * pageSize + 1} {t("to")}{" "}
          {Math.min(data.length, (pageIndex + 1) * pageSize)} {t("from")} {totalRowCount ?? data.length} {t("Entries")}
        </small>
      </div>
      <div>
        {pageCount > 1 && (
          <Pagination aria-label="Page navigation example">
            <Form.Select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              title={"Entries per page"}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
            <Pagination.First title={t("First")} onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
            <Pagination.Prev title={t("Previous")} onClick={() => previousPage()} disabled={!canPreviousPage} />
            <Pagination.Item title={t("Current")} active>
              {pageIndex + 1}
            </Pagination.Item>
            <Pagination.Next title={t("Next")} onClick={() => nextPage()} disabled={!canNextPage} />
            <Pagination.Last title={t("Last")} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
          </Pagination>
        )}
      </div>
    </div>
  );
}
