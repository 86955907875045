interface IProps {
  isOpen: boolean;
}

function CollapseChevron(props: IProps) {
  const { isOpen } = props;
  return <i className={`fa fa-chevron-${isOpen ? "down" : "right"}`} />;
}

export default CollapseChevron;
