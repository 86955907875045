const routePrefix = "/Trainings";

export class TrainingRoutePaths {
  public static HomePage = routePrefix;
  public static IndexPage = `${routePrefix}/Index`;
  public static TrainingAttendees = `${routePrefix}/Attendees`;
  public static CreatePage = `${routePrefix}/Create`;
  public static EditPage = `${routePrefix}/Edit/:id`;
  public static EditPageFn = (dto: { id: string | number }) =>
    `${routePrefix}/Edit/:id`.replace(":id", dto.id.toString());
  public static TrainingDemands = `${routePrefix}/TrainingDemands/Index`;
  public static TrainingDemandCreate = `${routePrefix}/TrainingDemands/Create`;
  public static TrainingDemandEdit = `${routePrefix}/TrainingDemands/Edit/:id`;
  public static TrainingCostIndex = `${routePrefix}/Costs`;
}
