import React from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { ApiException } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../services/serviceContext";

import GenericAlert from "../../shared/components/alerts/genericAlert";
import ConnectionTable from "../components/connectionTable";

function IndexPageConnections() {
  const { t } = useTranslation();
  const { connectionsApi } = useAPIs();
  const { data } = useSWR(["/api/connections"], () => connectionsApi.getAll());
  const [validationResult, setValidationResult] = React.useState<ApiException>();
  const [validationChecked, setValidationChecked] = React.useState(false);
  const [autoFix, setAutoFix] = React.useState(false);
  const [autoFixResult, setAutoFixResult] = React.useState<ApiException>();

  const handleValidationCheck = async () => {
    try {
      await connectionsApi.validate();
    } catch (ex) {
      if (ApiException.isApiException(ex)) {
        setValidationResult(ex);
      }
    } finally {
      setValidationChecked(true);
    }
  };

  const handleAutoFix = async () => {
    try {
      await connectionsApi.fixValidationErrors();
    } catch (ex) {
      if (ApiException.isApiException(ex)) {
        setAutoFixResult(ex);
      }
    } finally {
      setAutoFix(true);
    }
  };

  return (
    <>
      <h2>{t("Connections")}</h2>
      <Card className="mb-3">
        <Card.Body>
          <div>
            <Button variant="primary" onClick={handleValidationCheck}>
              {t("Validate connections")}
            </Button>
            <div>
              {validationResult && (
                <pre
                  style={{
                    background: "#f6f8fa",
                    fontSize: ".8rem",
                    padding: ".5rem",
                  }}
                >
                  {JSON.stringify(validationResult, null, 2)}
                </pre>
              )}
            </div>
          </div>

          {validationChecked && !autoFix && (
            <Button variant="warning" onClick={handleAutoFix}>
              {t("Auto fix validation warnings")}
            </Button>
          )}
          {autoFix && (
            <GenericAlert heading={t("Validation has been applied")} type="info">
              {autoFixResult && (
                <pre
                  style={{
                    background: "#f6f8fa",
                    fontSize: ".8rem",
                    padding: ".5rem",
                  }}
                >
                  {JSON.stringify(autoFixResult, null, 2)}
                </pre>
              )}
            </GenericAlert>
          )}
          <ConnectionTable tableRows={data} />
        </Card.Body>
      </Card>
    </>
  );
}
export default IndexPageConnections;
