import { IDepartmentDTO, ITenantDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";

const baseUrl = "/api/departments";

export default class DepartmentService extends BaseExportService<IDepartmentDTO> {
  constructor() {
    super("Departments");
  }

  mapToExport(x: IDepartmentDTO): ExportType {
    return {
      id: x.id,
      title: x.title,
    };
  }

  mapDepartmentsWithTenants(items: IDepartmentDTO[], tenants: ITenantDTO[]) {
    return items.map((dep) => ({
      ...dep,
      departmentPositionsCount: dep.departmentPositions?.length ?? 0,
      tenantsCount: tenants.filter((x) => x.departmentPositions?.some((y) => y.department?.id === dep.id)).length,
      tenants: tenants.filter((x) => x.departmentPositions?.some((y) => y.department?.id === dep.id)),
    }));
  }
}
