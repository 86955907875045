import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import FeatureFlagService from "../../../../pbdServices/services/FeatureFlags/featureFlagService";
import { SettingsRoutePaths } from "../../settingsRoutePaths";
import CrmSubsidebar from "./crmSubsidebar";
import TaskManagementSubsidebar from "./taskManagementSubsidebar";

interface SubsidebarData {
  title: string;
  links: LinkItem[];
}

interface LinkItem {
  title: string;
  link: string;
}

const ideaManagementSubsidebar: SubsidebarData = {
  title: "Idea management",
  links: [
    { title: "Idea categories", link: SettingsRoutePaths.IndexPageEmployeeIdeaCategories },
    // {
    //   title: "Custom Fields",
    //   link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.IdeaManagement),
    // },
  ],
};

const defectManagementSubsidebar: SubsidebarData = {
  title: "Error management",
  links: [
    { title: "Defect categories", link: SettingsRoutePaths.IndexPageDefectCategories },
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.DefectManagement),
    },
  ],
};

const trainingManagementSubsidebar: SubsidebarData = {
  title: "Training management",
  links: [
    { title: "Training categories", link: SettingsRoutePaths.IndexPageTrainingTypes },
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.TrainingManagement),
    },
  ],
};

const crmManagementSubsidebar: SubsidebarData = {
  title: "CRM",
  links: [
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.Crm),
    },
  ],
};

const documentManagementSubsidebar: SubsidebarData = {
  title: "Document management",
  links: [
    { title: "Changelog placeholders", link: SettingsRoutePaths.IndexPageDocumentManagementChangelogPlaceholers },
  ],
};

const claimManagementSubsidebar: SubsidebarData = {
  title: "Claims",
  links: [
    { title: "Deadlines", link: SettingsRoutePaths.ClaimDeadlines },
    { title: "Notifications", link: SettingsRoutePaths.ClaimNotifications },
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.ClaimManagement),
    },
    {
      title: "Workflows",
      link: SettingsRoutePaths.IndexPageWorkflows.replace(":module", PbdModule.ClaimManagement),
    },
  ],
};

const auditManagementSubsidebar: SubsidebarData = {
  title: "Audit management",
  links: [
    { title: "Audit requirements", link: SettingsRoutePaths.IndexPageAuditRequirements },
    { title: "Audit categories", link: SettingsRoutePaths.IndexPageAuditTypes },
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.AuditManagement),
    },
  ],
};

const employeesSubsidebar: SubsidebarData = {
  title: "Employees",
  links: [
    { title: "Departments", link: SettingsRoutePaths.IndexPageDepartments },
    { title: "Positions", link: SettingsRoutePaths.IndexPageDepartmentPosition },
  ],
};
const absencePlannerSubsidebar: SubsidebarData = {
  title: "Absence planner",
  links: [
    { title: "Absence type", link: SettingsRoutePaths.IndexPageAbsenceType },
    { title: "Absence policy", link: SettingsRoutePaths.IndexPageAbsencePolicy },
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.AbsencePlanner),
    },
  ],
};

const opportunitiesSubsidebar: SubsidebarData = {
  title: "Risks & Chances",
  links: [
    { title: "Categories", link: SettingsRoutePaths.IndexPageOpportunityCategories },
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.OpportunityManagement),
    },
  ],
};

const maintenanceSubsidebar: SubsidebarData = {
  title: "Maintenance",
  links: [
    { title: "Inventory categories", link: SettingsRoutePaths.IndexPageInventoryCategories },
    { title: "Status", link: SettingsRoutePaths.IndexPageInventoryStatus },
    { title: "Inventory inspections", link: SettingsRoutePaths.IndexPageInventoryInspections },
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.MaintenanceManagement),
    },
  ],
};

const taskSubsidebar: SubsidebarData = {
  title: "Tasks",
  links: [
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.ToDoManagement),
    },
  ],
};

const goalSubsidebar: SubsidebarData = {
  title: "Goal management",
  links: [
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.GoalManagement),
    },
  ],
};

const productsSubsidebar: SubsidebarData = {
  title: PbdModule.Products.toString(),
  links: [
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.Products),
    },
  ],
};

const generalSubsidebar: SubsidebarData = {
  title: "General",
  links: [
    { title: "Units", link: SettingsRoutePaths.IndexPagesUnits },
    { title: "Places", link: SettingsRoutePaths.IndexPagePlaces },
    { title: "Tags", link: SettingsRoutePaths.IndexPageTags },
    { title: "Cost categories", link: SettingsRoutePaths.IndexPageCostCategories },
    { title: "Cost centers", link: SettingsRoutePaths.IndexPageCostCenters },
    { title: "Currencies", link: SettingsRoutePaths.IndexPageCurrencies },
    { title: "Connection categories", link: SettingsRoutePaths.IndexPageConnectionCategories },
    { title: "Bank holidays", link: SettingsRoutePaths.CreatePageBankHolidays },
    { title: "Routing rules", link: SettingsRoutePaths.RoutingRulesIndexPage },
    { title: "File categories", link: SettingsRoutePaths.IndexPageFileCategories },
  ],
};

const tenantSubSidebar: SubsidebarData = {
  title: "Tenants",
  links: [
    {
      title: "Custom Fields",
      link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.TenantManagement),
    },
  ],
};

if (FeatureFlagService.isAvailableForDev()) {
  generalSubsidebar.links.push({ title: "Working hours", link: SettingsRoutePaths.CreatePageWorkingHours });
  tenantSubSidebar.links.push({
    title: "Custom Fields",
    link: SettingsRoutePaths.IndexPageCustomFields.replace(":module", PbdModule.TenantManagement),
  });
}

function SubsidebarComponent() {
  let { pathname } = useLocation();
  pathname = pathname.toLowerCase();

  if (pathname.startsWith("/settings/ideamanagement")) {
    return <SubsidebarContent data={ideaManagementSubsidebar} />;
  } else if (pathname.startsWith("/settings/documentmanagement")) {
    return <SubsidebarContent data={documentManagementSubsidebar} />;
  } else if (pathname.startsWith("/settings/claimmanagement")) {
    return <SubsidebarContent data={claimManagementSubsidebar} />;
  } else if (pathname.startsWith("/settings/training")) {
    return <SubsidebarContent data={trainingManagementSubsidebar} />;
  } else if (pathname.startsWith("/settings/crm") || pathname.startsWith("/settings/organisations")) {
    return <CrmSubsidebar />;
  } else if (pathname.startsWith("/settings/auditmanagement")) {
    return <SubsidebarContent data={auditManagementSubsidebar} />;
  } else if (pathname.startsWith("/settings/employees")) {
    return <SubsidebarContent data={employeesSubsidebar} />;
  } else if (pathname.startsWith("/settings/opportunitymanagement")) {
    return <SubsidebarContent data={opportunitiesSubsidebar} />;
  } else if (pathname.startsWith("/settings/maintenance")) {
    return <SubsidebarContent data={maintenanceSubsidebar} />;
  } else if (pathname.startsWith("/settings/defectmanagement")) {
    return <SubsidebarContent data={defectManagementSubsidebar} />;
  } else if (pathname.startsWith("/settings/projectandtaskmanagement")) {
    return <TaskManagementSubsidebar />;
  } else if (pathname.startsWith("/settings/todomanagement")) {
    return <SubsidebarContent data={taskSubsidebar} />;
  } else if (pathname.startsWith("/settings/goalmanagement")) {
    return <SubsidebarContent data={goalSubsidebar} />;
  } else if (pathname.startsWith("/settings/absenceplanner")) {
    return <SubsidebarContent data={absencePlannerSubsidebar} />;
  } else if (pathname.startsWith("/settings/products")) {
    return <SubsidebarContent data={productsSubsidebar} />;
  } else if (pathname.startsWith("/settings/tenant")) {
    return <SubsidebarContent data={tenantSubSidebar} />;
  } else {
    return <SubsidebarContent data={generalSubsidebar} />;
  }
}

export default SubsidebarComponent;

interface IProps {
  data: SubsidebarData;
}

function SubsidebarContent(props: IProps) {
  const { data } = props;
  const { t } = useTranslation();
  let { pathname } = useLocation();
  pathname = pathname.toLowerCase();
  return (
    <ListGroup variant="flush">
      <ListGroup.Item>
        <h5>{t(data.title)}</h5>
      </ListGroup.Item>
      {data.links.map((x) => (
        <ListGroup.Item key={x.link} action as={Link} to={x.link} active={pathname.startsWith(x.link)}>
          {t(x.title)}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}
