import React from "react";
import { Alert, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { ResetPasswordDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../services/serviceContext";

import { AccountRoutePaths } from "../accountRoutePaths";
import CardFooterAccount from "../components/cardFooterAccount";
import ResetPasswordForm from "../components/resetPasswordForm";

function ResetPasswordPage() {
  const { t } = useTranslation();
  const { accountApi } = useAPIs();
  const [success, setSuccess] = React.useState(false);
  const [query, setQuery] = useQueryParams({
    code: withDefault(StringParam, undefined),
    userName: withDefault(StringParam, undefined),
    email: withDefault(StringParam, undefined),
  });

  return (
    <Card className="accountCard">
      <Card.Body>
        <h1 className="text-center">{t("Reset password")}</h1>
        <hr />
        {success && (
          <Alert variant="success">
            {t("Password reset successful.")} {t("You can now login with your new password.")}
            <p className="text-center">
              <Link to={AccountRoutePaths.Login}>{t("Go to login")}</Link>
            </p>
          </Alert>
        )}
        {!success && (
          <ResetPasswordForm
            itemToUpdate={{ ...query, userName: query.userName ?? query.email }}
            onSubmit={(x) => accountApi.resetPassword(new ResetPasswordDTO(x))}
            onSuccess={() => setSuccess(true)}
          />
        )}
        <div className="text-center">
          <p>
            <Link to={AccountRoutePaths.Login}>{t("Back to login")}</Link>
          </p>
        </div>
        <CardFooterAccount />
      </Card.Body>
    </Card>
  );
}

export default ResetPasswordPage;
