import React from "react";
import { Card, CardBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import NoEntriesAlert from "../../shared/components/alerts/noEntriesAlert";
import RecycleBinAlert from "../../shared/components/alerts/recycleBinAlert";
import GenericSearchComponent from "../../shared/components/search/genericSearchComponent";
import SettingsCardHeader from "./settingsCardHeader";

interface IProps {
  data?: unknown[];
  pathToCreate: string;
  cardTitle: string;
  toggleRecycleBin?: () => void;
  loading: boolean;
  showRecycleBin?: boolean;
  search?: (keyword: string) => void;
  children: React.ReactNode;
}

const BaseSettingsIndexCard: React.FC<IProps> = (props) => {
  const { data, pathToCreate, cardTitle, toggleRecycleBin, loading, showRecycleBin, search } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Card className="mb-3">
        <SettingsCardHeader
          recycleBinActive={showRecycleBin}
          toggleRecycleBin={toggleRecycleBin}
          cardTitle={t(cardTitle)}
        />
        <CardBody>
          {!loading && data && data.length == 0 && !showRecycleBin && <NoEntriesAlert createLink={pathToCreate} />}
          {showRecycleBin && <RecycleBinAlert />}
          {data && data.length > 5 && search && <GenericSearchComponent doSearch={search} />}
          {props.children}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default BaseSettingsIndexCard;
