import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../../icons/qmBaseIcons";

interface IProps {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
}

function GlobalFilter({ value: initialValue, onChange, debounce = 500, ...props }: IProps) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(initialValue);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <>
      <InputGroup.Text>
        <qmBaseIcons.Search />
      </InputGroup.Text>
      <Form.Control value={value} type="search" onChange={handleInputChange} placeholder={t("Search...")} />
    </>
  );
}

export default GlobalFilter;
