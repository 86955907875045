import { uniq } from "lodash";
import * as yup from "yup";

import {
  ArticleRevisionType,
  IArticleRevisionApprovalDTO,
  IArticleRevisionCreateDTO,
  IArticleRevisionDTO,
  IArticleRevisionReviewDTO,
  IArticleRevisionsControllerClient,
  ITenantDTO,
  ITenantsControllerClient,
  TenantsQueryField,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { nullableString } from "../../../services/validation/stringSchemas";
import { TenantsMockData } from "../../__tests__/config/mockData/tenantMockData";
import { MeAsUser } from "../UserSettings/models/me-as-user";
import { PaginationHelpers } from "../pagination/pagination-helpers";
import { ArticleRevisionReviewVM } from "./models/article-revision-review-vm";
import { ArticleRevisionApprovalVM } from "./models/articleRevisionApprovalVm";

export default class ArticleRevisionService {
  tenantsApi: ITenantsControllerClient;
  articleRevisionsApi: IArticleRevisionsControllerClient;
  constructor(tenantsApi: ITenantsControllerClient, articleRevisionsApi: IArticleRevisionsControllerClient) {
    this.tenantsApi = tenantsApi;
    this.articleRevisionsApi = articleRevisionsApi;
  }

  async mapApprovalsToTenants(items: IArticleRevisionApprovalDTO[]): Promise<ArticleRevisionApprovalVM[]> {
    const tenants = await this.getUniqueTenants(items.map((x) => x.approverId));
    return items.map((x) => {
      const approver = tenants.find((t) => t.id == x.approverId) ?? TenantsMockData.getDeletedPlaceholder(x.approverId);
      return { ...x, approver };
    });
  }

  async getArticleRevisionsReviewsMapped(id: number): Promise<ArticleRevisionReviewVM[]> {
    const items = await this.articleRevisionsApi.getReviewsById(id);
    const tenants = await PaginationHelpers.getAllPaged3(
      (x) =>
        this.tenantsApi.getAllQuery({
          id: x.id,
          fields: [TenantsQueryField.ApplicationUser],
          pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
        }),
      items.map((x) => x.reviewerId),
    );

    return items.map((x) => {
      const reviewer = tenants.find((t) => t.id == x.reviewerId) ?? TenantsMockData.getDeletedPlaceholder(x.reviewerId);
      return { ...x, ...reviewer, articleRevisionReviewId: x.id };
    });
  }

  getArticleRevisionsReviewsMappedForAll(
    items: IArticleRevisionReviewDTO[],
    tenants: ITenantDTO[],
  ): ArticleRevisionReviewVM[] {
    return items.map((x) => {
      const reviewer = tenants.find((t) => t.id == x.reviewerId) ?? TenantsMockData.getDeletedPlaceholder(x.reviewerId);
      return { ...x, ...reviewer, articleRevisionReviewId: x.id };
    });
  }

  async getUniqueTenants(ids: number[]): Promise<ITenantDTO[]> {
    if (ids.length == 0) return [];
    const unique = uniq(ids);
    return (
      await this.tenantsApi.getAllQuery({
        id: Array.from(unique.values()),
        fields: [TenantsQueryField.ApplicationUser],
      })
    ).data;
  }

  static myApprovalRequired(
    items: IArticleRevisionDTO[] | undefined,
    meAsUser: MeAsUser,
  ): IArticleRevisionDTO[] | undefined {
    if (!items) return items;
    return items.filter(
      (x) =>
        x.isPublishProcessStarted &&
        !x.publishProcessStopped &&
        !x.isPublished &&
        x.approvals
          ?.filter((a) => !a.isApproved)
          .map((y) => y.approverId)
          .includes(meAsUser.tenant.id),
    );
  }

  static otherApprovalRequired(items: IArticleRevisionDTO[] | undefined): IArticleRevisionDTO[] | undefined {
    if (!items) return items;
    return items.filter(
      (x) =>
        x.isPublishProcessStarted &&
        !x.publishProcessStopped &&
        !x.isPublished &&
        x.approvals?.filter((a) => !a.isApproved) &&
        x.approvals.filter((a) => !a.isApproved).length > 0,
    );
  }

  static readonly ValidationSchema: yup.ObjectSchema<IArticleRevisionCreateDTO> = yup.object({
    title: yup.string().required().min(3).max(350),
    content: yup.string().when("type", { is: "Flow", then: (s) => s.required() }),
    articleId: yup.number().required(),
    abstract: nullableString,
    type: yup.mixed<ArticleRevisionType>().oneOf(Object.values(ArticleRevisionType)).required(),
  });
}
