const routePrefix = "/QualificationMatrix";

export class QualificationMatrixRoutePaths {
  public static HomePage = routePrefix;
  public static IndexPageTenants = `${routePrefix}/Tenants/Index`;
  public static QualificationRequirementsIndex = `${routePrefix}/QualificationRequirements/Index`;
  public static DetailsPageQualificationRequirementsForTenant = `${routePrefix}/QualificationRequirements/Tenants/Details/:id`;
  public static DetailsPageQualificationRequirementsForTenantFn = (tenantId: number) =>
    `${routePrefix}/QualificationRequirements/Tenants/Details/${tenantId}`;

  public static IndexPageJobDescriptions = `${routePrefix}/JobDescriptions/Index`;
  public static DetailsPageJobDescriptions = `${routePrefix}/JobDescriptions/DepartmentPositions/Details/:id`;
  public static DetailsPageTenantJobDescription = `${routePrefix}/JobDescriptions/Tenants/Details/:id`;

  public static IndexPageQualification = `${routePrefix}/Qualifications/Index`;
  public static CreatePageQualification = `${routePrefix}/Qualifications/Create`;
  public static DetailsPageQualification = `${routePrefix}/Qualifications/Details/:id`;
  public static MatrixPageQualification = `${routePrefix}/Qualifications/Matrix`;
  public static QualificationMatrixPrint = `QualificationMatrix/Home/Print`;

  public static IndexPageCompanyFunction = `${routePrefix}/CompanyFunctions/Index`;
  public static CreatePageCompanyFunction = `${routePrefix}/CompanyFunctions/Create`;
  public static MatrixPageCompanyFunction = `${routePrefix}/CompanyFunctions/Matrix`;
  public static DetailsPageCompanyFunction = `${routePrefix}/CompanyFunctions/Details/:id`;
  public static DetailsPageCompanyFunctionFn = (id: number) =>
    `${routePrefix}/CompanyFunctions/Details/:id`.replace(":id", id.toString());

  public static IndexPageTenantQualificationsDone = `${routePrefix}/TenantQualificationsDone/Index`;
  public static EditPageTenantQualificationDone = `${routePrefix}/TenantQualificationsDone/Edit/:id`;
  public static CreatePageTenantQualificationDone = `${routePrefix}/TenantQualificationsDone/Create`;

  public static ConsolidateQualifications = `${routePrefix}/ConsolidateQualifications`;

  public static DepartmentPositionMatrix = `${routePrefix}/DepartmentPositions/Matrix`;
  public static DepartmentMatrix = `${routePrefix}/Departments/Matrix`;
  public static CompanyFunctionQualificationMatrix = `${routePrefix}/CompanyFunctionQualification/Matrix`;
  public static DepartmentPositionCompanyFunctionMatrix = `${routePrefix}/DepamrtmentPositionCompanyFunction/Matrix`;

  public static PerfTestPage = `${routePrefix}/PerfTest`;
}
