import { Field, Form as FormFormik, Formik } from "formik";
import { TFunction } from "i18next";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { IResetPasswordDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { useToggle } from "@/hooks/useToggle";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import UserService from "../../../pbdServices/services/Users/userService";
import { nameofFactory } from "../../../utils/nameofFactory";
import StringHelpers from "../../../utils/stringHelpers";
import PasswordStrengthComponent from "../../profile/components/passwordStrengthComponent";
import { FormikTextInput, FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../shared/components/forms/formik/formikValidationSummary";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";

const nameof = nameofFactory<IResetPasswordDTO>();

export const getRegisterValidationSchema = (t: TFunction) => {
  const ValidationSchema: yup.ObjectSchema<IResetPasswordDTO> = yup.object({
    userName: yup.string().required(t("This field is required")).max(100),
    password: yup
      .string()
      .matches(
        UserService.strongRegex,
        t("Your password must contain lower case uppercase number and special characters"),
      )
      .required(t("This field is required"))
      .min(GlobalQmBaseConstants.PasswordRequiredLength)
      .max(100),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], t("Passwords must match"))
      .required(),
    code: yup.string().required(),
  });
  return ValidationSchema;
};

interface IProps {
  itemToUpdate?: Partial<IResetPasswordDTO>;
  onSubmit: (dto: IResetPasswordDTO) => Promise<void>;
  onSuccess: () => void;
}

function ResetPasswordForm(props: IProps) {
  const { t } = useTranslation();
  const { onSubmit, onSuccess, itemToUpdate } = props;
  const [passwordShown, togglePasswordVisibility] = useToggle();

  const initialValues: IResetPasswordDTO = {
    userName: itemToUpdate?.userName ?? "",
    code: itemToUpdate?.code ?? "",
    password: "",
    confirmPassword: "",
  };

  const submitter = useFormikAPISubmitter<IResetPasswordDTO>((val) => onSubmit(val), [onSubmit], onSuccess);

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getRegisterValidationSchema(t)}>
      {(formikBag) => (
        <FormFormik>
          <FormikValidationSummary formikBag={formikBag} keysToExclude={["code", "userName", "password"]} />
          {StringHelpers.isNullOrWhitespace(itemToUpdate?.code) && (
            <FormikTextInputGroup name={nameof("code")} label={t("Recovery code")} />
          )}

          <FormikTextInputGroup
            name={nameof("userName")}
            autoComplete="username"
            label={t("Email or user name")}
            readOnly={!StringHelpers.isNullOrWhitespace(itemToUpdate?.userName)}
          />
          <Form.Group className="mb-3">
            <Form.Label htmlFor="password">{t("Password")}</Form.Label>
            <InputGroup>
              <Field
                name={nameof("password")}
                component={FormikTextInput}
                type={passwordShown ? "text" : "password"}
                autoComplete="new-password"
              />

              <Button onClick={togglePasswordVisibility} title={t("Show password")}>
                <qmBaseIcons.NotWatching />
              </Button>
            </InputGroup>
            <PasswordStrengthComponent password={formikBag.values.password} />
          </Form.Group>
          <FormikTextInputGroup
            label={t("Confirm password")}
            name={nameof("confirmPassword")}
            type={passwordShown ? "text" : "password"}
            autoComplete="new-password"
          />
          <Form.Group className="mb-3">
            <div className="d-grid gap-2">
              <Button variant="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
                {formikBag.isSubmitting ? t("Loading...") : t("Reset")}
              </Button>
            </div>
          </Form.Group>
        </FormFormik>
      )}
    </Formik>
  );
}
export default ResetPasswordForm;
