interface IExternalChartSourceConfigExtension {
  /**Name of the data set */
  name: string;
  url: string;
  /**Most probably x value for example date as string */
  key: string;
  /**Most probably y value */
  value: string;
  /**Unit of the value */
  unit?: string;
  groupBy?: string;
  /**Optional settings pre configured per chart */
  settings?: string;

  yAxisConfig?: AxisConfig;
}

export interface IAxisConfig {
  divideBy: number;
  minValue?: number;
  maxValue?: number;
  label?: string;
}

export class AxisConfig implements IAxisConfig {
  constructor(data?: IAxisConfig) {
    this.divideBy = data?.divideBy ?? -1;
    this.label = data?.label;
    this.maxValue = data?.maxValue;
    this.minValue = data?.minValue;
  }
  divideBy: number;
  minValue?: number;
  maxValue?: number;
  label?: string;
}

export class ExternalChartSourceConfigExtension implements IExternalChartSourceConfigExtension {
  constructor(data?: IExternalChartSourceConfigExtension) {
    this.name = data?.name ?? "";
    this.url = data?.url ?? "";
    this.key = data?.key ?? "";
    this.value = data?.value ?? "";
    this.groupBy = data?.groupBy ?? "";
    this.settings = data?.settings ?? "";
    this.yAxisConfig = data?.yAxisConfig ?? new AxisConfig();
  }
  name: string;
  url: string;
  key: string;
  value: string;
  groupBy?: string | undefined;
  settings?: string | undefined;
  yAxisConfig?: AxisConfig;
}
