import React, { FocusEventHandler } from "react";
import { ActionMeta, SingleValue } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";

import { ICategoryDTO, ICategoryMinDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../services/serviceContext";

import { getLabel, getValue } from "./reactSelectHelpers";

const loadOptions = async (inputValue: string, query: (qs: { q: string }) => Promise<ICategoryDTO[]>) => {
  return query({ q: inputValue });
};

interface IProps {
  onChange: (title: string, id?: number) => void;
  defaultValue?: ICategoryMinDTO;
  isClearable?: boolean;
  onBlur?: FocusEventHandler;
}

export function CostCategorySelect(props: IProps) {
  const { isClearable, defaultValue, onBlur, onChange } = props;
  const { costCategoriesApi } = useAPIs();
  const [value, setValue] = React.useState<ICategoryDTO>();

  function handleChange(dto: SingleValue<ICategoryDTO>, action: ActionMeta<ICategoryDTO>) {
    if (dto && action.action == "select-option") {
      setValue(dto);
      onChange(dto.title, dto.id);
    } else if (action.action == "clear") {
      onChange("", undefined);
    }
  }

  return (
    <AsyncCreatableSelect
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      cacheOptions
      defaultOptions
      defaultValue={defaultValue as ICategoryDTO}
      value={value}
      onChange={handleChange}
      loadOptions={(val, cb) => loadOptions(val, (qs) => costCategoriesApi.getAllQuery(qs))}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      isClearable={isClearable}
      onBlur={onBlur}
      onCreateOption={(val) => {
        //@ts-expect-error TODO: Fix with better typings
        setValue({ title: val });
        onChange(val, 0);
      }}
    />
  );
}
