import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup } from "reactstrap";

import { ITimeIntervalDTO, TimeInterval } from "@/generatedCode/pbd-core/pbd-core-api";

import { calculateTimeSpanFromInterval } from "../../../../../pbdServices/services/DateTime/calculateDates";

interface IProps {
  id: string;
  onChange: (id: string, dto: ITimeIntervalDTO) => void;
  timeInterval?: ITimeIntervalDTO;
}

const timeIntervals = Object.keys(TimeInterval)
  .filter((k) => typeof k == "string")
  .map((x) => x);

function TimeIntervalPicker(props: IProps) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [timeInterval, setTimeInterval] = React.useState<ITimeIntervalDTO>();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (props.timeInterval) {
      setTimeInterval(props.timeInterval);
    } else {
      setTimeInterval({ value: 0, type: TimeInterval.Year, timeSpanISO: undefined });
    }
  }, [props.timeInterval]);

  function handleValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (timeInterval) updateTimeSpan(event.target.valueAsNumber, timeInterval.type);
  }

  function handleTypeChange(type: TimeInterval) {
    if (timeInterval) updateTimeSpan(timeInterval.value, type);
  }

  function updateTimeSpan(value: number, type: TimeInterval) {
    const timeSpan = calculateTimeSpanFromInterval(value, type).toISO();
    const interval: ITimeIntervalDTO = {
      value: value,
      type: type,
      timeSpanISO: timeSpan,
    };
    setTimeInterval(interval);
    props.onChange(props.id, interval);
  }

  return (
    <InputGroup>
      <Input
        type="number"
        value={timeInterval == null ? "0" : timeInterval.value.toString()}
        onChange={handleValueChange}
      />
      <ButtonDropdown addonType="append" isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
        <DropdownToggle caret>
          {timeInterval == null ? t(TimeInterval.Year.toString()) : t(timeInterval.type.toString())}
        </DropdownToggle>
        <DropdownMenu>
          {timeIntervals.map((x) => (
            <DropdownItem key={x} onClick={() => handleTypeChange(x as TimeInterval)} defaultValue={x}>
              {t(x)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    </InputGroup>
  );
}

export default TimeIntervalPicker;
