import i18next from "i18next";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconComponent } from "../icons/icon-base";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import CustomDropdownToggleButton from "./customDropdownToggleButton";

export class ContextButtons {
  static printButton(href: string): IAction {
    return {
      id: "print",
      icon: qmBaseIcons.Print,
      label: i18next.t("Print"),
      href,
      tag: "a",
      target: "_blank",
    };
  }
  static copyUrlToClipboard(onClick: () => void): IAction {
    return {
      id: "copy",
      icon: qmBaseIcons.Clipboard,
      label: i18next.t("Copy link"),
      onClick,
    };
  }
}

interface IAction {
  id: string;
  icon: IconComponent;
  label: string;
  onClick?: () => void;
  href?: string;
  target?: "_blank";
  tag?: "a" | "link";
  disabled?: boolean;
}

interface IProps {
  dropdownId?: string;
  actions: IAction[];
  asButtonGroup?: boolean;
  size?: "sm" | "lg";
}

/**
 * Ellipsis button to implement context menus
 */
function ContextMenuButton(props: IProps) {
  const { t } = useTranslation();
  const { actions, dropdownId = "context-Menu-Dropdown", asButtonGroup } = props;
  return (
    <Dropdown as={asButtonGroup ? ButtonGroup : undefined}>
      <Dropdown.Toggle
        variant="outline-secondary"
        title={t("Card title context menu")}
        as={CustomDropdownToggleButton}
        id={dropdownId}
        size={props.size}
      >
        <qmBaseIcons.EllipsisVertical />
      </Dropdown.Toggle>
      <Dropdown.Menu align={"end"}>
        {actions.map((x) => (
          <Dropdown.Item
            key={x.id}
            as={x.tag == "link" ? Link : undefined}
            href={x.href}
            to={x.tag == "link" ? x.href : undefined}
            target={x.target}
            onClick={x.onClick}
            disabled={x.disabled}
          >
            <x.icon /> {x.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
export default ContextMenuButton;
