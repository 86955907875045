import {
  CommentCreateDTO,
  CommentDTO,
  CommentEditDTO,
  FileDTO,
  FileParameter,
  IAuditTrailDTO,
  PredefinedFileCategory,
} from "@/generatedCode/pbd-core/pbd-core-api";

export interface IBaseApi extends IFilesBaseApi, ICommentsBaseApi, IAuditTrailBaseApi {}

export function isRequiredFilesApi(dto: IBaseApi): dto is Required<IFilesBaseApi> {
  return dto.getConnectedFiles != undefined && dto.uploadFiles != undefined;
}

export interface IFilesBaseApi {
  /**
   * @param isDeleted (optional)
   * @return Success
   */
  getConnectedFiles?(id: number, isDeleted?: boolean | undefined): Promise<FileDTO[]>;
  /**
   * @param files (optional)
   * @param note (optional)
   * @return No Content
   */
  uploadFiles?(
    id: number,
    files?: FileParameter[] | undefined,
    note?: string | undefined,
    categoryId?: number | undefined,
    predefinedCategory?: PredefinedFileCategory | undefined,
  ): Promise<void>;
}

export function isRequiredAuditTrailApi(dto: IBaseApi): dto is Required<IAuditTrailBaseApi> {
  return dto.getAuditTrailById != undefined;
}

export interface IAuditTrailBaseApi {
  /**
   * @return Success
   */
  getAuditTrailById?(id: number): Promise<IAuditTrailDTO[]>;
}

export function isRequiredCommentApi(dto: IBaseApi): dto is ICommentRequiredBaseApi {
  return dto.getComments != undefined && dto.createComment != undefined;
}

export interface ICommentRequiredBaseApi {
  /**
   * @return Success
   */
  getComments(id: number): Promise<CommentDTO[]>;
  /**
   * @param body (optional)
   * @return Success
   */
  createComment(id: number, body: CommentCreateDTO): Promise<CommentDTO>;
  /**
   * @param body (optional)
   * @return Success
   */
  editComment?(id: number, commentId: number, body: CommentEditDTO): Promise<void>;
  /**
   * @return Success
   */
  deleteComment?(id: number, commentId: number): Promise<void>;
}

export interface ICommentsBaseApi {
  /**
   * @return Success
   */
  getComments?(id: number): Promise<CommentDTO[]>;
  /**
   * @param body (optional)
   * @return Success
   */
  createComment?(id: number, body: CommentCreateDTO): Promise<CommentDTO>;
  /**
   * @param body (optional)
   * @return Success
   */
  editComment?(id: number, commentId: number, body: CommentEditDTO): Promise<void>;
  /**
   * @return Success
   */
  deleteComment?(id: number, commentId: number): Promise<void>;
}
