import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { DateTime } from "luxon";

import { ICustomField, IExternalIdSetting, ITenantDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../utils/dateTimeLuxonHelpers";
import { WithWarnings } from "../../../pbdServices/Models/Shared/with-warnings";
import ITenantQualificationRequirementVm from "../../../pbdServices/services/Qualifications/models/tenantQualificationRequirementVm";
import { createdAtColumn } from "../../shared/components/tables/columns/createdAtColumn";
import { getCustomFieldsColumns2 } from "../../shared/components/tables/columns/customFieldColumns";
import { externalIdColumn } from "../../shared/components/tables/columns/externalIdColumn";
import { idColumn } from "../../shared/components/tables/columns/idColumn";
import { tagsColumn } from "../../shared/components/tables/columns/tagsColumn";
import { lastUpdatedAtColumn } from "../../shared/components/tables/columns/updatedAtColumn";
import { BaseTableProps } from "../../shared/components/tables/v8/base-table-props";
import ReactstrapTable from "../../shared/components/tables/v8/reactstrapTable";
import AvatarSpanWithName from "../../shared/components/tenants/avatarSpanWithName";
import AttendeeTableCellRequirements from "../../trainings/components/attendeesPage/attendeeTableCellRequirements";
import TenantRoutePaths from "../tenantRoutePaths";

type TableType = WithWarnings<
  ITenantDTO & { reviewedAt?: DateTime; qualificationRequirements?: ITenantQualificationRequirementVm[] }
>;

interface IProps<T extends TableType> extends BaseTableProps<T> {
  externalIdSetting?: IExternalIdSetting;
  customFields?: ICustomField[];
  autoResetPage?: boolean;
}

const columnHelper = createColumnHelper<TableType>();

export function TenantTable<T extends TableType>(props: IProps<T>) {
  const {
    columnsVisibleDefault = ["name", "department"],
    columnsToExclude = ["reviewedAt", "requirements"],
    localStorageStateKey = "TenantDTO",
    externalIdSetting,
    hideTitleLink,
    additionalColumns = [],
  } = props;

  const columns = [
    idColumn,
    externalIdColumn(externalIdSetting),
    // columnHelper.accessor("externalId",externalIdColumn),
    columnHelper.accessor("fullName", {
      id: "name",
      header: i18next.t("Name"),
      cell: (info) => (
        <AvatarSpanWithName
          tenant={info.row.original}
          showNotificationBell
          linkTo={hideTitleLink ? undefined : TenantRoutePaths.EditPageFn(info.row.original)}
        />
      ),
      enableHiding: false,
    }),
    tagsColumn,
    columnHelper.accessor((x) => x.primaryDepartmentPosition?.department.title ?? "", {
      header: i18next.t("Department"),
      id: "department",
      enableSorting: false,
    }),
    columnHelper.accessor((x) => x.primaryDepartmentPosition?.title ?? "", {
      header: i18next.t("Position"),
      id: "position",
      enableSorting: false,
    }),
    columnHelper.accessor("telephone", { header: i18next.t("Telephone"), id: "telephone", enableSorting: false }),
    columnHelper.accessor("email", { header: i18next.t("Email"), id: "email", enableSorting: false }),
    columnHelper.accessor("reviewedAt", {
      id: "reviewedAt",
      header: i18next.t("Reviewed"),
      meta: { className: "text-end" },
      cell: ({ row }) => (
        <>{row.original.reviewedAt && DateTimeLuxonHelpers.convertUtcToDate(row.original.reviewedAt)}</>
      ),
    }),
    columnHelper.accessor("qualificationRequirements", {
      id: "requirements",
      header: i18next.t("Requirements"),
      cell: ({ row }) => <AttendeeTableCellRequirements data={row.original} showPercentage />,
    }),
    createdAtColumn,
    lastUpdatedAtColumn,
    ...getCustomFieldsColumns2(props.customFields),
    ...additionalColumns,
  ].filter((x) => x.id && !columnsToExclude.map((m) => m.toString()).includes(x.id));

  return (
    <ReactstrapTable<T>
      columns={columns as ColumnDef<T>[]}
      columnsVisibleDefault={columnsVisibleDefault}
      localStorageStateKey={localStorageStateKey}
      {...props}
    />
  );
}
