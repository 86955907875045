const routePrefix = "/AbsencePlanner";

export class AbsencePlannerRoutePaths {
  public static HomePage = routePrefix;

  public static AbsenceScheduleIndex = `${routePrefix}/AbsenceSchedule/`;

  public static AbsencePolicyIndexDeleted = `${routePrefix}/AbsencePolices/Indexdeleted`;

  public static AbsenceRequestIndex = `${routePrefix}/AbsenceRequests/Index`;
  public static AbsenceRequestCreate = `${routePrefix}/AbsenceRequests/Create`;
  public static AbsenceRequestEdit = `${routePrefix}/AbsenceRequests/Edit/:id`;
  public static AbsencePlannerStatistics = `${routePrefix}/Statistics`;
  public static AbsenceRequestIndexDeleted = `${routePrefix}/AbsenceRequests/Indexdeleted`;
}
