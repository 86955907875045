const routePrefix = "/Products";

export class ProductRoutePaths {
  public static HomePage = routePrefix;
  public static IndexPage = `${routePrefix}/Index`;
  public static IndexDeleted = `${routePrefix}/Index/?isDeleted=1`;
  public static CreatePage = `${routePrefix}/Create`;
  public static EditPage = `${routePrefix}/Edit/:id`;
  // public static Settings = `/Settings/Audits`;
}
