import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";

import { Functioning } from "@/generatedCode/pbd-core/pbd-core-api";

import { ReactSelectDTO } from "../../../Models/ReactSelect/reactSelectDTO";

interface IProps {
  onChange: (id?: string | string[]) => void;
  selectedIds?: string[];
  isMulti?: boolean;
  isClearable?: boolean;
}
const InventoryFunctioningSelect: React.FC<IProps> = (props) => {
  const [options, setOptions] = useState<ReactSelectDTO[]>();
  const { onChange, selectedIds, isMulti, isClearable } = props;
  const { t } = useTranslation();
  React.useEffect(() => {
    async function getData() {
      const options: ReactSelectDTO[] = [];
      options.push({ label: t(Functioning.Yes), value: Functioning.Yes });
      options.push({ label: t(Functioning.No), value: Functioning.No });
      options.push({ label: t(Functioning.Limited), value: Functioning.Limited });
      setOptions(options);
      return options;
    }
    getData();
  }, []);

  function handleChange(newValue: MultiValue<ReactSelectDTO | undefined> | SingleValue<ReactSelectDTO | undefined>) {
    if (newValue == undefined) {
      onChange(undefined);
    } else {
      if (Array.isArray(newValue)) {
        onChange(newValue.map((x) => x.value));
      } else {
        const option = newValue as ReactSelectDTO;
        onChange(option.value);
      }
    }
  }

  return (
    <React.Fragment>
      {selectedIds && options && (
        <Select
          menuPosition="fixed"
          isSearchable
          isClearable={isClearable}
          options={options}
          defaultValue={selectedIds.map((x) => options.find((f) => f.value == x.toString()))}
          onChange={handleChange}
          placeholder={`${t("Search")}...`}
          name="inventoryFunctioningId"
          isMulti={isMulti}
        />
      )}
    </React.Fragment>
  );
};

export default InventoryFunctioningSelect;
