import { IAppDTO, PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { ModuleUiInfoDefinition } from "../../../Constants/ModuleUiInfo";

export class AppVM {
  constructor(dto: IAppDTO) {
    this.id = dto.id;
    this.type = dto.type;
    this.href = dto.href!;
    this.showElement = dto.showElement;
    this.displayName = dto.type.toString();
    this.parentNavbarItemId = dto.parentNavbarItemId;
    this.children = [];
  }
  /**> 99 for manually added apps */
  id: number;

  type: PbdModule;
  /**Translated displayname */
  displayName: string;
  /**Translated displayName for sidebar */
  displayNameSidebar?: string;

  href: string;

  showElement: boolean;
  parentNavbarItemId?: number;
  children: AppVM[];
  appInfo?: ModuleUiInfoDefinition;

  /**Will be determined in appContext */
  hasAccess?: boolean;

  isVisibleInAppList?: boolean;
}
