import { CustomFieldApiObjectName } from "./custom-field-api-object-name";

export class CustomFieldApiValue {
  constructor(id: number, objectName: CustomFieldApiObjectName) {
    this.id = id;
    this.objectName = objectName;
  }

  id: number;
  objectName: CustomFieldApiObjectName;
}
