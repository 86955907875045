import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWRImmutable from "swr/immutable";
import { BooleanParam, StringParam, useQueryParams, withDefault } from "use-query-params";

import { useExternalLoginHook } from "@/hooks/useExternalLoginHook";
import { useMyProfileHook } from "@/hooks/useMyProfileHook";
import { useAPIServices } from "../../../services/serviceContext";
import StringHelpers from "../../../utils/stringHelpers";
import GenericAlert from "../../shared/components/alerts/genericAlert";
import Divider from "../../shared/components/divider/divider";
import { LoadingComponent } from "../../shared/components/loading/loadingComponent";
import CardFooterAccount from "../components/cardFooterAccount";
import ExternalLoginForm from "../components/externalLoginForm";
import HealthAlert from "../components/healthAlert";
import LoginForm from "../components/loginForm";

function LoginPage() {
  const { t } = useTranslation();
  const { data } = useExternalLoginHook();
  const { settingsService } = useAPIServices();
  const { data: healthReport } = useSWRImmutable("/api/health", () => settingsService.getHealth());
  // This hook must be called to ensure the user will redirect to home page if he is already logged in.
  const { data: meAsUser } = useMyProfileHook();
  const [query, setQuery] = useQueryParams({
    returnUrl: withDefault(StringParam, undefined),
    expired: withDefault(BooleanParam, undefined),
  });

  const qsForExternalLogin = query.returnUrl ? `?returnUrl=${query.returnUrl}` : null;

  return (
    <Card className="accountCard">
      <Card.Body>
        <h1 className="text-center">{t("Login")}</h1>
        <hr />
        {query.expired && (
          <GenericAlert heading={t("Session expired")}>
            {t("Your session has expired please login again.")}
          </GenericAlert>
        )}
        {healthReport && <HealthAlert data={healthReport} />}
        {!data?.loginProviders ? (
          <div className="text-center">
            <div>
              <LoadingComponent />
            </div>
            <span>{t("Loading...")}</span>
          </div>
        ) : (
          <>
            {data.enableExternalLogins && data.loginProviders.length > 0 && (
              <>
                <ExternalLoginForm loginProviders={data.loginProviders} qsForExternalLogin={qsForExternalLogin} />
                <Divider content={t("Or")} />
              </>
            )}
            {data.isLocalSignInActivated ? (
              <LoginForm returnUrl={query.returnUrl} />
            ) : (
              <GenericAlert type="info">
                {t(
                  "Your organization has disabled the local user accounts. Please select a login provider from the list above.",
                )}
              </GenericAlert>
            )}
          </>
        )}
        {!StringHelpers.isNullOrWhitespace(data?.tenantHelpContactEmail) && (
          <div className="text-center">
            {t("You need help? Please contact")}{" "}
            <a href={`mailto:${data?.tenantHelpContactEmail}`}>{data?.tenantHelpContactEmail}</a>
          </div>
        )}
        <CardFooterAccount />
      </Card.Body>
    </Card>
  );
}

export default LoginPage;
