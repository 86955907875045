import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { TrainingRoutePaths } from "./trainingRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.Trainings_Standard, PbdRoles.Trainings_ModuleAdmin],
};

export const trainingLazyRoutes: RouteObject[] = [
  {
    path: TrainingRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageTraining")),
    ...defaultRouteParams,
  },
  {
    path: TrainingRoutePaths.IndexPage,
    Component: lazy(() => import("./pages/indexPageTraining")),
    ...defaultRouteParams,
  },
  {
    path: TrainingRoutePaths.CreatePage,
    Component: lazy(() => import("./pages/createPageTraining")),
    ...defaultRouteParams,
  },
  {
    path: TrainingRoutePaths.EditPage,
    Component: lazy(() => import("./pages/detailsPageTraining")),
    ...defaultRouteParams,
  },
  {
    path: TrainingRoutePaths.TrainingAttendees,
    Component: lazy(() => import("../trainingAttendees/pages/indexPageTrainingAttendees")),
    ...defaultRouteParams,
  },
  {
    path: TrainingRoutePaths.TrainingDemands,
    Component: lazy(() => import("../trainingDemand/pages/indexPageTrainingDemand")),
    ...defaultRouteParams,
  },
  {
    path: TrainingRoutePaths.TrainingDemandCreate,
    Component: lazy(() => import("../trainingDemand/pages/createPageTrainingDemand")),
    ...defaultRouteParams,
  },
  {
    path: TrainingRoutePaths.TrainingDemandEdit,
    Component: lazy(() => import("../trainingDemand/pages/editPageTrainingDemand")),
    ...defaultRouteParams,
  },
];
