import { Settings } from "luxon";

export class NumberHelpers {
  static convertToMoney(input?: number, currency = "EUR") {
    if (!input) return "-";
    return new Intl.NumberFormat(Settings.defaultLocale, { style: "currency", currency: currency }).format(input);
    // return converted;
  }
  static convertToPercentage(input: number, minimumFractionDigits = 2, maximumFractionDigits = 2) {
    return new Intl.NumberFormat(Settings.defaultLocale, {
      style: "percent",
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(input);
  }

  /**
   * Converts a number to formatted string
   * @param minimumFractionDigits {number} [2]
   * @param maximumFractionDigits {number} [2]
   */
  static convertToFractionNumber(input: number, minimumFractionDigits = 2, maximumFractionDigits = 2) {
    return new Intl.NumberFormat(Settings.defaultLocale, {
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(input);
  }

  static isNumber(value: string | number): boolean {
    return value != null && value !== "" && !isNaN(Number(value.toString()));
  }

  static byteToMegabyte(value: number): number {
    return value / (1024 * 1024);
  }

  static megabyteToByte(value: number): number {
    return value * 1024 * 1024;
  }

  /**If value > 99 it returns 99+ */
  static cutOffMax(value?: number) {
    if (value && value > 99) {
      return "99+";
    } else {
      return value;
    }
  }
}
