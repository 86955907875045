import React from "react";
import { useTranslation } from "react-i18next";
import { Progress } from "reactstrap";

const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])(?=.{10,})/;
const okRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])(?=.{8,})/;
const mediumRegex = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*\d))|((?=.*[A-Z])(?=.*\d)))(?=.{8,})/;

interface Strength {
  value: number;
  displayName: "Weak" | "Medium" | "Ok" | "Strong";
  color: "danger" | "success" | "warning" | "info";
}

const checkPassword = (pw: string) => {
  let resp: Strength = { value: 0, displayName: "Weak", color: "danger" };
  if (strongRegex.test(pw)) {
    resp = { value: 100, displayName: "Strong", color: "success" };
  } else if (okRegex.test(pw)) {
    resp = { value: 70, displayName: "Ok", color: "info" };
  } else if (mediumRegex.test(pw)) {
    resp = { value: 30, displayName: "Medium", color: "warning" };
  }
  return resp;
};

interface IProps {
  password: string;
}

const PasswordStrengthComponent: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { password } = props;
  const pwStrength = React.useMemo(() => checkPassword(password), [password]);

  return (
    <React.Fragment>
      <div className="text-center">
        {t("Password strength")}
        <b className="ms-2">{t(pwStrength.displayName)}</b>
      </div>
      <Progress value={pwStrength.value} color={pwStrength.color} />
    </React.Fragment>
  );
};
export default PasswordStrengthComponent;
