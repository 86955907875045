import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  onClick: () => void;
  disabled?: boolean;
  size?: "sm";
  notAllowedMessage?: string;
  id?: string;
  active?: boolean;
}

const ToggleEditModeButton: React.FC<IProps> = (props) => {
  const { onClick, disabled, size, notAllowedMessage = "Not allowed", id, active } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Button
        id={id}
        active={active}
        onClick={onClick}
        disabled={disabled}
        size={size}
        title={disabled ? t(notAllowedMessage) : t("Toggle edit mode")}
      >
        <qmBaseIcons.Pencil />
      </Button>
    </React.Fragment>
  );
};

export default ToggleEditModeButton;
