import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { ConfirmEmailDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { ValidationResultDescriber } from "../../../pbdServices/Models/Shared/validation-result-describer";
import { ApiResult, wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import { useAPIs } from "../../../services/serviceContext";
import GenericAlert from "../../shared/components/alerts/genericAlert";
import ButtonBlockWrapper from "../../shared/components/buttons/buttonBlockWrapper";
import ButtonLink from "../../shared/components/buttons/buttonLink";
import { AccountRoutePaths } from "../accountRoutePaths";
import CardFooterAccount from "../components/cardFooterAccount";

function ConfirmEmailPage() {
  const { t } = useTranslation();
  const { accountApi } = useAPIs();
  const [apiResult, setApiResult] = React.useState<ApiResult<void>>();
  const [query, setQuery] = useQueryParams({
    userId: withDefault(StringParam, undefined),
    code: withDefault(StringParam, undefined),
  });

  React.useEffect(() => {
    async function getData() {
      const { userId, code } = query;
      if (userId && code && !apiResult) {
        const resp = await wrapApiCallWithToast(
          () => accountApi.confirmEmail(new ConfirmEmailDTO({ userId: userId, code: code })),
          { hideOnSuccess: true },
        );
        setApiResult(resp);
      }
    }
    getData();
  }, [query]);

  return (
    <Card className="text-center accountCard">
      <Card.Body>
        <Card.Title as="h3">{t("Email confirmation")}</Card.Title>
        <hr />
        {apiResult?.isErr && (
          <GenericAlert
            type="danger"
            description={t("Confirming your email did not succeed.")}
            entries={[ValidationResultDescriber.emailConfirmationFailed()]}
          />
        )}
        {apiResult?.isOk && (
          <>
            <GenericAlert
              type="success"
              description={t("Thank you for confirming your email.")}
              entries={undefined}
              dismissible={false}
            />
            <ButtonBlockWrapper>
              <ButtonLink variant="primary" to={AccountRoutePaths.Login}>
                {t("Login")}
              </ButtonLink>
            </ButtonBlockWrapper>
          </>
        )}
        <CardFooterAccount />
      </Card.Body>
    </Card>
  );
}

export default ConfirmEmailPage;
