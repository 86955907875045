import {
  IClaimDeadlineEntry,
  ICustomField,
  IEntityTemplateDTO,
  INotificationEntry,
  IWorkflowRule,
} from "@/generatedCode/pbd-core/pbd-core-api";

export class ClaimSettings {
  constructor() {
    this.customFields = [];
  }
  customFields: ICustomField[];
  deadlines?: IClaimDeadlineEntry[];
  notifications?: INotificationEntry[];
  workflowRules?: IWorkflowRule[];
  templates?: IEntityTemplateDTO[];
}
