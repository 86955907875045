import React from "react";
import { Button } from "react-bootstrap";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  href: string;
  label?: string | React.ReactNode;
  renderAs?: "link" | "button";
  color?: string;
  className?: string;
}

function ExternalLink(props: IProps) {
  const { href, label, renderAs = "link", color = "primary", className } = props;
  if (renderAs == "button") {
    return (
      <Button href={href} target="_blank" rel="noopener noreferrer" variant={color} className={className}>
        <span>
          {label ?? href} <qmBaseIcons.ExternalLink />
        </span>
      </Button>
    );
  } else {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
        <span>
          {label ?? href} <qmBaseIcons.ExternalLink />
        </span>
      </a>
    );
  }
}
export default ExternalLink;
