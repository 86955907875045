declare global {
  // Extend the Array interface
  interface Array<T> {
    /**This is for local compare
     * 1. First
     * 10. Tenth
     * 3. Third
     * will be => 1. First, 3. Third, 10. Tenth
     * This seems to be impossible with lodash.
     */
    sortByString(key: ValueGetterString<T>, order?: SortingOrder): T[];
  }
}

type ValueGetterString<T = unknown> = (item: T) => string;
type SortingOrder = "ascending" | "descending";

export function sortByString<T>(
  array: T[] | null | undefined,
  key: ValueGetterString<T>,
  order: SortingOrder = "ascending",
): T[] {
  if (!array) return [];
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
  if (order == "ascending") {
    return [...array].sort((a, b) => collator.compare(key(a), key(b)));
  }
  return [...array].sort((a, b) => collator.compare(key(b), key(a)));
}

if (!Array.prototype.sortByString) {
  // Add the method to Array.prototype
  Array.prototype.sortByString = function <T>(
    this: T[] | undefined,
    key: ValueGetterString<T>,
    order: SortingOrder = "ascending",
  ): T[] {
    return sortByString(this, key, order);
  };
}
