import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ErrorService from "../../../services/errors/error-service";
import { ProfileRoutePaths } from "../../profile/profileRoutePaths";
import { StartpageRoutePaths } from "../../startpage/startpageRoutePaths";
import { qmBaseIcons } from "../components/icons/qmBaseIcons";

import NotFoundContent from "./notFoundContent";

interface IProps {
  code: string;
  children?: React.ReactNode;
}

function ErrorPageContentDefault(props: IProps) {
  const { t, i18n } = useTranslation("errorCodes");
  const { code } = props;
  const errDescription = ErrorService.getErrorCodeDescription(code, t);
  return (
    <>
      <h1 className="display-4">
        {code} -{" "}
        {errDescription.title != code && errDescription.title ? t(errDescription.title.toString()) : t("Unknown error")}
      </h1>
      <p className="lead">{t(errDescription.detail ?? `${code}-detail`)}</p>
      {code == "404" && <NotFoundContent />}
      {props.children}
      <div className="mb-3">
        <Button variant="primary" className="me-2" as="a" href={StartpageRoutePaths.StartPage}>
          <qmBaseIcons.Home /> {t("Take me home")}
        </Button>
        <Button
          variant="outline-secondary"
          as="a"
          href={`${ProfileRoutePaths.IssuesCreatePage}/?url=${window.location.href}`}
        >
          {t("Contact support")}
        </Button>
      </div>
    </>
  );
}
export default ErrorPageContentDefault;
