import DescriptionListItem, { IDescriptionListItemProps } from "./descriptionListItem";

export interface IProps {
  list: IDescriptionListItemProps[];
  top?: React.ReactNode;
}

function DescriptionList(props: IProps) {
  const { list, top } = props;
  return (
    <dl>
      {top}
      {list.map((x, i) => (
        <DescriptionListItem {...x} key={i} />
      ))}
    </dl>
  );
}

export default DescriptionList;
