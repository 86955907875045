import React, { useRef } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import { useAPIs } from "../../../services/serviceContext";

import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import { LoadingComponent } from "../../shared/components/loading/loadingComponent";
import { AccountRoutePaths } from "../accountRoutePaths";
import CardFooterAccount from "../components/cardFooterAccount";

function GoodbyePage() {
  const { t } = useTranslation();
  const { accountApi } = useAPIs();
  const navigate = useNavigate();
  const initialized = useRef(false);
  const [loggedOut, setLoggedOut] = useQueryParam("loggedOut", BooleanParam);

  React.useEffect(() => {
    async function getData() {
      initialized.current = true;
      if (!loggedOut) {
        const resp = await wrapApiCallWithToast(() => accountApi.signOut(), { hideOnSuccess: true });
        if (resp.isOk) {
          setLoggedOut(true);
          navigate(0);
        }
      }
    }
    if (!initialized.current) {
      getData();
    }
  }, [loggedOut, setLoggedOut]);

  return (
    <Card className="text-center accountCard">
      {loggedOut ? (
        <Card.Body>
          <Card.Title as="h3">{t("See you soon!")}</Card.Title>
          <hr />
          <p className="lead">{t("Thanks for using our services.")}</p>
          <div className="text-center">
            <p>
              <Link to={AccountRoutePaths.Login}>{t("Back to login")}</Link>
            </p>
          </div>
          <CardFooterAccount />
        </Card.Body>
      ) : (
        <LoadingComponent />
      )}
    </Card>
  );
}

export default GoodbyePage;
