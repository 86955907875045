import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import { qmBaseIcons } from "../icons/qmBaseIcons";

function RecycleBinAlert() {
  const { t } = useTranslation();
  return (
    <Alert color="danger" className="text-center mb-3">
      <h4 className="alert-heading">
        <qmBaseIcons.Delete /> {t("Recycle bin")}
      </h4>
      <hr />
    </Alert>
  );
}

export default RecycleBinAlert;
