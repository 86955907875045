export default class AdminTokenStore {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static STORAGE_KEY = "adminToken";

  static getToken() {
    return window.sessionStorage.getItem(AdminTokenStore.STORAGE_KEY);
  }

  static setToken(token: string) {
    window.sessionStorage.setItem(AdminTokenStore.STORAGE_KEY, token);
  }

  static removeToken(): void {
    window.sessionStorage.removeItem(AdminTokenStore.STORAGE_KEY);
  }
}
