import { Field, Formik } from "formik";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import { FormGroup, Label } from "reactstrap";
import * as yup from "yup";

import { BoundType, BoundaryLevel, IOpportunityCategoryBoundary } from "@/generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { nameofFactory } from "../../../../utils/nameofFactory";
import { BaseFormProps } from "../../../shared/components/forms/formik/BaseFormProps";
import FormikCustomForm from "../../../shared/components/forms/formik/formikCustomForm";
import { FormikNumberInputGroup } from "../../../shared/components/forms/formik/formikNumberInput";
import { FormikSelectInput } from "../../../shared/components/forms/formik/formikSelectInput";
import { FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";

const nameof = nameofFactory<IOpportunityCategoryBoundary>();

const ValidationSchema: yup.ObjectSchema<IOpportunityCategoryBoundary> = yup.object({
  boundaryName: yup.string().required(),
  boundaryValue: yup.number().required(),
  type: yup.mixed<BoundType>().oneOf(Object.values(BoundType)).required(),
  boundaryLevel: yup.mixed<BoundaryLevel>().oneOf(Object.values(BoundaryLevel)).required(),
  boundaryId: yup.string().required(),
  description: yup.string(),
});

function EditFormCriticalValues(props: BaseFormProps<IOpportunityCategoryBoundary>) {
  const { itemToUpdate, onSubmit, onCancel, onSuccess } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IOpportunityCategoryBoundary>((val) => onSubmit(val), [onSubmit], onSuccess);

  const initialValues: IOpportunityCategoryBoundary = {
    boundaryId: itemToUpdate?.boundaryId ?? nanoid(),
    type: itemToUpdate?.type ?? BoundType.None,
    boundaryLevel: itemToUpdate?.boundaryLevel ?? BoundaryLevel.None,
    boundaryName: itemToUpdate?.boundaryName ?? "",
    description: itemToUpdate?.description ?? "",
    boundaryValue: itemToUpdate?.boundaryValue ?? 0,
  };

  return (
    <>
      {itemToUpdate ? (
        <h6>
          {t("Edit")}: {itemToUpdate.boundaryName}
        </h6>
      ) : (
        <h6>{t("New")}</h6>
      )}
      <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
        {(formikBag) => (
          <FormikCustomForm formikBag={formikBag} onCancel={onCancel}>
            <FormGroup>
              <Label for="type">{t("Type")}</Label>
              <Field component={FormikSelectInput} id="type" name="type">
                {Object.keys(BoundType).map((x) => (
                  <option key={x} value={x}>
                    {t(x)}
                  </option>
                ))}
              </Field>
            </FormGroup>
            <FormGroup>
              <Label for="boundaryLevel">{t("Level")}</Label>
              <Field component={FormikSelectInput} id="boundaryLevel" name="boundaryLevel">
                {Object.keys(BoundaryLevel).map((x) => (
                  <option key={x} value={x}>
                    {t(x)}
                  </option>
                ))}
              </Field>
            </FormGroup>
            <FormikTextInputGroup name={nameof("boundaryName")} label={t("Title")} />
            <FormikNumberInputGroup name={nameof("boundaryValue")} label={t("Value")} />
            <FormikTextInputGroup name={nameof("description")} textarea rows={3} />
          </FormikCustomForm>
        )}
      </Formik>
    </>
  );
}

export default EditFormCriticalValues;
