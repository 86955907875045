import React from "react";

interface IProps {
  content?: React.ReactNode;
}

const Divider: React.FC<IProps> = (props) => {
  const { content } = props;
  return (
    <React.Fragment>
      <div className="text-center d-flex mt-4">
        {content ? (
          <>
            <hr className="flex-grow-1" />
            <span className="px-2 font-weight-lighter align-self-center">{content}</span>
            <hr className="flex-grow-1" />
          </>
        ) : (
          <hr className="flex-grow-1" />
        )}
      </div>
    </React.Fragment>
  );
};
export default Divider;
