import axios from "axios";
import { ChartDataset } from "chart.js";
import _ from "lodash";
import { DateTime } from "luxon";

import { IDashboardDTO, IDashboardItemDTO, IDashboardsControllerClient } from "@/generatedCode/pbd-core/pbd-core-api";

import { QualityMonitorRoutePaths } from "../../../ClientApp/qualityMonitor/qualityMonitorRoutePaths";
import ColorService from "../Colors/colorService";
import JsonHelpers from "../Json/jsonHelpers";

import { ChartSetting } from "./models/chart-setting";
import { BaseDashboardItemConfig } from "./models/dashboard-item-content";
import { DashboardLayout } from "./models/dashboard-layout";
import { ExternalChartSourceConfigExtension } from "./models/external-chart-source-config-extension";

interface IDataPoint {
  x: DateTime;
  y: number;
  groupBy: string;
}

export class DataPoint implements IDataPoint {
  constructor(item: Record<string, string | number>, config: ExternalChartSourceConfigExtension) {
    this.x = DateTime.fromISO(item[config.key].toString());
    this.y = Number(item[config.value]);
    this.groupBy = config.groupBy ? item[config.groupBy].toString() : "notImplemented";
  }
  x: DateTime;
  y: number;
  groupBy: string;
}

export interface DashboardItemVm extends IDashboardItemDTO {
  contentMapped: BaseDashboardItemConfig;
}

export interface IDashboardVm extends IDashboardDTO {
  layoutParsed: DashboardLayout;
  charts: DashboardItemVm[];
}

export default class DashboardService {
  constructor(dashboardsApi: IDashboardsControllerClient) {
    this._dashboardsApi = dashboardsApi;
  }
  private _dashboardsApi: IDashboardsControllerClient;

  async getAll() {
    const resp = await this._dashboardsApi.getAll();
    return resp.map((r) => {
      return {
        ...r,
        linkTo: QualityMonitorRoutePaths.DetailsPageDashboard.replace(":id", r.id),
      };
    });
  }

  private _mapDashboards(items: IDashboardItemDTO[]): DashboardItemVm[] {
    return items.map((x) => {
      return { ...x, contentMapped: new BaseDashboardItemConfig(x) };
    });
  }

  getById(id: string) {
    return this._dashboardsApi.getById(id);
  }

  async getByIdAsVm(id: string): Promise<IDashboardVm | undefined> {
    const item = await this.getById(id);
    if (!item) return undefined;
    let charts: DashboardItemVm[] = [];
    try {
      charts = this._mapDashboards(item.items ?? []);
    } catch {
      console.warn("Error mapping dashboards");
    }
    const layoutParsed = this.getLayoutParsed(item, charts);
    charts.sort((a, b) => layoutParsed.itemOrder.indexOf(a.id) - layoutParsed.itemOrder.indexOf(b.id));
    return {
      ...item,
      layoutParsed,
      charts,
    };
  }

  async getChartData(item: ExternalChartSourceConfigExtension): Promise<DataPoint[]> {
    const resp = await axios.get<Record<string, string | number>[]>(item.url);
    const mapped = resp.data.map((x) => new DataPoint(x, item));
    return mapped;
  }

  groupBy(items: DataPoint[], groupBy: "Month" | "Day") {
    if (groupBy == "Day") return items;

    const mapped = items.map((t) => {
      return {
        key: `${t.x.toISODate().slice(0, 7)}_${t.groupBy}`,
        value: t.y,
      };
    });

    const ans = _(mapped)
      .groupBy((x) => x.key)
      .map((platform, id) => ({
        key: id,
        sumOfValues: _.sumBy(platform, (x) => x.value),
      }));
    const values = ans.value();
    const localResult = values.map((x) => {
      const m2: DataPoint = {
        x: DateTime.fromISO(x.key.split("_")[0]),
        y: x.sumOfValues,
        groupBy: x.key.split("_")[1] ?? "notImplemented",
      };
      return m2;
    });
    return localResult;
  }

  groupedDataByParam(items: DataPoint[]): ChartDataset<"bar", number[]>[] {
    const data: ChartDataset<"bar", number[]>[] = [];
    const unique = Array.from(_.uniq(items.map((x) => x.groupBy))).sort((a, b) => a.localeCompare(b));
    const colors = ColorService.getColorMap(unique.length);
    for (let index = 0; index < unique.length; index++) {
      const grouped = unique[index];
      const itemsInGroup = items.filter((x) => x.groupBy == grouped);
      data.push({
        label: grouped,
        data: items.map((x) => itemsInGroup.find((y) => y.x == x.x)?.y ?? 0),
        backgroundColor: colors[index],
      });
    }
    return data;
  }

  getInitialSettings(item?: string): ChartSetting {
    if (item) {
      const data = JsonHelpers.parse<ChartSetting>(item);
      data.from = DateTime.now().startOf(data.dateType ?? "week");
      data.to = DateTime.now().endOf(data.dateType ?? "week");
      return data;
    }
    return {
      from: DateTime.now().minus({ days: 7 }),
      to: DateTime.now().plus({ days: 7 }),
      groupBy: "Day",
      dateType: "month",
    };
  }

  getLayoutParsed(dashboard: IDashboardDTO, charts: IDashboardItemDTO[]): DashboardLayout {
    const layout = dashboard.layoutDefinition
      ? JsonHelpers.parse<DashboardLayout>(dashboard.layoutDefinition)
      : new DashboardLayout();
    layout.itemOrder.push(...charts.map((x) => x.id));
    layout.itemOrder = Array.from(_.uniq(layout.itemOrder));
    return layout;
  }
}
