import clsx from "clsx";
import React from "react";
import { Overlay } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ITenantDTO, ITenantMinDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import TenantService from "../../../../pbdServices/services/Tenants/tenantService";
import TenantHoverCard from "../hoverCards/tenantHoverCard";
import { useHoverActionsHook } from "../hoverCards/useHoverActionsHook";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import AvatarSpan, { AvatarSize } from "./avatarSpan";
import NoUserComponent from "./noUserComponent";

interface IProps {
  tenant: ITenantDTO | ITenantMinDTO;
  linkTo?: string;
  onClick?: () => void;
  title?: string;
  showNotificationBell?: boolean;
  size?: AvatarSize;
  className?: string;
  includePopover?: boolean;
  showPosition?: boolean;
}
function AvatarSpanWithName(props: IProps) {
  const {
    tenant,
    onClick,
    linkTo,
    title,
    showNotificationBell = window.location.pathname.startsWith("/Employees"),
    className,
    includePopover = true,
    showPosition,
  } = props;

  const { show, setShow, handleMouseEnter, handleOnMouseLeave, debouncedHandleMouseLeave, target } =
    useHoverActionsHook();

  const displayName = tenant.fullName;
  const tenantType = tenant as ITenantDTO;
  const isUser: boolean = tenantType.applicationUserId ? true : false;
  const clickableStyle: React.CSSProperties = { cursor: onClick != null ? "pointer" : undefined };

  return (
    <>
      {includePopover && (
        <Overlay target={target.current} show={show}>
          {(cardProps) => (
            <TenantHoverCard
              item={tenant}
              id="popover-contained"
              onMouseLeave={() => setShow(false)}
              onMouseEnter={() => debouncedHandleMouseLeave.cancel()}
              {...cardProps}
            />
          )}
        </Overlay>
      )}

      <div
        className={clsx(`avatarWithNameContainer${onClick != null ? " hoverInput" : ""}`, className)}
        onClick={onClick}
        style={clickableStyle}
        title={title}
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <div className="d-flex">
          <div className="me-1">
            <AvatarSpan {...props} />
          </div>
          <div>
            <div ref={target}>
              <div>
                {linkTo == null ? (
                  <span>{displayName}</span>
                ) : (
                  <span>
                    <Link to={linkTo}>{displayName}</Link>
                  </span>
                )}

                {showNotificationBell && <NoUserComponent item={tenant} />}
              </div>
              {showPosition && TenantService.isTenant(tenant) && tenantType.primaryDepartmentPosition && (
                <div>
                  <small className="text-secondary">{tenantType.primaryDepartmentPosition.title}</small>
                </div>
              )}
            </div>
          </div>
          {onClick && (
            <div>
              <qmBaseIcons.Pencil className="ms-1 me-1 text-white" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AvatarSpanWithName;
