import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import StringHelpers from "../../../../../utils/stringHelpers";

interface IProps {
  label?: React.ReactNode;
  name: string;
}

export function CustomFormLabel(props: IProps) {
  const { t } = useTranslation();
  const { label, name } = props;
  return <Form.Label>{label ?? t(StringHelpers.formatLabelText(name))}</Form.Label>;
}
