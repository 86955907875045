import React from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { ConfirmChangeEmailDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import { useAPIs } from "../../../services/serviceContext";
import GenericAlert from "../../shared/components/alerts/genericAlert";
import { StartpageRoutePaths } from "../../startpage/startpageRoutePaths";
import CardFooterAccount from "../components/cardFooterAccount";

function ConfirmChangeEmailPage() {
  const { t } = useTranslation();
  const { accountApi } = useAPIs();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [query, setQuery] = useQueryParams({
    userId: withDefault(StringParam, undefined),
    code: withDefault(StringParam, undefined),
    email: withDefault(StringParam, undefined),
  });

  React.useEffect(() => {
    async function getData() {
      const { userId, code, email } = query;
      if (userId && code && email) {
        const resp = await wrapApiCallWithToast(() =>
          accountApi.confirmEmailChange(
            new ConfirmChangeEmailDTO({
              userId,
              code,
              email,
            }),
          ),
        );
        if (resp.isErr) {
          setError(true);
        }
      } else {
        setError(true);
      }
    }
    getData();
  }, [query.code, query.email, query.userId]);

  return (
    <Card className="text-center accountCard">
      <Card.Body>
        <Card.Title as="h3">{t("Email change confirmation")}</Card.Title>
        <hr />
        {error && (
          <GenericAlert type="danger">
            {t("Confirming your email did not succeed.")} {t("Possible problems")}
            <ul>
              <li>{t("The email was older than 24 hours")}</li>
              <li>{t("The email is already taken by someone else")}</li>
              {!query.email && <li>{t("You did not provide a new email")}</li>}
            </ul>
          </GenericAlert>
        )}
        {!loading && !error && (
          <React.Fragment>
            <p className="lead">{t("You have changed your email address.")}</p>
            <p>
              {t("New email address for")}: {query.email}
            </p>
            <div className="d-grid gap-2 mb-3">
              <Button variant="primary" as="a" href={StartpageRoutePaths.StartPage}>
                {t("Continue")}
              </Button>
            </div>
          </React.Fragment>
        )}
        <CardFooterAccount />
      </Card.Body>
    </Card>
  );
}

export default ConfirmChangeEmailPage;
