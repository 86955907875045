import React from "react";
import { Breadcrumb, Button, Card, CardBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useSWR from "swr";

import { useAPIs } from "../../../services/serviceContext";

import { useTypedParams } from "@/hooks/useTypedParams";
import { AdminRoutePaths } from "../../admin/adminRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import IdComponent from "../../shared/components/id/idComponent";
import CostTemplateItemForm from "../components/costTemplateItemForm";

interface IProps {}

const EditPageCostTemplateItem: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { id, costTemplateId } = useTypedParams(["id", "costTemplateId"]);
  const navigate = useNavigate();
  const { costTemplatesApi } = useAPIs();

  const { data, mutate } = useSWR(["/api/costTemplates", id, costTemplateId], () =>
    costTemplatesApi.getById(costTemplateId),
  );

  const parentRoute = AdminRoutePaths.EditCostTemplate.replace(":id", costTemplateId);

  const handleSuccess = () => {
    mutate();
    navigate(parentRoute);
  };

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: AdminRoutePaths.GeneralConfiguration }}>
          {t("General")}
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: parentRoute }}>
          {data?.title}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <IdComponent id={id} />
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card className="mb-3">
        <CardBody>
          <div className="d-flex justify-content-end">
            <Button variant="danger">
              <qmBaseIcons.Delete />
            </Button>
          </div>
          {data && (
            <CostTemplateItemForm
              onCancel={() => navigate(parentRoute)}
              itemToUpdate={data.items.find((f) => f.id == id)}
              item={data}
              onSuccess={handleSuccess}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default EditPageCostTemplateItem;
