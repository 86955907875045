import { DateTime } from "luxon";
import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DateTimeLuxonHelpers } from "../../../../utils/dateTimeLuxonHelpers";
import { qmBaseIcons } from "../icons/qmBaseIcons";

const formatDate = (date: DateTime, includeTime: boolean) => date.toISO().slice(0, includeTime ? 16 : 10);

const getInputValue = (date: DateTime | undefined, includeTime: boolean) => {
  if (!date) return "";
  return formatDate(date, includeTime);
};

interface IProps {
  label: string;
  from?: DateTime;
  to?: DateTime;
  setFrom: (from?: DateTime) => void;
  setTo: (to?: DateTime) => void;
  minInput?: DateTime;
  maxInput?: DateTime;
  type?: "date" | "datetime-local";
  readOnly?: boolean;
  className?: string;
}

/**
 * Complicated with date handling utc vs local time
 */
function DateRangeFilter(props: IProps) {
  const { t } = useTranslation();
  const { label, from, to, setFrom, setTo, minInput, maxInput, type = "date", readOnly, className = "mb-3" } = props;

  const handleSet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    let date = e.target.value ? DateTime.fromISO(e.target.value) : undefined;
    if (type == "date" && date) {
      if (name == "from") {
        date = date.startOf("day");
      } else if (name == "to") {
        date = date.endOf("day");
      }
    }
    if (name == "from") {
      if (date && to) {
        if (date.year > 1000 && date > to) {
          setTo(date);
        }
      }
      setFrom(date);
    } else if (name == "to") {
      if (date && from) {
        if (date.year > 1000 && from > date) {
          setFrom(date);
        }
      }
      setTo(date);
    }
  };

  return (
    <Form.Group className={className}>
      <Form.Label htmlFor={label}>{label}</Form.Label>
      <InputGroup id={label}>
        <Form.Control
          type={type}
          value={getInputValue(from, type == "datetime-local")}
          // defaultValue={getInputValue(from, type == "datetime-local")}
          onChange={handleSet}
          min={minInput ? DateTimeLuxonHelpers.getISODateForQuery(minInput) : undefined}
          max={maxInput ? DateTimeLuxonHelpers.getISODateForQuery(maxInput) : undefined}
          // max={to ? DateTimeLuxonHelpers.getISODateForQuery(to) : undefined}
          readOnly={readOnly}
          title={t("From")}
          name="from"
        />
        <InputGroup.Text>
          <qmBaseIcons.ArrowRight />
        </InputGroup.Text>
        <Form.Control
          type={type}
          value={getInputValue(to, type == "datetime-local")}
          // defaultValue={getInputValue(to, type == "datetime-local")}
          onChange={handleSet}
          // min={from ? DateTimeLuxonHelpers.getISODateForQuery(from) : undefined}
          min={minInput ? DateTimeLuxonHelpers.getISODateForQuery(minInput) : undefined}
          max={maxInput ? DateTimeLuxonHelpers.getISODateForQuery(maxInput) : undefined}
          readOnly={readOnly}
          title={t("To")}
          name="to"
        />
      </InputGroup>
    </Form.Group>
  );
}
export default DateRangeFilter;
