import React from "react";
import { Container } from "react-bootstrap";

interface IProps {
  children: React.ReactNode;
}

function ErrorPageLayout({ children }: IProps) {
  return (
    <div className="h-100 d-flex align-items-center mt-5">
      <Container className="text-center">{children}</Container>
    </div>
  );
}
export default ErrorPageLayout;
