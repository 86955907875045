import { debounce } from "lodash";
import { useRef, useState } from "react";

export function useHoverActionsHook() {
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const debouncedHandleMouseEnter = debounce(() => setShow(true), 700);

  const handleMouseEnter = () => {
    if (!show) {
      debouncedHandleMouseEnter();
    }
  };

  const debouncedHandleMouseLeave = debounce(() => setShow(false), 400);

  const handleOnMouseLeave = () => {
    // setShow(false);
    if (show) {
      debouncedHandleMouseLeave();
    } else {
      debouncedHandleMouseEnter.cancel();
    }
  };
  return {
    setShow,
    show,
    handleMouseEnter,
    handleOnMouseLeave,
    debouncedHandleMouseEnter,
    debouncedHandleMouseLeave,
    target,
  };
}
