import React from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ICustomField, IWorkflowRule, PbdModule, SettingType } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../../services/serviceContext";

import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { useRefreshHook } from "@/hooks/useRefreshHook";
import FeatureFlagService from "../../../../pbdServices/services/FeatureFlags/featureFlagService";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import RequiredRolesComponent from "../../../admin/roles/components/requiredRolesComponent";
import AppUnderDevelopmentAlert from "../../../shared/components/alerts/appUnderDevelopmentAlert";
import EditFormWorkflow from "../components/editFormWorkflow";
import WorkflowRenderer from "../components/workflowRenderer";

function IndexPageWorkflows() {
  const { t } = useTranslation();
  const { customFieldsApi, moduleSettingsApi } = useAPIs();
  const { moduleSettingsService } = useAPIServices();
  const { module } = useParams<{ module: PbdModule }>();
  const [existingRules, setExistingRules] = React.useState<IWorkflowRule[]>();
  const [customFields, setCustomFields] = React.useState<ICustomField[]>();
  const [{ refresh, handleRefresh }] = useRefreshHook();
  const confirm = useConfirmation();

  React.useEffect(() => {
    async function getData() {
      if (module) {
        const cf = await customFieldsApi.getCustomFieldsByModule(module);
        setCustomFields(cf);
        const rulesResp = await moduleSettingsApi.getWorkflows(module);
        setExistingRules(rulesResp);
      }
    }
    getData();
  }, [module, moduleSettingsService, refresh]);

  const tryToDelete = async (item: IWorkflowRule) => {
    await confirm({ itemsToDelete: [item] }).then(() => hardDelete(item.id));
  };

  const hardDelete = async (id: string) => {
    if (module) await moduleSettingsService.hardDelete(module, SettingType.WorkflowRule, id);
    handleRefresh();
  };

  if (!FeatureFlagService.isWorkflowRuleAvailable()) {
    return <AppUnderDevelopmentAlert />;
  }

  return (
    <Card className="mb-3">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <Card.Title as="h5">{t("Workflows")}</Card.Title>
          <RequiredRolesComponent roles={[PbdRoles.Admin]} />
        </div>
        .
      </Card.Header>
      <Card.Body>
        <AppUnderDevelopmentAlert />
        <p className="lead">
          {t(
            "A workflow allows you to specify actions on work item fields to automate their behavior. By defining conditions, you can control when the system applies these changes to the fields. Learn more about work item rules in our documentation.",
          )}
        </p>
        {existingRules && <WorkflowRenderer workflowRules={existingRules} />}
        {customFields && <EditFormWorkflow customFields={customFields} refreshParent={handleRefresh} app={module} />}
        {existingRules && (
          <>
            <h4>
              {t("Existing rules")} ({existingRules.length}) (RAW)
            </h4>
            {existingRules.map((x) => (
              <div key={x.id}>
                <h5>{x.title}</h5>
                <pre>{JSON.stringify(x, null, 2)}</pre>
                <Button variant="outline-danger" onClick={() => tryToDelete(x)}>
                  {t("Delete")}
                </Button>
              </div>
            ))}
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default IndexPageWorkflows;
