import React from "react";
import { Card, Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

import {
  IOpportunityCategoryBoundary,
  IOpportunityCategoryDTO,
  OpportunityCategoryBoundary,
} from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import CollapseChevron from "../../../shared/components/icons/collapseChevron";
import { TableRowClickCommand } from "../../../shared/components/tables/TableRowClickCommand";
import { BoundariesTable } from "./boundariesTable";
import EditFormCriticalValues from "./editFormCriticalValues";

interface IProps {
  baseDTO: IOpportunityCategoryDTO;
  refreshParent: () => void;
}

function CriticalValuesConnectedCard(props: IProps) {
  const { baseDTO, refreshParent } = props;
  const { t } = useTranslation();
  const { opportunityCategoriesApi } = useAPIs();
  const [itemToUpdate, setItemToUpdate] = React.useState<IOpportunityCategoryBoundary>();
  const [showForm, setShowForm] = React.useState(false);
  const confirm = useConfirmation();
  const [isOpen, setIsOpen] = useQueryParam("boundaries", withDefault(BooleanParam, false));

  const toggle = () => setIsOpen(!isOpen);

  function toggleShowForm() {
    setItemToUpdate(undefined);
    setShowForm(!showForm);
  }

  function handleRowEditClick(command: TableRowClickCommand<IOpportunityCategoryBoundary>) {
    const { action, row } = command;
    setItemToUpdate(undefined);
    if (!row) throw Error("Item not found");
    setItemToUpdate(row);
    switch (action) {
      case "Select":
        setShowForm(true);
        break;
      case "Delete":
        confirm({ itemsToDelete: [{ id: row.boundaryId, title: row.boundaryName }] }).then(async () => {
          await opportunityCategoriesApi.deleteBoundary(baseDTO.id, row.boundaryId);
          refreshParent();
        });
        break;
      default:
        throw Error("Not implemented");
    }
  }

  const handleSubmit = (dto: IOpportunityCategoryBoundary) =>
    opportunityCategoriesApi.addOrUpdateBoundary(baseDTO.id, new OpportunityCategoryBoundary(dto));

  const handleSuccess = () => {
    setShowForm(false);
    setItemToUpdate(undefined);
    refreshParent();
  };

  return (
    <>
      <Card.Header className="d-flex justify-content-between">
        <Card.Title as="h5">
          <a href="#criticalValuesCard" onClick={toggle}>
            <span>
              <CollapseChevron isOpen={isOpen} /> 4. {t("Critical values")}
            </span>
          </a>
        </Card.Title>
      </Card.Header>
      <Collapse in={isOpen}>
        <Card.Body>
          {showForm && (
            <EditFormCriticalValues
              itemToUpdate={itemToUpdate}
              onSubmit={handleSubmit}
              onCancel={toggleShowForm}
              onSuccess={handleSuccess}
            />
          )}
          {!showForm && (
            <BoundariesTable
              tableRows={baseDTO.boundaries}
              onRowClick={handleRowEditClick}
              onCreateClick={toggleShowForm}
            />
          )}
        </Card.Body>
      </Collapse>
    </>
  );
}

export default CriticalValuesConnectedCard;
