export interface IReactSelectDTO<T extends string | number> {
  label: string;
  value: T;
}

interface IMapFrom<T extends string | number> {
  id: T;
  title: string;
}

export class ReactSelectDTO<TId extends string | number = number> implements IReactSelectDTO<TId> {
  constructor({ id, title }: IMapFrom<TId>) {
    this.label = title;
    this.value = id;
  }
  label: string;
  value: TId;
}
