import React, { createContext, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { useApps } from "@/hooks/useApps";
import { usePageTitle } from "@/hooks/usePageTitle";

interface ContextInterface {
  pageTitle: string;
  setPageTitle: (title: string) => void;
}

const CustomHelmetContext = createContext<ContextInterface | null>(null);

const useCustomHelmetContext = () => {
  const object = useContext(CustomHelmetContext);

  if (!object) {
    throw new Error("useAuthorizationContextNew must be used within a Provider");
  }
  return object;
};

interface Props {
  children: React.ReactNode;
}

/**
 * This context makes sure the authorization state is available. That's it no redirect or any other logic
 */
const CustomHelmetContextProvider = ({ children }: Props) => {
  const { available, productConfig } = useApps();
  const pageTitle = usePageTitle(available, productConfig);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export { CustomHelmetContext, CustomHelmetContextProvider, useCustomHelmetContext };
