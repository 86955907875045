import { DateTime } from "luxon";

import {
  BaseMinDTO,
  DepartmentPositionDTO,
  DepartmentPositionForTenantDTO,
  EntityKey,
  Gender,
  ITenantDTO,
  ITenantDetailsDTO,
  PbdModule,
  TenantDTO,
  TenantDetailsDTO,
  TenantMinDTO,
} from "@/generatedCode/pbd-core/pbd-core-api";

import { TestBirthdays } from "../../../../ClientApp/startpage/components/sidebarStartpage.test";
import { DTOMap } from "../../../../utils/filterMap";
import { ValidationResult } from "../../../Models/Shared/validation-result";
import { buildCapabilities } from "./capabilityMockData";

const department = new BaseMinDTO({ title: "IT", id: 1 });

export interface ITenantMockDTO extends ITenantDTO {
  // capabilities: ICapabilitiesDTO;
  isDeleted: boolean;
  isPrivate: boolean;
  isInterface: boolean;
  applicationUserId?: string;
  warnings?: ValidationResult[];
}

export class TenantsMockData extends DTOMap<ITenantMockDTO> {
  constructor() {
    const items: ITenantMockDTO[] = [
      {
        id: 1,
        fullName: "Philipp Pähler",
        firstName: "Philipp",
        lastName: "Pähler",
        gender: Gender.Male,
        birthday: DateTime.fromISO("1985-11-26"),
        publishBirthday: true,
        departmentPositions: [
          new DepartmentPositionForTenantDTO({ isPrimary: true, id: 1, title: "test", department }),
        ],
        capabilities: buildCapabilities(),
        isEmployee: true,
        tags: [],
        createdAt: DateTime.fromISO("2021-01-01"),
        externalId: "",
        isInterface: false,
        isPrivate: false,
        isDeleted: false,
        type: PbdModule.TenantManagement,
        email: "test@test.com",
        entityKey: EntityKey.Tenant,
      },
      {
        id: 2,
        fullName: "John Doe",
        firstName: "John",
        lastName: "Doe",
        email: "john@doe.com",
        birthday: DateTime.fromISO("1985-11-26"),
        publishBirthday: true,
        initial: "JD",
        gender: Gender.Male,
        isEmployee: true,
        tags: [],
        applicationUserId: "b77d409a-10cd-4a47-8e94-b0cd0ab50aa1",
        isInterface: false,
        createdAt: DateTime.fromISO("2020-01-01"),
        departmentPositions: [],
        capabilities: buildCapabilities(),
        externalId: "",
        isPrivate: false,
        isDeleted: false,
        type: PbdModule.TenantManagement,
        cultureInfoCode: "en-US",
        entityKey: EntityKey.Tenant,
      },
      {
        id: 3,
        fullName: "Maxima Musterfrau",
        firstName: "Maxima",
        lastName: "MusterFau",
        gender: Gender.Female,
        birthday: DateTime.fromISO("1980-10-20"),
        publishBirthday: true,
        departmentPositions: [
          new DepartmentPositionForTenantDTO({ isPrimary: true, id: 1, title: "test", department }),
        ],
        capabilities: buildCapabilities(),
        isEmployee: true,
        tags: [],
        createdAt: DateTime.fromISO("2021-01-01"),
        externalId: "",
        isInterface: false,
        isDeleted: false,
        isPrivate: false,
        type: PbdModule.TenantManagement,
        entityKey: EntityKey.Tenant,
      },
      {
        id: 4,
        fullName: "Lutz Dieckhöfer",
        firstName: "Lutz",
        lastName: "Dieckhöfer",
        gender: Gender.Male,
        birthday: DateTime.fromISO("1985-01-15"),
        publishBirthday: false,
        departmentPositions: [
          new DepartmentPositionForTenantDTO({ isPrimary: true, id: 2, title: "test", department }),
        ],
        capabilities: buildCapabilities(),
        isEmployee: true,
        tags: [],
        createdAt: DateTime.fromISO("2021-01-01"),
        externalId: "",
        isInterface: false,
        isDeleted: false,
        isPrivate: false,
        type: PbdModule.TenantManagement,
        entityKey: EntityKey.Tenant,
      },
      {
        id: 5,
        fullName: "Max Mustermann",
        firstName: "Max",
        lastName: "Mustermann",
        gender: Gender.Male,
        departmentPositions: [
          new DepartmentPositionForTenantDTO({ isPrimary: true, id: 2, title: "test", department }),
        ],
        capabilities: buildCapabilities(),
        publishBirthday: true,
        birthday: DateTime.fromISO("2021-08-14"),
        isEmployee: true,
        tags: [],
        createdAt: DateTime.fromISO("2021-01-01"),
        externalId: "",
        isInterface: false,
        isDeleted: false,
        isPrivate: false,
        type: PbdModule.TenantManagement,
        entityKey: EntityKey.Tenant,
      },
      {
        id: 6,
        fullName: "Published birthday without date",
        firstName: "Max",
        lastName: "Mustermann",
        gender: Gender.Male,
        departmentPositions: [
          new DepartmentPositionForTenantDTO({
            isPrimary: false,
            id: 5,
            title: "test",
            department: new BaseMinDTO({ id: 2, title: "Sales" }),
          }),
          new DepartmentPositionForTenantDTO({
            isPrimary: true,
            id: 2,
            title: "test",
            department: new BaseMinDTO({ id: 1, title: "IT" }),
          }),
        ],
        capabilities: buildCapabilities(),
        primaryDepartmentPosition: new DepartmentPositionDTO({
          isPrimary: true,
          id: 4,
          title: "test",
          entityKey: EntityKey.DepartmentPosition,
          department: new BaseMinDTO({ id: 1, title: "Department" }),
        }),
        publishBirthday: true,
        isEmployee: true,
        tags: [],
        createdAt: DateTime.fromISO("2021-01-01"),
        externalId: "",
        isInterface: false,
        isDeleted: false,
        isPrivate: false,
        type: PbdModule.TenantManagement,
        email: "test@test.com",
        entityKey: EntityKey.Tenant,
      },
    ];

    super(items);
  }

  get itemsDetails(): ITenantDetailsDTO[] {
    const itemsDetails = this.items.map((x) => new TenantDetailsDTO({ ...x, isEmployee: false }));
    return itemsDetails;
  }

  getJohnDoeMin(): TenantMinDTO {
    const johnDoe = this.getJohnDoe();
    return new TenantMinDTO(johnDoe);
  }

  getJohnDoe() {
    return this.mustGetById(2);
  }

  static getDeletedPlaceholder(id: number) {
    return new TenantDTO({
      id,
      fullName: "Deleted person",
      lastName: "Deleted",
      isEmployee: false,
      externalId: "",
      publishBirthday: false,
      isInterface: false,
      gender: Gender.NotSet,
      isPrivate: false,
      createdAt: DateTime.now(),
      type: PbdModule.TenantManagement,
      entityKey: EntityKey.Tenant,
    });
  }

  getAllEmployees() {
    return this.items.filter((x) => x.isEmployee);
  }

  getAllTenantsWithoutBirthday(birthdays: TestBirthdays[]): ITenantMockDTO[] {
    const mappedItems = this.items.map((x) => {
      if (x.id == birthdays[0].id) {
        x.birthday = birthdays[0].birthday;
      } else if (x.id == birthdays[1].id) {
        x.birthday = birthdays[1].birthday;
      } else {
        x.birthday = undefined;
      }
      return x;
    });
    return mappedItems;
  }
}
