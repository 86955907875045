import React, { forwardRef } from "react";
import { Badge } from "react-bootstrap";

interface NotificationBellToggleProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  showBadge?: boolean;
  badgeCount?: number;
}

const NotificationBellToggle = forwardRef<HTMLButtonElement, NotificationBellToggleProps>(
  ({ onClick, showBadge, badgeCount }, ref) => (
    <button
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="nav-link position-relative"
    >
      <i className="fa-regular fa-bell bell-icon"></i>
      {showBadge && badgeCount && badgeCount > 0 && (
        <Badge
          pill
          bg="info"
          style={{
            position: "absolute",
            top: "5%",
            right: "-20%",
            padding: "2px 6px",
          }}
        >
          {badgeCount}
        </Badge>
      )}
    </button>
  ),
);

export default NotificationBellToggle;
