/**Encapsulates an error from the validation.
 * Based on: https://github.com/dotnet/aspnetcore/blob/05b2afb73b55b11d8cb3691dedfebba1f0285332/src/Identity/Extensions.Core/src/IdentityError.cs
 */
export class ValidationResult {
  constructor(code: string) {
    this.code = code;
    this.description = `validationResult::${code}`;
  }
  /**
   * Gets or sets the code for this error.
   * @value
   * The code for this error.
   */
  code: string;

  /**
   * Gets or sets the description for this error.
   * @value
   * The description for this error.
   */
  description: string;
}
