import React from "react";
import { Badge } from "react-bootstrap";

import { ITagDTO } from "@/generatedCode/pbd-core/pbd-core-api";

interface IProps {
  tag: ITagDTO;
  size?: "lg";
}

function getStyle(tag: ITagDTO, size?: "lg") {
  if (tag.color == null && tag.backgroundColor == null) {
    return null;
  } else {
    const style: React.CSSProperties = {
      color: tag.color,
      backgroundColor: tag.backgroundColor,
    };
    return style;
  }
}

function TagComponent(props: IProps) {
  const { tag, size } = props;
  const style = getStyle(props.tag);
  if (style) {
    return (
      <Badge className="me-1" style={style} bg="secondary">
        {tag.title}
      </Badge>
    );
  } else {
    return (
      <Badge className="me-1" bg="success">
        {tag.title}
      </Badge>
    );
  }
}

export default TagComponent;
