import _ from "lodash";

import { ITenantDTO, ITenantMinDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { ClaimTypeNames } from "../../../Constants/ClaimTypeNames";
import { DateTimeLuxonHelpers } from "../../../utils/dateTimeLuxonHelpers";
import { filterMap, median } from "../../../utils/filterMap";
import { MeAsUser } from "../UserSettings/models/me-as-user";

/**
 * This should be used to centralize logic for calculations with tenants
 */
export class TenantServiceInMemory {
  getAges(tenants: ITenantDTO[]) {
    return filterMap(tenants, (t) => (t.birthday ? DateTimeLuxonHelpers.getAge(t.birthday) : undefined));
  }

  getAge(tenant: ITenantDTO) {
    if (tenant.birthday === undefined) return undefined;
    const age = DateTimeLuxonHelpers.getAge(tenant.birthday);
    return age < 0 ? undefined : age;
  }

  getAverageAge(tenants: ITenantDTO[]) {
    return _.mean(this.getAges(tenants));
  }

  getMinimumAge(tenants: ITenantDTO[]) {
    return _.min(this.getAges(tenants));
  }
  getMaximumAge(tenants: ITenantDTO[]) {
    return _.max(this.getAges(tenants));
  }

  getMedianAge(tenants: ITenantDTO[]) {
    if (tenants.length === 0) return 0;
    const ages = this.getAges(tenants);
    return median(ages);
  }

  static getTenantsByDepartment(tenants: ITenantDTO[], departmentId: number) {
    const result = tenants.filter((x) => x.departmentPositions?.map((x) => x.department?.id).includes(departmentId));
    return result;
  }

  static getTenantsByDepartmentPosition<T extends ITenantDTO>(tenants: T[], departmentPositionId: number) {
    return tenants.filter((x) => x.departmentPositions?.map((x) => x.id).includes(departmentPositionId));
  }

  static getFullNameBasedOnSorting<T extends ITenantDTO>(tenants: T[], meAsUser: MeAsUser) {
    const claimForOrder = meAsUser.user.claims?.filter((x) => x.claimType === ClaimTypeNames.TenantNameOrder);
    if (!claimForOrder) return tenants;

    for (let index = 0; index < tenants.length; index++) {
      const element = tenants[index];
      element.fullName = `${element.lastName}, ${element.firstName}`;
    }
    return tenants;
  }

  static groupByOccurrence<T extends ITenantMinDTO>(items: T[]) {
    const result = _(items)
      .groupBy((x) => x.id)
      // .map((items, id) => ({ tenant: items.find((x) => x.id.toString() == id), count: items.length }))
      .map((x, id) => {
        const tenant = items.find((y) => y.id.toString() == id);
        if (!tenant) throw Error("Missing tenant");
        return { tenant, count: x.length };
      })
      .value();

    return result;
  }
}
