const routePrefix = "/Goals";

export class GoalRoutePaths {
  public static HomePage = routePrefix;
  public static IndexPage = `${routePrefix}/Index`;
  // public static IndexDeleted = `${routePrefix}/IndexDeleted`;
  public static IndexDeleted = `${routePrefix}/Index/?isDeleted=1`;
  public static CreatePage = `${routePrefix}/Create`;
  public static EditPage = `${routePrefix}/Edit/:id`;
  public static EditPageFn = (id: number) => `${routePrefix}/Edit/${id}`;
  public static Graph = `${routePrefix}/Graph`;
  public static CommentsPage = `${routePrefix}/Comments`;
  public static NotificationsPage = `${routePrefix}/Notifications`;
}
