import { DateTime, Duration } from "luxon";

import { CostUnit, ICostDTO, ITagDTO, ITagDTOWithCount, PbdStatus } from "@/generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../utils/dateTimeLuxonHelpers";
import { filterMap } from "../../../utils/filterMap";
import { NumberHelpers } from "../../../utils/numberHelpers";
import CostService from "../../services/Costs/costService";
import { ICostSum } from "../../services/Costs/models/ICostSum";
import TagService from "../../services/Tags/tagService";

export type BaseKpiNeedsValidation = "averageProcessingTime";

export interface IBaseKpi {
  status: PbdStatus;
  tags?: ITagDTO[] | undefined;
  createdAt: DateTime;
  doneAt?: DateTime;
  deadline?: DateTime;
  costs?: ICostDTO[];
}

export interface IBaseKpiValidation {
  kpiName: BaseKpiNeedsValidation;
  displayMessage: string;
}

export class BaseKpis<T extends IBaseKpi> {
  total: T[];
  open: T[];
  inProgress: T[];
  completed: T[];
  notCompleted: T[];
  notCompletedDelayed: T[];
  tags: ITagDTOWithCount[];
  totalProcessingTimeDur: Duration;
  totalProcessingTime: number;
  totalProcessingTimeFormattedAsDays: string;
  averageProcessingTimeDur?: Duration;
  averageProcessingTime?: number;
  averageProcessingTimeFormattedAsDays?: string;
  costs: ICostDTO[];
  costSums: ICostSum[];
  costAsHours: number;
  costAsHoursFormatted: string;
  timeCostsDuration: Duration;
  totalUrl?: string;
  errorMessages: IBaseKpiValidation[];

  constructor(items: T[], totalUrl?: string) {
    this.total = items;
    this.errorMessages = [];

    this.open = items.filter((x) => x.status == PbdStatus.Open);
    this.inProgress = items.filter((x) => x.status == PbdStatus.InProgress);
    this.completed = items.filter((x) => x.status == PbdStatus.Completed);
    this.notCompleted = items.filter((x) => x.status != PbdStatus.Completed);

    this.notCompletedDelayed = items.filter(
      (x) => x.deadline != null && x.status != PbdStatus.Completed && DateTimeLuxonHelpers.inPast(x.deadline),
    );

    this.tags = TagService.groupAndSortTagArrays(items.map((x) => x.tags ?? []));
    this.totalProcessingTimeDur = items
      .filter((x) => x.status == PbdStatus.Completed && x.doneAt)
      .reduce((pv, cv) => {
        return pv.plus(cv.doneAt?.diff(cv.createdAt) ?? Duration.fromMillis(0));
      }, Duration.fromMillis(0));

    this.totalProcessingTime = this.totalProcessingTimeDur.as("milliseconds");
    this.totalProcessingTimeFormattedAsDays = NumberHelpers.convertToFractionNumber(
      this.totalProcessingTimeDur.as("days"),
    );

    const n = this.completed.length;
    if (n > 0) {
      this.averageProcessingTimeDur = Duration.fromMillis(this.totalProcessingTimeDur.as("milliseconds") / n);
      this.averageProcessingTime = this.averageProcessingTimeDur.as("milliseconds");
      this.averageProcessingTimeFormattedAsDays = NumberHelpers.convertToFractionNumber(
        this.averageProcessingTimeDur.as("days"),
      );
    } else {
      this.errorMessages.push({
        kpiName: "averageProcessingTime",
        displayMessage: "No completed items",
      });
    }

    this.costs = filterMap(items, (x) => x.costs).flatMap((c) => c);
    this.costSums = CostService.getSumOfCosts(this.costs.filter((x) => x.costUnit != CostUnit.Time));

    const timeCosts = this.costs.filter((x) => x.costUnit == CostUnit.Time);

    this.timeCostsDuration = CostService.getSumOfDurations(timeCosts);
    this.costAsHours = this.timeCostsDuration.as("hours");
    this.costAsHoursFormatted = NumberHelpers.convertToFractionNumber(this.costAsHours);
    this.totalUrl = (totalUrl ?? "").replace(/^\//g, "");
  }

  validateKpi(kpiName: BaseKpiNeedsValidation) {
    return this.errorMessages.find((x) => x.kpiName == kpiName);
  }
}
