const routePrefix = "/MaintenanceManagement";

export class MaintenanceManagementRoutePaths {
  public static HomePage = routePrefix;
  public static IndexPage = `${routePrefix}/InventoryItems/Index`;
  public static CreateInventoryItem = `${routePrefix}/InventoryItem/Create`;
  public static EditPageInventoryItem = `${routePrefix}/InventoryItem/Edit/:id`;
  public static EditPageInventoryItemFn = (id: number | string) =>
    `${routePrefix}/InventoryItem/Edit/:id`.replace(":id", id.toString());
  public static UpcomingInspection = `${routePrefix}/InventoryInspectionsConnected`;
  public static InspectionsDone = `${routePrefix}/InventoryInspectionsDone`;
  public static InspectionsDoneEdit = `${routePrefix}/InventoryInspectionsDone/Edit/:id`;
  public static CreatePageInspectionsDone = `${routePrefix}/InventoryInspectionsDone/Create`;
  public static RepairsDone = `${routePrefix}/InventoryItemRepairs`;
  public static RepairsDoneEdit = `${routePrefix}/InventoryItemRepairs/Edit/:id`;
  public static CreatePageRepair = `${routePrefix}/InventoryItemRepairs/Create`;
  public static IndexDeleted = `${routePrefix}/InventoryItems/Index/?isDeleted=1`;
  public static CostsIndex = `${routePrefix}/Costs`;

  public static Settings = `/Settings/Maintenance`;
}
