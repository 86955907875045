import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PbdModule } from "@/generatedCode/pbd-core/pbd-core-api";

import { useAppContext } from "@/components/contexts/appContext";
import { useAppNotificationHook } from "@/hooks/useAppNotificationHook";
import { ValidArticleApps } from "../../../pbdServices/services/Articles/articleService";
import FeatureFlagService from "../../../pbdServices/services/FeatureFlags/featureFlagService";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { ArticleRoutePaths } from "../articleRoutePaths";

function ArticleSidebar() {
  const { t } = useTranslation();
  const { currentApp, meAsUser, appSettings, productConfig } = useAppContext();
  const articleSettings = appSettings[currentApp as ValidArticleApps];

  const [{ appNotifications, loading }] = useAppNotificationHook(currentApp, meAsUser);

  const links: SidebarItem[] = [];
  if (!loading && appNotifications) {
    links.push(
      new SidebarItem({
        icon: qmBaseIcons.Notification,
        title: t("Notifications"),
        href: ArticleRoutePaths.NotificationsPage(currentApp),
        count: appNotifications.totalCount,
        countTitle: appNotifications.notifications.map((x) => x.title).join(),
      }),
    );
  }

  if (!articleSettings.articleFolderSettings?.isEnabled) {
    links.push(SidebarItem.created(ArticleRoutePaths.CreatePage(currentApp), t));
  }
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Newspaper,
      title: t("News"),
      href: ArticleRoutePaths.IndexPageNews(currentApp),
    }),
  );
  if (articleSettings.articleFolderSettings?.isEnabled) {
    links.push(
      new SidebarItem({
        icon: qmBaseIcons.FolderTree,
        title: t("Folder tree"),
        href: ArticleRoutePaths.IndexPageMenuItems(currentApp),
      }),
    );
  }
  links.push(SidebarItem.overview(ArticleRoutePaths.IndexPage(currentApp), t));
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Unlock,
      title: t("Unpublished"),
      href: ArticleRoutePaths.UnpublishedPage(currentApp),
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Atlas,
      title: t("Templates"),
      href: ArticleRoutePaths.IndexTemplatesPage(currentApp),
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.ChartBar,
      title: t("Reportings"),
      href: ArticleRoutePaths.ReportingsPage(currentApp),
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Clock,
      title: t("Recent"),
      href: ArticleRoutePaths.RecentPage(currentApp),
    }),
  );
  links.push(SidebarItem.deleted(ArticleRoutePaths.IndexDeletedPage(currentApp), t));

  switch (currentApp) {
    case PbdModule.Blog:
    case PbdModule.DocumentManagement:
    case PbdModule.KnowledgeBase:
      return (
        <SidebarNew app={currentApp} items={links}>
          {currentApp == PbdModule.DocumentManagement &&
            FeatureFlagService.isCarenavigatorCertificateAvailable(meAsUser, productConfig) && (
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  as={Link}
                  to={ArticleRoutePaths.CareNavigatorCertificate}
                  className="bg-body-tertiary"
                >
                  <qmBaseIcons.Certificate /> {t("Certificate")}
                </ListGroup.Item>
              </ListGroup>
            )}
        </SidebarNew>
      );
    default:
      console.error("Unknown app");
      return null;
  }
}
export default ArticleSidebar;
