import { IEmailsControllerClient, ISentEmailDTO } from "@/generatedCode/pbd-core/pbd-core-api";

import { TableClickCommand } from "../../../ClientApp/shared/components/tables/TableClickCommand";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";
import { EmailQueryParameters } from "./models/query-parameter";

export default class extends BaseExportService<ISentEmailDTO> {
  api: IEmailsControllerClient;
  constructor(api: IEmailsControllerClient) {
    super("Emails");
    this.api = api;
  }
  mapToExport(x: ISentEmailDTO): ExportType {
    return {
      to: x.to,
      subject: x.subject,
      content: x.htmlBody,
      status: x.deliveryStatus,
      sentAt: x.sentAt,
    };
  }

  async exportToCsvFromCommand(command: TableClickCommand, query?: EmailQueryParameters): Promise<void> {
    if (!command.isAllSelected && command.selected == undefined) {
      throw Error("Wrong data");
    }

    const pageSize = command.isAllSelected
      ? GlobalQmBaseConstants.DefaultPageSize_DuringMigration
      : command.selected!.length;
    const ids = !command.isAllSelected ? command.selected?.map((x) => Number(x)) : undefined;
    const resp = await this.api.getAllQuery({ ...query, id: ids, pageSize });
    this.exportToCsv(resp.data);
  }
}
