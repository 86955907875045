import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { IModuleSettingDTO, PbdModule, SettingType } from "@/generatedCode/pbd-core/pbd-core-api";

import { useTryDelete } from "../../../pbdServices/services/Api/api-hooks";
import SwrApiHelpers from "../../../pbdServices/services/Api/swr-api-helpers";
import { useAPIs } from "../../../services/serviceContext";
import { AdminRoutePaths } from "../../admin/adminRoutePaths";

function DetailsPageModuleSettings() {
  const { t } = useTranslation();
  const { moduleSettingsApi } = useAPIs();
  const { pbdModule, type } = useParams<{ pbdModule: PbdModule; type: SettingType }>();
  const navigate = useNavigate();

  const { data } = useSWR(
    `/api/moduleSettings/${pbdModule}/${type}`,
    () => moduleSettingsApi.getByModuleAndType(pbdModule, type),
    SwrApiHelpers.getDefaultGetOptions(navigate),
  );

  const tryDelete = useTryDelete<IModuleSettingDTO & { id: string }, string>(
    (dto) => moduleSettingsApi.deleteModuleSettingComplete(dto.module, dto.type),
    [moduleSettingsApi],
    () => navigate(AdminRoutePaths.IndexPageModuleSettings),
  );

  return (
    <Card className="mb-3">
      <Card.Body>
        {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
        {data && (
          <Button variant="danger" onClick={() => tryDelete({ ...data, id: data.module })}>
            {t("Delete")}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default DetailsPageModuleSettings;
