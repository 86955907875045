import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { DateTime } from "luxon";
import { CellProps, Column } from "react-table";

import { DateTimeLuxonHelpers } from "../../../../../utils/dateTimeLuxonHelpers";

interface IProps {
  lastUpdatedAt?: DateTime;
}

/**
 * This column requires a property of type tags:TagDTO
 */
export function getLastUpdatedAtColumn<T extends IProps>() {
  const column: Column<T> = {
    id: "lastUpdatedAt",
    Header: <>{i18next.t("Updated")}</>,
    // This accessor is probably required for conditional dates. TODO: Needs to be verified PP
    accessor: (x) => x.lastUpdatedAt?.toMillis(),
    //@ts-expect-error: Not included in default typing but required for align right for date and number columns
    className: "text-end",
    Cell: ({ row }: CellProps<T>) => (
      <>{row.original.lastUpdatedAt ? DateTimeLuxonHelpers.convertUtcToDateTime(row.original.lastUpdatedAt) : ""}</>
    ),
  };
  return column;
}

const columnHelper = createColumnHelper<IProps>();

export const lastUpdatedAtColumn = columnHelper.accessor("lastUpdatedAt", {
  id: "lastUpdatedAt",
  header: () => <>{i18next.t("Updated")}</>,
  cell: (x) => (x.getValue() ? DateTimeLuxonHelpers.convertUtcToDateTime(x.getValue()) : ""),
  meta: { className: "text-end" },
});
