import React from "react";

import { FilterElement } from "./availableSearchFilters";

export function useSelectedFilterHook(availableFilters: FilterElement[], defaultValue?: Record<string, unknown>) {
  const [selectedFilters, setSelectedFilters] = React.useState<FilterElement[]>();

  React.useEffect(() => {
    if (!selectedFilters && defaultValue) {
      const newFilter: FilterElement[] = [];
      Object.keys(defaultValue).forEach((x, i) => {
        const filterFound = availableFilters.find((f) => f.queryKey == x);
        if (filterFound && defaultValue[x]) {
          filterFound.queryValue = defaultValue[x];
          newFilter.push(filterFound);
        }
      });
      setSelectedFilters(newFilter);
    } else if (!selectedFilters && !defaultValue) {
      setSelectedFilters([]);
    }
  }, [availableFilters, defaultValue]);

  return [selectedFilters, setSelectedFilters] as const;
}
