import React from "react";
import { useTranslation } from "react-i18next";

import { ValidationProblemDetails } from "../../../../services/restClients/validationProblemDetails";

interface IProps {
  errorContent: ValidationProblemDetails;
}

const ErrorMapperDiv: React.FC<IProps> = (props) => {
  const { errorContent } = props;
  const { t } = useTranslation();
  return (
    <div>
      <hr />
      <h6>{t("Further details")}</h6>
      <dl>
        {errorContent.errors &&
          Object.keys(errorContent.errors).map((key, i) => {
            return (
              <React.Fragment key={i}>
                {key && <dt>{key}</dt>}
                <dd>
                  {errorContent.errors[key].map((d, index) => (
                    <div key={index}>{d}</div>
                  ))}
                </dd>
              </React.Fragment>
            );
          })}
      </dl>
    </div>
  );
};

export default ErrorMapperDiv;
