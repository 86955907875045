const routePrefix = "/Opportunities";

export class OpportunityRoutePaths {
  public static HomePage = routePrefix;
  public static IndexPage = `${routePrefix}/Index`;
  public static IndexDeleted = `${routePrefix}/Index/?isDeleted=1`;
  public static CreatePage = `${routePrefix}/Create`;
  public static EditPage = `${routePrefix}/Edit/:id`;
  public static EvaluationsPage = `${routePrefix}/Evaluations/:id`;
  public static IndexPageEvaluations = `${routePrefix}/Evaluations/Index`;
  public static CreatePageEvaluations = `${routePrefix}/Evaluations/Create`;
  public static EditPageEvaluations = `${routePrefix}/Evaluations/Edit/:id`;
  public static EditPageEvaluationsFn = (id: number) =>
    `${routePrefix}/Evaluations/Edit/:id`.replace(":id", id.toString());
}
