import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { HttpVerbs, ICategoryDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../services/serviceContext";

import { useAppContext } from "@/components/contexts/appContext";
import { useConfirmation } from "@/components/contexts/modalConfirmationContext";
import { useRecycleBinHook } from "@/hooks/useRecycleBinHook";
import { ErrorMessage } from "../../../../Models/Errors/ErrorMessage";
import { SettingsIndexDTO } from "../../../../Models/Settings/SettingsIndexDTO";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import SwrApiHelpers from "../../../../pbdServices/services/Api/swr-api-helpers";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import { TableClickCommand } from "../../../shared/components/tables/TableClickCommand";
import BaseSettingsIndexCard from "../../components/baseSettingsIndexCard";
import { BaseSettingsTable } from "../../components/baseSettingsTable";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function mapDataToSettingsDTO(data?: ICategoryDTO[]) {
  if (!data) return undefined;
  const tableData: SettingsIndexDTO[] = [];
  data.forEach((d) => {
    const item = new SettingsIndexDTO({
      id: d.id,
      title: d.title,
      createdAt: d.createdAt,
      color: d.color,
      links: [
        {
          rel: "self",
          href: SettingsRoutePaths.EditPageDefectCategories.replace(":id", d.id.toString()),
          method: HttpVerbs.GET,
        },
      ],
    });
    tableData.push(item);
  });
  return tableData;
}

const IndexPageDefectCategories: React.FC = () => {
  const navigate = useNavigate();
  const { defectCategoriesApi } = useAPIs();
  const { showRecycleBin, toggleRecycleBin } = useRecycleBinHook();
  const { meAsUser, setErrorMessage } = useAppContext();
  const [selected, setSelected] = React.useState<SettingsIndexDTO[]>([]);
  const confirm = useConfirmation();
  const {
    data,
    isValidating: loading,
    mutate,
  } = useSWR(
    [`/api/defectCategories`, showRecycleBin],
    () => defectCategoriesApi.getAllQuery({ isDeleted: showRecycleBin }),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  const handleClick = (command: TableClickCommand) => {
    if (command.action == "Delete") {
      if (selected) {
        confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
      }
    }
  };

  const submitDeleteRequest = async (items: number[]) => {
    if (!hasRole(meAsUser, [PbdRoles.Admin])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => defectCategoriesApi.delete(Number(s)));
      }
      mutate();
    }
  };

  const mappedData = React.useMemo(() => mapDataToSettingsDTO(data), [data]);

  return (
    <React.Fragment>
      <BaseSettingsIndexCard
        data={data}
        cardTitle="Defect categories"
        pathToCreate={SettingsRoutePaths.CreatePageDefectCategories}
        loading={loading}
        showRecycleBin={showRecycleBin}
        toggleRecycleBin={toggleRecycleBin}
      >
        <BaseSettingsTable
          tableRows={mappedData}
          onCreateClick={() => navigate(SettingsRoutePaths.CreatePageDefectCategories)}
          setSelected={(ids) => setSelected(ids.map((x) => x))}
          availableTableActions={["Delete"]}
          onClick={handleClick}
        />
      </BaseSettingsIndexCard>
    </React.Fragment>
  );
};

export default IndexPageDefectCategories;
