import React from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { ExternalLoginConfirmationDTO, IExternalLoginConfirmationDTO } from "@/generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../services/serviceContext";

import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import { ApiError } from "../../../pbdServices/services/Api/models/api-error";
import GenericAlert from "../../shared/components/alerts/genericAlert";
import { AccountRoutePaths } from "../accountRoutePaths";

const ExternalLoginCallbackPage = () => {
  const { t } = useTranslation();
  const { accountApi } = useAPIs();
  const { data, error: externalLoginInfoError } = useSWR("/api/account/externalLoginConfirmation", () =>
    accountApi.getExternalLoginConfirmationInfo(),
  );
  const [error, setError] = React.useState<ApiError>();

  const handleSubmit = async (dto: IExternalLoginConfirmationDTO) => {
    const resp = await wrapApiCallWithToast(() =>
      accountApi.externalLoginConfirmation(new ExternalLoginConfirmationDTO(dto)),
    );
    if (resp.isOk) {
      window.location.href = AccountRoutePaths.ActivateProfilePage;
    } else if (resp.isErr) {
      setError(resp.error);
    }
  };

  return (
    <Card className="accountCard">
      <Card.Body>
        <h1 className="text-center">{t("Login")}</h1>
        {error && (
          <GenericAlert type="danger">
            {t(error.name)} {error.message}
          </GenericAlert>
        )}
        {!data?.canConnectLogin && (
          <GenericAlert type="info">
            {t(
              "Accounts can not be created in self service. Please contact your administrator for further information.",
            )}
          </GenericAlert>
        )}
        <hr />
        {data?.canConnectLogin && (
          <>
            <h5>{t("Data from external login provider")}</h5>
            <dl>
              <dt>{t("Email")}</dt>
              <dd>{data.email}</dd>
              <dt>{t("First name")}</dt>
              <dd>{data.firstName}</dd>
              <dt>{t("Last name")}</dt>
              <dd>{data.lastName}</dd>
            </dl>
            <div className="d-grid gap-2 mb-3">
              <Button variant="primary" onClick={() => handleSubmit(data)}>
                {data.isUserExisting ? t("Connect login") : t("Create account")}
              </Button>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};
export default ExternalLoginCallbackPage;
