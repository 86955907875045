import { jwtDecode } from "jwt-decode";
import { DateTime } from "luxon";

export default class JsonWebTokenHandler {
  static getExpirationDate(token: string) {
    const decodedTokenPayload = jwtDecode(token);
    if (decodedTokenPayload.exp) {
      return DateTime.fromMillis(decodedTokenPayload.exp * 1000);
    }
    return undefined;
  }
}
