import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useSWR from "swr";

import { useAPIServices, useAPIs } from "../../../services/serviceContext";

import { useAppContext } from "@/components/contexts/appContext";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { DateTimeLuxonHelpers } from "../../../utils/dateTimeLuxonHelpers";
import EightDReportDetailsCard from "../../eightDReports/components/eightDReportDetailsCard";
import IdComponent from "../../shared/components/id/idComponent";
import AvatarSpanWithName from "../../shared/components/tenants/avatarSpanWithName";
import PrintoutIncludeOptions, { PrintOptions } from "../components/printoutIncludeOptions";

function PrintEightDReportPage() {
  const { t } = useTranslation();
  const { appSettings } = useAppContext();
  const { eightDReportsApi } = useAPIs();
  const { moduleSettingsService, claimService } = useAPIServices();
  const { id } = useParams<{ id: string }>();
  const [options, setOptions] = useLocalStorage<PrintOptions>("printOptions", new PrintOptions());

  const { data: item, mutate: handleRefresh } = useSWR(`/api/eightDReports/${id}`, () =>
    eightDReportsApi.getById(Number(id)),
  );

  const { data: connectedElements } = useSWR(item ? `/api/claims/${item.claimId}` : null, () =>
    item?.claimId ? claimService.getConnectedElements(item.claimId) : undefined,
  );

  const handleOptionsChange = (value: PrintOptions) => setOptions(value);

  return (
    <>
      <PrintoutIncludeOptions
        availableOptions={["includeImages", "showOnlyCompletedSections"]}
        onChange={handleOptionsChange}
        initialOptions={options}
      />
      {item && connectedElements && (
        <>
          <h2>
            {t("8D-Report")} {item.claim?.title} <IdComponent id={item.id} />
          </h2>
          <hr />

          <EightDReportDetailsCard
            report={item}
            refreshParent={handleRefresh}
            customFields={appSettings.ClaimManagement.customFields}
            customFieldsTodo={appSettings.ToDoManagement.customFields ?? []}
            printOptions={options}
            {...connectedElements}
          />

          {item.isDone && item.doneBy && item.doneAt && (
            <Card className="mb-3">
              <Card.Body>
                <h5>{t("Done by")}</h5>
                <AvatarSpanWithName tenant={item.doneBy} />
                {DateTimeLuxonHelpers.convertUtcToDateTime(item.doneAt)}
              </Card.Body>
            </Card>
          )}
          <div className="pagebreakBulk"> </div>
        </>
      )}
    </>
  );
}

export default PrintEightDReportPage;
