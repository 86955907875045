import { Modal } from "react-bootstrap";

import EnhancedSubmitButton from "../../buttons/enhancedSubmitButton";
import { ConnectModalProps } from "../connectModalProps";

interface IProps extends Omit<ConnectModalProps<unknown>, "onSubmit" | "refreshParent"> {
  submitting: boolean;
  selected: unknown[];
  onSubmitClick: () => void;
  showFooter?: boolean;
}

function ConnectionModal(props: IProps) {
  const { modal, toggle, modalTitle, onSubmitClick, submitting, canSubmit, selected, showFooter = true } = props;
  return (
    <Modal show={modal} onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      {showFooter && (
        <Modal.Footer>
          <EnhancedSubmitButton
            onClick={onSubmitClick}
            submitting={submitting}
            disabled={selected.length == 0}
            canSubmit={canSubmit}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ConnectionModal;
