import JsonHelpers from "../Json/jsonHelpers";

export type SettingType = Record<string, unknown> | string[] | string | boolean;

export interface IBaseSetting<TSettingType, TKey> {
  key: TKey;
  parse(s?: string): TSettingType;
  stringify(data: TSettingType): string;
}

export class JsonSetting<T, TKey> implements IBaseSetting<T, TKey> {
  key: TKey;
  defaultValue?: T;

  constructor(key: TKey, defaultValue?: T) {
    this.key = key;
    this.defaultValue = defaultValue;
  }
  parse(s?: string) {
    if (s === undefined) {
      if (this.defaultValue === undefined) throw new Error("Unknown key");

      return this.defaultValue;
    }

    return JsonHelpers.parseToCamelCase<T>(s);
  }
  stringify(data: T) {
    return JsonHelpers.stringify(data);
  }
}

export class StringArraySetting<TKey> implements IBaseSetting<string[], TKey> {
  key: TKey;
  constructor(key: TKey) {
    this.key = key;
  }
  parse(s?: string) {
    return s?.split(",") ?? [];
  }
  stringify(data: string[]) {
    return data.join(",");
  }
}

const truePattern = /^true$/i;

export class BooleanSetting<TKey> implements IBaseSetting<boolean, TKey> {
  key: TKey;
  constructor(key: TKey) {
    this.key = key;
  }
  parse(s?: string) {
    return s !== undefined && truePattern.test(s);
  }
  stringify(data: boolean) {
    return data ? "True" : "False";
  }
}

export abstract class BaseSettingsService<TKey> {
  abstract getRawSettingValue(key: TKey): Promise<string | undefined>;
  abstract setSettingRawValue(key: TKey, value: string): Promise<void>;

  getSetting<TResult, T extends IBaseSetting<TResult, TKey>>(setting: T): Promise<TResult> {
    return this.getRawSettingValue(setting.key).then((val) => setting.parse(val));
  }

  setSetting<TValue, T extends IBaseSetting<TValue, TKey>>(setting: T, value: TValue) {
    this.setSettingRawValue(setting.key, setting.stringify(value));
  }
}
